
import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import { XYChart, CategoryAxis, ValueAxis, ColumnSeries, XYCursor, LabelBullet} from '@amcharts/amcharts4/charts';


const Histograph: React.FC = () => {
  useEffect(() => {
    // Themes
    am4core.useTheme(am4themes_animated);

    // Create chart instance
let chart = am4core.create('chartdiv', am4charts.XYChart);
    // Add data
    const data = [
      { category: 'Account Transfer', AG: 100, CA: 200, GB: 11326317.37, JM: 180, KY: 612968.1858, TC: 3219.67, US: 21063208.97},
      { category: 'Bill Transfer', AG: 150, CA: 253642.0882, GB: 643634.5871, JM: 190, KY: 15253.9639, TC: 180, US: 464068.98},
      { category: 'Global Acc Transfer', AG: 200, CA: 220, GB: 250, JM: 230, KY: 180, TC: 200, US: 1790826.09},
      { category: 'Jn 3 Day Acc Transfer', AG: 120, CA: 140, GB: 462606.4203, JM: 150, KY: 110, TC: 180, US: 200 },
      { category: 'Mobile TopUp', AG: 180, CA: 200, GB: 373.5221, JM: 210, KY: 160, TC: 190, US: 34 },
      { category: 'Money Gram P2P', AG: 250, CA: 270, GB: 300, JM: 280, KY: 240.9492, TC: 250, US: 320 },
      { category: 'Mt Card Transfer', AG: 300, CA: 5421799.611, GB: 12878657.02, JM: 1190.6101, KY: 1054426.452, TC: 300, US: 16714625.57},
      { category: 'Person-Person Transfer', AG: 18793.83, CA: 4305570.419, GB: 13772717.78, JM: 88907.2476, KY: 1118159.758, TC: 5550.15, US: 17049305.6},
      { category: 'Third-Party Transfer', AG: 220, CA: 1369689.632, GB: 1097839.575, JM: 250, KY: 672326.3725, TC: 220, US: 1187717.97},
    ];

    // Disable the AmCharts logo
chart.logo.disabled = true;
    
    chart.data=data;
    // Find the largest number in the data
// Find the largest number in the data
let max = Math.max(
  ...data.map(item => Math.max(...Object.values(item)
      .filter(val => typeof val === 'number')
      .map(val => Number(val)) // Explicitly convert to number
    ))
);



    // Create category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = -35;
    categoryAxis.renderer.labels.template.horizontalCenter = 'right';
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.renderer.grid.template.disabled = false;
    categoryAxis.title.text = 'Transfer Type';
    

    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = false; // Disable grid lines for y-axis
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.strictMinMax = true;
    valueAxis.min = 10;
    valueAxis.max = max*10;
    valueAxis.logarithmic = true;
    valueAxis.numberFormatter.numberFormat = '$#,###';
    valueAxis.title.text = 'Total Amount($)';

    // Create series for each country
    let countries = ['AG', 'CA', 'GB', 'JM', 'KY', 'TC', 'US'];

    for (let country of countries) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = country;
      series.dataFields.categoryX = 'category';
      series.name = country;
      series.stacked = true;
      series.columns.template.tooltipText = '{name}: {valueY.value}';
    }

    

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position='top';

    return () => {
      chart.dispose(); // Cleanup on unmount
    };
  }, []);
  

  return <div id="chartdiv" style={{ width: '100%', height: '550px' }}></div>;
};

export default Histograph;





// import React, { useEffect, useState } from 'react';
// import * as am4core from '@amcharts/amcharts4/core';
// import * as am4charts from '@amcharts/amcharts4/charts';
// import am4themes_animated from '@amcharts/amcharts4/themes/animated';
// import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
// import { XYChart, CategoryAxis, ValueAxis, ColumnSeries, XYCursor, LabelBullet} from '@amcharts/amcharts4/charts';


// const Histograph: React.FC = () => {
//   const [chart, setChart] = useState<XYChart | null>(null);

//   useEffect(() => {
//     // Themes
//     am4core.useTheme(am4themes_animated);

//     // Create chart instance
// let chart = am4core.create('chartdiv', am4charts.XYChart);
// setChart(chart);


//     // Add data
//     const data = [
//       { category: 'Account Transfer', AG: 100, CA: 200, GB: 11326317.37, JM: 180, KY: 612968.1858, TC: 3219.67, US: 21063208.97},
//       { category: 'Bill Transfer', AG: 150, CA: 253642.0882, GB: 643634.5871, JM: 190, KY: 15253.9639, TC: 180, US: 464068.98},
//       { category: 'Global Acc Transfer', AG: 200, CA: 220, GB: 250, JM: 230, KY: 180, TC: 200, US: 1790826.09},
//       { category: 'Jn 3 Day Acc Transfer', AG: 120, CA: 140, GB: 462606.4203, JM: 150, KY: 110, TC: 180, US: 200 },
//       { category: 'Mobile TopUp', AG: 180, CA: 200, GB: 373.5221, JM: 210, KY: 160, TC: 190, US: 34 },
//       { category: 'Money Gram P2P', AG: 250, CA: 270, GB: 300, JM: 280, KY: 240.9492, TC: 250, US: 320 },
//       { category: 'Mt Card Transfer', AG: 300, CA: 5421799.611, GB: 12878657.02, JM: 1190.6101, KY: 1054426.452, TC: 300, US: 16714625.57},
//       { category: 'Person-Person Transfer', AG: 18793.83, CA: 4305570.419, GB: 13772717.78, JM: 88907.2476, KY: 1118159.758, TC: 5550.15, US: 17049305.6},
//       { category: 'Third-Party Transfer', AG: 220, CA: 1369689.632, GB: 1097839.575, JM: 250, KY: 672326.3725, TC: 220, US: 1187717.97},
//     ];

//     // Disable the AmCharts logo
// chart.logo.disabled = true;
    
//     chart.data=data;
//     // Find the largest number in the data
// // Find the largest number in the data
// let max = Math.max(
//   ...data.map(item => Math.max(...Object.values(item)
//       .filter(val => typeof val === 'number')
//       .map(val => Number(val)) // Explicitly convert to number
//     ))
// );



//     // Create category axis
//     let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
//     categoryAxis.dataFields.category = 'category';
//     categoryAxis.renderer.grid.template.location = 0;
//     categoryAxis.renderer.minGridDistance = 30;
//     categoryAxis.renderer.labels.template.rotation = -45;
//     categoryAxis.renderer.labels.template.horizontalCenter = 'right';
//     categoryAxis.renderer.labels.template.verticalCenter = 'middle';
//     categoryAxis.renderer.grid.template.disabled = false;
//     categoryAxis.title.text = 'Transfer Type';
    

//     // Create value axis
//     let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
//     valueAxis.renderer.grid.template.disabled = false; // Disable grid lines for y-axis
//     valueAxis.renderer.minGridDistance = 30;
//     valueAxis.strictMinMax = true;
//     valueAxis.min = 10;
//     valueAxis.max = max*10;
//     valueAxis.logarithmic = true;
//     valueAxis.numberFormatter.numberFormat = '$#,###';
//     valueAxis.title.text = 'Total Amount($)';

//     // Create series for each country
//     let countries = ['AG', 'CA', 'GB', 'JM', 'KY', 'TC', 'US'];

//     for (let country of countries) {
//       let series = chart.series.push(new am4charts.ColumnSeries());
//       series.dataFields.valueY = country;
//       series.dataFields.categoryX = 'category';
//       series.name = country;
//       series.stacked = false;
//       series.columns.template.tooltipText = '{name}: ${valueY.value}';
//     }

    

//     // Add legend
//     chart.legend = new am4charts.Legend();
//     chart.legend.position='top';

//     return () => {
//       chart.dispose(); // Cleanup on unmount
//     };
//   }, []);
//    // Function to toggle series visibility
//    const toggleSeries = (series: am4charts.Series) => {
//     series.hidden = !series.hidden;
//     chart?.invalidateData(); // Use optional chaining (?.) to safely access chart and its method
//   };
  

//   return  (
//     <div>
//       <div id="chartdiv" style={{ width: '100%', height: '450px' }}></div>
//       {/* Add buttons or checkboxes to toggle series visibility */}
//       <button onClick={() => toggleSeries(chart.series.getIndex(0))}>Toggle AG</button>
//       <button onClick={() => toggleSeries(chart.series.getIndex(1))}>Toggle CA</button>
//       {/* Add more buttons or checkboxes for other countries */}
//     </div>
//   );
// };

// export default Histograph;




