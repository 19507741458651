import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import DataEnrichmentModal from '../../../process/finance/banking/JNM/components/DataEnrichmentModal'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import CustomerDataRuleEnrichModal from '../../../process/finance/banking/JNM/components/CustomerDataRuleEnrichModal'

const DataEnrichmentReport: React.FC<any> = (props) => {
  const [enrichmentmodalOpen] = useState(false)
  const [modalOpen, setModal] = useState(false)
  const [heading, setHeading] = useState('')
  const [ruleid, setRuleid] = useState(0)
  const [affectedValue, setAffectedValue] = useState(0) // State for affected value

  const navigate = useNavigate()

  const onModalClose = () => {
    setModal(false)
  }

  const viewEnrichedDataByRule = (id, rule, affected) => {
    setRuleid(id)
    setHeading(rule)
    setAffectedValue(affected) // Update affected value state

    let apiData = {
      ruleId: id,
      ruleDesc: rule,
      rows: 10,
      page: 1,
    }

    props.fetchEnrichedCustData(apiData, Math.ceil(affected / 10))

    const modalId = 'kt_modal_enrich_report' // Replace with your actual modal ID
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)

    if (modalTrigger) {
      ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
    }
  }

  const Data_Enrichment_Breadcrumbs: Array<PageLink> = [
    {
      title: 'Finance - Banking Business Process - Data Profiling Summary',
      path: '/process/finance/banking/data-enrichment',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const handleDownloadPDF = () => {
    const table = document.getElementById('EnrichReportData')

    if (!table) {
      console.error('Report Id not found.')
      return
    }

    html2canvas(table).then((canvas) => {
      const pdf = new jsPDF('p', 'mm', 'a4')
      const imgData = canvas.toDataURL('image/png')
      const pdfWidth = 210
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight)
      pdf.save('EnrichReportData.pdf')
    })
  }

  return (
    <>
      <PageTitle breadcrumbs={Data_Enrichment_Breadcrumbs}>Data Enrichment Report</PageTitle>

      <div className='card' id='EnrichReportData'>
        <div className='card-body py-0 pt-0'>
          <div className='card-header mb-6 px-0'>
            <div className='card-title'>
              <h2>Data Enrichment Report</h2>
            </div>
            <div className='card-toolbar m-0'>
              <button
                type='button'
                className='btn btn-sm btn-light-primary'
                onClick={handleDownloadPDF}
              >
                <i className='ki-duotone ki-cloud-download fs-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                Download Report
              </button>
            </div>
          </div>

          <div className='notice d-flex bg-light-success rounded border-success border border-dashed mb-2 p-6'>
            <i className='ki-duotone ki-double-check fs-2tx text-success me-4'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-semibold'>
                <h4 className='text-gray-900 fw-bold'>Data Enrichment Completed Successfully</h4>
                <div className='fs-6 text-gray-700'>
                  Your selected data enrichment rules have been successfully applied to the dataset.
                  The necessary updates have been made to ensure data accuracy and consistency.
                  <strong> Here's a summary of the changes:</strong>
                </div>
              </div>
            </div>
          </div>

          {/* <div className='row g-5 g-xl-8' id='data'>
            <div className='col-xl-6'>
              <DataProfilingReportList className='card-xl-stretch mb-xl-8' {...props} />
            </div> 
             <div className='col-xl-6'>
              <DataEnrichmentReportList className='card-xl-stretch mb-xl-8' {...props} />
            </div>
          </div> */}

          <div className='card-body'>
            <div className='row mb-2'>
              {props.selectedEnrichmentFieldData &&
                props.selectedEnrichmentFieldData.selectedEnrichmentRule &&
                props.selectedEnrichmentFieldData.selectedEnrichmentRule.map(
                  (r) =>
                    r.affected && (
                      <div className='col-md-6 pb-10 pb-lg-0'>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <h2>{r.name ? r.name : ''}</h2>
                          <span
                            className='btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2 me-4'
                            onClick={() => viewEnrichedDataByRule(r.Id, r.desc, r.affected)}
                          >
                            <i className='ki-duotone ki-message-text-2 fs-3'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                              <span className='path3'></span>
                            </i>
                            {r.affected ? r.affected : ''}
                          </span>
                        </div>

                        <p className='fs-6 fw-semibold text-gray-600 py-2'>
                          {r.desc ? r.desc : ''}
                        </p>
                        <div className='card-body pt-0'>
                          <div className='table-responsive'>
                            <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px'>
                              <tbody className='fw-semibold text-gray-600'>
                                <tr>
                                  <td className='text-muted'>
                                    <div className='d-flex align-items-center'>
                                      <i className='ki-duotone ki-devices fs-2 me-2'>
                                        <span className='path1'></span>
                                        <span className='path2'></span>
                                        <span className='path3'></span>
                                        <span className='path4'></span>
                                        <span className='path5'></span>
                                      </i>
                                      Total number of records processed
                                    </div>
                                  </td>
                                  <td className='fw-bold text-end'>
                                    <a
                                      href='/metronic8/demo1/apps/invoices/view/invoice-3.html'
                                      className='text-gray-600 text-hover-primary'
                                    >
                                      {props.selectedEnrichmentFieldData &&
                                      props.selectedEnrichmentFieldData.fieldData &&
                                      props.selectedEnrichmentFieldData.fieldData.totalNoOfRows
                                        ? props.selectedEnrichmentFieldData.fieldData.totalNoOfRows
                                        : ''}
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='text-muted'>
                                    <div className='d-flex align-items-center'>
                                      <i className='ki-duotone ki-devices fs-2 me-2'>
                                        <span className='path1'></span>
                                        <span className='path2'></span>
                                        <span className='path3'></span>
                                        <span className='path4'></span>
                                        <span className='path5'></span>
                                      </i>
                                      Records affected by enrichment process
                                    </div>
                                  </td>
                                  <td className='fw-bold text-end'>
                                    <a
                                      href='/metronic8/demo1/apps/invoices/view/invoice-3.html'
                                      className='text-gray-600 text-hover-primary'
                                    >
                                      {r.affected ? r.affected : ''}
                                    </a>
                                  </td>
                                </tr>
                                {/* <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-devices fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                                <span className='path5'></span>
                              </i>
                              Number of records unchanged
                            </div>
                          </td>
                          <td className='fw-bold text-end'>
                            <a
                              href='/metronic8/demo1/apps/invoices/view/invoice-3.html'
                              className='text-gray-600 text-hover-primary'
                            >
                              236
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className='text-muted'>
                            <div className='d-flex align-items-center'>
                              <i className='ki-duotone ki-devices fs-2 me-2'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                                <span className='path5'></span>
                              </i>
                              Number of records successfully enriched
                            </div>
                          </td>
                          <td className='fw-bold text-end'>
                            <a
                              href='/metronic8/demo1/apps/invoices/view/invoice-3.html'
                              className='text-gray-600 text-hover-primary'
                            >
                              6,440
                            </a>
                          </td>
                        </tr> */}
                                <tr>
                                  <td className='text-muted'>
                                    <div className='d-flex align-items-center'>
                                      <i className='ki-duotone ki-discount fs-2 me-2'>
                                        <span className='path1'></span>
                                        <span className='path2'></span>
                                      </i>
                                      Enrichment Percentage
                                      <span
                                        className='ms-1'
                                        data-bs-toggle='tooltip'
                                        aria-label='Reward value earned by customer when purchasing this order'
                                        data-bs-original-title='Reward value earned by customer when purchasing this order'
                                        data-kt-initialized='1'
                                      >
                                        <i className='ki-duotone ki-information-5 text-gray-500 fs-6'>
                                          <span className='path1'></span>
                                          <span className='path2'></span>
                                          <span className='path3'></span>
                                        </i>
                                      </span>
                                    </div>
                                  </td>
                                  <td className='fw-bold text-end'>
                                    {r.percentage ? r.percentage : ''}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>

            <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed p-3'>
              <i className='ki-duotone ki-design-1 fs-5tx text-primary'></i>
              <div className='d-flex flex-stack flex-grow-1'>
                <div className='fw-semibold'>
                  <div className='fs-6 text-gray-700'>
                    Thank you for ensuring data accuracy and integrity with our enrichment process.
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end align-items-center mt-8'>
              <button
                type='button'
                className='btn btn-sm btn-light-primary'
                id='kt_enrichment_rules_submition_cancel'
                // onClick={() => navigate('/process/bpms/finance/remittance/enrichment')}
                onClick={() => navigate('/process/bpms/finance/remittance/profiling/report')}
              >
                <i className='ki-duotone ki-arrow-left fs-2 ms-1 me-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                Go to Report
              </button>
              {/* <button
                type='button'
                className='btn btn-sm btn-primary'
                id='kt_enrichment_rules_submition'
                onClick={() => {}}
              >
                Data Insight
                <i className='ki-duotone ki-arrow-right fs-2 ms-1 me-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <DataEnrichmentModal />
      <CustomerDataRuleEnrichModal
        heading={heading}
        affected={affectedValue}
        props={props}
        ruleId={ruleid}
      />
    </>
  )
}

export default DataEnrichmentReport
