import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const ChartjsComponent: React.FC = () => {
  useEffect(() => {
    am4core.useTheme(am4themes_animated);
    // Create chart instance
    let chart = am4core.create('chartdivvv', am4charts.XYChart);
       // Disable the AmCharts logo
chart.logo.disabled = true;

    // Add data
    const data = [
      {
        country: 'AG',
        male: 8,
        female: 0,
        unknown: 0,
      },
      {
        country: 'CA',
        male: 28089,
        female: 31920,
        unknown: 838,
      },
      {
        country: 'GB',
        male: 88832,
        female: 88098,
        unknown: 3310,
      },
      {
        country: 'JM',
        male: 26,
        female: 29,
        unknown: 0,
      },
      {
        country: 'KY',
        male: 4284,
        female: 4114,
        unknown: 0,
      },
      {
        country: 'TC',
        male: 8,
        female: 24,
        unknown: 0,
      },
      {
        country: 'US',
        male: 103794,
        female: 113027,
        unknown: 6287,
      },
    ];
    chart.data = data;

    // Find the maximum value
    let max = Math.max(
      ...data.map(item => Math.max(item.male, item.female, item.unknown))
    );

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'country';
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.title.text = 'Number of Customers';
    categoryAxis.renderer.cellStartLocation = 0.1; // Start the first cell a bit earlier
    categoryAxis.renderer.cellEndLocation = 0.8; // End the last cell a bit later
    
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Gender';
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.strictMinMax = true;
    valueAxis.min = 1; 
    valueAxis.max = max * 10;
    valueAxis.logarithmic = true;

    // Adjust grid zIndex
    valueAxis.renderer.gridContainer.zIndex = -1;

    // Create series
    function createSeries(field: string, name: string, color: string) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'country';
      series.name = name;
      series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
      series.columns.template.fill = am4core.color(color); // Set custom color
      series.columns.template.fillOpacity = 8;
      series.columns.template.stroke = am4core.color(color); // Set border color same as fill color

      return series;
    }

    // Usage with custom colors
    createSeries('male', 'Male', '#6771dc');
    createSeries('female', 'Female', '#a367dc');
    createSeries('unknown', 'Unknown', '#dc6967');

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';

    // Dispose chart when unmounting
    return () => {
      if (chart) {
        chart.dispose();
      }
    };
  }, []);

  return <div id='chartdivvv' style={{ width: '100%', height: '450px' }}></div>;
};

export default ChartjsComponent;


// import React, { useEffect } from 'react';
// import Chart, { ChartConfiguration, ChartTypeRegistry } from 'chart.js/auto';

// const ChartjsComponent: React.FC = () => {
//   useEffect(() => {
//     // Chart container reference
//     const ctx = document.getElementById('kt_chartjs_1') as HTMLCanvasElement;

//     // Chart labels
//     const labels = ['AG', 'CA', 'GB', 'JM', 'KY', 'TC', 'US'];

//     // Chart data
//     const data = {
//       labels: labels,
//       datasets: [
//         {
//           label: 'Male',
//           data: [8, 28089, 88832, 26, 4284, 8, 103794], // Example data for the bars
//           backgroundColor: '#6771dc', // Dark blue
//           borderColor: '#6771dc',
//           borderWidth: 1,
//         },
//         {
//           label: 'Female',
//           data: [0, 31920, 88098, 29, 4114, 24, 113027], // Example data for the bars
//           backgroundColor: '#a367dc', // Dark purple
//           borderColor: '#a367dc',
//           borderWidth: 1,
//         },
//         {
//           label: 'Unknown',
//           data: [0, 838, 3310, 0, 0, 0, 6287], // Example data for the bars
//           backgroundColor: '#dc6967', // Dark violet
//           borderColor: '#dc6967',
//           borderWidth: 1,
//         }
//       ]
//     };

//     // Chart config
//     const config: ChartConfiguration<keyof ChartTypeRegistry, number[], string> = {
//       type: 'bar',
//       data: data,
//       options: {
//         responsive: true,
//         interaction: {
//           intersect: false,
//         },
//         scales: {
//           x: {
//             stacked: false, // Change to false for side-by-side bars
//             animate: true // Animate scale changes
//           },
//           y: {
//             stacked: false, // Change to false for side-by-side bars
//             animate: true, // Animate scale changes
//             min: 100 // Set the minimum value of the y-axis to 100

//           }
//         }
//       }
//     };

//     // Initialize ChartJS
//     if (ctx) {
//       new Chart(ctx, config);
//     }

//   }, []); // Empty dependency array to run this effect only once

//   return (
//     <canvas id="kt_chartjs_1" width="100%" height="43"></canvas>
//   );
// };

// export default ChartjsComponent;
