import Histograph from './components/Histograph'
import React from 'react'

const TransactionByCountryChart: React.FC = () => {
  return (
    <>
      <div className='row gx-5 gx-xl-10'>
          <div className='card card-flush h-xl-100'>
            <div className='card-header pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-900'>Transfer Types by Send Countries</span>
              </h3>
            </div>
            <Histograph />
          </div>
        </div>
        {/* <div className='row gx-5 gx-xl-10'>
          <div className='card card-flush h-xl-100'>
            <div className='card-header pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-900'>Transfer Types by Send Countries</span>
              </h3>
            </div>
            <Circular />
          </div>
        </div> */}
    </>
  )
}

export default TransactionByCountryChart
