type Props = {
  img: string
}

// const CardsWidgetDQ = ({img}: Props) => (
//   <div className='card-flush'>
//     <div className='card-body'>
//       <img src={img} alt='Data Quality' className='w-100' />
//     </div>
//   </div>
// )

const CardsWidgetDQ = ({img}: Props) => (
  // <div className='card-flush' style={{ width: '100%', height: '320px' }}>
  <div className='card-flush' >
     <img src={img} alt='Data Quality' style={{ height: '330px', width: '100%' }} />
  </div>
)
export {CardsWidgetDQ}
