
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
// import {useListView} from '../../../app/modules/apps/user-management/users-list/core/ListViewProvider'

type Props = {
  className: string
  id: string
}

const TablesRuleEngine: React.FC<Props> = ({className,id }) => {
//   const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    // setItemIdForUpdate(null)
  }

  return (
    <div id= {id} className={`card ${className} `} >
      
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Businees Rules List</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>last months 100 new rules added</span>
        </h3>
        <div className='card-toolbar'>
          
          <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          
            <button type='button' className='btn btn-light-primary me-3'>
              <KTIcon iconName='exit-up' className='fs-2' />
              Export
            </button>
       

         
            <button type='button' className='btn btn-primary' onClick={openAddUserModal}>
              <KTIcon iconName='plus' className='fs-2' />
              Add Rule
            </button>
          
          </div>
          
          <div
            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px'
            data-kt-menu='true'
          >
           
            <div className='menu-item px-3'>
              <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>Quick Actions</div>
            </div>
          
           
            <div className='separator mb-3 opacity-75'></div>
           
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-3'>
                New Ticket
              </a>
            </div>
          
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-3'>
                New Customer
              </a>
            </div>
          
            <div
              className='menu-item px-3'
              data-kt-menu-trigger='hover'
              data-kt-menu-placement='right-start'
              data-kt-menu-flip='left-start, top'
            >
            
              <a href='#' className='menu-link px-3'>
                <span className='menu-title'>New Group</span>
                <span className='menu-arrow'></span>
              </a>
            
              <div className='menu-sub menu-sub-dropdown w-175px py-4'>
              
                <div className='menu-item px-3'>
                  <a href='#' className='menu-link px-3'>
                    Admin Group
                  </a>
                </div>
               
                <div className='menu-item px-3'>
                  <a href='#' className='menu-link px-3'>
                    Staff Group
                  </a>
                </div>
             
                <div className='menu-item px-3'>
                  <a href='#' className='menu-link px-3'>
                    Member Group
                  </a>
                </div>
               
              </div>
             
            </div>
           
            <div className='menu-item px-3'>
              <a href='#' className='menu-link px-3'>
                New Contact
              </a>
            </div>
           
            <div className='separator mt-3 opacity-75'></div>
           
            <div className='menu-item px-3'>
              <div className='menu-content px-3 py-3'>
                <a className='btn btn-primary btn-sm px-4' href='#'>
                  Generate Reports
                </a>
              </div>
            </div>
         
          </div>
       
         
        </div>
      </div>
   
      <div className='card-body py-3'>
       
        <div className='table-responsive'>
         
          <table className='table align-middle gs-0 gy-4'>
          
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-300px rounded-start'>Rule Name</th>
                <th className='min-w-125px'>Rule Type</th>
                <th className='min-w-125px'>Created Date</th>
                <th className='min-w-125px'>Description</th>
                <th className='min-w-200px text-end rounded-end'></th>
              </tr>
            </thead>
         
       
            <tbody>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label bg-light'>
                        <KTIcon iconName='abstract-26' className='fs-2x text-primary' />
                      </span>
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        User Authentication
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Users must provide valid credentials to access the system
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    Access Control
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    05/28/2020
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Time: 04:30 PM
                  </span>
                </td>
                <td>
                  <a
                    href='#'
                    className=' text-muted text-dark fw-bold text-hover-primary d-block mb-1 fs-7'
                  >
                    Password Complexity, Account Lockout
                  </a>
                </td>

                <td className='text-end'>
                  <a
                    href='#'
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                  >
                    View
                  </a>
                  <a
                    href='#'
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                  >
                    Edit
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label bg-light'>
                        <KTIcon iconName='abstract-26' className='fs-2x text-primary' />
                      </span>
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      Product Pricing
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      Prices for products should be set based on cost plus a fixed profit margin
                    </span>
                    </div>
                  </div>
                </td>

                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  Pricing Strategy
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    05/28/2020
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Time: 04:30 PM
                  </span>
                </td>
                <td>
                  <a
                    href='#'
                    className=' text-muted text-dark fw-bold text-hover-primary d-block mb-1 fs-7'
                  >
                    Loyalty Discount, Volume Discount
                  </a>
                </td>

                <td className='text-end'>
                  <a
                    href='#'
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                  >
                    View
                  </a>
                  <a
                    href='#'
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                  >
                    Edit
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label bg-light'>
                        <KTIcon iconName='abstract-26' className='fs-2x text-primary' />
                      </span>
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      Order Processing
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      Orders must be verified for product availability before processing
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  Operations
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    05/28/2020
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Time: 04:30 PM
                  </span>
                </td>
                <td>
                  <a
                    href='#'
                    className=' text-muted text-dark fw-bold text-hover-primary d-block mb-1 fs-7'
                  >
                    Inventory Check, Expedited Shipping

                  </a>
                </td>

                <td className='text-end'>
                  <a
                    href='#'
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                  >
                    View
                  </a>
                  <a
                    href='#'
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                  >
                    Edit
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label bg-light'>
                        <KTIcon iconName='abstract-26' className='fs-2x text-primary' />
                      </span>
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Employee Leave Management
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                      Employees must submit leave requests at least two weeks in advance
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  Human Resources

                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    05/28/2020
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Time: 04:30 PM
                  </span>
                </td>
                <td>
                  <a
                    href='#'
                    className=' text-muted text-dark fw-bold text-hover-primary d-block mb-1 fs-7'
                  >
                    Advance Notice, Maximum Leave Duration
                  </a>
                </td>

                <td className='text-end'>
                  <a
                    href='#'
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                  >
                    View
                  </a>
                  <a
                    href='#'
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                  >
                    Edit
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label bg-light'>
                        <KTIcon iconName='abstract-26' className='fs-2x text-primary' />
                      </span>
                    </div>
                    <div className='d-flex justify-content-start flex-column'>
                      <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        Compliance with Regulatory Standards
                      </a>
                      <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        All financial transactions must comply with local and international regulations
                      </span>
                    </div>
                  </div>
                </td>

                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                  Governance
                  </a>
                </td>
                <td>
                  <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                    05/28/2020
                  </a>
                  <span className='text-muted fw-semibold text-muted d-block fs-7'>
                    Time: 04:30 PM
                  </span>
                </td>
                <td>
                  <a
                    href='#'
                    className=' text-muted text-dark fw-bold text-hover-primary d-block mb-1 fs-7'
                  >
                    Regular Audits, Reporting Requirements
                  </a>
                </td>

                <td className='text-end'>
                  <a
                    href='#'
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                  >
                    View
                  </a>
                  <a
                    href='#'
                    className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'
                  >
                    Edit
                  </a>
                </td>
              </tr>
            </tbody>
         
          </table>
        
        </div>
  
      </div>
 
    </div>
  )
}

export {TablesRuleEngine}
