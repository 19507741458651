import React, {useState} from 'react'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import DatabaseConnOption from './connection/DatabaseConnOption'
import APIConnOption from './connection/APIConnOption'
import FlatFileConnOption from './connection/FlatFileConnOption'
import UploadFile from '../../finance/banking/remittance/UploadFile'

const SourceConnectivity = (props: any) => {
  const [modalOpen, setModalOpen] = useState(false)

  const finance_conn_Breadcrumbs: Array<PageLink> = [
    {
      title: 'Finance',
      path: '/process/bpms/finance',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Money Remittance',
      path: '/process/bpms/finance',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const openModal = () => {
    setModalOpen(true)
    props.uploadExcelProps()
    const modalId = 'upload_modal' // Replace with your actual modal ID
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    if (modalTrigger) {
      ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
    }
  }

  const closeModal = () => {
    props.uploadExcelProps()
    setTimeout(() => {
      setModalOpen(false)
    }, 1000)
  }

  return (
    <>
    <PageTitle breadcrumbs={finance_conn_Breadcrumbs}>Source Data Connection</PageTitle>
    
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <div className='card h-xxl-100'>
            <div className='card-header pt-7'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-800'>Source Data Connectivity</span>
                <span className='text-gray-400 mt-1 fw-semibold fs-6'>
                  Select your end point gateway
                </span>
              </h3>
              <div className='card-toolbar'></div>
            </div>
            <div className='card-body d-flex flex-column justify-content-between pb-5 px-0'>
              <ul className='nav nav-pills nav-pills-custom mb-3 mx-9 flex-column' role='tablist'>
                <li className='nav-item mb-3 me-3 me-lg-6' role='presentation'>
                  <a
                    className='nav-link btn btn-outline btn-flex btn-active-color-primary flex-column overflow-hidden w-200px h-85px pt-5 pb-2 mb-2 active'
                    data-bs-toggle='pill'
                    id='kt_database_conn_tab_1'
                    href='#kt_database_conn_content_1'
                    aria-selected='true'
                    role='tab'
                  >
                    <div className='nav-icon mb-3'>
                      <i className='ki-duotone ki-abstract-26 fs-1 p-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </div>
                    <span className='nav-text text-gray-800 fw-bold fs-6 lh-1'>Database</span>
                    <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
                  </a>
                </li>
                <li className='nav-item mb-3 me-3 me-lg-6' role='presentation'>
                  <a
                    className='nav-link btn btn-outline btn-flex btn-active-color-primary flex-column overflow-hidden w-200px h-85px pt-5 pb-2 mb-2'
                    data-bs-toggle='pill'
                    id='kt_API_conn_tab_1'
                    href='#kt_API_conn_content_1'
                    aria-selected='false'
                    role='tab'
                  >
                    <div className='nav-icon mb-3'>
                      <i className='ki-duotone ki-router fs-2x p-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </div>
                    <span className='nav-text text-gray-800 fw-bold fs-6 lh-1'>API Gateway</span>
                    <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
                  </a>
                </li>
                <li className='nav-item mb-3 me-3 me-lg-6' role='presentation'>
                  <a
                    className='nav-link btn btn-outline btn-flex btn-active-color-primary flex-column overflow-hidden w-200px h-85px pt-5 pb-2 mb-2'
                    data-bs-toggle='pill'
                    id='kt_FlatFile_conn_tab_1'
                    href='#kt_FlatFile_conn_content_1'
                    aria-selected='false'
                    role='tab'
                  >
                    <div className='nav-icon mb-3'>
                      <i className='ki-duotone ki-file fs-1 p-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </div>
                    <span className='nav-text text-gray-800 fw-bold fs-6 lh-1'>Flat File</span>
                    <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary'></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='col-xl-9'>
          <div className='card h-xxl-100'>
            <div className='card-header pt-7'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-800'>Source Data Connectivity</span>
                <span className='text-gray-400 mt-1 fw-semibold fs-6'>
                  Select your data source connectivity channel
                </span>
              </h3>
              <div className='card-toolbar'></div>
            </div>
            <div className='card-body d-flex flex-column justify-content-between pb-5 px-0'>
              <div className='tab-content ps-4 pe-6'>
                <div
                  className='tab-pane fade show active min-h-auto'
                  id='kt_database_conn_content_1'
                  role='tabpanel'
                  aria-labelledby='kt_database_conn_tab_1'
                >
                  <DatabaseConnOption {...props} />
                </div>

                <div
                  className='tab-pane fade show min-h-auto'
                  id='kt_API_conn_content_1'
                  role='tabpanel'
                  aria-labelledby='kt_API_conn_tab_1'
                >
                  <APIConnOption {...props} />
                </div>

                <div
                  className='tab-pane fade show min-h-auto'
                  id='kt_FlatFile_conn_content_1'
                  role='tabpanel'
                  aria-labelledby='kt_FlatFile_conn_tab_1'
                >
                  <FlatFileConnOption {...props} />
                </div>
              </div>
              {/* <div className='d-flex flex-stack pt-0 pb-8'>
                <div
                  style={{
                    marginLeft: 'auto',
                    ...(window.innerWidth > 768 ? {paddingRight: '28px'} : {paddingRight: '15px'}),
                  }}
                >
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    onClick={() => {
                      openModal()
                      props.uploadExcelProps()
                    }}
                  >
                    Continue
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                  <SweetAlertExample />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <>
        <span data-bs-toggle='modal' data-bs-target='#upload_modal'></span>
        <div className='modal fade' onClick={closeModal} tabIndex={-1} id='upload_modal'>
          <div
            className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered h-auto'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='modal-content' style={{width: '100%'}}>
              <div className='modal-header'>
                <h5 className='modal-title'>{'Upload Rules'}</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <i className='ki-duotone ki-cross fs-2x'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </div>
              </div>
              <div className='modal-body'>
                <UploadFile fileType='CSV' props={props} />
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default SourceConnectivity
