import React, {useState, useEffect} from 'react'
import ViewActionModal from './ViewActionModal'
import {BarChart2} from './BarChart2'
import PieChart from './PieChart'
import {SCHEMA} from './Config'

// const ViewBreakdownWidget: React.FC<ReportStateProp> = ({data,rows,page}) => {
const ViewBreakdownWidget: any = ({data, props}) => {
  const selectedRuleArray = props?.reportState?.selectedRule ? props.reportState.selectedRule : []
  const numberOfRules = selectedRuleArray ? selectedRuleArray.length : 0 // Assuming selectedRuleArray is an array of rules

  const [index, setIndex] = useState(0)
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }
  const [heading, setHeading] = useState<string>('')

  const handleDivClick = (clickedData, count) => {
    // Your custom logic for the click event
    setHeading(clickedData)
    props.clearPopupProps()
    // let input= clickedData==="Unique Records"?'UNIQUE':clickedData==="Error Records"?"ERROR":"NULL"
    let input

    switch (clickedData) {
      case 'Unique Records':
        input = 'UNIQUE'
        break
      case 'Null Records':
        input = 'NULL'
        break

      default:
        // Default case if clickedData doesn't match any of the specified values
        input = ''
    }
    props.getGraphData(
      SCHEMA,
      props.selectedTable,
      props?.reportState?.fieldName && data.fieldName,
      '',
      input,
      count,
      1,
      clickedData
    )
    const modalId = 'kt_modal_1' // Replace with your actual modal ID
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    if (modalTrigger) {
      ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
    }
  }

  const handleRuleClick = (id, rule) => {
    // Your custom logic for the click event
    setHeading(rule)
    let input = 'ERROR'

    props.getErrorData(
      SCHEMA,
      props.selectedTable,
      props?.reportState?.fieldName && data.fieldName,
      id,
      input,
      Number(selectedRuleArray.find((obj) => obj.Id === id).count),
      1,
      rule,
      10,
    )
    const modalId = 'kt_modal_1' // Replace with your actual modal ID
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    if (modalTrigger) {
      ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
    }
  }

  // React.useEffect(()=>{
  //   if(props.graphDataSuccess===true&&heading!=='')
  //   {
  //       const modalId = 'kt_modal_1' // Replace with your actual modal ID
  //   const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
  //   if (modalTrigger) {
  //     ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
  //   }
  //   }
  // },[props.graphDataSuccess,heading])

  // React.useEffect(()=>{
  //   if(props.errorDataSuccess===true&&heading!=='')
  //   {
  //       const modalId = 'kt_modal_1' // Replace with your actual modal ID
  //   const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
  //   if (modalTrigger) {
  //     ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
  //   }
  //   }
  // },[props.errorDataSuccess,heading])

  React.useEffect(() => {
    // setHeading('');
    props.clearPopupProps()
  }, [])

  return (
    <>
      {data && (
        <>
          <div className='row g-xxl-12'>
            {/* begin::Col */}
            <div className='col-xxl-12'>
              <div className='card card-xxl-stretch mb-5 mb-xxl-10'>
                <div className='card-header'>
                  <div className='card-title'>
                    <h3>{props?.reportState?.fieldName && data.fieldName} | Report Breakdown</h3>
                  </div>
                </div>
                <div className='card-body'>
                  <span className='fs-5 fw-semibold text-gray-600 pb-5 d-block'>
                    Kindly find the applied rules' statistics and a consolidated analysis of{' '}
                    <b>{props?.reportState?.fieldName && data.fieldName}</b>.
                  </span>
                  <div className='row pb-4'>
                    <div className='col'>
                      <div className='card card-dashed flex-center min-w-175px my-3 p-6'>
                        <span className='fs-4 fw-semibold text-info pb-1 px-2'>Total Records</span>
                        <span className='fs-lg-2tx fw-bold d-flex justify-content-center'>
                          <span
                            data-kt-countup='true'
                            data-kt-countup-value={
                              props?.reportState?.fieldData?.totalNoOfRows && data.fieldData.totalNoOfRows
                            }
                          >
                            {Number(data.fieldData.totalNoOfRows).toLocaleString()}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='card card-dashed flex-center min-w-175px my-3 p-6'>
                        <span className='fs-4 fw-semibold text-success pb-1 px-2'>
                          Unique Records
                        </span>
                        <span className='fs-lg-2tx fw-bold d-flex justify-content-center'>
                          <span
                            data-kt-countup='true'
                            data-kt-countup-value={
                              props?.reportState?.fieldData?.totalNoOfUniqueRows &&
                              data.fieldData.totalNoOfUniqueRows
                            }
                          >
                            {Number(
                              props?.reportState?.fieldData?.totalNoOfUniqueRows &&
                                data.fieldData.totalNoOfUniqueRows
                            ).toLocaleString()}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className='col'>
                      <div className='card card-dashed flex-center min-w-175px my-3 p-6'>
                        <span className='fs-4 fw-semibold text-danger pb-1 px-2'>Null Records</span>
                        <span className='fs-lg-2tx fw-bold d-flex justify-content-center'>
                          <span
                            data-kt-countup='true'
                            data-kt-countup-value={data.fieldData.totalNoOfNullRows}
                          >
                            {Number(data.fieldData.totalNoOfNullRows).toLocaleString()}
                          </span>
                        </span>
                      </div>
                    </div>
                    {/* <div className='col'>
                  <div className='card card-dashed flex-center min-w-175px my-3 p-6'>
                    <span className='fs-4 fw-semibold text-primary pb-1 px-2'>Errors Records</span>
                    <span className='fs-lg-2tx fw-bold d-flex justify-content-center'>
                      <span
                        data-kt-countup='true'
                        data-kt-countup-value={data.fieldData.totalNoOfErrorRows}
                      >
                        {Number(data.fieldData.totalNoOfErrorRows).toLocaleString()}
                      </span>
                    </span>
                  </div>
                </div> */}
                  </div>

                  <div className='notice d-flex rounded border-primary border border-dashed p-6'>
                    <i className='ki-duotone ki-percentage fs-2tx text-primary me-4'>
                      <span className='path1'></span>
                      <span className='path2 bg-light-primary'></span>
                    </i>
                    <div className='row pb-4'style={{width:'100%'}}>
                      <div
                        className='col d-flex align-items-center flex-shrink-0'
                        // onClick={() => handleDivClick('Unique Records',data.totalNoOfUniqueRows)}
                        // style={{cursor: 'pointer'}}
                      >
                        <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
                        <span className='fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block'>
                          Unique Percentage:
                        </span>
                        <div className='progress w-100px w-xl-150px w-xxl-250px h-25px bg-light-success'>
                          <div
                            className='progress-bar rounded bg-success fs-7 fw-bold'
                            role='progressbar'
                            style={{width: `${data.fieldData.uniquePercentage}`, minWidth: '1%'}}
                            aria-valuenow={data.fieldData.uniquePercentage}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            {data.fieldData.uniquePercentage}
                          </div>
                        </div>
                      </div>
                      <div
                        className='col d-flex align-items-center flex-shrink-0'
                        onClick={() => handleDivClick('Null Records', data.fieldData.totalNoOfNullRows)}
                        style={{cursor: 'pointer',width:'100%'}}
                      >
                        <div className='bullet bg-secondary h-35px w-1px mx-5'></div>
                        <span className='fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block'>
                          Null Percentage:
                        </span>
                        <div className='progress w-100px w-xl-150px w-xxl-300px h-25px bg-light-danger'>
                          <div
                            className='progress-bar rounded bg-danger fs-7 fw-bold'
                            role='progressbar'
                            style={{width: `${data.fieldData.nullPercentage}`, minWidth: '1%'}}
                            aria-valuenow={data.fieldData.nullPercentage}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            {data.fieldData.nullPercentage}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card card-xxl-stretch mb-5 mb-xxl-10'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Applied Rules List</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>
                  {numberOfRules} business rules selected
                </span>
              </h3>
            </div>

            <div className='card-body py-3'>
              <div className='table-responsive'>
                <table className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-300px rounded-start'>Rule Name</th>
                      <th className='min-w-125px'>Description</th>
                      <th className='min-w-125px'>Error Percentage</th>
                      <th className='min-w-200px text-end rounded-end'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-35px me-4'>
                        <span className='symbol-label bg-light-primary'>
                          {' '}
                          <i className='ki-duotone ki-color-swatch fs-2 text-primary'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                            <span className='path4'></span>
                            <span className='path5'></span>
                            <span className='path6'></span>
                            <span className='path7'></span>
                            <span className='path8'></span>
                            <span className='path9'></span>
                            <span className='path10'></span>
                            <span className='path11'></span>
                            <span className='path12'></span>
                            <span className='path13'></span>
                            <span className='path14'></span>
                            <span className='path15'></span>
                            <span className='path16'></span>
                            <span className='path17'></span>
                            <span className='path18'></span>
                            <span className='path19'></span>
                            <span className='path20'></span>
                            <span className='path21'></span>
                          </i>
                        </span>
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          User Authentication
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span className=' text-muted text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                      Password Complexity, Account Lockout
                    </span>
                  </td>
                  <td>
                    <div className='d-flex align-items-center flex-shrink-0'>
                      <div className='progress w-100px w-xl-150px w-xxl-300px h-25px bg-light-primary'>
                        <div
                          className='progress-bar rounded bg-primary fs-7 fw-bold'
                          role='progressbar'
                          style={{width: '32%'}}
                          aria-valuenow={32}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        >
                          32%
                        </div>{' '}
                      </div>
                    </div>
                  </td>
                  <td className='text-end'>
                    <span className='btn btn-bg-light btn-color-muted btn-active-light-primary btn-sm px-4 me-2'>
                      View Records
                    </span>
                  </td>
                </tr> */}
                    {selectedRuleArray.map((item, id) => (
                      <tr key={id}>
                        <td>
                          <div className='d-flex align-items-center'>
                            <div className='symbol symbol-35px me-4'>
                              <span className='symbol-label bg-light-primary'>
                                {' '}
                                <i className='ki-duotone ki-color-swatch fs-2 text-primary'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                  <span className='path3'></span>
                                  <span className='path4'></span>
                                  <span className='path5'></span>
                                  <span className='path6'></span>
                                  <span className='path7'></span>
                                  <span className='path8'></span>
                                  <span className='path9'></span>
                                  <span className='path10'></span>
                                  <span className='path11'></span>
                                  <span className='path12'></span>
                                  <span className='path13'></span>
                                  <span className='path14'></span>
                                  <span className='path15'></span>
                                  <span className='path16'></span>
                                  <span className='path17'></span>
                                  <span className='path18'></span>
                                  <span className='path19'></span>
                                  <span className='path20'></span>
                                  <span className='path21'></span>
                                </i>
                              </span>
                            </div>
                            <div className='d-flex justify-content-start flex-column'>
                              <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                                {item.rule}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className='text-muted text-dark fw-bold text-hover-primary d-block mb-1 fs-7'>
                            {item.desc}
                          </span>
                        </td>
                        <td>
                          <div className='d-flex align-items-center flex-shrink-0'>
                            <div className='progress w-100px w-xl-150px w-xxl-300px h-25px bg-light-primary'>
                              <div
                                className='progress-bar rounded bg-primary fs-7 fw-bold'
                                role='progressbar'
                                style={{width: `${item.error}`, minWidth: '1%'}}
                                aria-valuenow={item.error}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              >
                                {item.error}
                              </div>{' '}
                            </div>
                          </div>
                        </td>
                        <td className='text-end'>
                          <span
                            className='btn btn-bg-light btn-color-muted btn-active-light-primary btn-sm px-4 me-2'
                            onClick={() => handleRuleClick(item.Id, item.rule)}
                          >
                            View Records
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='row g-5 g-xl-12 '>
            <div className='col-xxl-12'>
              <div className='card h-xl-100'>
                <div className='card-header position-relative py-0 border-bottom-2'>
                  <ul className='nav nav-stretch nav-pills nav-pills-custom d-flex mt-2'>
                    {/* <li className='nav-item p-0 ms-0 me-8'>
                  <a
                    className='nav-link btn btn-color-muted active px-0'
                    data-bs-toggle='tab'
                    id='kt_chart_widgets_22_tab_1'
                    href='#kt_chart_widgets_22_tab_content_1'
                  >
                    <span className='nav-text fw-semibold fs-4 mb-3'>Overview </span>
                    <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded'></span>
                  </a>
                </li> */}
                    <li className='nav-item p-0 ms-0'>
                      <a
                        className='nav-link btn btn-color-muted px-0'
                        data-bs-toggle='tab'
                        id='kt_chart_widgets_22_tab_2'
                        href='#kt_chart_widgets_22_tab_content_2'
                      >
                        <span className='nav-text fw-semibold fs-4 mb-3'>Statistics</span>
                        <span className='bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded'></span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='card-body pb-3'>
                  <div className='tab-content'>
                    <div
                      className='tab-pane fade show active'
                      id='kt_chart_widgets_22_tab_content_1'
                    >
                      <div className='d-flex flex-wrap flex-md-nowrap'>
                        <div className='card-body pb-0 '>
                          <div className='card-header mb-5'>
                           
                            <h3 className='card-title align-items-start flex-column'>
                              <span className='card-label fw-bold text-dark'>
                                Selected Business Rules
                              </span>
                              <span className='text-gray-400 mt-1 fw-semibold fs-7'>
                                {numberOfRules} rules are applied
                              </span>
                            </h3>
                            <div className='card-toolbar'>
                              <div className='d-flex justify-content-end'>
                                <button
                                  className='carousel-control-prev position-relative me-5 active'
                                  role='button'
                                  onClick={() =>
                                    handleSelect((index - 1 + numberOfRules) % numberOfRules)
                                  }
                                >
                                  <i className='ki-duotone ki-left-square fs-2x text-gray-400'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </button>

                                <button
                                  className='carousel-control-next position-relative me-1'
                                  role='button'
                                  onClick={() => handleSelect((index + 1) % numberOfRules)}
                                >
                                  <i className='ki-duotone ki-right-square fs-2x text-gray-800'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                </button>
                              </div>
                            </div>
                          </div>
                          {selectedRuleArray.map((rule, i) => (
                            <div key={i} className={`carousel-item${i === index ? ' active' : ''}`}>
                              <PieChart
                                data={data}
                                props={props}
                                Datakey={rule}
                                ruleid={rule.Id}
                                count={rule.count}
                              />
                            </div>
                          ))}
                        </div>
                        <div className='card-body pb-0'>
                          <BarChart2 data={data} props={props} className={''} />
                        </div>
                      </div>
                      {/* <h3>Data Field - {data.fieldName}</h3>
                  <br></br>
                  <div className='d-flex flex-wrap flex-md-nowrap'>
                    <div className='me-md-5 w-100'>
                      <div
                        className='d-flex border border-gray-300 border-dashed rounded p-5 mb-5'
                        // onClick={() => handleDivClick('totalRecords')}
                        // style={{cursor: 'pointer'}}
                      >
                        <div className='d-flex align-items-center flex-grow-1 me-2'>
                          <div className='symbol symbol-35px me-4'>
                            <span className='symbol-label bg-light-primary'>
                              {' '}
                              <i className='ki-duotone ki-cloud-change fs-2 text-primary'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                              </i>
                            </span>
                          </div>

                          <div className='me-2'>
                            <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                              Total Records
                            </span>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <span className='badge badge-lg badge-light-primary align-self-center px-2'>
                            {data.fieldData.totalNoOfNullRows}
                          </span>
                        </div>
                      </div>

                      <div
                        className='d-flex border border-gray-300 border-dashed rounded p-5 mb-5'
                        // onClick={() => handleDivClick('uniqueRecords')}
                        // style={{cursor: 'pointer'}}
                      >
                        <div className='d-flex align-items-center flex-grow-1 me-2'>
                          <div className='symbol symbol-35px me-4'>
                            <span className='symbol-label bg-light-success'>
                              {' '}
                              <i className='ki-duotone ki-color-swatch fs-2 text-success'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                                <span className='path5'></span>
                                <span className='path6'></span>
                                <span className='path7'></span>
                                <span className='path8'></span>
                                <span className='path9'></span>
                                <span className='path10'></span>
                                <span className='path11'></span>
                                <span className='path12'></span>
                                <span className='path13'></span>
                                <span className='path14'></span>
                                <span className='path15'></span>
                                <span className='path16'></span>
                                <span className='path17'></span>
                                <span className='path18'></span>
                                <span className='path19'></span>
                                <span className='path20'></span>
                                <span className='path21'></span>
                              </i>
                            </span>
                          </div>
                          <div className='me-2'>
                            <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                              Unique Records
                            </span>
                          </div>
                        </div>
                        <div className='d-flex align-items-center fs-7'>
                          <span className='badge badge-lg badge-light-success align-self-center px-2'>
                            {data.fieldData.totalNoOfUniqueRows}
                          </span>
                        </div>
                      </div>

                      <div
                        className='d-flex border border-gray-300 border-dashed rounded p-5 mb-5'
                        // onClick={() => handleDivClick('nullRecords')}
                        // style={{cursor: 'pointer'}}
                      >
                        <div className='d-flex align-items-center flex-grow-1 me-2'>
                          <div className='symbol symbol-35px me-4'>
                            <span className='symbol-label bg-light-danger'>
                              {' '}
                              <i className='ki-duotone ki-compass fs-2 text-danger'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                              </i>
                            </span>
                          </div>
                          <div className='me-2'>
                            <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                              Empty Records
                            </span>
                          </div>
                        </div>
                        <div className='d-flex align-items-center fs-7'>
                          <span className='badge badge-lg badge-light-danger align-self-center px-2'>
                            {data.fieldData.totalNoOfNullRows}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex justify-content-between flex-column  w-100'>
                      <div
                        className='d-flex border border-gray-300 border-dashed rounded p-5 mb-5'
                        onClick={() => handleDivClick('Error Records')}
                        style={{cursor: 'pointer'}}
                      >
                        <div className='d-flex align-items-center flex-grow-1 me-2'>
                          <div className='symbol symbol-35px me-4'>
                            <span className='symbol-label bg-light-primary'>
                              <i className='ki-duotone ki-chart-simple fs-2 text-primary'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                              </i>
                            </span>
                          </div>
                          <div className='me-2'>
                            <span
                              className='text-gray-800 text-hover-primary fs-6 fw-bold'
                              style={{cursor: 'pointer'}}
                            >
                              Error Percentage
                            </span>
                          </div>
                        </div>
                        <div className='d-flex align-items-center fs-7'>
                          <span className='badge badge-lg badge-light-info align-self-center px-2'>
                            {data.fieldData.errorPercentage}
                          </span>
                        </div>
                      </div>

                      <div
                        className='d-flex border border-gray-300 border-dashed rounded p-5 mb-5'
                        onClick={() => handleDivClick('Unique Records')}
                        style={{cursor: 'pointer'}}
                      >
                        <div className='d-flex align-items-center flex-grow-1 me-2'>
                          <div className='symbol symbol-35px me-4'>
                            <span className='symbol-label bg-light-primary'>
                              <i className='ki-duotone ki-chart-simple fs-2 text-primary'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                              </i>
                            </span>
                          </div>
                          <div className='me-2'>
                            <span
                              className='text-gray-800 text-hover-primary fs-6 fw-bold'
                              style={{cursor: 'pointer'}}
                            >
                              Unique Percentage
                            </span>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <span className='badge badge-lg badge-light-info align-self-center px-2'>
                            {data.fieldData.uniquePercentage}
                          </span>
                        </div>
                      </div>
                      <div
                        className='d-flex border border-gray-300 border-dashed rounded p-5 mb-5'
                        onClick={() => handleDivClick('Null Records')}
                        style={{cursor: 'pointer'}}
                      >
                        <div className='d-flex align-items-center flex-grow-1 me-2'>
                          <div className='symbol symbol-35px me-4'>
                            <span className='symbol-label bg-light-primary'>
                              <i className='ki-duotone ki-chart-simple fs-2 text-primary'>
                                <span className='path1'></span>
                                <span className='path2'></span>
                                <span className='path3'></span>
                                <span className='path4'></span>
                              </i>
                            </span>
                          </div>
                          <div className='me-2'>
                            <span
                              className='text-gray-800 text-hover-primary fs-6 fw-bold'
                              style={{cursor: 'pointer'}}
                            >
                              Null Percentage
                            </span>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <span className='badge badge-lg badge-light-info align-self-center px-2'>
                            {data.fieldData.nullPercentage}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    </div>
                    <div className='tab-pane fade' id='kt_chart_widgets_22_tab_content_2'></div>
                  </div>
                </div>
              </div>
            </div>

            {/* {props.graphDataSuccess && heading !== '' && 
          <ViewActionModal
          rows={(props?.graphDataPagination?.row) ? (props.graphDataPagination.row) : 0}
          page={(props?.graphDataPagination?.pages )? (props.graphDataPagination.pages) : 0}
          data={props.graphData.map(
            ({
              CustomerKey,
              FirstName,
              MiddleName,
              LastName,
              EmailAddress,
              Phone,
              BirthDate,
              YearlyIncome,
              CustomerStatus,
            }) => ({
              CustomerKey,
              Name: `${FirstName} ${MiddleName || ''} ${LastName}`,
              EmailAddress,
              Phone,
              BirthDate,
              YearlyIncome,
              CustomerStatus,
            })
          )}
          heading={heading}
          props={props}
          />
                  }

        {props.errorDataSuccess === true &&  (
          <ViewActionModal
            rows={(props?.errorDataPagination?.rows) ? (props.errorDataPagination.rows) : 0}
            page={(props?.errorDataPagination?.page )? (props.errorDataPagination.page) : 0}
            data={props.errorData.map(
              ({
                CustomerKey,
                FirstName,
                MiddleName,
                LastName,
                EmailAddress,
                Phone,
                BirthDate,
                YearlyIncome,
                CustomerStatus,
              }) => ({
                CustomerKey,
                Name: `${FirstName} ${MiddleName || ''} ${LastName}`,
                EmailAddress,
                Phone,
                BirthDate,
                YearlyIncome,
                CustomerStatus,
              })
            )}
            heading={heading}
            props={props}
          />
        )} */}

            <ViewActionModal heading={heading} props={props} />
          </div>
        </>
      )}
    </>
  )
}
export default ViewBreakdownWidget
