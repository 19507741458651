import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

const DataReadinessComponent: React.FC<any> = (props) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const navigate = useNavigate()

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value)
    props.financeOption(event.target.value)
  }

  // const handleContinueClick = () => {
  //   // props.onContinue()
  //   if (selectedOption === 'data-profiling') {
  //     navigate('/process/bpms/finance/remittance/profiling');
  //   } else if (selectedOption === 'data-enrichment') {
  //     navigate('/process/finance/banking/data-enrichment');
  //   }
  // };
  useEffect(() => {
    if (props.component && props.component === 'profiling' && props.ProfilingCompleted === false) {
      props.financeOption('data-profiling')
    } else if (
      props.component &&
      props.component === 'enrichment' &&
      props.AppliedDataEnrichment === false
    ) {
      props.financeOption('enrichment')
    } else props.financeOption('')
  }, [])

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* Content container */}
      <div id='kt_app_content_container' className='app-container container-xxl'>
        {/* Card */}
        <div className='text-center pt-15'>
          {/* Title */}
          <h2 className='fs-2x fw-bold mb-0'>Data Adequacy</h2>
          {/* Description */}
          <p className='text-gray-400 fs-4 fw-semibold py-7'>
            Click on the below option to analysis, examination
            <br />
            and understanding the characteristics of the data.
          </p>
          {/* Action */}
          <div className='fv-row mx-auto mw-800px w-100 py-10 fv-plugins-bootstrap5 fv-plugins-framework'>
            <div className='row'>
              <div className='col-lg-6'>
                {props.component === 'enrichment' || props.ProfilingCompleted === true ? null : (
                  <input
                    type='radio'
                    className={'btn-check'}
                    name='data_readiness'
                    value='data-profiling'
                    checked={props.financesolutionoption === 'data-profiling'}
                    onChange={handleRadioChange}
                    id='kt_captute_data_readiness_profiling' // Correct id here
                  />
                )}
                <label
                  className={`btn btn-outline btn-outline-dashed ${
                    props.component === 'enrichment' || props.ProfilingCompleted === true
                      ? 'btn-success'
                      : 'btn-active-light-primary'
                  } p-7 d-flex align-items-center mb-10`}
                  htmlFor='kt_captute_data_readiness_profiling' // Match the correct id here
                >
                  <i className='ki-duotone ki-technology-2 fs-3x me-5'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                  <span className='d-block fw-semibold text-start'>
                    <span
                      className={`${
                        props.component === 'enrichment' || props.ProfilingCompleted === true
                          ? 'tesx-light'
                          : 'text-dark'
                      } fw-bold d-block fs-4 mb-2`}
                    >
                      Data Profiling
                    </span>
                    <span
                      className={`${
                        props.component === 'enrichment' || props.ProfilingCompleted === true
                          ? 'tesx-light'
                          : 'text-muted'
                      } fw-semibold fs-6`}
                    >
                      to systematically analyze and assess the quality, structure, and content of a
                      dataset
                    </span>
                  </span>
                </label>
              </div>
              <div className='col-lg-6'>
                {props.component === 'enrichment' && props.AppliedDataEnrichment === false && (
                  <input
                    type='radio'
                    className='btn-check'
                    name='data_readiness'
                    value='data-enrichment'
                    checked={selectedOption === 'data-enrichment'}
                    onChange={handleRadioChange}
                    id='kt_captute_data_readiness_enrichment'
                  />
                )}
                <label
                  className={`btn btn-outline ${
                    props.component === 'enrichment' && 'btn-outline-dashed'
                  } ${
                    props.AppliedDataEnrichment === true
                      ? 'btn-success'
                      : ' btn-active-light-primary'
                  } p-7 d-flex align-items-center`}
                  htmlFor='kt_captute_data_readiness_enrichment'
                >
                  <i className='ki-duotone ki-data fs-3x me-5'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                    <span className='path3'></span>
                    <span className='path4'></span>
                    <span className='path5'></span>
                  </i>
                  <span className='d-block fw-semibold text-start'>
                    <span
                      className={`${
                        props.AppliedDataEnrichment === true ? 'text-light' : 'text-dark'
                      } fw-bold d-block fs-4 mb-2`}
                    >
                      Data Enrichment
                    </span>
                    <span
                      className={`${
                        props.AppliedDataEnrichment === true ? 'text-light' : 'text-dark'
                      } fw-semibold fs-6`}
                    >
                      to enhance and improve the quality of existing datasets by adding additional
                      relevant info.
                    </span>
                  </span>
                </label>
              </div>
            </div>
            {/* <div className='d-flex flex-stack pt-10'>
            <div style={{marginLeft: 'auto'}}>
              <button
                type='button'
                className='btn btn-lg btn-primary'
                onClick={handleContinueClick}
              >
                Continue
                <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </button>
            </div>
          </div> */}
            {/* Conditional rendering based on selected option */}
            {/* {selectedOption === 'data-profiling' && <DataProfilingComponent />}
          {selectedOption === 'data-enrichment' && <DataEnrichmentComponent />} */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataReadinessComponent
