/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
}

const TablesWidget2: React.FC<Props> = ({className}) => {
  return (
    <div style={{maxHeight: '520px', overflow: 'auto'}} className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Rules Book</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>More than 100 new products</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}

        {/* end::Table container */}

        {/* Customization part 1*/}
        <div className='m-0'>
          {/* Collapsible Heading  */}
          <div
            className='d-flex align-items-center collapsible py-3 toggle collapsed'
            data-bs-toggle='collapse'
            data-bs-target='#kt_job_1_2'
          >
            {/* Toggle Icon  */}
            <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
              <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            </div>
            {/* Heading Title  */}
            <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>What is your job role?</h4>
          </div>

          {/* Collapsible Content  */}
          <div id='kt_job_1_2' className='collapse fs-6 ms-1'>
            {/* List Items  */}
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with JavaScript</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Good time-management skills</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with React</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with HTML / CSS</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with REST API</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Git knowledge is a plus</div>
              </div>
            </div>
          </div>

          {/* Separator  */}
          <div className='separator separator-dashed'></div>
        </div>

        {/* Customization 1 ends */}

        {/* Customization part 2*/}
        <div className='m-0'>
          {/* Collapsible Heading  */}
          <div
            className='d-flex align-items-center collapsible py-3 toggle collapsed'
            data-bs-toggle='collapse'
            data-bs-target='#kt_job_3_4'
          >
            {/* Toggle Icon  */}
            <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
              <i className='ki-duotone ki-minus-square toggle-on text-primary fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <i className='ki-duotone ki-plus-square toggle-off fs-2'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            </div>
            {/* Heading Title  */}
            <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>What is your job role?</h4>
          </div>

          {/* Collapsible Content  */}
          <div id='kt_job_3_4' className='collapse fs-6 ms-1'>
            {/* List Items  */}
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with JavaScript</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Good time-management skills</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with React</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with HTML / CSS</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with REST API</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Git knowledge is a plus</div>
              </div>
            </div>
          </div>

          {/* Separator  */}
          <div className='separator separator-dashed'></div>
        </div>

        {/* Customization 2 ends */}

        {/* Customization part 3*/}
        <div className='m-0'>
          {/* Collapsible Heading  */}
          <div
            className='d-flex align-items-center collapsible py-3 toggle collapsed'
            data-bs-toggle='collapse'
            data-bs-target='#kt_job_5_6'
          >
            {/* Toggle Icon  */}
            <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
              <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            </div>
            {/* Heading Title  */}
            <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>What is your job role?</h4>
          </div>

          {/* Collapsible Content  */}
          <div id='kt_job_5_6' className='collapse fs-6 ms-1'>
            {/* List Items  */}
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with JavaScript</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Good time-management skills</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with React</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with HTML / CSS</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with REST API</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Git knowledge is a plus</div>
              </div>
            </div>
          </div>

          {/* Separator  */}
          <div className='separator separator-dashed'></div>
        </div>

        {/* Customization 3 ends */}

        {/* Customization part 4*/}
        <div className='m-0'>
          {/* Collapsible Heading  */}
          <div
            className='d-flex align-items-center collapsible py-3 toggle collapsed'
            data-bs-toggle='collapse'
            data-bs-target='#kt_job_7_8'
          >
            {/* Toggle Icon  */}
            <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
              <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                <span className='path1'></span>
                <span className='path2'></span>
                <span className='path3'></span>
              </i>
            </div>
            {/* Heading Title  */}
            <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>What is your job role?</h4>
          </div>

          {/* Collapsible Content  */}
          <div id='kt_job_7_8' className='collapse fs-6 ms-1'>
            {/* List Items  */}
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with JavaScript</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Good time-management skills</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with React</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with HTML / CSS</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10 mb-n1'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Experience with REST API</div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='d-flex align-items-center ps-10'>
                <span className='bullet me-3'></span>
                <div className='text-gray-600 fw-semibold fs-6'>Git knowledge is a plus</div>
              </div>
            </div>
          </div>

          {/* Separator  */}
          <div className='separator separator-dashed'></div>
        </div>

        {/* Customization 4 ends */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget2}
