import React, { useEffect } from 'react';
import { create } from '@amcharts/amcharts4/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import { XYChart, CategoryAxis, ValueAxis, ColumnSeries, XYCursor, LabelBullet } from '@amcharts/amcharts4/charts';

const NewChartComponent: React.FC = () => {
  useEffect(() => {
    // Define colors array
    const colors = ['#8e44ad', '#9b59b6', '#2980b9', '#3498db', '#16a085', '#1abc9c', '#dc6967'];

    // Use am4core
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    const chart = create('chartdiv', XYChart);
    chart.language.locale = am4lang_en_US;
    chart.paddingRight = 20;
       // Disable the AmCharts logo
chart.logo.disabled = true;

    // Add data
    const data = [
      { country: '18-24', value: 712 },
      { country: '25-34', value: 3159 },
      { country: '35-44', value: 3381 },
      { country: '45-54', value: 2715 },
      { country: '55-64', value: 1817 },
      { country: '65+', value: 820 },
      { country: 'Invalid', value: 23 },
    ];
    chart.data=data; 
    const max = Math.max(...data.map(item => item.value));
    
    // Create axes
    const categoryAxis = chart.xAxes.push(new CategoryAxis());
    categoryAxis.dataFields.category = 'country';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.disabled = true; // Disable grid lines for x-axis
    categoryAxis.title.text = 'Age Range';

    const valueAxis = chart.yAxes.push(new ValueAxis());
    valueAxis.renderer.grid.template.disabled = false; // Disable grid lines for y-axis
    valueAxis.title.text = 'Number of People';
    valueAxis.min = 1; // Start from 1
    valueAxis.max = 4000; // Adjust max value
    //valueAxis.logarithmic = true; // Use logarithmic scale

    // Create series
    const series = chart.series.push(new ColumnSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'country';
    series.name = 'Country Values';

    // Set colors for each column
    series.columns.template.stroke = am4core.color('#000'); // Default stroke color
    series.columns.template.strokeOpacity = 0.5; // Adjust opacity
    series.columns.template.adapter.add('fill', (fill, target) => {
      // Check if target.dataItem is defined
      if (target.dataItem) {
        const country = target.dataItem['categoryX'];
        // Set different colors based on country
        switch(country) {
            case '18-24': return am4core.color('#8e44ad');
            case '25-34': return am4core.color('#9b59b6');
            case '35-44': return am4core.color('#2980b9'); 
            case '45-54': return am4core.color('#3498db'); 
            case '55-64': return am4core.color('#16a085'); 
            case '65+': return am4core.color('#1abc9c'); 
            case 'Invalid': return am4core.color('#dc6967'); 

          default: return fill;
        }
      }
      // Return default fill if target.dataItem is undefined
      return fill;
    });

    // Add value labels on top of the bars
    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.text = '{valueY}';
    labelBullet.label.dy = -10; // Adjust vertical offset

    // Assign colors to each series individually
    for (let i = 0; i < colors.length; i++) {
      const column = series.columns.template;
      column.adapter.add("fill", (fill, target) => {
        if (target.dataItem && i === target.dataItem.index) {
          // Set label text color to match bar color
          labelBullet.label.fill = am4core.color(colors[i]);
          return am4core.color(colors[i]);
        }
        return fill;
      });
    }

    // Add vertical axis line on the left side
    const valueAxis2 = chart.yAxes.push(new ValueAxis());
    valueAxis2.renderer.line.stroke = am4core.color('#000');
    valueAxis2.renderer.line.strokeWidth = 0.3;
    valueAxis2.renderer.line.strokeOpacity = 1;

    // Add cursor
    chart.cursor = new XYCursor();

    // Dispose chart when component unmounts
    return () => {
      chart.dispose();
    };
  }, []);

  return <div id="chartdiv" style={{ width: '100%', height: '450px' }}></div>;
};

export default NewChartComponent;
