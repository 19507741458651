import React, {useEffect} from 'react'
import {create} from '@amcharts/amcharts4/core'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US'
import {
  XYChart,
  CategoryAxis,
  ValueAxis,
  ColumnSeries,
  XYCursor,
  LabelBullet,
} from '@amcharts/amcharts4/charts'

const TotalProceduresChart: React.FC = () => {
  useEffect(() => {
    // Define colors array
    const colors = ['#8e44ad', '#9b59b6', '#2980b9', '#3498db', '#16a085', '#1abc9c', '#dc6967']

    // Use am4core
    am4core.useTheme(am4themes_animated)

    // Create chart instance
    const chart = create('chartdiv', XYChart)
    chart.language.locale = am4lang_en_US
    chart.paddingRight = 20
    // Disable the AmCharts logo
    chart.logo.disabled = true

    // Add data
    const data = [
      {country: '0', value: 208321},
      {country: '1', value: 16555},
      {country: '2', value: 5829},
      {country: '3', value: 1768},
      {country: '4', value: 531},
      {country: '5', value: 145},
      {country: '6', value: 57},
      {country: '6+', value: 22},
    ]

    chart.data = data
    const max = Math.max(...data.map((item) => item.value))

    // Create axes
    const categoryAxis = chart.xAxes.push(new CategoryAxis())
    categoryAxis.dataFields.category = 'country'
    categoryAxis.renderer.minGridDistance = 30
    categoryAxis.renderer.grid.template.disabled = true // Disable grid lines for x-axis
    categoryAxis.title.text = 'Precondition'

    // Create Y-axis
    const valueAxis = chart.yAxes.push(new ValueAxis())
    valueAxis.renderer.grid.template.disabled = false
    valueAxis.title.text = 'Count'
    valueAxis.min = 1

    // Set logarithmic scale
    valueAxis.logarithmic = true

    // Customize tick values
    valueAxis.numberFormatter = new am4core.NumberFormatter()
    valueAxis.numberFormatter.numberFormat = '#.#a' // Format the numbers in "a" notation

    // Define desired tick values
    valueAxis.adapter.add('max', (max) => {
      if (max < 100) return 100
      if (max < 5000) return 5000
      if (max < 10000) return 10000
      return max
    })

    // Define desired grid intervals for logarithmic scale
    valueAxis.renderer.adapter.add('minGridDistance', (minGridDistance) => {
      return 60 // Adjust as needed
    })

    // Create series
    const series = chart.series.push(new ColumnSeries())
    series.dataFields.valueY = 'value'
    series.dataFields.categoryX = 'country'
    series.name = 'Country Values'

    // Set colors for each column
    series.columns.template.stroke = am4core.color('#000') // Default stroke color
    series.columns.template.strokeOpacity = 0.5 // Adjust opacity
    series.columns.template.adapter.add('fill', (fill, target) => {
      // Check if target.dataItem is defined
      if (target.dataItem) {
        const country = target.dataItem['categoryX']
        // Set different colors based on country
        switch (country) {
          case '0':
            return am4core.color('#8e44ad')
          case '1':
            return am4core.color('#9b59b6')
          case '2':
            return am4core.color('#2980b9')
          case '3':
            return am4core.color('#3498db')
          case '4':
            return am4core.color('#16a085')
          case '5':
            return am4core.color('#1abc9c')
          case '6':
            return am4core.color('#dc6967')
          case '6+':
            return am4core.color('#dc6967')
          default:
            return fill
        }
      }
      // Return default fill if target.dataItem is undefined
      return fill
    })

    // Add value labels on top of the bars
    const labelBullet = series.bullets.push(new LabelBullet())
    labelBullet.label.text = '{valueY}'
    labelBullet.label.dy = -10 // Adjust vertical offset

    // Assign colors to each series individually
    for (let i = 0; i < colors.length; i++) {
      const column = series.columns.template
      column.adapter.add('fill', (fill, target) => {
        if (target.dataItem && i === target.dataItem.index) {
          // Set label text color to match bar color
          labelBullet.label.fill = am4core.color(colors[i])
          return am4core.color(colors[i])
        }
        return fill
      })
    }

    // Add vertical axis line on the left side
    const valueAxis2 = chart.yAxes.push(new ValueAxis())
    valueAxis2.renderer.line.stroke = am4core.color('#000')
    valueAxis2.renderer.line.strokeWidth = 0.3
    valueAxis2.renderer.line.strokeOpacity = 1

    // Add cursor
    chart.cursor = new XYCursor()

    // Dispose chart when component unmounts
    return () => {
      chart.dispose()
    }
  }, [])

  return <div id='chartdiv' style={{width: '100%', height: '450px'}}></div>
}

export default TotalProceduresChart
