import ChartComponent from './components/ChartComponent'
import React, {useState} from 'react'
import RecentTranComponent from './components/RecentTranComponent'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'




const Data_Enrich_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intellegent Analytics',
    path: '/app/intellegent-analytics',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OutboundTransactionChart: React.FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={Data_Enrich_Breadcrumbs}>Outbound Transaction Insight</PageTitle>
      <div className='row gx-5 gx-xl-10 mb-12'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-500'>Total Transactions by Country</span>
            </h3>
          </div>
          <ChartComponent />
        </div>
      </div>
      <div className='row gx-5 gx-xl-10'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-500'>Total Currency ($) by Country</span>
            </h3>
          </div>
          <RecentTranComponent />
        </div>
      </div>
    </>
  )
}

export default OutboundTransactionChart
