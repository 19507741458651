import React from 'react';
import customerChurn from '../../../../../_metronic/assets/myimages/CustChurn.png';

const CustomerChurn = () => {
  return (
    <>
    <div className='card'>
      <div className='card-body' style={{ height: '550px', overflowY: 'auto' }}>
        <img src={customerChurn} alt="customerchum" style={{ maxWidth: '100%' }} />
      </div>
    </div>
    </>
  );
};

export default CustomerChurn;
