import React from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import DataEnrichRuleBook from './DataEnrichRuleBook'

const Data_Enrich_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Business Process Management Process',
    path: '/process/bpms',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Finance',
    path: '/process/bpms/finance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Money Remittance',
    path: '/process/bpms/finance/remittance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Data Enrichment',
    path: '/process/bpms/finance/enrichment',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DataEnrichRulePreview: React.FC<any> = (props) => {

  return (
    <>
      <PageTitle breadcrumbs={Data_Enrich_Breadcrumbs}>Preview Enrichment Rules</PageTitle>

      <div className='d-flex flex-column flex-lg-row mb-0'>
        <div className='flex-lg-row-fluid'>
          <div className='card mb-4 mb-xl-2'>
            <div className='card-header mt-1'>
              <div className='card-title flex-column'>
                <h2 className='mb-2'>Enrichment Rules Confirmation </h2>
              </div>
            </div>
            <div className='card-body'>
              <div className='notice d-flex bg-light-primary rounded border-primary border border-dashed mb-10 p-4'>
                {/* Icon */}
                <i className='ki-duotone ki-information fs-2tx text-primary me-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
                {/* Wrapper */}
                <div className='d-flex flex-stack flex-grow-1'>
                  {/* Content */}
                  <div className='mb-3 mb-md-0 fw-semibold'>
                    <div className='fs-6 text-gray-700'>
                      <span className='text-gray-900 fw-bold fs-3'>Need Attention ! </span>Before proceeding with the data enrichment process, we want to inform you
                      about the potential impact on your database records.
                    </div>
                  </div>
                </div>
              </div>
              <DataEnrichRuleBook {...props}/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DataEnrichRulePreview
