import {connect} from 'react-redux'
import {SelectState} from './Selector'

import {
  setDynamicFields,
  setSelectedTable,
  setSelectedColumn,
  fetchColumnRules,
  fetchTables,
  setColumnRules,
  setTableColumns,
  fetchTableColumns,
  toggleColumnEnabled,
  setSelectedRules,
  appendSelectedRules,
  postDataToApi,
  clearProps,
  removeSelectedRules,
  getGraphData,
  getErrorData,
  clearPopupProps,
  resetState,
  getDataEnrichment,
  financeOption,
  applyDataEnrichment,
  completedDataProfiling,
  uploadExcelProps,
  selectedEnrichmentField,
  modalClicked,
  setEnrichmentRules,
  fetchBusinessRules,
  getCustomerDataonEnrichment,
  fetchEnrichedCustData,
  setDBSubSection,
  getDbNames,
  getTableNames,
  getTableInfo,
  // getDataPreview,
  getDataHistoryDetails,
  getDataHistory,
  getDataExecution,
  setArchivalTable,
} from '../Redux/Actions/actions'
// import {MasterInit} from '../../../../../../_metronic/layout/MasterInit'
import {AppRoutes} from '../../../../../routing/AppRoutes'

interface StateProps {
  dynamicFields: any[]
  selectedTable: string | null
  // ... (other state props)
}

const mapStateToProps = (state: StateProps) => {
  return {...SelectState(state)}
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearProps: () => {
      dispatch(clearProps())
    },
    setDynamicFields: (params: any) => {
      dispatch(setDynamicFields(params))
    },
    setSelectedTable: (params1: any) => {
      dispatch(setSelectedTable(params1))
    },
    setSelectedColumn: (params: any) => {
      dispatch(setSelectedColumn(params))
    },
    // fetchColumnRules: (params1: any, params2: any, params3: any) => {
    //   dispatch(fetchColumnRules(params1,params2,params3));
    // },
    fetchColumnRules: (params1: any) => {
      dispatch(fetchColumnRules(params1))
    },

    fetchTables: (params: any) => {
      dispatch(fetchTables(params))
    },
    setColumnRules: (params1: any, params2: any) => {
      dispatch(setColumnRules(params1, params2))
    },
    setTableColumns: (params1: any, params2: any) => {
      dispatch(setTableColumns(params1, params2))
    },
    fetchTableColumns: (params1: any) => {
      dispatch(fetchTableColumns(params1))
    },
    toggleColumnEnabled: (params1: any) => {
      dispatch(toggleColumnEnabled(params1))
    },
    setSelectedRules: (params: any) => {
      dispatch(setSelectedRules(params))
    },
    appendSelectedRules: (params1: any, params2: any, params3: any) => {
      dispatch(appendSelectedRules(params1, params2, params3))
    },
    removeSelectedRules: (params: string) => {
      dispatch(removeSelectedRules(params))
    },
    postDataToApi: (params1: any, params2: any) => {
      dispatch(postDataToApi(params1, params2))
    },
    getDataEnrichment: (params1: any, params2: any) => {
      dispatch(getDataEnrichment(params1, params2))
    },
    applyDataEnrichment: (params1: any, params2: any) => {
      dispatch(applyDataEnrichment(params1, params2))
    },
    getGraphData: (
      params1: string,
      params2: string,
      params3: string,
      params4: string,
      params5: string,
      params6: number,
      params7: number,
      params8: string
    ) => {
      dispatch(getGraphData(params1, params2, params3, params4, params5, params6, params7, params8))
    },
    getErrorData: (
      params1: string,
      params2: string,
      params3: string,
      params4: string,
      params5: string,
      params6: number,
      params7: number,
      params8: string,
      params9: number
    ) => {
      dispatch(
        getErrorData(
          params1,
          params2,
          params3,
          params4,
          params5,
          params6,
          params7,
          params8,
          params9
        )
      )
    },
    completedDataProfiling: () => {
      dispatch(completedDataProfiling())
    },
    clearPopupProps: () => {
      dispatch(clearPopupProps())
    },
    financeOption: (params) => {
      dispatch(financeOption(params))
    },
    uploadExcelProps: () => {
      dispatch(uploadExcelProps())
    },
    setArchivalTable: (params) =>{
      dispatch(setArchivalTable(params))
    },
    resetState: () => {
      dispatch(resetState())
    },
    selectedEnrichmentField: (params) => {
      dispatch(selectedEnrichmentField(params))
    },
    modalClicked: () => {
      dispatch(modalClicked())
    },
    setEnrichmentRules: (params) => {
      dispatch(setEnrichmentRules(params))
    },
    fetchBusinessRules: (params1, params2) => {
      dispatch(fetchBusinessRules(params1, params2))
    },
    getCustomerDataonEnrichment: (params, params1) => {
      dispatch(getCustomerDataonEnrichment(params, params1))
    },
    fetchEnrichedCustData: (params1, params2) => {
      dispatch(fetchEnrichedCustData(params1, params2))
    },
    setDBSubSection: (selectedOption, sectionName) => {
      dispatch(setDBSubSection(selectedOption, sectionName))
    },

    // getDbNames: (params: string) => {
    //   dispatch(getDbNames(params))
    // },

    getDbNames: (params: any) => {
      const product = params.product; // Extracting the 'product' property from the object
      dispatch(getDbNames(product)); // Passing the 'product' property as a string to the function
    },
        
    // getTableNames: (params: string, params1: string) => {
    //   dispatch(getTableNames(params, params1))
    // },

    getTableNames: (params: { product: string, schemaName: string }) => {
      dispatch(getTableNames(params.product, params.schemaName))
    },


    // getTableInfo: (params: string, params1: string, params2: string) => {
    //   dispatch(getTableInfo(params, params1, params2))
    // },

      getTableInfo: (params: { product: string, schemaName: string, tableName: string }) => {
      dispatch(getTableInfo(params.product, params.schemaName, params.tableName))
    },

    // getDataHistory: (params: string) => {
    //   dispatch(getDataHistory(params))
    // },

    getDataHistory: (params: any) => {
      const product = params.product; // Extracting the 'product' property from the object
      dispatch(getDataHistory(product)); // Passing the 'product' property as a string to the function
    },

    // getDataHistoryDetails: (params: string, params1: string) => {
    //   dispatch(getDataHistoryDetails(params, params1))
    // },

    getDataHistoryDetails: (params: { product: string, archivalTaskId: string }) => {
      dispatch(getDataHistoryDetails(params.product, params.archivalTaskId))
    },

    // getDataPreview: (params: string, params1: string) => {
    //   dispatch(getDataPreview(params, params1))
    // },

    getDataExecution: (params: string) => {
      dispatch(getDataExecution(params))
    },

    // getDataExecution: (params: { product: string, schemaName: string, tableName: string, retentionYear: string}) => {
    //   dispatch(getDataExecution(params.product, params.schemaName, params.tableName, params.retentionYear))
    // },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes)
