import {Modal} from 'bootstrap'
import DataReadinessComponent from './DataReadinessComponent'
import $ from 'jquery'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import DataLoader from './DataLoader'
import {SCHEMA} from '../../../../../pages/data/trad/brms/Config'

interface Rule {
  Id: string
  rule: string
  desc: string
}

const DataEnrichmentModal = (props) => {
  const navigate = useNavigate()
  const [showLoader, setShowLoader] = useState(false)

  const handleClick = () => {
    // let apiData = {
    //   db: SCHEMA,
    //   table: props.selectedTable,
    //   data: props.selectedRulesHistory.map((item) => ({
    //     FieldName: item.column,
    //     selected_rules: item.rules.map((rule: Rule, index: number) => ({
    //       Id: item.ruleIds[index],
    //       rule: rule,
    //     })),
    //   })),
    // }
    // // props.applyDataEnrichment(apiData)
    // props.getDataEnrichment('', apiData)
    navigate('/process/bpms/finance/remittance/enrichment/report')
  }

  useEffect(() => {
    if (!props.isApplyingDataEnrichment && !props.enrichmentDataWaiting) {
      setShowLoader(false);
    }
  }, [props.isApplyingDataEnrichment, props.enrichmentDataWaiting]);
  useEffect(() => {
      setShowLoader(true);
  }, [props.ismodalClicked]);

  return (
    <>
      <span data-bs-toggle='modal' data-bs-target='#kt_modal_DE_Loader'></span>
      <div className='modal fade' tabIndex={-1} id='kt_modal_DE_Loader'>
        <div className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered h-auto modal-dialog mw-550px'>
          <div className='modal-content' style={{width: '100%'}}>
            <div className='modal-header py-2'>
              <h2>Data Enrichment</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <i className='ki-duotone ki-cross fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>
            {/* {props.isApplyingDataEnrichment&& */}
            <DataLoader {...props} />
            {/* } */}
            {/* <div className='d-flex flex-stack pt-10'>
              <div style={{marginLeft: 'auto'}}>
                {
                  //  props.AppliedDataEnrichment?
                  showLoader ?null: (
                    <button
                      type='button'
                      className='btn btn-sm btn-primary'
                      data-bs-dismiss='modal'
                      onClick={handleClick}
                    >
                      Enrichment Report
                      <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </button>
                  ) 
                }
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default DataEnrichmentModal
