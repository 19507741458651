import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { checkIsActive, KTIcon, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  props?: any;
};

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  props,
}) => {
  const { pathname } = useLocation();
  // const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    
    const storedMenuState = localStorage.getItem('menuState');
    if (storedMenuState !== null) {
      setMenuOpen(JSON.parse(storedMenuState));
    }
  }, []);

  const handleMenuClick = (e) => {
    console.log("ENTERED")
    e.stopPropagation()
    console.log("ENTERED1")
    localStorage.setItem('title',title)
    const newMenuState = !isMenuOpen;
    setIsActive(checkIsActive(pathname, to))
    console.log("ENTERED2",isActive,title)
    setMenuOpen(newMenuState);
    console.log("ENTERED3",isMenuOpen)
    localStorage.setItem('menuState', JSON.stringify(newMenuState));
  };

  return (
    <div
      key={title}
      className={clsx(`menu-item menu-accordion ${( (isMenuOpen)&&localStorage.getItem('title')===title)&&'here show'}` )}
      onClick={(e)=>handleMenuClick(e)}
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx(`menu-sub menu-sub-accordion ${( (isMenuOpen|| isActive)&&localStorage.getItem('title')===title)&&'menu-active-bg'}`  )}>
        {children}
      </div>
    </div>
  );
};

export { SidebarMenuItemWithSub };
