import React from 'react';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import FinanceListWidget from './FinanceListWidget';

const BP_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Business Process Management Solution',
    path: '/process/bpms/finance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const FinanceProductList = (props) => {
  return (
    <>
      <PageTitle breadcrumbs={BP_Breadcrumbs}>Finance Business Process</PageTitle>

      <FinanceListWidget {...props} />
    </>
  );
};

export default FinanceProductList;
