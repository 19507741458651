import React, { useEffect } from 'react';
import { create } from '@amcharts/amcharts4/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import { XYChart, CategoryAxis, ValueAxis, ColumnSeries, XYCursor, LabelBullet } from '@amcharts/amcharts4/charts';

const RecentTranComponent: React.FC = () => {
  useEffect(() => {
    // Use am4core
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    const chart = create('chartdiv', XYChart);
    chart.language.locale = am4lang_en_US;
    chart.paddingRight = 20;
    
       // Disable the AmCharts logo
chart.logo.disabled = true;

    // Add data
    const data = [
      { country: 'AG', value: 18793.83 },
      { country: 'CA', value: 11350701.75 },
      { country: 'GB', value: 40182146.28 },
      { country: 'JM', value: 90097.86 },
      { country: 'KY', value: 3473375.68 },
      { country: 'TC', value: 8769.82 },
      { country: 'US', value: 58269787.18 },
    ];
    chart.data=data;

    const max = Math.max(...data.map(item => item.value));

    

    // Create axes
    const categoryAxis = chart.xAxes.push(new CategoryAxis());
    categoryAxis.dataFields.category = 'country';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.disabled = true; // Disable grid lines for x-axis
    categoryAxis.title.text = 'Country Code';

    const valueAxis = chart.yAxes.push(new ValueAxis());
    valueAxis.renderer.grid.template.disabled = false; // Disable grid lines for y-axis
    valueAxis.title.text = 'Currency($)';
    valueAxis.numberFormatter.numberFormat = '$#,###'; // Add commas to the vertical axis values
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.strictMinMax = true;
    valueAxis.min = 1; // Start from 1
    valueAxis.max= max*10;
    valueAxis.logarithmic = true;
    // Create series
    const series = chart.series.push(new ColumnSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'country';
    series.name = 'Country Values';

    // Define an array of unique colors
    const colors = ['#488f31', '#8ba944', '#c7c261', '#ffdc87', '#f7aa63', '#FF5733', '#de425b'];

    // Set different colors for each column
    series.columns.template.stroke = am4core.color('#000'); // Default stroke color
    series.columns.template.strokeOpacity = 0.5; // Adjust opacity
    series.columns.template.adapter.add('fill', (fill, target) => {
      // Check if target.dataItem is defined
      if (target.dataItem) {
        return am4core.color(colors[target.dataItem.index]);
      }
      // Return default fill if target.dataItem is undefined
      return fill;
    });

    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.text = '{valueY}';
    labelBullet.label.dy = -10; // Adjust vertical offset
    labelBullet.label.truncate = false; // Ensure the whole number is displayed on top of the bars
    labelBullet.label.numberFormatter.numberFormat = '$#,###';// Display full number with commas

    // Assign colors to each series individually
    for (let i = 0; i < colors.length; i++) {
      const column = series.columns.template;
      column.adapter.add('fill', (fill, target) => {
        if (target.dataItem && i === target.dataItem.index) {
          // Set label text color to match bar color
          labelBullet.label.fill = am4core.color(colors[i]);
          return am4core.color(colors[i]);
        }
        return fill;
      });
    }

    // Add vertical axis line on the left side
    const valueAxis2 = chart.yAxes.push(new ValueAxis());
    valueAxis2.renderer.line.stroke = am4core.color('#000');
    valueAxis2.renderer.line.strokeWidth = 0.3;
    valueAxis2.renderer.line.strokeOpacity = 1;

    // Add a horizontal line on top of the chart
const topLine = valueAxis.axisRanges.create();
topLine.value = max * 10; // Set the value where the horizontal line should appear
topLine.grid.stroke = am4core.color('black'); // Set line color
topLine.grid.strokeWidth = 2; // Set line width


    // Add cursor
    chart.cursor = new XYCursor();

    // Dispose chart when component unmounts
    return () => {
      chart.dispose();
    };
  }, []);

  return <div id="chartdiv" style={{ width: '100%', height: '450px' }}></div>;
};

export default RecentTranComponent;
