import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../_metronic/partials'

interface RuleData {
  Id: string
  rule: string
  desc: string
  error: string
  stats: {
    error: string
  }
}

interface BarChartProps {
  data: {
    fieldData: {
      fieldName: string
      TotalNoOfRows: string
      NoOfRowsNulls: string
      nullPercentage: string
      uniquePercentage: string
      errorPercentage: string
      unique: string
    }
    fieldName: string
    selectedRule: RuleData[]
  }
  props: {
    selectedTable: string
    selectedColumn: string
    getGraphData: (db: string, table: string, column: string, input: string) => void
    graphData: any[]
    graphDataSuccess: boolean
  }
  className: string
}

const BarChart2: React.FC<BarChartProps> = ({className, data}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, data])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, data.fieldData.nullPercentage, data.fieldData.uniquePercentage)
    )

    if (chart) {
      chart.render()
    }
    return chart
  }
  return <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '460px'}} />
}

export {BarChart2}

function getChartOptions(
  height: number,
  nullPercentage: string,
  uniquePercentage: string
): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  // const errorValue = parseFloat(errorPercentage.replace('%', '')) || 0;
  const nullValue = parseFloat(nullPercentage.replace('%', '')) || 0
  const uniqueValue = parseFloat(uniquePercentage.replace('%', '')) || 0

  return {
    series: [
      {
        name: 'Error',
        data: [nullValue, uniqueValue],
      },
      // {
      //   name: 'Total',
      //   data: [100,100],
      // },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      width:'60%',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Null', 'Unique'],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '10px',
      },
      y: {
        formatter: function (val) {
          return val + '%'
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
