import React from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import ArchivalTableInfo from './component/ArchivalTableInfo'
import ArchivalDBStructure from './component/ArchivalDBStructure'

const data_archival_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intelligent Analytics',
    path: '/data/quality',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DataArchivalSelection: React.FC<any> = (props) => {
  return (
    <>
      <PageTitle breadcrumbs={data_archival_Breadcrumbs}>Data Archival Solution</PageTitle>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <ArchivalDBStructure {...props} />
        </div>
        <div className='col-xl-8'>
          <ArchivalTableInfo {...props} />
        </div>
      </div>
    </>
  )
}

export default DataArchivalSelection
