import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import UploadFile from '../../../finance/banking/remittance/UploadFile'

const FlatFileConnOption = (props: any) => {
  const [selectedFileConn, setSelectedFileConn] = useState('')
  const [modalOpen, setModalOpen] = useState(false)

  const handleFileConnChange = (value) => {
    setSelectedFileConn(value)
    // openModal()
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const openModal = () => {
    setModalOpen(true)
    const modalId = `upload_modal_${selectedFileConn}`
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    if (modalTrigger) {
      ;(modalTrigger as any).click()
    }
  }

  const openSweetAlert = () => {
    // e.preventDefault();

    Swal.fire({
      text: 'Are you sure you want to cancel ?',
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: 'No,return',
      confirmButtonText: 'Yes, Cancel it',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn  btn-light',
      },
    })
  }

  useEffect(() => {
    if (modalOpen) {
      const modalId = `upload_modal_${selectedFileConn}`
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
      if (modalTrigger) {
        ;(modalTrigger as any).click()
      }
    }
  }, [modalOpen, selectedFileConn])

  return (
    <>
      <div className='row mx-auto w-100 py-10 fv-plugins-bootstrap5 fv-plugins-framework'>
        <div className='col-lg-5'>
          <input
            type='radio'
            className='btn-check'
            name='file_conn_name'
            value='JSON'
            checked={selectedFileConn === 'JSON'}
            onChange={() => handleFileConnChange('JSON')}
            id='kt_db_conn_json'
          />
          <label
            className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10'
            htmlFor='kt_db_conn_json'
          >
            <i className='ki-duotone ki-folder fs-3x '>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            {/* begin::Info */}
            <span className='d-block fw-semibold text-start'>
              <span className='text-dark fw-bold d-block fs-4 mb-2'>JSON Files</span>
              <span className='text-muted fw-semibold fs-6'>
                If you need more info, please check it out
              </span>
            </span>
            {/* end::Info */}
          </label>
          {/* end::Option */}
          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
        </div>
        <div className='col-lg-5'>
          <input
            type='radio'
            className='btn-check'
            name='file_conn_name'
            value='CSV'
            checked={selectedFileConn === 'CSV'}
            onChange={() => handleFileConnChange('CSV')}
            id='kt_db_conn_csv'
          />
          <label
            className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10'
            htmlFor='kt_db_conn_csv'
          >
            <i className='ki-duotone ki-folder fs-3x'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            {/* begin::Info */}
            <span className='d-block fw-semibold text-start'>
              <span className='text-dark fw-bold d-block fs-4 mb-2'>CSV Files</span>
              <span className='text-muted fw-semibold fs-6'>
                If you need more info, please check it out
              </span>
            </span>
            {/* end::Info */}
          </label>
          {/* end::Option */}
          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
        </div>
        {/* end::Col */}
      </div>
      <div className='d-flex flex-stack pt-0 pb-8'>
        <div
          style={{
            marginLeft: 'auto',
            ...(window.innerWidth > 768 ? {paddingRight: '28px'} : {paddingRight: '15px'}),
          }}
        >
          <button
            type='button'
            disabled={selectedFileConn===''?true:false}
            className={`btn btn-lg ${selectedFileConn===''?'btn-light':'btn-primary'}`}
            onClick={() => {
              openModal()
              // props.uploadExcelProps()
            }}
          >
            Continue
            <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </button>
          {/* <SweetAlertExample /> */}
        </div>
      </div>
      {modalOpen && (
        <>
          <span data-bs-toggle='modal' data-bs-target={`#upload_modal_${selectedFileConn}`}></span>
          <div className='modal fade' tabIndex={-1} id={`upload_modal_${selectedFileConn}`}>
            <div
              className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered h-auto'
              onClick={(e) => e.stopPropagation()}
            >
              <div className='modal-content' style={{width: '100%'}}>
                <div className='modal-header'>
                  <h5 className='modal-title'>{selectedFileConn}</h5>
                  <div
                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={closeModal}
                  >
                    <i className='ki-duotone ki-cross fs-2x'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </div>
                </div>
                <div className='modal-body'>
                  <UploadFile fileType={selectedFileConn} props={props} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default FlatFileConnOption
