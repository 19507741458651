import {ThunkAction} from 'redux-thunk'
import {AnyAction} from 'redux'
import axios from 'axios'
import {
  SET_DYNAMIC_FIELDS,
  SET_SELECTED_TABLE,
  SET_SELECTED_COLUMN,
  SET_TABLE_COLUMNS,
  SET_COLUMN_RULES,
  TOGGLE_COLUMN_ENABLED,
  APPEND_SELECTED_RULES,
  SET_SELECTED_RULES,
  POST_DATA_SUCCESS,
  POST_DATA_FAILED,
  POST_DATA_WAITING,
  CLEAR_PROPS,
  REMOVE_SELECTED_RULES,
  GRAPH_DATA_SUCCESS,
  GRAPH_DATA_LOADING,
  GRAPH_DATA_FAILED,
  FETCHING_COLUMNS,
  FETCHING_COLUMNS_FAILED,
  FETCHING_RULES,
  FETCHING_RULES_FAILED,
  ERROR_DATA_LOADING,
  ERROR_DATA_SUCCESS,
  ERROR_DATA_FAILED,
  CLEAR_POPUP_PROPS,
  RESET_STATE,
  FINANCE_SOLUTION_OPTION,
  APPLYING_DATA_ENRICHMENT,
  APPLIED_DATA_ENRICHMENT,
  FAILED_DATA_ENRICHMENT,
  DATA_PROFILING_COMPLETED,
  SELECTED_ENRICHMENT_FIELD,
  SET_ENRICHMENT_SELECTED_RULES,
  FETCHING_BUSINESS_RULES,
  FETCH_BUSINESS_RULES_SUCCESS,
  FETCH_BUSINESS_RULES_FAILED,
  ENRICHMENT_MODAL_DATA_LOADING,
  ENRICHMENT_MODAL_DATA_SUCCESS,
  ENRICHMENT_MODAL_DATA_FAILED,
  FETCHING_ENRICHED_DATA,
  FETCHING_ENRICHED_DATA_SUCCESS,
  FETCHING_ENRICHED_DATA_FAILED,
  DATABASE_STRUC_SELECTED_SECTION,
} from './actionTypes'
import {
  DATA_ENRICHMENT_API,
  DATA_ENRICHMENT_EXECUTE_API,
  DATA_REPORT_POST_API,
  DA_EXECUTION_API,
  DA_FETCH_SCHEMA_API,
  DA_FETCH_TABLES_API,
  DA_FETCT_HISTORY_API,
  DA_FETCT_HISTORY_INFO_API,
  DA_FETCT_TBLINFO_API,
  ENRICHMENT_DATA_SUCCESS,
  ENRICHMENT_DATA_WAITING,
  FETCH_COLUMNS_API,
  FETCH_ENRICHED_DATA_API,
  FETCH_ENRICHMENT_RULE_API,
  FETCH_ENRICHMENT_RULE_VIEW_API,
  // FETCH_ENRICH_RULE_DATA_API,
  FETCH_ERROR_DATA_API,
  FETCH_GRAPH_DATA_API,
  FETCH_RULES_API,
  FETCH_TABLE_API,
} from '../../../brms/Config'
import { table } from 'console'

interface Rule {
  Id: string
  rule: string
  desc: string
}

interface ColumnRules {
  [key: string]: {
    rules: Rule[]
  }
}

export interface StateProps {
  dynamicFields: any[]
  selectedTable: string | null
  selectedColumn: any
  postingData?: boolean
  postDataSuccess?: boolean
  postDataFailed?: boolean
  postResponse?: any
  postDataErrorMessage?: string
  tableColumns: Record<string, any>
  columnRules: any
  columnEnabled: Array<Record<string, boolean>>
  selectedRules: any[]
  selectedRulesHistory: any[]
}

// Action Types

interface SetDynamicFieldsAction {
  type: typeof SET_DYNAMIC_FIELDS
  payload: any[]
}

interface setDBSubSectionAction {
  type: typeof DATABASE_STRUC_SELECTED_SECTION
  payload: {
    sectionName: string
  }
}

interface SetSelectedTableAction {
  type: typeof SET_SELECTED_TABLE
  payload: {
    tableName: string
  }
}

interface SetSelectedColumnAction {
  type: typeof SET_SELECTED_COLUMN
  payload: string
}

interface SetTableColumnsAction {
  type: typeof SET_TABLE_COLUMNS
  payload: {
    tableName: string
    columns: string[]
  }
}

interface SetColumnRulesAction {
  type: typeof SET_COLUMN_RULES
  payload: {
    column: string
    rules: ColumnRules
  }
}

interface ToggleColumnEnabledAction {
  type: typeof TOGGLE_COLUMN_ENABLED
  payload: any
}

interface SetSelectedRulesAction {
  type: typeof SET_SELECTED_RULES
  payload: any[]
}

interface AppendSelectedRulesAction {
  type: typeof APPEND_SELECTED_RULES
  payload: {
    rules: any[]
    column: string
    ruleIds: string[]
  }
}

interface RemoveSelectedRulesAction {
  type: typeof REMOVE_SELECTED_RULES
  payload: {
    column: string
  }
}

interface PostDataSuccessAction {
  type: typeof POST_DATA_SUCCESS
  payload: any
}

interface PostDataFailedAction {
  type: typeof POST_DATA_FAILED
  payload: string
}

interface PostDataWaitingAction {
  type: typeof POST_DATA_WAITING
}

export type ActionTypes =
  | SetDynamicFieldsAction
  | SetSelectedTableAction
  | SetSelectedColumnAction
  | SetTableColumnsAction
  | SetColumnRulesAction
  | ToggleColumnEnabledAction
  | SetSelectedRulesAction
  | AppendSelectedRulesAction
  | PostDataSuccessAction
  | PostDataFailedAction
  | PostDataWaitingAction

export const clearProps = () => ({
  type: CLEAR_PROPS,
})

// Action Creators

export const setDynamicFields = (fields: any[]): SetDynamicFieldsAction => ({
  type: SET_DYNAMIC_FIELDS,
  payload: fields,
})

export const setSelectedTable = (tableName: string): SetSelectedTableAction => ({
  type: SET_SELECTED_TABLE,
  payload: {tableName},
})

export const setSelectedColumn = (columnName: string): SetSelectedColumnAction => ({
  type: SET_SELECTED_COLUMN,
  payload: columnName,
})

export const setTableColumns = (tableName: string, columns: string[]): SetTableColumnsAction => ({
  type: SET_TABLE_COLUMNS,
  payload: {tableName, columns},
})

export const setColumnRules = (column: string, rules: ColumnRules): SetColumnRulesAction => ({
  type: SET_COLUMN_RULES,
  payload: {column, rules},
})

export const fetchTables =
  (db: string): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      console.log('Sending request to the server1...')

      dispatch({type: 'FETCHING_TABLES'})

      const response = await axios.post(FETCH_TABLE_API, db)

      const data = response.data
      dispatch(setDynamicFields(data.response.table))
    } catch (error) {
      dispatch({type: 'FETCH_TABLES_FAILED', payload: 'Something went wrong'})
      console.error('Error fetching tables:', error)
    }
  }

export const fetchTableColumns =
  (table: string): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch({type: FETCHING_COLUMNS})
      const response = await axios.post(FETCH_COLUMNS_API, table)
      const data = response.data

      dispatch(setTableColumns(data.response.table, data.response.columns))
    } catch (error) {
      dispatch({type: FETCHING_COLUMNS_FAILED})
      console.error('Error fetching table columns:', error)
    }
  }

export const fetchColumnRules =
  (column: string): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch({type: FETCHING_RULES})
      const response = await axios.post(FETCH_RULES_API, column)
      const data = response.data

      const customData = {
        [data.response.column]: {
          rules: data.response.rules,
        },
      }

      dispatch(setColumnRules(data.response.column, customData))
    } catch (error) {
      dispatch({type: FETCHING_RULES_FAILED})
      console.error('Error fetching column rules:', error)
    }
  }

export const toggleColumnEnabled = (enabledColumns: any): ToggleColumnEnabledAction => ({
  type: TOGGLE_COLUMN_ENABLED,
  payload: enabledColumns,
})

export const setSelectedRules = (rules: any): SetSelectedRulesAction => ({
  type: SET_SELECTED_RULES,
  payload: rules,
})

export const appendSelectedRules = (
  column: string,
  rules: string[],
  ruleids: string[]
): AppendSelectedRulesAction => ({
  type: APPEND_SELECTED_RULES,
  payload: {rules, column, ruleIds: ruleids},
})

export const removeSelectedRules = (column: string): RemoveSelectedRulesAction => ({
  type: REMOVE_SELECTED_RULES,
  payload: {column},
})

export const postDataToApi =
  (apiEndpoint: string, apiData: any): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    // Dispatch action to indicate the start of the API call
    dispatch({type: POST_DATA_WAITING})

    try {
      const response = await axios.post(DATA_REPORT_POST_API, apiData)

      const respData = response.data.response

      console.log('Resp GetStat : ', respData)

      dispatch({
        type: POST_DATA_SUCCESS,
        payload: respData,
      })
    } catch (error) {
      console.error('Error fetching column rules:', error)
    }
  }

// DATA_ENRICHMENT_API
export const applyDataEnrichment =
  (apiEndpoint: string, apiData: any): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    // Dispatch action to indicate the start of the API call
    dispatch({type: APPLYING_DATA_ENRICHMENT})

    let applyData = {
      db: apiData.db,
      table: apiData.table,
      column: apiData.data[0].FieldName,
      ruleData: apiData.data[0].selected_rules.map((item) => ({ruleId: item.Id})),
      // data: apiData.data.map((item) => ({ FieldName: item.FieldName })),
    }

    try {
      console.log('Apply Enrichment Reques Param :: ', applyData)

      // const response = await axios.post(DATA_ENRICHMENT_API, applyData);
      const response = await axios.post(DATA_ENRICHMENT_EXECUTE_API, applyData)

      const respData =
        apiData.data[0].FieldName === 'FirstName_NME' ||
        apiData.data[0].FieldName === 'LastName_NME'
          ? response.data.response
          : {
              message: 'Data Enrichment Completed',
              statusCode: 200,
            }

      //   const respData = {
      //     "message": "Data Enrichment Completed",
      //     "statusCode": 200,
      //  }

      dispatch({
        type: APPLIED_DATA_ENRICHMENT,
        payload: respData,
      })

      // Dispatch the getDataEnrichment action and await its completion
      // await dispatch(getDataEnrichment('', apiData))
    } catch (error) {
      dispatch({
        type: FAILED_DATA_ENRICHMENT,
        payload: error,
      })
    }
  }

// FETCH_BUSINESS_RULES
export const fetchBusinessRules =
  (apiEndpoint: string, apiData: any): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    // Dispatch action to indicate the start of the API call
    dispatch({type: FETCHING_BUSINESS_RULES})

    try {
      let req = {
        params: apiData,
      }

      const response = await axios.get(
        apiData.column === 'FirstName_NME' || apiData.column === 'LastName_NME'
          ? FETCH_ENRICHMENT_RULE_API
          : DATA_ENRICHMENT_API,
        req
      )

      console.log('Fetch Enrich Rule ::  ', response)

      const respData = response.data.response

      dispatch({
        type: FETCH_BUSINESS_RULES_SUCCESS,
        payload: respData,
      })

      // Dispatch the getDataEnrichment action and await its completion
      // await dispatch(getDataEnrichment('', apiData));
    } catch (error) {
      dispatch({
        type: FETCH_BUSINESS_RULES_FAILED,
        payload: error,
      })
    }
  }

export const getDataEnrichment =
  (apiEndpoint: string, apiData: any): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    // Dispatch action to indicate the start of the API call
    dispatch({type: ENRICHMENT_DATA_WAITING})

    try {
      const response = await axios.post(DATA_REPORT_POST_API, apiData)

      const respData = response.data.response

      dispatch({
        type: ENRICHMENT_DATA_SUCCESS,
        payload: respData,
      })
    } catch (error) {
      console.error('Error fetching column rules:', error)
    }
  }

// export const getGraphData =
//   (
//     db: string,
//     table: string,
//     column: string,
//     input: string
//   ): ThunkAction<void, StateProps, unknown, AnyAction> =>
//   async (dispatch) => {
//     dispatch({type: GRAPH_DATA_LOADING})
//     try {
//       // const response = await axios.post('/api/getColumnRules', { db, table, column, input });
//       // const data = response.data;

//       const data = {
//         CustomerID: '11006',
//         FieldName: 'phone',
//         FieldData: '1 (11) 500 555-0184',
//         Rule: 'Phone Unique Value Rule',
//         Data: [
//           {
//             CustomerKey: 11006,
//             GeographyKey: 8,
//             CustomerAlternateKey: 'AW00011006',
//             Title: null,
//             FirstName: 'Janet',
//             MiddleName: 'G',
//             LastName: 'Alvarez',
//             NameStyle: 0,
//             BirthDate: '1976-12-02',
//             Suffix: null,
//             Gender: 'F',
//             EmailAddress: 'janet9@adventure-works.com',
//             YearlyIncome: 70000.0,
//             TotalChildren: 0,
//             NumberChildrenAtHome: 0,
//             EnglishEducation: 'Bachelors',
//             SpanishEducation: 'Licenciatura',
//             FrenchEducation: 'Bac + 4',
//             EnglishOccupation: 'Professional',
//             SpanishOccupation: 'Profesional',
//             FrenchOccupation: 'Cadre',
//             HouseOwnerFlag: '1',
//             NumberCarsOwned: 1,
//             AddressLine1: '2612 Berry Dr',
//             AddressLine2: null,
//             Phone: '1 (11) 500 555-0184',
//             DateFirstPurchase: '2011-01-24',
//             CommuteDistance: '5-10 Miles',
//             CustomerStatus: 'Active',
//           },
//           {
//             CustomerKey: 11006,
//             GeographyKey: 8,
//             CustomerAlternateKey: 'AW00011006',
//             Title: null,
//             FirstName: 'Janet',
//             MiddleName: 'G',
//             LastName: 'Alvarez',
//             NameStyle: 0,
//             BirthDate: '1976-12-02',
//             Suffix: null,
//             Gender: 'F',
//             EmailAddress: 'janet9@adventure-works.com',
//             YearlyIncome: 70000.0,
//             TotalChildren: 0,
//             NumberChildrenAtHome: 0,
//             EnglishEducation: 'Bachelors',
//             SpanishEducation: 'Licenciatura',
//             FrenchEducation: 'Bac + 4',
//             EnglishOccupation: 'Professional',
//             SpanishOccupation: 'Profesional',
//             FrenchOccupation: 'Cadre',
//             HouseOwnerFlag: '1',
//             NumberCarsOwned: 1,
//             AddressLine1: '2612 Berry Dr',
//             AddressLine2: null,
//             Phone: '1 (11) 500 555-0184',
//             DateFirstPurchase: '2011-01-24',
//             CommuteDistance: '5-10 Miles',
//             CustomerStatus: 'Active',
//           },
//         ],
//       }
//       dispatch({type: GRAPH_DATA_SUCCESS, payload: data})
//     } catch (error) {
//       dispatch({type: GRAPH_DATA_FAILED, payload: error})
//     }
//   }

export const getGraphData =
  (
    db: string,
    table: string,
    column: string,
    rule: string,
    input: string,
    count: number,
    page: number,
    heading: string
  ): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({type: GRAPH_DATA_LOADING})
    try {
      const response = await axios.post(FETCH_GRAPH_DATA_API, {
        db,
        table,
        column,
        input,
        rows: 8,
        page: page,
      })
      const respData = response.data.response

      // dispatch({type: GRAPH_DATA_SUCCESS, payload: data})
      dispatch({
        type: GRAPH_DATA_SUCCESS,
        payload: {
          response: respData,
          count: count,
          fieldName: column,
          input: input,
          ruleID: rule,
          page: page,
          heading: heading,
        },
      })
    } catch (error) {
      dispatch({type: GRAPH_DATA_FAILED, payload: error})
    }
  }

export const getErrorData =
  (
    db: string,
    table: string,
    column: string,
    rule: string,
    input: string,
    count: number,
    page: number,
    heading: string,
    rows: number
  ): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({type: ERROR_DATA_LOADING})
    console.log('SASD', rows)
    try {
      const response = await axios.post(FETCH_ERROR_DATA_API, {
        db,
        table,
        column,
        ruleId: rule,
        input,
        rows: rows,
        page: page,
      })
      const respData = response.data.response

      // dispatch({type: GRAPH_DATA_SUCCESS, payload: data})
      dispatch({
        type: ERROR_DATA_SUCCESS,
        payload: {
          response: respData,
          count: count,
          fieldName: column,
          input: input,
          ruleID: rule,
          page: page,
          heading: heading,
          rows: rows,
        },
      })
    } catch (error) {
      dispatch({type: ERROR_DATA_FAILED, payload: error})
    }
  }

export const clearPopupProps = () => ({
  type: CLEAR_POPUP_PROPS,
})

export const resetState = () => {
  return {
    type: RESET_STATE,
  }
}

export const financeOption = (option) => {
  return {
    type: FINANCE_SOLUTION_OPTION,
    payload: option,
  }
}

export const completedDataProfiling = () => {
  return {
    type: DATA_PROFILING_COMPLETED,
  }
}

export const uploadExcelProps = () => {
  return {
    type: 'UPLOAD_EXCEL_PROPS',
  }
}

export const setArchivalTable = (table) => {
  return {
    type: 'setArchivalTable',
    payload:table
  }
}

export const selectedEnrichmentField = (params) => {
  return {
    type: SELECTED_ENRICHMENT_FIELD,
    payload: params,
  }
}

export const modalClicked = () => {
  return {
    type: 'MODAL_CLICKED',
  }
}

export const setEnrichmentRules = (params) => {
  return {
    type: SET_ENRICHMENT_SELECTED_RULES,
    payload: params,
  }
}

export const getCustomerDataonEnrichment =
  (apiData, params2): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({type: ENRICHMENT_MODAL_DATA_LOADING})

    try {
      let apiParams = {...apiData, input: 'ERROR'}

      const response = await axios.get(FETCH_ENRICHMENT_RULE_VIEW_API, {params: apiParams})

      const respData = response.data.response

      dispatch({
        type: ENRICHMENT_MODAL_DATA_SUCCESS,
        payload: {response: respData, rows: apiData.rows, page: params2},
      })
    } catch (error) {
      dispatch({type: ENRICHMENT_MODAL_DATA_FAILED, payload: error})
    }
  }
// Arun Testing
export const setDBSubSection =
  (selectedOption: any, sectionName: string): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    console.log('##sectionName : ', sectionName)
    dispatch({
      type: DATABASE_STRUC_SELECTED_SECTION,
      // payload: sectionName,
      payload: {selectedOption: selectedOption, sectionName: selectedOption.section},
    })
  }

export const fetchEnrichedCustData =
  (params1: any, params2): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    // Dispatch action to indicate the start of the API call
    dispatch({
      type: FETCHING_ENRICHED_DATA,
      payload: params1, // Include params1 in the payload
    })

    try {
      const {ruleId, rows, page} = params1
      const url = `${FETCH_ENRICHED_DATA_API}/${ruleId}?rows=${rows}&page=${page}`
      const response = await axios.get(url)
      const respData = response.data.response
      dispatch({
        type: FETCHING_ENRICHED_DATA_SUCCESS,
        //payload: respData,
        payload: {response: respData, rows: params1.rows, page: params2},
      })
    } catch (error) {
      dispatch({
        type: FETCHING_ENRICHED_DATA_FAILED,
        payload: error,
      })
    }
  }

// Data Archival Flow
export const getDbNames =
  (product): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({type: 'DB_NAMES_LOADING'})
    try {
      const URL = `${DA_FETCH_SCHEMA_API}?product=${product}`
      const resp = await axios.get(URL)

      // const data = response.data;
      // Perform API call to fetch schema names for the specified product
      // const response = {
      //   product: product,
      //   schema: ['information_schema', 'datanxzen-QA', 'datanxzen-dev', 'open_university'],
      // };
      dispatch({type: 'DB_NAMES_SUCCESS', payload: resp.data.response})
    } catch (error) {
      dispatch({type: 'DB_NAMES_FAILED', payload: error})
    }
  }

export const getTableNames =
  (product: string, schemaName: string): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({type: 'TABLE_NAMES_LOADING'})
    try {
      const URL = `${DA_FETCH_TABLES_API}?product=datanxzen&schema=${schemaName}`
      const resp = await axios.get(URL)

      // const data = {
      //   schema: 'datanxzen-QA',
      //   tables: ['customerdata', 'transaction'],
      // }
      dispatch({type: 'TABLE_NAMES_SUCCESS', payload: resp.data.response})
    } catch (error) {
      dispatch({type: 'TABLE_NAMES_FAILED', payload: error})
    }
  }

export const getTableInfo =
  (
    product: string,
    schemaName: string,
    tableName: string
  ): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({type: 'TABLE_INFO_LOADING',payload:{table:tableName}})
    try {
      const URL = `${DA_FETCT_TBLINFO_API}?product=datanxzen&schema=${schemaName}&table=${tableName}`
      const resp = await axios.get(URL)
      console.log('#getTableInfo resp : ', resp)
      dispatch({type: 'TABLE_INFO_SUCCESS', payload: {data:resp.data.response,table:tableName}})
    } catch (error) {
      dispatch({type: 'TABLE_INFO_FAILED', payload: error})
    }
  }

export const getDataHistory =
  (product): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({type: 'DATA_HISTORY_LOADING'})
    try {
      const URL = `${DA_FETCT_HISTORY_API}?name=${product}`
      const resp = await axios.get(URL)
      console.log('#getDataHistory resp : ', resp)
      // const data = [
      //   {
      //     archivalTaskId: 'AT123456',
      //     archivalTaskName: 'Archive Customer Data',
      //     executionStartTime: '2024-03-15T08:30:00Z',
      //     executionEndTime: '2024-03-15T09:00:00Z',
      //     archivalTableName: 'customer_data',
      //     archivedRecordCount: 5000,
      //     executionTime: '00:30:00',
      //   },
      //   {
      //     archivalTaskId: 'AT789012',
      //     archivalTaskName: 'Archive Product Data',
      //     executionStartTime: '2024-03-16T10:00:00Z',
      //     executionEndTime: '2024-03-16T11:00:00Z',
      //     archivalTableName: 'product_data',
      //     archivedRecordCount: 8000,
      //     executionTime: '01:00:00',
      //   },
      //   {
      //     archivalTaskId: 'AT345678',
      //     archivalTaskName: 'Archive Order Data',
      //     executionStartTime: '2024-03-17T09:00:00Z',
      //     executionEndTime: '2024-03-17T10:30:00Z',
      //     archivalTableName: 'order_data',
      //     archivedRecordCount: 10000,
      //     executionTime: '01:30:00',
      //   },
      // ]
      dispatch({type: 'DATA_HISTORY_SUCCESS', payload: resp.data.response})
    } catch (error) {
      dispatch({type: 'DATA_HISTORY_FAILED', payload: error})
    }
  }

export const getDataHistoryDetails =
  (product: string, archivalTaskId: string): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({type: 'DATA_HISTORY_DETAILS_LOADING'})
    try {
      const URL = `${DA_FETCT_HISTORY_INFO_API}/${archivalTaskId}?product=${product}`
      const resp = await axios.get(URL)
      console.log('#getDataHistoryDetails resp : ', resp)

      // const data = {
      //   archivalTaskId: 'AT123456',
      //   archivalTaskName: 'Archive Customer Data',
      //   executionStartTime: '2024-03-15T08:30:00Z',
      //   executionEndTime: '2024-03-15T09:00:00Z',
      //   archivalTableName: 'customer_data',
      //   archivedRecordCount: 5000,
      //   executionTime: '00:30:00',
      // }

      dispatch({type: 'DATA_HISTORY_DETAILS_SUCCESS', payload: resp.data.response})
    } catch (error) {
      dispatch({type: 'DATA_HISTORY_DETAILS_FAILED', payload: error})
    }
  }

// export const getDataPreview =
//   (product: string, archivalTaskId: string): ThunkAction<void, StateProps, unknown, AnyAction> =>
//   async (dispatch) => {
//     dispatch({type: 'DATA_PREVIEW_LOADING'})
//     try {
//        const URL = `${DA_FETCT_HISTORY_INFO_API}/${archivalTaskId}?product=${product}`
//       const resp = await axios.get(URL)
//       console.log('#getDataHistoryDetails resp : ', resp)

//       const data = {
//         archivalTaskId: 'AT123456',
//         archivalTaskName: 'Archive Customer Data',
//         executionStartTime: '2024-03-15T08:30:00Z',
//         executionEndTime: '2024-03-15T09:00:00Z',
//         archivalTableName: 'customer_data',
//         archivedRecordCount: 5000,
//         executionTime: '00:30:00',
//       }

//       dispatch({type: 'DATA_PREVIEW_SUCCESS', payload: data})
//     } catch (error) {
//       dispatch({type: 'DATA_PREVIEW_FAILED', payload: error})
//     }
//   }

export const getDataExecution =
  (param: string): ThunkAction<void, StateProps, unknown, AnyAction> =>
  async (dispatch) => {
    dispatch({type: 'DATA_EXECUTION_LOADING'})
    try {
      
       console.log('##getDataExecution Request Param : ', param)
       const resp = await axios.post(DA_EXECUTION_API, {param});
       console.log('##getDataExecution Resp Param : ', resp)

      // const data = {
      //   archivalTaskId: 'AT345678',
      //   archivalTaskName: 'Archive Order Data',
      //   executionStartTime: '2024-03-17T09:00:00Z',
      //   archivalTableName: 'order_data',
      //   archivedRecordCount: 10000,
      //   estimateTimeValue: '10',
      //   estimateTimeMeasuredIn: 'minutes',
      // }
      
      dispatch({type: 'DATA_EXECUTION_SUCCESS', payload: resp.data.response})

    } catch (error) {

      dispatch({type: 'DATA_EXECUTION_FAILED', payload: error})
    }
  }
