import React from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {FetchListOfRecordEngine} from './FetchListOfRecordEngine'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
// Define a type for the state object
interface ReportStateProp {
  // data: any
  heading: string
  // rows: number
  // page: number,
  props: any
}
const ViewActionModal: React.FC<ReportStateProp> = ({heading, props}) => {
  const [data, setData] = React.useState<any>(null)
  const [rows, setRows] = React.useState<number>(10)
  const [page, setPage] = React.useState<number>(1)
  const [loading, setLoading] = React.useState<boolean>(false)

  // console.log("props.componentData.componen ::", props.componentData.component);

  React.useEffect(() => {
    if (props.graphDataLoading) {
      setLoading(true)
    }
    if (props.graphDataSuccess === true && props.componentData.component === 'GRAPH') {
      setLoading(false)
      setData(
        props.graphData.map(
          ({
            CustomerKey,
            FirstName,
            MiddleName,
            LastName,
            EmailAddress,
            Phone,
            BirthDate,
            Gender,
            documentName,
            documentValue,
            Zipcode,
          }) => ({
            FirstName: `${FirstName ? FirstName : ''}`,
            LastName: `${LastName ? LastName : ''}`,
            EmailAddress,
            Phone,
            BirthDate,
            Gender,
            Zipcode,
          })
        )
      )
    }
  }, [props.graphDataSuccess, props.componentData, heading])

  React.useEffect(() => {
    if (props.errorDataLoading || props.graphDataLoading) {
      setLoading(true)
    }
    if (
      props.errorDataSuccess === true &&
      (props.componentData.component === 'ERROR' || props.componentData.component === 'GRAPH')
    ) {
      setLoading(false)
      setRows(props?.errorDataPagination?.row)
      setData(
        props.errorData.map(
          ({
            CustomerKey,
            FirstName,
            MiddleName,
            LastName,
            EmailAddress,
            Phone,
            BirthDate,
            Gender,
            documentName,
            documentValue,
            Zipcode,
          }) => ({
            CustomerKey,
            // Name: `${FirstName}`,
            FirstName: `${FirstName ? FirstName : ''}`,
            LastName: `${LastName ? LastName : ''}`,
            EmailAddress,
            Phone,
            BirthDate,
            Gender,
            Zipcode,
            // Identification_Id:`${documentName.slice(0,2).toUpperCase()}-${documentValue}`
          })
        )
      )
    }
  }, [props.errorDataSuccess, props.componentData, heading])

  React.useEffect(() => {
    if (props.componentData.component === 'ERROR') {
      setRows(props?.errorDataPagination?.row ? props.errorDataPagination.row : 0)
      setPage(props?.errorDataPagination?.pages ? props.errorDataPagination.pages : 0)
    } else {
      setRows(props?.graphDataPagination?.rows ? props.graphDataPagination.rows : 0)
      setPage(props?.graphDataPagination?.page ? Number(props.graphDataPagination.page): 0)
    }
    console.log("ROWSSS:",rows,"PAGE:",page,props?.graphDataPagination?.rows)
  }, [data, props.errorDataSuccess, props.graphDataPagination])
  // console.log("HEADING",heading)

  return (
    <>
      {/* <span data-bs-toggle='modal' data-bs-target='#kt_modal_1'></span>
      <div className='modal bg-body fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog  modal-fullscreen'>
          <div className='modal-content shadow-none' style={{width: '100%'}}></div> */}

      <span data-bs-toggle='modal' data-bs-target='#kt_modal_1'></span>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered h-auto modal-dialog modal-xl mw-1255 px'>
          <div className='modal-content shadow-none' style={{width: '100%'}}>
            <div className='modal-header pb-4 pt-2 mt-0 justify-content-end'>
              <h5
                className='modal-title'
                style={{
                  float: 'left',
                  width: '100%',
                  justifyContent: 'left',
                  alignItems: 'left',
                  display: 'flex',
                  paddingLeft: '0px',
                }}
              >
                <h2>{props.componentData.heading}</h2>
              </h5>
              <span
                style={{
                  float: 'right',
                  width: '100%',
                  justifyContent: 'right',
                  alignItems: 'right',
                  display: 'flex',
                }}
              >
                {data && data.length > 0 && (
                  <ReactHTMLTableToExcel
                    id='test-table-xls-button'
                    className='download-table-xls-button btn btn-light-primary me-3'
                    table={'table-to-xls'}
                    filename='DataFieldReport'
                    sheet='DataFieldReport'
                    //  buttonText='Export'

                    buttonText={
                      <>
                        <KTIcon iconName='exit-up' className='fs-2' />
                        Export
                      </>
                    }
                  />
                )}
              </span>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i
                  className='ki-duotone ki-cross fs-2x'
                  onClick={() => {
                    console.log('CLOSE')
                    props.clearPopupProps()
                  }}
                >
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>
            <div className='modal-body py-2 scroll-y m-1'>
              {loading ? (
                'Loading...'
              ) : data && data.length ? (
                <FetchListOfRecordEngine
                  heading={heading}
                  data={data}
                  rows={rows}
                  page={page}
                  totalpages={page}
                  props={props}
                />
              ) : (
                'No Records Found!'
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewActionModal
