import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

const RandomForestFeatures: React.FC = () => {
  useEffect(() => {
    // Themes
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create('chartdiv', am4charts.XYChart);

    // Set data
    const data = [
      { category: 'AdmitForDay', value: 0.125 },
      { category: 'NumberOfProcedure', value: 0.095 },
      { category: 'NumberOfDiagnosis', value: 0.087 },
      { category: 'InscClaimAmtReimbursed', value: 0.055 },
      { category: 'RenalDiseaseIndicator', value: 0.047 },
      { category: 'ChronicCond_ObstrPulmonary', value: 0.043 },
      { category: 'ChronicCond_KidneyDisease', value: 0.041 },
      { category: 'ChronicCond_Depression', value: 0.036 },
      { category: 'ChronicCond_Heartfailure', value: 0.032 },
      { category: 'Age', value: 0.03 },
    ];

    chart.data = data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'category';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.rotation = -35;
    categoryAxis.renderer.labels.template.horizontalCenter = 'right';
    categoryAxis.renderer.labels.template.verticalCenter = 'middle';
    categoryAxis.renderer.grid.template.disabled = false;
    categoryAxis.title.text = 'Values';

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Index';

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'category';
    series.columns.template.tooltipText = '{category}: {valueY}';
    series.columns.template.fill = am4core.color('#2196F3');

    chart.logo.disabled = true;

    return () => {
      chart.dispose(); // Cleanup on unmount
    };
  }, []);

  return <div id='chartdiv' style={{ width: '100%', height: '540px' }}></div>;
};

export default RandomForestFeatures;
