import React from 'react'
import {useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const data_masked_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intelligent Data',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ummaskeddata = [
  {
    FullName: 'Gry Lane',
    First_Name: 'Gry',
    Last_Name: 'Lane',
    Email: 'linda01@example.com',
    Phone: '4515972187',
    Occupation: 'Retired',
    Nationality: 'US',
    ID: 'Id7584162',
    Address: '629 Allison Green Apt. 350, West Lauramouth',
  },
  {
    FullName: 'Brent Obrien',
    First_Name: 'Brent',
    Last_Name: 'Obrien',
    Email: 'selenamoon@example.net',
    Phone: '(875)469-9931x1489',
    Occupation: 'bus operator',
    Nationality: 'NULL',
    ID: 'Id6554464',
    Address: '95689 Coffey Rapid, New Elizabeth',
  },
  {
    FullName: 'Abc Keith',
    First_Name: 'Abc',
    Last_Name: 'Keith',
    Email: 'lopezjeffrey@example.net',
    Phone: '001-695-557-4765x3132',
    Occupation: 'MANAGER - RETIRED',
    Nationality: 'JM',
    ID: 'Id6024504',
    Address: '5971 Hunter Avenue Apt. 764, East Andreatown',
  },
  {
    FullName: 'Lauren Evans',
    First_Name: 'Lauren',
    Last_Name: 'Evans',
    Email: 'robertyoung@example.net',
    Phone: '+1-297-671-4301x56921',
    Occupation: 'Farmer',
    Nationality: 'JM',
    ID: 'Id1543799',
    Address: '82030 Robertson Ports Apt. 082, Sandrachester',
  },
  {
    FullName: 'Sarah Ross',
    First_Name: 'Sarah',
    Last_Name: 'Ross',
    Email: 'lewisbrenda@example.net',
    Phone: '305.472.5857x456',
    Occupation: 'CARPENTER',
    Nationality: 'JAMAICA',
    ID: 'Id7813541',
    Address: '40015 Thomas Dam Suite 882, New Anthony',
  },
  {
    FullName: 'Theodore Day',
    First_Name: 'Theodore',
    Last_Name: 'Day',
    Email: 'georgerhonda@example.org',
    Phone: '243.877.5589',
    Occupation: 'CHEF',
    Nationality: 'JM',
    ID: 'Id7627333',
    Address: '1425 Long Highway, West Johnmouth',
  },
  {
    FullName: 'April West',
    First_Name: 'April',
    Last_Name: 'West',
    Email: 'georgejackson@example.com',
    Phone: '(214)743-2076x25888',
    Occupation: 'CHEF',
    Nationality: 'JM',
    ID: 'Id479734',
    Address: '23143 Joshua Village, Hobbsview',
  },
  {
    FullName: 'Allen Alvarad',
    First_Name: 'Allen',
    Last_Name: 'Alvarad',
    Email: 'bmiller@example.org',
    Phone: '450-697-5487x675',
    Occupation: 'employer of burger King',
    Nationality: 'JM',
    ID: 'Id7493801',
    Address: '8112 Sierra Stravenue Apt. 419, Mistyberg',
  },
  {
    FullName: 'Jesse Adams',
    First_Name: 'Jesse',
    Last_Name: 'Adams',
    Email: 'brandi43@example.net',
    Phone: '677.641.9492x556',
    Occupation: 'Manager',
    Nationality: 'GB',
    ID: 'Id818158',
    Address: '3613 Alison Court Apt. 892, West Alan',
  },
  {
    FullName: 'Elizabeth Wall',
    First_Name: 'Elizabeth',
    Last_Name: 'Wall',
    Email: 'osmith@example.com',
    Phone: '843.798.8550',
    Occupation: 'Customer Service Representative',
    Nationality: 'JM',
    ID: 'Id5992412',
    Address: '46596 Mccoy Forest Apt. 967, East Samanthafurt',
  },
  {
    FullName: 'Erk Carroll',
    First_Name: 'Erk',
    Last_Name: 'Carroll',
    Email: 'sierra12@example.org',
    Phone: '001-265-724-1303x89011',
    Occupation: 'Nursein',
    Nationality: 'JM',
    ID: 'Id1233626',
    Address: '3181 Casey Rue, Jillmouth',
  },
  {
    FullName: 'Ariel Bishop',
    First_Name: 'Ariel',
    Last_Name: 'Bishop',
    Email: 'bdavis@example.org',
    Phone: '(915)911-3090x9791',
    Occupation: 'Software Engineer',
    Nationality: 'JM',
    ID: 'Id6562343',
    Address: '809 Sanders Port, Kyleville',
  },
  {
    FullName: 'Karen',
    First_Name: 'Karen',
    Last_Name: 'NULL',
    Email: '(402)791-9613',
    Phone: 'OPERATIONS MANAGER',
    Occupation: 'Software Engineer',
    Nationality: 'JM',
    ID: 'Id1513629',
    Address: '521 Marissa Grove, West Nancy',
  },
  {
    FullName: 'Jenna Jones',
    First_Name: 'Jenna',
    Last_Name: 'Jones',
    Email: 'NULL',
    Phone: '(815)688-1374',
    Occupation: 'REGISTER NURSE',
    Nationality: 'JM',
    ID: 'Id5260590',
    Address: '1583 Pena Inlet, North Brenda',
  },
  {
    FullName: 'Mary Woodard',
    First_Name: 'Mary',
    Last_Name: 'Woodard',
    Email: 'shauncruz@example.com',
    Phone: '4752420830',
    Occupation: 'Business consultant',
    Nationality: 'JM',
    ID: 'Id4751187',
    Address: 'USNV Jackson, FPO AE 22518',
  },
  {
    FullName: 'Mackenzie Mclaughlin',
    First_Name: 'Mackenzie',
    Last_Name: 'Mclaughlin',
    Email: 'thompsonandre@example.com',
    Phone: '(584)532-4932x64671',
    Occupation: 'LPN',
    Nationality: 'US',
    ID: 'Id7009912',
    Address: '083 Terri Vista Suite 670, Andrewport',
  },
  {
    FullName: 'Alicia Morgan',
    First_Name: 'Alicia',
    Last_Name: 'Morgan',
    Email: 'paulfleming@example.net',
    Phone: '001-278-747-6447x4375',
    Occupation: 'Self-employed',
    Nationality: 'US',
    ID: 'Id4220003',
    Address: '211 Davis Plaza, Ashleyshire',
  },
  {
    FullName: 'Candice Coleman',
    First_Name: 'Candice',
    Last_Name: 'Coleman',
    Email: 'andersonkatelyn@example.org',
    Phone: '2842755814',
    Occupation: 'firefighter',
    Nationality: 'JM',
    ID: 'Id4454789',
    Address: '611 Green Crossing Apt. 149, North Jaredburgh',
  },
  {
    FullName: 'Wendy Ortiz',
    First_Name: 'Wendy',
    Last_Name: 'Ortiz',
    Email: 'rebeccadougherty@example.net',
    Phone: '(201)992-6257',
    Occupation: 'laborer',
    Nationality: 'JM',
    ID: 'Id7495717',
    Address: '5878 Heidi Cliff Apt. 639, West Michaelmouth',
  },
  {
    FullName: 'Gerald Brown',
    First_Name: 'Gerald',
    Last_Name: 'Brown',
    Email: 'spencerray@example.org',
    Phone: '4633148130',
    Occupation: 'LAWYER',
    Nationality: 'UNITED STATES',
    ID: 'Id6566302',
    Address: '69746 Laura Roads, Lake William',
  },
  {
    FullName: 'Michael Gates',
    First_Name: 'Michael',
    Last_Name: 'Gates',
    Email: 'william31@example.org',
    Phone: '524.536.5289x727',
    Occupation: 'Customer service',
    Nationality: 'JM',
    ID: 'Id7750547',
    Address: 'USNS Harris, FPO AP 34864',
  },
  {
    FullName: 'Shirley Chavez',
    First_Name: 'Shirley',
    Last_Name: 'Chavez',
    Email: 'uowens@example.org',
    Phone: '875-580-6749',
    Occupation: 'Construction',
    Nationality: 'JM',
    ID: 'Id7336718',
    Address: '62032 Marcus Centers Apt. 548, Halefurt',
  },
  {
    FullName: 'Michael Martinez',
    First_Name: 'Michael',
    Last_Name: 'Martinez',
    Email: 'ronald36@example.com',
    Phone: '(754)859-3428',
    Occupation: 'BARBER',
    Nationality: 'US',
    ID: 'Id3029834',
    Address: 'USCGC Gilmore, FPO AA 94802',
  },
  {
    FullName: 'Tracey Hawkins',
    First_Name: 'Tracey',
    Last_Name: 'Hawkins',
    Email: 'carrollandrea@example.com',
    Phone: '859.328.4760',
    Occupation: 'Accounts Receivable Specialist',
    Nationality: 'JM',
    ID: 'Id7825627',
    Address: '8149 Tracy Landing, Port Amyborough',
  },
  {
    FullName: 'Catherine Arnold',
    First_Name: 'Catherine',
    Last_Name: 'Arnold',
    Email: 'whiteshawn@example.org',
    Phone: '6502083230',
    Occupation: 'Housekeeper',
    Nationality: 'NULL',
    ID: 'Id1059305',
    Address: '8662 Long Crest, Loweside',
  },
  {
    FullName: 'Cory Howard',
    First_Name: 'Cory',
    Last_Name: 'Howard',
    Email: 'hardindavid@example.com',
    Phone: '(787)560-9544x8272',
    Occupation: 'DIRECT SUPPORT STAFF',
    Nationality: 'UNITED STATES',
    ID: 'Id6579849',
    Address: '044 Jennifer Glen, South Matthew',
  },
  {
    FullName: 'Kelsey Palmer',
    First_Name: 'Kelsey',
    Last_Name: 'Palmer',
    Email: 'nixonnicholas@example.org',
    Phone: '001-723-232-2094x3526',
    Occupation: 'Bookkeeping',
    Nationality: 'US',
    ID: 'Id7123141',
    Address: '98763 Ashley Shoals, Markfurt',
  },
  {
    FullName: 'Denise Garcia',
    First_Name: 'Denise',
    Last_Name: 'Garcia',
    Email: 'mckeetiffany@example.net',
    Phone: '+1-629-614-5285x7830',
    Occupation: 'RESTAURANT OWNER',
    Nationality: 'JM',
    ID: 'Id804619',
    Address: '8415 Hogan Views Apt. 585, Teresabury',
  },
  {
    FullName: 'Elizabeth Kline',
    First_Name: 'Elizabeth',
    Last_Name: 'Kline',
    Email: 'ugreen@example.org',
    Phone: '(551)722-7160',
    Occupation: 'Support worker',
    Nationality: 'GB',
    ID: 'Id2183150',
    Address: '0526 Rachel Knolls, Amandamouth',
  },
  {
    FullName: 'Destiny Gould',
    First_Name: 'Destiny',
    Last_Name: 'Gould',
    Email: 'cory26@example.com',
    Phone: '(938)374-4253',
    Occupation: 'CUSTOMER SERVICE',
    Nationality: 'JAMAICA',
    ID: 'Id1085084',
    Address: '76280 Matthew Extensions, Richardville',
  },
]

const CompareData = [
  {
    Unmasked_Full_Name: 'Gry Lane',
    Masked_Full_Name: 'Kathy Riley',
    Unmasked_First_Name: 'Gry',
    Masked_First_Name: 'Kathy',
    Unmasked_Last_Name: 'Lane',
    Masked_Last_Name: 'Riley',
    Unmasked_Email: 'linda01@example.com',
    Masked_Email: 'ymerritt@example.org',
    Unmasked_Phone: '4515972187',
    Masked_Phone: '001-465-872-9580x55476',
    Unmasked_Occupation: 'Retired',
    Masked_Occupation: 'Conference local yet record. ',
    Unmasked_Nationality: 'US',
    Masked_Nationality: 'Algeria',
    Unmasked_ID: 'Id7584162',
    Masked_ID: '001-993-221-9316',
    Unmasked_Address: '629 Allison Green Apt. 350, West Lauramouth',
    Masked_Address: 'PSC 9484, Box 0519\nAPO AE 91894',
  },
  {
    Unmasked_Full_Name: 'Brent Obrien',
    Masked_Full_Name: 'Derrick Good',
    Unmasked_First_Name: 'Brent',
    Masked_First_Name: 'Derrick',
    Unmasked_Last_Name: 'Obrien',
    Masked_Last_Name: 'Good',
    Unmasked_Email: 'selenamoon@example.net',
    Masked_Email: 'amy74@example.com',
    Unmasked_Phone: '(875)469-9931x1489',
    Masked_Phone: '414-353-8979x499',
    Unmasked_Occupation: 'bus operator',
    Masked_Occupation: 'Training nor only table picture boy detail.',
    Unmasked_Nationality: 'NULL',
    Masked_Nationality: 'Jamaica',
    Unmasked_ID: 'Id6554464',
    Masked_ID: '001-275-735-7639',
    Unmasked_Address: '95689 Coffey Rapid, New Elizabeth',
    Masked_Address: '50169 Kevin Ferry\nWest Tracy, MS 69521',
  },
  {
    Unmasked_Full_Name: 'Abc Keith',
    Masked_Full_Name: 'William Johnson MD',
    Unmasked_First_Name: 'Abc',
    Masked_First_Name: 'William',
    Unmasked_Last_Name: 'Keith',
    Masked_Last_Name: 'MD',
    Unmasked_Email: 'lopezjeffrey@example.net',
    Masked_Email: 'gonzalezangela@example.com',
    Unmasked_Phone: '001-695-557-4765x3132',
    Masked_Phone: '+1-819-869-7302x8680',
    Unmasked_Occupation: 'MANAGER - RETIRED',
    Masked_Occupation: 'Everybody action assume get. Key take while.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Slovakia (Slovak Republic)',
    Unmasked_ID: 'Id6024504',
    Masked_ID: '+1-268-850-8063x45349',
    Unmasked_Address: '5971 Hunter Avenue Apt. 764, East Andreatown',
    Masked_Address: '436 Rebecca Junction\nLaurenland, AL 08769',
  },
  {
    Unmasked_Full_Name: 'Lauren Evans',
    Masked_Full_Name: 'Jason Mann',
    Unmasked_First_Name: 'Lauren',
    Masked_First_Name: 'Jason',
    Unmasked_Last_Name: 'Evans',
    Masked_Last_Name: 'Mann',
    Unmasked_Email: 'robertyoung@example.net',
    Masked_Email: 'alejandra32@example.org',
    Unmasked_Phone: '+1-297-671-4301x56921',
    Masked_Phone: '(730)283-8024x87641',
    Unmasked_Occupation: 'Farmer',
    Masked_Occupation: 'Chair on near good central teacher skill.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Oman',
    Unmasked_ID: 'Id1543799',
    Masked_ID: '7288667336',
    Unmasked_Address: '82030 Robertson Ports Apt. 082, Sandrachester',
    Masked_Address: '7075 Kyle Mission Apt. 258\nWest Micheal, AR 40996',
  },
  {
    Unmasked_Full_Name: 'Sarah Ross',
    Masked_Full_Name: 'Evan Benton',
    Unmasked_First_Name: 'Sarah',
    Masked_First_Name: 'Evan',
    Unmasked_Last_Name: 'Ross',
    Masked_Last_Name: 'Benton',
    Unmasked_Email: 'lewisbrenda@example.net',
    Masked_Email: 'brianahill@example.net',
    Unmasked_Phone: '305.472.5857x456',
    Masked_Phone: '001-514-972-8680x856',
    Unmasked_Occupation: 'CARPENTER',
    Masked_Occupation: 'Director beautiful social executive.',
    Unmasked_Nationality: 'JAMAICA',
    Masked_Nationality: 'Samoa',
    Unmasked_ID: 'Id7813541',
    Masked_ID: '535.753.5138x19996',
    Unmasked_Address: '40015 Thomas Dam Suite 882, New Anthony',
    Masked_Address: '445 Myers Isle\nEast Melissamouth, CA 92545',
  },
  {
    Unmasked_Full_Name: 'Theodore Day',
    Masked_Full_Name: 'Ann Torres',
    Unmasked_First_Name: 'Theodore',
    Masked_First_Name: 'Ann',
    Unmasked_Last_Name: 'Day',
    Masked_Last_Name: 'Torres',
    Unmasked_Email: 'georgerhonda@example.org',
    Masked_Email: 'maddenkimberly@example.org',
    Unmasked_Phone: '243.877.5589',
    Masked_Phone: '2968800222',
    Unmasked_Occupation: 'CHEF',
    Masked_Occupation: 'Message name home thought less against. ',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Ukraine',
    Unmasked_ID: 'Id7627333',
    Masked_ID: '001-973-665-6780x5263',
    Unmasked_Address: '1425 Long Highway, West Johnmouth',
    Masked_Address: 'USNS Lee\nFPO AP 02204',
  },
  {
    Unmasked_Full_Name: 'April West',
    Masked_Full_Name: 'Charles Meza',
    Unmasked_First_Name: 'April',
    Masked_First_Name: 'Charles',
    Unmasked_Last_Name: 'West',
    Masked_Last_Name: 'Meza',
    Unmasked_Email: 'georgejackson@example.com',
    Masked_Email: 'jenna15@example.org',
    Unmasked_Phone: '(214)743-2076x25888',
    Masked_Phone: '(458)710-5270',
    Unmasked_Occupation: 'CHEF',
    Masked_Occupation: 'Attack interesting attention event artist third sort.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Kiribati',
    Unmasked_ID: 'Id479734',
    Masked_ID: '(635)834-7179x228',
    Unmasked_Address: '23143 Joshua Village, Hobbsview',
    Masked_Address: '9127 Dickerson Turnpike\nNorth Kelly, TN 43282',
  },
  {
    Unmasked_Full_Name: 'Allen Alvarad',
    Masked_Full_Name: 'Cassandra Curtis',
    Unmasked_First_Name: 'Allen',
    Masked_First_Name: 'Cassandra',
    Unmasked_Last_Name: 'Alvarad',
    Masked_Last_Name: 'Curtis',
    Unmasked_Email: 'bmiller@example.org',
    Masked_Email: 'collinsamy@example.com',
    Unmasked_Phone: '450-697-5487x675',
    Masked_Phone: '(411)609-2045',
    Unmasked_Occupation: 'employer of burger King',
    Masked_Occupation: 'Challenge treatment number arrive actually.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Austria',
    Unmasked_ID: 'Id7493801',
    Masked_ID: '(827)274-7071x8500',
    Unmasked_Address: '8112 Sierra Stravenue Apt. 419, Mistyberg',
    Masked_Address: '8445 Weaver Trail\nMarioview, AK 78731',
  },
  {
    Unmasked_Full_Name: 'Jesse Adams',
    Masked_Full_Name: 'Richard Bennett',
    Unmasked_First_Name: 'Jesse',
    Masked_First_Name: 'Richard',
    Unmasked_Last_Name: 'Adams',
    Masked_Last_Name: 'Bennett',
    Unmasked_Email: 'brandi43@example.net',
    Masked_Email: 'kpark@example.com',
    Unmasked_Phone: '677.641.9492x556',
    Masked_Phone: '3894409553',
    Unmasked_Occupation: 'Manager',
    Masked_Occupation: 'Field ability support.',
    Unmasked_Nationality: 'GB',
    Masked_Nationality: 'United Kingdom',
    Unmasked_ID: 'Id818158',
    Masked_ID: '(512)917-6042x513',
    Unmasked_Address: '3613 Alison Court Apt. 892, West Alan',
    Masked_Address: '13971 Martin Brooks\nEast Stefanie, FM 77991',
  },
  {
    Unmasked_Full_Name: 'Elizabeth Wall',
    Masked_Full_Name: 'Alex Ray',
    Unmasked_First_Name: 'Elizabeth',
    Masked_First_Name: 'Alex',
    Unmasked_Last_Name: 'Wall',
    Masked_Last_Name: 'Ray',
    Unmasked_Email: 'osmith@example.com',
    Masked_Email: 'igutierrez@example.com',
    Unmasked_Phone: '843.798.8550',
    Masked_Phone: '+1-536-320-1627x50315',
    Unmasked_Occupation: 'Customer Service Representative',
    Masked_Occupation: 'Pull you stop be budget base face.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Gabon',
    Unmasked_ID: 'Id5992412',
    Masked_ID: '7763612325',
    Unmasked_Address: '46596 Mccoy Forest Apt. 967, East Samanthafurt',
    Masked_Address: '314 Anderson Knoll Apt. 728\nPort Andrewchester, UT 33237',
  },
  {
    Unmasked_Full_Name: 'Erk Carroll',
    Masked_Full_Name: 'Todd Olson',
    Unmasked_First_Name: 'Erk',
    Masked_First_Name: 'Todd',
    Unmasked_Last_Name: 'Carroll',
    Masked_Last_Name: 'Olson',
    Unmasked_Email: 'sierra12@example.org',
    Masked_Email: 'donna97@example.net',
    Unmasked_Phone: '001-265-724-1303x89011',
    Masked_Phone: '762.971.6120x25872',
    Unmasked_Occupation: 'Nursein',
    Masked_Occupation: 'Require day dark more away energy.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'South Georgia and the South Sandwich Islands',
    Unmasked_ID: 'Id1233626',
    Masked_ID: '+1-388-728-8481x788',
    Unmasked_Address: '3181 Casey Rue, Jillmouth',
    Masked_Address: '3161 Williams Turnpike Apt. 439\nPort Amanda, IA 47354',
  },
  {
    Unmasked_Full_Name: 'Ariel Bishop',
    Masked_Full_Name: 'Arthur Parker',
    Unmasked_First_Name: 'Ariel',
    Masked_First_Name: 'Arthur',
    Unmasked_Last_Name: 'Bishop',
    Masked_Last_Name: 'Parker',
    Unmasked_Email: 'bdavis@example.org',
    Masked_Email: 'nkim@example.org',
    Unmasked_Phone: '(915)911-3090x9791',
    Masked_Phone: '(792)573-4744x856',
    Unmasked_Occupation: 'Software Engineer',
    Masked_Occupation: 'Nor operation after thus hospital deep these.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Rwanda',
    Unmasked_ID: 'Id6562343',
    Masked_ID: '+1-413-484-5310x7152',
    Unmasked_Address: '809 Sanders Port, Kyleville',
    Masked_Address: '064 Chapman Fall Apt. 304\nPort Erinside, CT 79952',
  },
  {
    Unmasked_Full_Name: 'Karen ',
    Masked_Full_Name: 'Rhonda Harris',
    Unmasked_First_Name: 'Karen',
    Masked_First_Name: 'Rhonda',
    Unmasked_Last_Name: '',
    Masked_Last_Name: 'Harris',
    Unmasked_Email: 'lharris@example.org',
    Masked_Email: '(402)791-9613',
    Unmasked_Phone: '271.800.6882',
    Masked_Phone: 'OPERATIONS MANAGER',
    Unmasked_Occupation: 'Thing change couple themselves present collection region.',
    Masked_Occupation: '--SELECT COUNTRY--',
    Unmasked_Nationality: '--SELECT COUNTRY--',
    Masked_Nationality: 'Azerbaijan',
    Unmasked_ID: 'Id1513629',
    Masked_ID: '+1-475-992-9221x1076',
    Unmasked_Address: '521 Marissa Grove, West Nancy',
    Masked_Address: '0777 Jones Ways\nEast Patrickview, TN 87515',
  },
  {
    Unmasked_Full_Name: 'Jenna Jones',
    Masked_Full_Name: 'Melanie Vaughan',
    Unmasked_First_Name: 'Jenna',
    Masked_First_Name: 'Melanie',
    Unmasked_Last_Name: 'Jones',
    Masked_Last_Name: 'Vaughan',
    Unmasked_Email: 'virginia29@example.org',
    Masked_Email: '(815)688-1374',
    Unmasked_Phone: '917.633.4909x5259',
    Masked_Phone: 'REGISTER NURSE',
    Unmasked_Occupation: 'Future last line. Talk improve look what bar.',
    Masked_Occupation: '--SELECT COUNTRY--',
    Unmasked_Nationality: '--SELECT COUNTRY--',
    Masked_Nationality: 'Saint Martin',
    Unmasked_ID: 'Id5260590',
    Masked_ID: '564-679-7978',
    Unmasked_Address: '1583 Pena Inlet, North Brenda',
    Masked_Address: 'Unit 5899 Box 5736\nDPO AE 18253',
  },
  {
    Unmasked_Full_Name: 'Mary Woodard',
    Masked_Full_Name: 'Joseph Chavez',
    Unmasked_First_Name: 'Mary',
    Masked_First_Name: 'Joseph',
    Unmasked_Last_Name: 'Woodard',
    Masked_Last_Name: 'Chavez',
    Unmasked_Email: 'shauncruz@example.com',
    Masked_Email: 'vegakaren@example.com',
    Unmasked_Phone: '4752420830',
    Masked_Phone: '561.362.9007',
    Unmasked_Occupation: 'Business consultant',
    Masked_Occupation: 'Everybody it born morning. In newspaper building decade thank',
    Unmasked_Nationality: 'NULL',
    Masked_Nationality: 'Jersey',
    Unmasked_ID: 'Id4751187',
    Masked_ID: '2979908224',
    Unmasked_Address: 'USNV Jackson, FPO AE 22518',
    Masked_Address: '117 Amanda Passage Suite 835\nRussellside, MN 57022',
  },
  {
    Unmasked_Full_Name: 'Mackenzie Mclaughlin',
    Masked_Full_Name: 'Lee Glass',
    Unmasked_First_Name: 'Mackenzie',
    Masked_First_Name: 'Lee',
    Unmasked_Last_Name: 'Mclaughlin',
    Masked_Last_Name: 'Glass',
    Unmasked_Email: 'thompsonandre@example.com',
    Masked_Email: 'johngreen@example.net',
    Unmasked_Phone: '(993)207-1050x9155',
    Masked_Phone: '(492)376-4483x722',
    Unmasked_Occupation: 'FORTUNE TELLER',
    Masked_Occupation: 'Choose manager church customer structure close general',
    Unmasked_Nationality: 'Jamaica',
    Masked_Nationality: 'Togo',
    Unmasked_ID: 'Id6804601',
    Masked_ID: '642-810-9158x18471',
    Unmasked_Address: 'USCGC Newton, FPO AP 31322',
    Masked_Address: '3313 Thomas Villages\nLake Anthonychester, LA 04992',
  },
  {
    Unmasked_Full_Name: 'Alicia Morgan',
    Masked_Full_Name: 'John Reyes',
    Unmasked_First_Name: 'Alicia',
    Masked_First_Name: 'John',
    Unmasked_Last_Name: 'Morgan',
    Masked_Last_Name: 'Reyes',
    Unmasked_Email: 'paulfleming@example.net',
    Masked_Email: 'nrivera@example.net',
    Unmasked_Phone: '001-278-747-6447x4375',
    Masked_Phone: '937-232-4097x83094',
    Unmasked_Occupation: 'Self-employed',
    Masked_Occupation: 'Window few threat drive list international',
    Unmasked_Nationality: 'US',
    Masked_Nationality: 'Nauru',
    Unmasked_ID: 'Id4220003',
    Masked_ID: '+1-503-216-2732x2714',
    Unmasked_Address: '211 Davis Plaza, Ashleyshire',
    Masked_Address: '162 Martin Heights\nPort Mariaberg, AK 26692',
  },
  {
    Unmasked_Full_Name: 'Candice Coleman',
    Masked_Full_Name: 'Deanna Bauer',
    Unmasked_First_Name: 'Candice',
    Masked_First_Name: 'Deanna',
    Unmasked_Last_Name: 'Coleman',
    Masked_Last_Name: 'Bauer',
    Unmasked_Email: 'andersonkatelyn@example.org',
    Masked_Email: 'jamessmith@example.com',
    Unmasked_Phone: '2842755814',
    Masked_Phone: '698-822-0249x1493',
    Unmasked_Occupation: 'firefighter',
    Masked_Occupation: 'Worry responsibility accept many.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Western Sahara',
    Unmasked_ID: 'Id4454789',
    Masked_ID: '001-520-635-0599',
    Unmasked_Address: '611 Green Crossing Apt. 149, North Jaredburgh',
    Masked_Address: '9362 Henry Mews Suite 534\nBrowningtown, AS 53718',
  },
  {
    Unmasked_Full_Name: 'Wendy Ortiz',
    Masked_Full_Name: 'Laurie Hicks',
    Unmasked_First_Name: 'Wendy',
    Masked_First_Name: 'Laurie',
    Unmasked_Last_Name: 'Ortiz',
    Masked_Last_Name: 'Hicks',
    Unmasked_Email: 'rebeccadougherty@example.net',
    Masked_Email: 'tiffany16@example.com',
    Unmasked_Phone: '(201)992-6257',
    Masked_Phone: '9954439487',
    Unmasked_Occupation: 'laborer',
    Masked_Occupation: 'Simply college walk interest poor score visit',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Sierra Leone',
    Unmasked_ID: 'Id7495717',
    Masked_ID: '527.630.2879',
    Unmasked_Address: '5878 Heidi Cliff Apt. 639, West Michaelmouth',
    Masked_Address: '589 Williamson Harbors Apt. 914\nLopezton, NV 99323',
  },
  {
    Unmasked_Full_Name: 'Gerald Brown',
    Masked_Full_Name: 'Jose Baldwin',
    Unmasked_First_Name: 'Gerald',
    Masked_First_Name: 'Jose',
    Unmasked_Last_Name: 'Brown',
    Masked_Last_Name: 'Baldwin',
    Unmasked_Email: 'spencerray@example.org',
    Masked_Email: 'peter49@example.org',
    Unmasked_Phone: '4633148130',
    Masked_Phone: '001-345-756-2102x27429',
    Unmasked_Occupation: 'LAWYER',
    Masked_Occupation: 'International official provide ever himself offer dog',
    Unmasked_Nationality: 'UNITED STATES',
    Masked_Nationality: 'French Guiana',
    Unmasked_ID: 'Id6566302',
    Masked_ID: '+1-625-652-4058x44390',
    Unmasked_Address: '69746 Laura Roads, Lake William',
    Masked_Address: '0515 Julie Crossroad Suite 108\nEast Karentown, AK 49302',
  },
  {
    Unmasked_Full_Name: 'Michael Gates',
    Masked_Full_Name: 'Christian Johnson',
    Unmasked_First_Name: 'Michael',
    Masked_First_Name: 'Christian',
    Unmasked_Last_Name: 'Gates',
    Masked_Last_Name: 'Johnson',
    Unmasked_Email: 'william31@example.org',
    Masked_Email: 'kristen30@example.net',
    Unmasked_Phone: '524.536.5289x727',
    Masked_Phone: '757.741.1315x46573',
    Unmasked_Occupation: 'Customer service',
    Masked_Occupation: 'Media total thing attack father try. Bag loss road top year.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Sierra Leone',
    Unmasked_ID: 'Id7750547',
    Masked_ID: '+1-240-329-6510x06954',
    Unmasked_Address: 'USNS Harris, FPO AP 34864',
    Masked_Address: '248 Clark Summit Suite 846\nNorth Melissamouth, WV 20455',
  },
  {
    Unmasked_Full_Name: 'Shirley Chavez',
    Masked_Full_Name: 'Carl Reed',
    Unmasked_First_Name: 'Shirley',
    Masked_First_Name: 'Carl',
    Unmasked_Last_Name: 'Chavez',
    Masked_Last_Name: 'Reed',
    Unmasked_Email: 'uowens@example.org',
    Masked_Email: 'amandasantos@example.com',
    Unmasked_Phone: '875-580-6749',
    Masked_Phone: '+1-827-986-8145x25511',
    Unmasked_Occupation: 'Construction',
    Masked_Occupation: 'Realize land study return defense. ',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Pitcairn Islands',
    Unmasked_ID: 'Id7336718',
    Masked_ID: '844-344-4541',
    Unmasked_Address: '62032 Marcus Centers Apt. 548, Halefurt',
    Masked_Address: '5432 Torres Cliff\nKimberlyhaven, AR 65120',
  },
  {
    Unmasked_Full_Name: 'Michael Martinez',
    Masked_Full_Name: 'Kelly Gomez',
    Unmasked_First_Name: 'Michael',
    Masked_First_Name: 'Kelly',
    Unmasked_Last_Name: 'Martinez',
    Masked_Last_Name: 'Gomez',
    Unmasked_Email: 'ronald36@example.com',
    Masked_Email: 'colemancharlotte@example.org',
    Unmasked_Phone: '(754)859-3428',
    Masked_Phone: '(739)842-6649x8924',
    Unmasked_Occupation: 'BARBER',
    Masked_Occupation: 'Also behind majority across pattern fly thousand',
    Unmasked_Nationality: 'US',
    Masked_Nationality: 'Antigua and Barbuda',
    Unmasked_ID: 'Id3029834',
    Masked_ID: '8864308322',
    Unmasked_Address: 'USCGC Gilmore, FPO AA 94802',
    Masked_Address: '314 Jessica Parkways Apt. 410\nNew Stephanie, VI 82773',
  },
  {
    Unmasked_Full_Name: 'Tracey Hawkins',
    Masked_Full_Name: 'Erin Allen',
    Unmasked_First_Name: 'Tracey',
    Masked_First_Name: 'Erin',
    Unmasked_Last_Name: 'Hawkins',
    Masked_Last_Name: 'Allen',
    Unmasked_Email: 'carrollandrea@example.com',
    Masked_Email: 'petersendenise@example.com',
    Unmasked_Phone: '859.328.4760',
    Masked_Phone: '269-961-5192',
    Unmasked_Occupation: 'Accounts Receivable Specialist',
    Masked_Occupation: 'Including weight type one news argue game.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Reunion',
    Unmasked_ID: 'Id7825627',
    Masked_ID: '(753)922-5115x465',
    Unmasked_Address: '8149 Tracy Landing, Port Amyborough',
    Masked_Address: '15790 Katie Centers Apt. 761\nNew Jon, NH 54817',
  },
  {
    Unmasked_Full_Name: 'Catherine Arnold',
    Masked_Full_Name: 'Lisa Henderson',
    Unmasked_First_Name: 'Catherine',
    Masked_First_Name: 'Lisa',
    Unmasked_Last_Name: 'Arnold',
    Masked_Last_Name: 'Henderson',
    Unmasked_Email: 'whiteshawn@example.org',
    Masked_Email: 'ewright@example.net',
    Unmasked_Phone: '6502083230',
    Masked_Phone: '(463)811-9871',
    Unmasked_Occupation: 'Housekeeper',
    Masked_Occupation: 'Compare stuff black grow later executive.',
    Unmasked_Nationality: 'NULL',
    Masked_Nationality: 'Cameroon',
    Unmasked_ID: 'Id1059305',
    Masked_ID: '(287)280-6371',
    Unmasked_Address: '8662 Long Crest, Loweside',
    Masked_Address: '256 West Corners Apt. 929\nPort Jack, TN 67707',
  },
  {
    Unmasked_Full_Name: 'Cory Howard',
    Masked_Full_Name: 'Edwin Williams',
    Unmasked_First_Name: 'Cory',
    Masked_First_Name: 'Edwin',
    Unmasked_Last_Name: 'Howard',
    Masked_Last_Name: 'Williams',
    Unmasked_Email: 'hardindavid@example.com',
    Masked_Email: 'denise57@example.net',
    Unmasked_Phone: '(787)560-9544x8272',
    Masked_Phone: '001-917-503-3191x7952',
    Unmasked_Occupation: 'DIRECT SUPPORT STAFF',
    Masked_Occupation: 'City evidence phone bed.\nProvide debate exist',
    Unmasked_Nationality: 'UNITED STATES',
    Masked_Nationality: 'Congo',
    Unmasked_ID: 'Id6579849',
    Masked_ID: '(394)551-9186',
    Unmasked_Address: '044 Jennifer Glen, South Matthew',
    Masked_Address: '1918 Anderson Path Suite 739\nNovakview, IA 77676',
  },
  {
    Unmasked_Full_Name: 'Kelsey Palmer',
    Masked_Full_Name: 'Thomas Jones',
    Unmasked_First_Name: 'Kelsey',
    Masked_First_Name: 'Thomas',
    Unmasked_Last_Name: 'Palmer',
    Masked_Last_Name: 'Jones',
    Unmasked_Email: 'nixonnicholas@example.org',
    Masked_Email: 'marshalljonathan@example.com',
    Unmasked_Phone: '001-723-232-2094x3526',
    Masked_Phone: '951.716.9577x490',
    Unmasked_Occupation: 'Bookkeeping',
    Masked_Occupation: 'Official question sort chance across exist take',
    Unmasked_Nationality: 'US',
    Masked_Nationality: 'United Kingdom',
    Unmasked_ID: 'Id7123141',
    Masked_ID: '(926)874-8236x3028',
    Unmasked_Address: '98763 Ashley Shoals, Markfurt',
    Masked_Address: '4226 Larson Junction Apt. 884\nAndrewville, DE 12740',
  },
  {
    Unmasked_Full_Name: 'Denise Garcia',
    Masked_Full_Name: 'Victoria Kim',
    Unmasked_First_Name: 'Denise',
    Masked_First_Name: 'Victoria',
    Unmasked_Last_Name: 'Garcia',
    Masked_Last_Name: 'Kim',
    Unmasked_Email: 'mckeetiffany@example.net',
    Masked_Email: 'shawnrobertson@example.org',
    Unmasked_Phone: '+1-629-614-5285x7830',
    Masked_Phone: '(257)587-7711x6488',
    Unmasked_Occupation: 'RESTAURANT OWNER',
    Masked_Occupation: 'Pressure throw improve agree least staff break.',
    Unmasked_Nationality: 'JM',
    Masked_Nationality: 'Sao Tome and Principe',
    Unmasked_ID: 'Id804619',
    Masked_ID: '734.567.5281',
    Unmasked_Address: '8415 Hogan Views Apt. 585, Teresabury',
    Masked_Address: '79987 Jason Lane Apt. 565\nSouth Jonathon, SC 56516',
  },
  {
    Unmasked_Full_Name: 'Elizabeth Kline',
    Masked_Full_Name: 'Sydney Andrade',
    Unmasked_First_Name: 'Elizabeth',
    Masked_First_Name: 'Sydney',
    Unmasked_Last_Name: 'Kline',
    Masked_Last_Name: 'Andrade',
    Unmasked_Email: 'ugreen@example.org',
    Masked_Email: 'michelearnold@example.net',
    Unmasked_Phone: '(551)722-7160',
    Masked_Phone: '928.934.6202',
    Unmasked_Occupation: 'Support worker',
    Masked_Occupation: 'Skill finally choose practice room education available.',
    Unmasked_Nationality: 'GB',
    Masked_Nationality: 'Turkey',
    Unmasked_ID: 'Id2183150',
    Masked_ID: '(777)744-7895',
    Unmasked_Address: '0526 Rachel Knolls, Amandamouth',
    Masked_Address: '086 Margaret Park\nSouth Megan, DC 61302',
  },
  {
    Unmasked_Full_Name: 'Destiny Gould',
    Masked_Full_Name: 'Michelle Galvan',
    Unmasked_First_Name: 'Destiny',
    Masked_First_Name: 'Michelle',
    Unmasked_Last_Name: 'Gould',
    Masked_Last_Name: 'Galvan',
    Unmasked_Email: 'cory26@example.com',
    Masked_Email: 'jonathon62@example.net',
    Unmasked_Phone: '(938)374-4253',
    Masked_Phone: '001-395-707-7725x07902',
    Unmasked_Occupation: 'CUSTOMER SERVICE',
    Masked_Occupation: 'Since security lose person test throughout analysis',
    Unmasked_Nationality: 'JAMAICA',
    Masked_Nationality: 'Serbia',
    Unmasked_ID: 'Id1085084',
    Masked_ID: '-5315',
    Unmasked_Address: '76280 Matthew Extensions, Richardville',
    Masked_Address: '05552 Angela Parkways\nHarrisberg, RI 75553',
  },
]

const DataMaskedStruture: React.FC<any> = (props) => {
  const [maskedtrue, setMaskedtrue] = useState(true)

  const togglmasked = () => {
    setMaskedtrue((prev) => !prev)
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  return (
    <>
      <PageTitle breadcrumbs={data_masked_Breadcrumbs}>Data Masking</PageTitle>
      <div className='card-body'>
        <div className='table-responsive' style={{height: '480px', overflowY: 'auto'}}>
          {maskedtrue ? (
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-200px rounded-start'>FullName</th>
                  <th className='min-w-125px'>FirstName</th>
                  <th className='min-w-125px'>LastName</th>
                  <th className='min-w-125px'>Email</th>
                  <th className='min-w-125px'>Phone</th>
                  <th className='min-w-125px'>Occupation</th>
                  <th className='min-w-125px'>Nationality</th>
                  <th className='min-w-125px'>ID</th>
                  <th className='min-w-300px rounded-end'>Address</th>
                </tr>
              </thead>

              <tbody>
                {ummaskeddata.map((data, index) => (
                  <tr key={index}>
                    <td>
                      <span className='text-dark ms-4 fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {data.FullName}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-semibold text-hover-primary d-block mb-1 fs-6'>
                        {data.First_Name}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-semibold text-hover-primary d-block mb-1 fs-6'>
                        {data.Last_Name}
                      </span>
                    </td>{' '}
                    <td>
                      <span className='text-dark fw-semibold text-hover-primary d-block mb-1 fs-6'>
                        {data.Email}
                      </span>
                    </td>{' '}
                    <td>
                      <span className='text-dark fw-semibold text-hover-primary d-block mb-1 fs-6'>
                        {data.Phone}
                      </span>
                    </td>{' '}
                    <td>
                      <span className='text-dark fw-semibold text-hover-primary d-block mb-1 fs-6'>
                        {data.Occupation}
                      </span>
                    </td>{' '}
                    <td>
                      <span className='text-dark fw-semibold text-hover-primary d-block mb-1 fs-6'>
                        {data.Nationality}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-semibold text-hover-primary d-block mb-1 fs-6'>
                        {data.ID}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-semibold text-hover-primary d-block mb-1 fs-6'>
                        {data.Address}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <>
              {CompareData.map((userData, index) => (
                <table key={index} className='table align-middle gs-0 gy-4'>
                  <thead>
                    <tr className='fw-bold text-muted bg-light'>
                      <th className='ps-4 min-w-200px rounded-start'>Data Field</th>
                      <th className='min-w-125px'>Original Data</th>
                      <th className='min-w-125px rounded-end'>Masked Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(userData).map(([key, value]) => {
                      if (key.startsWith('Unmasked')) {
                        const maskedKey = key.replace('Unmasked', 'Masked')
                        const maskedValue = userData[maskedKey]
                        const originalKey = key.replace('Unmasked_', '')
                        return (
                          <tr key={key}>
                            <td>
                              <span className='text-dark ms-4 fw-bold text-hover-primary d-block mb-1 fs-6'>
                                {originalKey}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark fw-semibold text-hover-primary d-block mb-1 fs-6'>
                                {value}
                              </span>
                            </td>
                            <td>
                              <span className='text-dark fw-semibold text-hover-primary d-block mb-1 fs-6'>
                                {maskedValue}
                              </span>
                            </td>
                          </tr>
                        )
                      } else {
                        return null
                      }
                    })}
                  </tbody>
                </table>
              ))}
            </>
          )}
        </div>
        <div className='m-4'>
          <button onClick={togglmasked} className='btn btn-light-primary btn-active-primary w-100'>
            {maskedtrue ? 'Convert Into Masked Data' : 'Back'}
          </button>
        </div>
      </div>
    </>
  )
}

export default DataMaskedStruture
