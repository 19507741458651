import React, { useState } from 'react'
import $ from 'jquery'

const InsuranceListWidget = (props) => {
  
  const [modalOpen,setModal]=useState(false)

  const onModalClose = () =>{
    setModal(false);
  }

  const handleClick = () => {
    props.financeOption('');
    setModal(true);
  
    // Use setTimeout to trigger the click after a slight delay
    setTimeout(() => {
      const modalId = 'kt_modal_JNM'; // Replace with your actual modal ID
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`);
      if (modalTrigger) {
        ;(modalTrigger as any).click(); // Cast to 'any' to access 'click' method
      }
    }, 1); // You can adjust the delay time as needed
  }
  
  return (
    <>
      <div id='kt_app_content' className='app-content flex-column-fluid'>
        <div className='row g-5 g-xl-10'>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Underwriting Data Analysis</span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Analysis</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                To enhance accuracy in risk assessment, streamline decision-making, and optimize underwriting strategies for insurance policies
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_JNM'
                    onClick={()=>{handleClick()}}
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Customer Profiling and Segmentation </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Profiling</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Identifying common traits, improve product/service offerings, and optimize customer engagement strategies for more effective and personalized interactions
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Customer'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Policy Issuance and Documentation</span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Policy</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                To streamline the issuance process and deliver comprehensive policy documentation that clearly outlines terms and conditions for both insurers and policyholders
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_account'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Claims Data Management
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Adjudication</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                Efficiently handle and assess claims, ensuring accuracy, compliance with policy terms, and adherence to regulatory standards
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Predictive Analytics for Risk Management
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Security</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Organizations proactively identify and mitigate risks, enabling better decision-making and strategic planning
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Security'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Fraud Detection and Prevention
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Security</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Use of advanced technologies, data analysis, and machine learning algorithms to identify, mitigate, and prevent fraudulent activities within an organization
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Compliance'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Customer Relationship Management(CRM)
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Engagement</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                Managing and analyzing customer interactions and data to improve relationships, drive sales, and enhance customer satisfaction
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Data-driven Marketing and Sales
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Personalization</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Utilizing data analytics to inform marketing and sales strategies, enabling personalized and targeted approaches based on customer behavior and preferences
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Security'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Telematics for Auto Insurance
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Tracking</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                To monitor and analyze driver behavior, helping insurance companies assess risk and offer personalized auto insurance plans
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Compliance'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Health Data for Life and Health Insurance
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Wellness</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                The use of health data, often from wearable devices or electronic health records, to assess risk and personalize life and health insurance policies
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                   Regulatory Compliance and Reporting
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Compliance</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Ensuring adherence to industry regulations and efficiently managing reporting requirements to meet legal standards and maintain organizational compliance
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Security'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Cybersecurity and Data Privacy
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Security</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Implementing measures to protect sensitive data from cyber threats and ensuring compliance with data privacy regulations to maintain customer trust
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Compliance'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Actuarial Modeling
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Risk Forecasting</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                Using statistical and mathematical techniques to assess risk and uncertainty, particularly in the insurance and finance industries
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Investment Management
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Optimization</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                The strategic management of financial assets and investments to optimize returns while considering risk tolerance and market conditions
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Security'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </>
  )
}

export default InsuranceListWidget
