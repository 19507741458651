import React from 'react';
import './uploadfile.css';

const SuccessCheck = () => {
    return <>
        <div className="swal-icon swal-icon--success">
    <span className="swal-icon--success__line swal-icon--success__line--long"></span>
    <span className="swal-icon--success__line swal-icon--success__line--tip"></span>

    <div className="swal-icon--success__ring"></div>
    <div className="swal-icon--success__hide-corners"></div>
  </div>
  </>
}


export default SuccessCheck;