import React, { useState } from 'react'
import $ from 'jquery'
import MoneyRemittanceIntroModal from '../../finance/banking/remittance/MoneyRemittanceIntroModal'
import { Navigate, useNavigate } from 'react-router-dom'

const FinanceListWidget = (props) => {
  
  const navigate = useNavigate()
  
  const [modalOpen,setModal]=useState(false)

  const onModalClose = () =>{
    setModal(false);
  }

  const handleClick = () => {
    props.financeOption('');
    setModal(true);
  
    // Use setTimeout to trigger the click after a slight delay
    setTimeout(() => {
      const modalId = 'kt_modal_JNM'; // Replace with your actual modal ID
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`);
      if (modalTrigger) {
        ;(modalTrigger as any).click(); // Cast to 'any' to access 'click' method
      }
    }, 1); // You can adjust the delay time as needed
  }
  
  return (
    <>
      <div id='kt_app_content' className='app-content flex-column-fluid'>
        <div className='row g-5 g-xl-10'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Money Remittance</span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Transaction</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                  Data intelligence could be applied to analyze transaction data, understand
                  patterns, and gain insights into the flow of funds
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_JNM'
                     // onClick={()=>{handleClick()}}
                      onClick={()=> navigate('/process/bpms/finance/remittance/connection')}
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Customer Onboarding </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Customer</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                  Data is essential for the collection and verification of customer information
                  during onboarding.
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Customer'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Account Management</span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Accounts</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                  Ongoing account management involves the analysis of transaction data and customer
                  behavior to personalize services and identify potential issues.
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_account'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Credit Scoring and Loan Approval
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Loan</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                  Loan approval processes depend on accurate and up-to-date financial information to
                  make informed lending decisions.
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Fraud Detection and Security
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Security</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                  Real-time monitoring of transaction data is crucial for detecting and preventing
                  fraudulent activities. Security protocols leverage data for identity verification and access control to safeguard sensitive information.
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Security'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Risk Management and Compliance
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Compliance</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                  Risk assessment models use data to evaluate market risks, credit risks, and
                  operational risks. Compliance processes require the collection and analysis of
                  data to ensure adherence to regulatory requirements.
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Compliance'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalOpen &&
      <MoneyRemittanceIntroModal component="profiling" onClose={onModalClose} {...props}/>}
    </>
  )
}

export default FinanceListWidget
