import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import { KTIcon } from '../../../../_metronic/helpers'

interface Rule {
  column: string
  rules: string[]
}

interface ValidationPanelProps {
  selectedRulesHistory?: Rule[]
}

const UserSelectionStructure: React.FC<ValidationPanelProps> = (props) => {
  const navigate = useNavigate()
  const [isScrollable, setIsScrollable] = useState(false)

  useEffect(() => {
    const cardBody = document.getElementById('validationPanelCardBody')
    if (cardBody) {
      const currentHeight = cardBody.scrollHeight
      setIsScrollable(currentHeight >= 410)
    }
  }, [props.selectedRulesHistory])

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Validation Panel</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Selected data fields mapped with business rule
          </span>
        </h3>
      </div>
      <div className='separator border-secondary my-4 mt-2'></div>
        <div className='card-body pt-0 pb-0'>
          <div
            id='validationPanelCardBody'
            className=''
            style={isScrollable ? {maxHeight: '415px', overflowY: 'auto'} : {height: 'auto'}}
          >
            {props?.selectedRulesHistory &&
              props?.selectedRulesHistory.map((r: Rule, index) => (
                <div className='column-wrapper' key={index}>
                  <div className='fw-bold text-gray-600 mt-3'>
                    {r.column.charAt(0).toUpperCase() + r.column.slice(1)}
                  </div>
                  <div className='d-flex flex-column text-gray-600'>
                    {r.rules.map((rule, index) => (
                      <div className='ms-5 d-flex align-items-center py-2' key={index}>
                        <span className='bullet bg-primary me-3'></span>
                        {rule}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <br />
        <div>
          {props.selectedRulesHistory &&
          props.selectedRulesHistory.length > 0 &&
          props.selectedRulesHistory[props.selectedRulesHistory.length - 1].rules.length > 0 ? (
            <button
              type='submit'
              className='btn btn-light btn-active-primary  me-3 w-100'
              onClick={() => navigate('/process/bpms/finance/remittance/profiling/preview')}
            >
              <span className='indicator-label'>Continue</span>
              <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
            </button>
          ) : (
            <button disabled type='submit' className='btn btn-light btn-active-primary  me-3 w-100'>
              <span className='indicator-label'>Continue</span>
              <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
            </button>
          )}
        </div>
      </div>
  )
}

export default UserSelectionStructure
