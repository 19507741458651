import React, {useState, useEffect} from 'react'
import {SCHEMA} from './Config'

interface ReportStateProp {
  data: any
  rows: number
  page: number
  totalpages: number
  heading: string
  props: any
}

const FetchListOfRecordEngine: React.FC<ReportStateProp> = ({
  data,
  heading,
  rows,
  page,
  totalpages,
  props,
}) => {
  const [currentPage, setCurrentPage] = useState(props?.componentData?.page)
  const [rowsPerPage, setRowsPerPage] = useState(rows)

  let recordsPerPage = rows // Default to 10 records per page
  console.log('ROWSSSS', recordsPerPage, rowsPerPage)

  const setRow = (v) => {
    recordsPerPage = v
  }

  // useEffect(() => {
  //   // Reset to the first page whenever the data changes
  //   setCurrentPage(props.componentData.page)
  // }, [props.componentData.page])

  const handleAPI = (pageNumber) => {
    props.getErrorData(
      SCHEMA,
      props.componentData.table,
      props.componentData.fieldName,
      props.componentData.ruleID,
      props.componentData.input,
      props.componentData.count,
      pageNumber,
      props.componentData.heading,
      recordsPerPage
    )
  }

  const totalPages = (totalpages && totalpages !== 0 && totalpages) || 1 // Default to 1 page if not provided
  const startIndex = (currentPage - 1) * recordsPerPage
  const endIndex = startIndex + recordsPerPage
  const headers = data.length ? Object.keys(data[0]) : []

  // Calculate the range of page numbers to display
  const startRange = Math.max(1, currentPage - 5)
  const endRange = Math.min(totalPages, startRange + 9)

  console.log(`ROWS:${rows},PAGE:${page},TOTALPAGES:${totalpages}`)

  return (
    <>
      <div className='card-body mt-0 pb-0 px-4 py-0'>
        <div className='table-responsive'>
          {data && data.length > 0 ? (
            <table id={'table-to-xls'} className='table table-row-bordered gy-5'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-800'>
                  {headers.map((heading, index) => (
                    <th key={heading} className='text-center'>
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {headers.map((header, colIndex) => (
                      <td key={colIndex} className='text-center'>
                        {row[header]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            'No Search Results'
          )}
          <hr />
        </div>
        {rowsPerPage !== 0 && page !== 0 && totalPages > 1 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              flexDirection: window.innerWidth <= 576 ? 'column' : 'row',
            }}
          >
            <div style={{display: 'flex', alignItems: 'center', width: 'auto'}}>
              <select
                className='form-select form-select-solid'
                style={{width: '80px'}}
                aria-label='Select example'
                defaultValue={rows}
                value={recordsPerPage}
                onChange={(e) => {
                  const selectedRows = parseInt(e.target.value)
                  console.log('SSS', selectedRows)
                  setRow(selectedRows)
                  // setCurrentPage() // Reset current page to 1 when changing rows per page
                  handleAPI(currentPage) // Fetch data for the first page after changing rows per page
                }}
              >
                {Array.from(
                  {length: Math.min(totalPages, 10)},
                  (
                    _,
                    index // Ensure not more than 10 options
                  ) => (
                    <option key={index} value={(index + 1) * 10}>
                      {(index + 1) * 10}
                    </option>
                  )
                )}
              </select>
              {`Showing  ${(currentPage - 1) * recordsPerPage + 1} to 
        ${Math.min(currentPage * rowsPerPage, totalPages)} of ${totalPages} records`}
            </div>
            <ul className='pagination'>
              <li
                className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                style={{cursor: 'pointer'}}
              >
                <a
                  className='page-link'
                  onClick={() => {
                    setCurrentPage(currentPage - 1)
                    handleAPI(currentPage - 1)
                  }}
                >
                  Previous
                </a>
              </li>
              {Array.from({length: endRange - startRange + 1}, (_, index) => (
                <li
                  key={startRange + index}
                  className={`page-item ${currentPage === startRange + index ? 'active' : ''}`}
                  style={{cursor: 'pointer'}}
                >
                  <a
                    className='page-link'
                    onClick={() => {
                      setCurrentPage(startRange + index)
                      handleAPI(startRange + index)
                    }}
                  >
                    {startRange + index}
                  </a>
                </li>
              ))}
              <li
                className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
                style={{cursor: 'pointer'}}
              >
                <a
                  className='page-link'
                  onClick={() => {
                    setCurrentPage(currentPage + 1)
                    handleAPI(currentPage + 1)
                  }}
                >
                  Next
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  )
}

export {FetchListOfRecordEngine}
