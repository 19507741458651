// ReduxProvider.tsx

import React, { ReactNode, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, Middleware } from "redux";
import  { thunk,ThunkMiddleware } from "redux-thunk";
import logger from "redux-logger";
import { enableBatching } from "redux-batched-actions";
import rootReducer, { RootState } from "../Redux/Reducers"; // Import your rootReducer and RootState
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { PersistGate } from "redux-persist/integration/react";
import { LayoutProvider, LayoutSplashScreen, MetronicSplashScreenProvider } from "../../../../../../_metronic/layout/core";

interface ReduxProviderProps {
  children: ReactNode;
}

let middlewares: Middleware[] = [];

if (process.env.NODE_ENV === "development") {
  middlewares = [...middlewares, logger];
}

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer<RootState>(persistConfig, enableBatching(rootReducer));

const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const storeWithMiddleware = createStore(
  persistedReducer,
  composeEnhancer(applyMiddleware(thunk as ThunkMiddleware, ...middlewares))
);

const ReduxProvider: React.FC<ReduxProviderProps> = (props) => {
  const [isStoreLoading, setIsStoreLoading] = useState(true);

  const persistor = persistStore(storeWithMiddleware, null, () => {
    // Delay setting isStoreLoading to false to ensure the loader is shown for a brief period
    setTimeout(() => {
      setIsStoreLoading(false);
    }, 500); // Adjust the timeout duration as needed
  });

  return (
    <Provider store={storeWithMiddleware}>
   <MetronicSplashScreenProvider>
        <PersistGate
          loading={ <LayoutSplashScreen visible={true} />}
          persistor={persistor}
          onBeforeLift={() => setIsStoreLoading(true)}
        >
          {isStoreLoading?<LayoutSplashScreen/>:props.children}
        </PersistGate>
      </MetronicSplashScreenProvider>
    </Provider>
  );
};

export default ReduxProvider;
