// LOCAL APP
// export const FETCH_TABLE_API = 'http://127.0.0.1:3000/api/tables';
// export const FETCH_COLUMNS_API = 'http://127.0.0.1:3000/api/columns';
// export const FETCH_RULES_API ='http://127.0.0.1:3000/api/rules';
// export const DATA_REPORT_POST_API = 'http://127.0.0.1:3000/api/report';
// export const FETCH_GRAPH_DATA_API = 'http://127.0.0.1:3000/api/data';
// export const FETCH_ERROR_DATA_API = 'http://127.0.0.1:3000/api/error';
// export const DATA_ENRICHMENT_API = 'http://127.0.0.1:3000/api/enrich';
// export const DATA_ENRICHMENT_EXECUTE_API = 'http://127.0.0.1:3000/api/enrich/execute';
// export const FETCH_ENRICHMENT_RULE_API = 'http://127.0.0.1:3000/api/enrich/rules';
// export const FETCH_ENRICHMENT_RULE_VIEW_API = 'http://127.0.0.1:3000/api/enrich/data';
// export const FETCH_ENRICHED_DATA_API = 'http://127.0.0.1:3000/api/enrich/data';

// export const ENRICHMENT_DATA_WAITING = 'ENRICHMENT_DATA_WAITING';
// export const ENRICHMENT_DATA_SUCCESS = 'ENRICHMENT_DATA_SUCCESS';
// export const SCHEMA = 'datanxzen-QA';

// // // Data Archival Flow
// export const DA_FETCH_SCHEMA_API = 'http://127.0.0.1:3000/api/data-archival/schema';
// export const DA_FETCH_TABLES_API = 'http://127.0.0.1:3000/api/data-archival/tables';
// export const DA_FETCT_TBLINFO_API = 'http://127.0.0.1:3000/api/data-archival/table-info';
// export const DA_EXECUTION_API = 'http://127.0.0.1:3000/api/data-archival/execution';
// export const DA_FETCT_HISTORY_API = 'http://127.0.0.1:3000/api/data-archival/history';
// export const DA_FETCT_HISTORY_INFO_API = 'http://127.0.0.1:3000/api/data-archival/history';

// AWS Deployment on Dev Server
export const FETCH_TABLE_API = 'https://datanxzen.asoftgroups.com:3000/api/tables';
export const FETCH_COLUMNS_API = 'https://datanxzen.asoftgroups.com:3000/api/columns';
export const FETCH_RULES_API ='https://datanxzen.asoftgroups.com:3000/api/rules';
export const DATA_REPORT_POST_API = 'https://datanxzen.asoftgroups.com:3000/api/report';
export const FETCH_GRAPH_DATA_API = 'https://datanxzen.asoftgroups.com:3000/api/data';
export const FETCH_ERROR_DATA_API = 'https://datanxzen.asoftgroups.com:3000/api/error';
export const DATA_ENRICHMENT_API = 'https://datanxzen.asoftgroups.com:3000/api/enrich';
export const DATA_ENRICHMENT_EXECUTE_API = 'https://datanxzen.asoftgroups.com:3000/api/enrich/execute';
export const FETCH_ENRICHMENT_RULE_API = 'https://datanxzen.asoftgroups.com:3000/api/enrich/rules';
export const FETCH_ENRICHMENT_RULE_VIEW_API = 'https://datanxzen.asoftgroups.com:3000/api/enrich/data';
export const FETCH_ENRICHED_DATA_API = 'https://datanxzen.asoftgroups.com:3000/api/enrich/data';

export const ENRICHMENT_DATA_WAITING = 'ENRICHMENT_DATA_WAITING';
export const ENRICHMENT_DATA_SUCCESS = 'ENRICHMENT_DATA_SUCCESS';
export const SCHEMA = 'datanxzen-QA';

// Data Archival Flow
export const DA_FETCH_SCHEMA_API = 'https://datanxzen.asoftgroups.com:3000/api/data-archival/schema';
export const DA_FETCH_TABLES_API = 'https://datanxzen.asoftgroups.com:3000/api/data-archival/tables';
export const DA_FETCT_TBLINFO_API = 'https://datanxzen.asoftgroups.com:3000/api/data-archival/table-info';
export const DA_EXECUTION_API = 'https://datanxzen.asoftgroups.com:3000/api/data-archival/execution';
export const DA_FETCT_HISTORY_API = 'https://datanxzen.asoftgroups.com:3000/api/data-archival/history';
export const DA_FETCT_HISTORY_INFO_API = 'https://datanxzen.asoftgroups.com:3000/api/data-archival/history';

export const BOTZILLA_URL='https://botzilla.asoftglobal.com/'
export const BOTZILLA_VERSION='v1.0.1'