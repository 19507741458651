import React, { useState } from 'react'
import {TablesRuleEngine} from './TablesRuleEngine'

const RulesList = () => {

  const [currentStep, setCurrentStep] = useState(1); // State to track the current step of the stepper

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

    return (
      <>
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
          <div className='d-flex flex-column flex-column-fluid'>
            <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
              <div
                id='kt_app_toolbar_container'
                className='app-container container-xxl d-flex flex-stack'
              >
                <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
                  <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                    <li className='breadcrumb-item text-muted'>
                      <a
                        href='../../demo1/dist/index.html'
                        className='text-muted text-hover-primary'
                      >
                        Data Intellegence
                      </a>
                    </li>
                    <li className='breadcrumb-item'>
                      <span className='bullet bg-gray-400 w-5px h-2px'></span>
                    </li>
                    <li className='breadcrumb-item text-muted'>Conventional</li>
                    <li className='breadcrumb-item'>
                      <span className='bullet bg-gray-400 w-5px h-2px'></span>
                    </li>
                    <li className='breadcrumb-item text-muted'>
                      Business Proccess Management System
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id='kt_app_content' className='app-content flex-column-fluid'>
          {/* Content container */}
          <div id='kt_app_content_container' className='app-container container-xxl'>
            {/* Card */}
            <div className='card'>
              {/* Card body */}
              <div className='card-body'>
                {/* Heading */}
                <div className='card-px text-center pt-15 pb-15'>
                  {/* Title */}
                  <h2 className='fs-2x fw-bold mb-0'>Create Project Modal Example</h2>
                  {/* Description */}
                  <p className='text-gray-400 fs-4 fw-semibold py-7'>
                    Click on the below buttons to launch
                    <br />
                    an extended project creation flow example.
                  </p>
                  {/* Action */}
                  <a
                    href='#'
                    className='btn btn-primary er fs-6 px-8 py-4'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_project'
                  >
                    Create Project
                  </a>
                </div>
                {/* Illustration */}
                <div className='text-center pb-15 px-5'>
                  <img
                    src='assets/media/illustrations/sketchy-1/6.png'
                    alt=''
                    className='mw-100 h-200px h-sm-325px'
                  />
                </div>
              </div>
              {/* Card body */}
            </div>
            {/* Card */}
          </div>
          {/* Content container */}
        </div>
      
        <div className='modal fade' id='kt_modal_create_project' tabIndex={-1} aria-hidden='true'>
          <div className='modal-dialog modal-fullscreen p-9'>
            <div className='modal-content modal-rounded'>
              <div className='modal-header'>
                <h2>Create Project</h2>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  data-bs-dismiss='modal'
                >
                  <i className='ki-duotone ki-cross fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </div>
              </div>
              <div className='modal-body scroll-y m-5'>
                <div
                  className='stepper stepper-links d-flex flex-column'
                  id='kt_modal_create_project_stepper'
                >
                  <div className='container'>
                    <div className='stepper-nav justify-content-center py-2'>
                      {[
                        'Project Type',
                        'Project Settings',
                        'Budget',
                        'Build A Team',
                        'Set First Target',
                        'Upload Files',
                        'Completed',
                      ].map((step, index) => (
                        <div
                          key={index}
                          className={`stepper-item me-5 me-md-15 ${
                            currentStep === index + 1 ? 'current' : ''
                          }`}
                          data-kt-stepper-element='nav'
                        >
                          <h3 className='stepper-title'>{step}</h3>
                        </div>
                      ))}
                    </div>
                    <form
                      className='mx-auto w-100 mw-600px pt-15 pb-10'
                      id='kt_modal_create_project_form'
                      method='post'
                    >
                      <div className={`current`} data-kt-stepper-element='content'>
                        {currentStep === 1 && (
                          <div className='w-100'>
                            <div className='pb-7 pb-lg-12'>
                              <h1 className='fw-bold text-dark'>Project Type</h1>
                              <div className='text-muted fw-semibold fs-4'>
                                If you need more info, please check out
                                <a href='#' className='link-primary fw-bold'>
                                  FAQ Page
                                </a>
                              </div>
                            </div>
                            <div className='fv-row mb-15' data-kt-buttons='true'>
                              {/* ... Radio buttons for project type ... */}
                            </div>
                            <div className='d-flex justify-content-end'>
                              <button
                                type='button'
                                className='btn btn-lg btn-primary'
                                onClick={handleNextStep}
                                data-kt-element='type-next'
                              >
                                <span className='indicator-label'>Project Settings</span>
                                <span className='indicator-progress'>
                                  Please wait...
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              </button>
                            </div>
                          </div>
                        )}
                        {/* ... Other steps ... */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TablesRuleEngine className='' id='' />
      </>
    )
  }


export default RulesList
