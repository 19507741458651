import React, {useEffect} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themes_animated)

const ColumnChart = () => {
  useEffect(() => {
    let chart = am4core.create('column', am4charts.XYChart)
    // Disable the AmCharts logo
    chart.logo.disabled = true

    // Color palette
    const colors = ['#007bff', '#28a745', '#fd7e14'] // Blue, Green, Orange

    // Add data
    let data = [
      {segment: 'Champions', recency: 4.23, frequency: 4.42, monetary: 4.31},

      null, // Add null data point for spacing
      {segment: 'Promising', recency: 3.48, frequency: 3.17, monetary: 2.72},

      null, // Add null data point for spacing
      {segment: 'Loyalist', recency: 3.8, frequency: 1.96, monetary: 2.45},

      null, // Add null data point for spacing
      {segment: 'At Risk', recency: 1.27, frequency: 3.31, monetary: 3.55},

      null, // Add null data point for spacing
      {segment: 'Dormant', recency: 1.49, frequency: 1.51, monetary: 1.6},
    ].filter((dataPoint) => dataPoint !== null) // Filter out null data points

    chart.data = data

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'segment'
    categoryAxis.renderer.grid.template.location = 0.5
    categoryAxis.renderer.minGridDistance = 10 // Adjust spacing between categories
    categoryAxis.renderer.labels.template.rotation = 0 // Rotate labels for better visibility (negative value)
    categoryAxis.renderer.labels.template.horizontalCenter = 'right' // Align labels to the right
    categoryAxis.renderer.cellStartLocation = 0.1 // Adjust start location of categories
    categoryAxis.renderer.cellEndLocation = 0.4 // Adjust end location of categories
    categoryAxis.width = am4core.percent(100) // Set the width to 50% of the available space

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

    // Create series for each score
    ;['recency', 'frequency', 'monetary'].forEach((score, index) => {
      let series = chart.series.push(new am4charts.ColumnSeries())
      series.dataFields.valueY = score
      series.dataFields.categoryX = 'segment'
      series.name = score.charAt(0).toUpperCase() + score.slice(1) // Capitalize first letter
      series.columns.template.tooltipText = `{name} - {valueY}[/]`
      series.columns.template.fill = am4core.color(colors[index]) // Assign color from data
      series.columns.template.fillOpacity = 1.0
      series.columns.template.width = am4core.percent(55) // Adjust column width
      series.columns.template.stroke = am4core.color(colors[index]) // Set border color same as fill color

      let columnTemplate = series.columns.template
      columnTemplate.strokeWidth = 2
      columnTemplate.strokeOpacity = 1
      columnTemplate.column.cornerRadiusTopLeft = 2 // Add rounded corners to columns
      columnTemplate.column.cornerRadiusTopRight = 2
      columnTemplate.column.cornerRadiusBottomLeft = 2
      columnTemplate.column.cornerRadiusBottomRight = 2
    })

    // Add legend
    chart.legend = new am4charts.Legend()
    chart.legend.position = 'bottom'

    // Add cursor
    chart.cursor = new am4charts.XYCursor()

    return () => {
      chart.dispose()
    }
  }, [])

  return <div id='column' style={{width: '100%', height: '450px'}}></div>
}

export default ColumnChart
