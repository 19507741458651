import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const Circular = () => {
  useEffect(() => {
    let chart = am4core.create('circular', am4charts.PieChart);
       // Disable the AmCharts logo
chart.logo.disabled = true;

    // Sample data
    const data = [
      { segment: 'Train Data', value: 3158 },
      { segment: 'Test Data', value: 9471 },
    ];

    chart.data = data;

    // Add title
    let title = chart.titles.create();
    //title.text = 'Distribution of Dataset';
    title.fontSize = 20;
    title.marginBottom = 20;

    // Create pie series
    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = 'value';
    series.dataFields.category = 'segment';
    series.slices.template.stroke = am4core.color('#fff'); // Add stroke to slices
    series.slices.template.strokeWidth = 1; // Set stroke width
    series.labels.template.disabled = true; // Disable default labels
    series.innerRadius = am4core.percent(50); // Set inner radius for donut chart

    // Add label in the center of the chart
    let label = chart.seriesContainer.createChild(am4core.Label);
    label.text = 'Total : 12629';
    label.horizontalCenter = 'middle';
    label.verticalCenter = 'middle';
    label.fontSize = 20;

    // Add color settings
    series.colors.list = [
      am4core.color('#67b7dc'), // Blue color for Train Data
      am4core.color('#8067dc'), // Green color for Test Data
    ];

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top'; // Position legend on the right side
    chart.legend.labels.template.fontSize = 14; // Increase font size
    chart.legend.labels.template.fontWeight = 'bold'; // Bold font


    return () => {
      chart.dispose();
    };
  }, []);

  return <div id="circular" style={{ width: '100%', height: '450px' }}></div>;
};

export default Circular;
