import React, {useState} from 'react'
import SolidGComponent from './components/SolidGComponent'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'

const Data_Enrich_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intellegent Analytics',
    path: '/app/intellegent-analytics',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const YTDTransferDataChart: React.FC = () => {
  return (
    <>
    <PageTitle breadcrumbs={Data_Enrich_Breadcrumbs}>Transaction Volume Insight</PageTitle>
      
      <div className='row gx-5 gx-xl-10'>
          <div className='card card-flush h-xl-100'>
            <div className='card-header pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-900'>Total Transactions Volume by Year</span>
              </h3>
            </div>
            <SolidGComponent />
          </div>
      </div>
    </>
  )
}

export default YTDTransferDataChart
