import React from 'react';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import ConstructionListWidget from './ConstructionListWidget';

const BP_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Business Process Management Solution',
    path: '/apps/process/bpms/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  
];

const ConstructionProductList = (props) => {
  return (
    <>
      <PageTitle breadcrumbs={BP_Breadcrumbs}>Construction Bussiness Process</PageTitle>

      <ConstructionListWidget {...props} />
    </>
  );
};

export default ConstructionProductList;
