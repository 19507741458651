import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

interface DataTableProps {
  getDbNames: (args: {product: string}) => void
  getTableNames: (args: {product: string; schemaName: string}) => void
  getTableInfo: (args: {product: string; schemaName: string; tableName: string}) => void
  getDataHistory: (args: {product: string}) => void
  dbNames?: {
    product: string
    schema: string[]
  }
  tableNames?: {tables: string[]}
  selectedDataArchivalTable?: string[]
  setArchivalTable: (args: string[]) => void
  tableInfoFailed: any
}

interface TableCollapseState {
  [key: string]: boolean
}

const ArchivalDBStructure: React.FC<DataTableProps> = (props) => {
  const navigate = useNavigate()
  const [selectedTables, setSelectedTables] = useState<string[]>([])
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)
  const [tableCollapseState, setTableCollapseState] = useState<TableCollapseState>({})

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      sessionStorage.clear()
    })

    const storedSelectedTables = sessionStorage.getItem('selectedTables')
    const storedSchema = sessionStorage.getItem('selectedSchema')

    if (storedSelectedTables) {
      setIsCollapsed(true)
      setSelectedTables(JSON.parse(storedSelectedTables))
    }

    if (storedSchema) {
      setIsCollapsed(true)
      setTableCollapseState((prevState) => ({
        ...prevState,
        [storedSchema]: true,
      }))
    }

    props.getDbNames({product: 'datanxzen'})
  }, [])

  const handleTableClick = (schema: string) => {
    setIsCollapsed(true)
    setTableCollapseState((prevState) => ({
      ...prevState,
      [schema]: !prevState[schema],
    }))
    props.getTableNames({product: props.dbNames!.product, schemaName: schema})
  }

  const handleCheckboxChange = (schema: string, table: string) => {
    const updatedSelectedTables = [table]
    sessionStorage.setItem('selectedTables', JSON.stringify(updatedSelectedTables))
    sessionStorage.setItem('selectedSchema', JSON.stringify(schema))

    setSelectedTables(updatedSelectedTables)
    props.setArchivalTable(updatedSelectedTables)

    props.getTableInfo({
      product: props.dbNames!.product,
      schemaName: schema,
      tableName: table,
    })
  }

  const handleHistoryClick = () => {
    props.getDataHistory({product: 'datanxzen'})
    navigate('/data/archival/history')
  }

  useEffect(() => {
    if (props.tableInfoFailed) {
      alert("Couldn't load table info properly")
    }
  }, [props.tableInfoFailed])

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Database Structure</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Archival tables mentioned in retention policy
          </span>
        </h3>
      </div>
      <div className='separator border-primary my-4 mt-2'></div>

      {props.dbNames && (
        <div className='card-body py-3' style={{height: '317px', overflowY: 'auto'}}>
          {props.dbNames.schema &&
            props.dbNames.schema.map((schema, index) => (
              <div className='m-0 mb-2' key={schema}>
                <div
                  className={`d-flex align-items-center collapsible py-0 toggle mb-2 ${
                    isCollapsed ? 'collapsed' : 'show'
                  }`}
                  data-bs-toggle='collapse'
                  data-bs-target={`#kt_job_${index}_300`}
                  onClick={() => handleTableClick(schema)}
                >
                  <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                    <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  </div>
                  <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>{schema}</h4>
                </div>

                <div
                  id={`kt_job_${index}_300`}
                  className={`collapse fs-6 ms-1 ${tableCollapseState[schema] ? 'show' : ''}`}
                >
                  <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                    <div className='m-0 mb-2'>
                      {props.tableNames?.tables &&
                        props.tableNames.tables.map((table: string) => (
                          <div className='m-0 mb-2' key={table}>
                            <div id={`kt_job_${table}`} className={` fs-6`}>
                              <div className='mb-1'>
                                <div className='row' key={table}>
                                  <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      name={table}
                                      value={selectedTables}
                                      checked={
                                        selectedTables.includes(table) ||
                                        props?.selectedDataArchivalTable?.includes(table) ||
                                        false
                                      }
                                      onChange={() => handleCheckboxChange(schema, table)}
                                    />
                                    <label className='form-check-label'>{table}</label>
                                  </div>
                                  <br />
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div className='separator separator-dashed'></div>
              </div>
            ))}
        </div>
      )}
      <div className='m-4'>
        <button onClick={handleHistoryClick} className='btn btn-light btn-active-secondary w-100'>
          {'History'}
        </button>
      </div>
    </div>
  )
}

export default ArchivalDBStructure
