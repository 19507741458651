import React, {Fragment, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {SCHEMA} from '../../../pages/data/trad/brms/Config'
import DataEnrichmentModal from '../../../process/finance/banking/JNM/components/DataEnrichmentModal'
import CustomerDataEnrichmentModal from '../../../pages/data/trad/brms/CustomerDataEnrichmentModal'

const DataEnrichRuleBook: React.FC<any> = (props) => {
  const navigate = useNavigate()

  const [enrichmentmodalOpen] = useState(false)
  const [modalOpen, setModal] = useState(false)
  const [heading, setHeading] = useState('')
  const [ruleid, setRuleid] = useState(0)
  const [affectedValue, setAffectedValue] = useState(0) // State for affected value

  const handleRuleClick = (id, rule, affected) => {
    setRuleid(id)
    setHeading(rule)
    setAffectedValue(affected) // Update affected value state

    let apiData = {
      db: SCHEMA,
      table: props?.selectedTable,
      column: props?.selectedEnrichmentFieldData?.fieldData?.fieldName,
      ruleId: id,
      rows: 10,
      page: 1,
    }

    props.getCustomerDataonEnrichment(apiData, Math.ceil(affected / 10))

    const modalId = 'kt_modal_1' // Replace with your actual modal ID
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)

    if (modalTrigger) {
      ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
    }
  }

  const onModalClose = () => {
    setModal(false)
  }

  const enrichmentopenModal = () => {
    props.modalClicked()

    const selectedRules = props.selectedEnrichmentFieldData.selectedEnrichmentRule

    let apiData = {
      db: SCHEMA,
      table: props.selectedTable,
      data: [
        {
          FieldName: props?.selectedEnrichmentFieldData?.fieldName,
          selected_rules: selectedRules.map((r) => ({
            Id: r.Id,
            rule: r.rule,
          })),
        },
      ],
    }
    props.applyDataEnrichment('', apiData)

    const modalId = 'kt_modal_DE_Loader' // Replace with your actual modal ID

    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    if (modalTrigger) {
      ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
    }
  }

  const handleClick = () => {
    const modalId = 'Enrichment_modal' // Replace with your actual modal ID
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    if (modalTrigger) {
      ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
    }
    //props.modalClicked()
  }

  useEffect(() => {
    if (enrichmentmodalOpen) {
      const modal1Id = 'enrichment_modal' // Replace with your actual modal ID
      const modalTrigger = document.querySelector(`[data-bs-target="#${modal1Id}"]`)
      if (modalTrigger) {
        ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
      }
    }

    if (modalOpen) {
      const modal2Id = 'upload_modal' // Replace with your actual modal ID
      const modalTrigger = document.querySelector(`[data-bs-target="#${modal2Id}"]`)
      if (modalTrigger) {
        ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
      }
    }
  }, [modalOpen, enrichmentmodalOpen])

  return (
    <>
      {props.selectedEnrichmentFieldData &&
        props.selectedEnrichmentFieldData.selectedEnrichmentRule &&
        props.selectedEnrichmentFieldData.selectedEnrichmentRule.map(
          (r) =>
            r.affected && (
              <Fragment key={r.Id}>
                {' '}
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='form-check form-check-custom form-check-solid me-4'>
                    <i className='ki-duotone ki-element-5 fs-2tx text-secondary text-hover-primary me-4'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    <label
                      className='form-check-label'
                      htmlFor='kt_modal_update_email_notification_1'
                    >
                      <div className='fw-bold text-gray-800'>{r.desc ? r.desc : ''}</div>
                      {props.selectedEnrichmentFieldData &&
                        props.selectedEnrichmentFieldData.fieldData &&
                        props.selectedEnrichmentFieldData.fieldData.totalNoOfRows && (
                          <div className='text-gray-600'>
                            Out of the total{' '}
                            <strong>
                              {Number(
                                props.selectedEnrichmentFieldData.fieldData.totalNoOfRows
                              ).toLocaleString()}
                            </strong>{' '}
                            records,{' '}
                            <strong className='text-info'>
                              {Number(r.affected).toLocaleString()}
                            </strong>{' '}
                            records will be impacted by this enrichment rule.
                          </div>
                        )}
                    </label>
                  </div>
                  <button
                    className='btn btn-sm btn-light btn-active-light-primary '
                    onClick={() => handleRuleClick(r.Id, r.desc, r.affected)}
                  >
                    <i className='ki-duotone ki-abstract-26 fs-2 me-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    View
                  </button>
                </div>
                <div className='separator separator-dashed my-5'></div>
              </Fragment>
            )
        )}

      <div className='d-flex justify-content-end align-items-center mt-5'>
        <button
          type='button'
          className='btn btn-light me-5'
          id='kt_enrichment_rules_submition_cancel'
          onClick={() => navigate('/process/bpms/finance/remittance/enrichment')}
        >
          Back
        </button>
        <button
          type='button'
          className='btn btn-primary'
          id='kt_enrichment_rules_submition'
          onClick={() => {
            setModal(true)
            handleClick()
          }}
        >
          <span className='indicator-label'>Submit Rules</span>
        </button>
      </div>

      <span data-bs-toggle='modal' data-bs-target='#Enrichment_modal'></span>
      <div className='modal fade' onClick={onModalClose} tabIndex={-2} id='Enrichment_modal'>
        <div
          className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered h-auto mw-650px'
          onClick={(e) => e.stopPropagation()}
        >
          <div className='modal-content bg-light-error' style={{width: '100%'}}>
            <div
              className='modal-body d-flex flex-center flex-column py-10 px-10 px-lg-20'
              style={{width: '100%'}}
            >
              <button
                type='button'
                className='position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger'
                data-bs-dismiss='modal'
              >
                <span className='svg-icon svg-icon-1'>
                  <i className='ki-duotone ki-cross fs-2x'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </span>
              </button>

              <span className='svg-icon svg-icon-5tx svg-icon-danger mb-5'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='12'
                  fill='currentColor'
                  className='bi bi-exclamation-circle'
                  viewBox='0 0 16 16'
                >
                  <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16' />
                  <path d='M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z' />
                </svg>
              </span>

              <div className='text-center'>
                <h5 className='fw-bolder fs-1 mb-5'>Are you Sure ?</h5>

                <div className='separator separator-dashed border-danger opacity-25 mb-5'></div>

                <div className='mb-9'>
                  Before proceeding with data enrichment, we want to inform you about the potential{' '}
                  <strong>impact on your records</strong>. <br />
                  <br />
                  After applying the data enrichment rules, a certain number of records will be
                  affected. <br />
                </div>
                <div className='d-flex flex-center flex-wrap'>
                  <button
                    className='btn btn-outline btn-outline-danger btn-active-danger m-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={onModalClose}
                  >
                    Cancel
                  </button>
                  <button className='btn btn-danger m-2' onClick={enrichmentopenModal}>
                    Accept
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DataEnrichmentModal {...props} />
      <CustomerDataEnrichmentModal
        heading={heading}
        affected={affectedValue}
        props={props}
        ruleid={ruleid}
      />
    </>
  )
}

export default DataEnrichRuleBook
