import React from 'react'

import RulesList from '../../../../app/pages/layout-builder/RulesList'

export function BusinessRuleProcessing() {
  return (
    <>
      <RulesList />
    </>
  )
}
