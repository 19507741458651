import React, {useEffect} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

am4core.useTheme(am4themes_animated)

const PieChart: React.FC = () => {
  useEffect(() => {
    let chart = am4core.create('Pie', am4charts.PieChart)
    // Disable the AmCharts logo
    chart.logo.disabled = true

    
   // Add data
   let data = [
    { segment: 'Champions', recency: 2747},
    { segment: 'Promising', recency: 1686},
    { segment: 'Loyalist', recency: 1033},
    { segment: 'At Risk', recency: 1308},
    { segment: 'Dormant', recency: 5855},
  ];
    chart.data = data

    // Define an array of colors for each segment
    const colors = ['#339BFF', '#33FFB5', '#9B33FF', '#FF5733', '#67b7dc']

    // Create pie series
    let series = chart.series.push(new am4charts.PieSeries())
    series.dataFields.value = 'recency'
    series.dataFields.category = 'segment'
    series.slices.template.stroke = am4core.color('#fff'); // Add stroke to slices
    series.slices.template.strokeWidth = 1; // Set stroke width
    series.labels.template.disabled = true; // Disable default labels

    // Assign each segment a color from the colors array
    series.colors.list = colors.map((color) => am4core.color(color))

    series.slices.template.stroke = am4core.color('#fff') // Add stroke to slices
    series.slices.template.strokeWidth = 1 // Set stroke width

    // Add custom slice labels
    // series.labels.template.disabled = true; // Disable default labels
    // let labelTemplate = series.slices.template.createChild(am4core.Label);
    // labelTemplate.text = '{value}';
    // labelTemplate.horizontalCenter = 'middle';
    // labelTemplate.verticalCenter = 'middle';
    // labelTemplate.fontSize = 12;
    // labelTemplate.fill = am4core.color('#fff'); // Set label text color

    // Disable slice labels
    series.labels.template.disabled = true
    // Disable ticks
    series.ticks.template.disabled = true
    // Add legend
    chart.legend = new am4charts.Legend()

    return () => {
      chart.dispose()
    }
  }, [])

  return <div id='Pie' style={{width: '100%', height: '450px'}}></div>
}

export default PieChart
