import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import UserSelectionWidget from './UserSelectionWidget'
import {SCHEMA} from './Config'
import Loader from './Loader'
import './index.css'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import CogLoader from './CogLoader'

interface Rule {
  Id: string
  rule: string
  desc: string
}

interface Props {
  appendSelectedRules: (column: string, rules: string[], ruleIds: string[]) => void
  selectedRulesHistory: any[]
  columnRules: any
  postDataToApi: (apiEndpoint: string, apiData: any) => void
  selectedColumnList: any[]
  postingData: boolean
  postDataFailed: boolean
  selectedTable: string
  postDataSuccess: boolean
}

const data_profiling_preview_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Finance',
    path: '/process/bpms/finance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Money Remittance',
    path: '/process/bpms/finance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Data Profiling',
    path: '/process/bpms/finance/remittance/profiling',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BusinessRulePreview: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const [column] = useState<string | null>(null)
  const [selectedRules, setSelectedRules] = useState<string[]>([])
  const [selectedRuleIds, setSelectedRuleIds] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showModal,setShowmodal] = useState(false)

  useEffect(() => {
    if (column) props.appendSelectedRules(column, selectedRules, selectedRuleIds)
  }, [column, selectedRules, selectedRuleIds, props])

  useEffect(() => {
    if (column && props?.selectedRulesHistory && props?.selectedRulesHistory.length > 0) {
      let index = props?.selectedRulesHistory.findIndex((item) => item.column === column)
      setSelectedRuleIds(props?.selectedRulesHistory[index]?.ruleIds.map((r: string) => r) || [])
      setSelectedRules(props?.selectedRulesHistory[index]?.rules.map((r: string) => r) || [])
    }
  }, [column, props])

  const executeRules = () => {
    const timerId = setTimeout(() => {
      setShowmodal(false);
      navigate('/process/bpms/finance/remittance/profiling/report')
    }, 8000); // 5 seconds

    return () => clearTimeout(timerId);
   
  }

  const postData = () => {
    let apiData = {
      db: SCHEMA,
      table: props.selectedTable,
      data: props.selectedRulesHistory.map((item) => ({
        FieldName: item.column,
        selected_rules: item.rules.map((rule: Rule, index: number) => ({
          Id: item.ruleIds[index],
          rule: rule,
        })),
      })),
    }
    props.postDataToApi('', apiData)
setShowmodal(true)
  }

  return (
    <>
      {loading ? <Loader /> : props.postDataFailed ? 'Something went wrong' : null}

      <PageTitle breadcrumbs={data_profiling_preview_Breadcrumbs}>Preview Selected Rules</PageTitle>
 
      {/* ends here */}
      <div className='row g-5'>
        {props?.selectedRulesHistory &&
          props?.selectedRulesHistory.map((r, index) => (
            <div key={`col-${index}`} className='col-xl-4'>
              <UserSelectionWidget
                className='card-xl-stretch mb-xl-6'
                image='abstract-4.svg'
                title={r.column}
                descriptions={r}
                cardId={`card_${index}`}
                {...props}
              />
            </div>
          ))}
      </div>

      <div className='d-flex justify-content-left pt-4' data-kt-filemanager-table-toolbar='base'>
        <button
          type='button'
          className='btn btn-flex btn-light-primary me-3'
          onClick={() => navigate('/data/quality')}
        >
          <i className='ki-duotone ki-exit-left fs-4'>
            <span className='path1'></span>
            <span className='path2'></span>
          </i>{' '}
          <i className='ki-duotone ki-exit-left fs-2'></i> Previous
        </button>

        <button
          type='button'
          className='btn btn-flex btn-primary'
          onClick={() => {
            postData()
            executeRules()
          }}
        >
          {' '}
          <i className='ki-duotone ki-setting fs-1'>
            <span className='path1'></span>
            <span className='path2'></span>
            <span className='path3'></span>
            <span className='path4'></span>
          </i>
          {/* {props.postingData ? 'Submitting Rules' : ' Execute / Run'} */}
          {showModal ? 'Submitting Rules' : ' Execute / Run'}
        </button>
      </div>
      {showModal && (
        <div className='modal fade show' tabIndex={-1} role='dialog' 
        style={{ display: 'flex', width: '100vw', background: 'transparent',boxShadow:'none',border:'none' }}>
          {/* <div className='modal-backdrop fade show'></div> */}
          <div
            style={{ width: '100%', background: 'transparent',boxShadow:'none',border:'none' }}
            className='d-flex flex-column justify-content-center align-items-center modal-dialog modal-dialog-centered modal-dialog mw-1000px h-100vh'>
            <div className='modal-content' style={{ width: '100%', height: '100%', background: 'transparent',boxShadow:'none',border:'none' }}>
              <div className='modal-header'>
                {/* Optionally, you can include a close button in the header */}
                {/* <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={() => setShowmodal(false)}></button> */}
              </div>
              <div className='modal-body'>
                <CogLoader {...props}/>
              </div>
            </div>
          </div>
        </div>
      )}

    </>
  )
}

export default BusinessRulePreview
