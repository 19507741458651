import React from 'react';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import ManufacturingListWidget from './ManufacturingListWidget';

const BP_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Business Process Management Solution',
    path: '/apps/process/bpms/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  
];

const ManufacturingProductList = (props) => {
  return (
    <>
      <PageTitle breadcrumbs={BP_Breadcrumbs}>Manufacturing Business Process</PageTitle>

      <ManufacturingListWidget {...props} />
    </>
  );
};

export default ManufacturingProductList;
