import React, {Fragment, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'

interface Rule {
  Id: string
  rule: string
  desc: string
}

interface UserSelectionWidgetProps {
  className: string
  image: string
  title: string
  descriptions: any
  cardId: string // Add a unique identifier for each card
  appendSelectedRules: (column: string, rules: string[], ruleIds: string[]) => void
  selectedRulesHistory: any[]
  columnRules: any
  postDataToApi: (apiEndpoint: string, apiData: any) => void
}

interface Props {
  className: string
  image: string
  title: string
  descriptions: any // You might want to replace 'any' with a more specific type
  appendSelectedRules: (column: string, rules: string[], ruleIds: string[]) => void
  selectedRulesHistory: any[] // You might want to replace 'any[]' with a more specific type
  columnRules: any // You might want to replace 'any' with a more specific type
  postDataToApi: (apiEndpoint: string, apiData: any) => void
}

interface UserSelectionWidgetProps extends Props {
  cardId: string
}

const UserSelectionWidget: React.FC<UserSelectionWidgetProps> = (props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [column, setColumn] = useState<string | any>(null)
  const [selectedRules, setSelectedRules] = useState<string[]>([])
  const [selectedRuleIds, setSelectedRuleIds] = useState<string[]>([])

  const toggleRule = (rule: Rule) => {
    const isSelected = selectedRuleIds.includes(rule.Id)
    if (isSelected) {
      setSelectedRuleIds((prevSelectedRuleIds) =>
        prevSelectedRuleIds.filter((selectedRule) => selectedRule !== rule.Id)
      )
      setSelectedRules((prevSelectedRules) =>
        prevSelectedRules.filter((selectedRule) => selectedRule !== rule.desc)
      )
    } else {
      setSelectedRuleIds((prevSelectedRuleIds) => [...prevSelectedRuleIds, rule.Id])
      setSelectedRules((prevSelectedRules) => [...prevSelectedRules, rule.desc])
    }
  }

  useEffect(() => {
    if (column) props.appendSelectedRules(column, selectedRules, selectedRuleIds)
  }, [column, selectedRules])

  useEffect(() => {
    if (modalOpen) {
      props.appendSelectedRules(props.descriptions.column, selectedRules, selectedRuleIds)
      if (props?.selectedRulesHistory && props?.selectedRulesHistory.length > 0) {
        const index = props?.selectedRulesHistory.findIndex(
          (item) => item.column === props.descriptions.column
        )
        setSelectedRuleIds(props?.selectedRulesHistory[index]?.ruleIds.map((r: string) => r))
        setSelectedRules(props?.selectedRulesHistory[index]?.rules.map((r: string) => r))
      }
    }
  }, [modalOpen])

  useEffect(() => {
    if (column) {
      if (props?.selectedRulesHistory && props?.selectedRulesHistory.length > 0) {
        let index = props?.selectedRulesHistory.findIndex((item) => item.column === column)
        setSelectedRuleIds(props?.selectedRulesHistory[index]?.ruleIds.map((r: string) => r))
        setSelectedRules(props?.selectedRulesHistory[index]?.rules.map((r: string) => r))
      }
    }
  }, [column])

  const openModal = (c: string) => {
    setColumn(c)
    setModalOpen(true)
    const modalId = `kt_modal_${props.cardId}`
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    if (modalTrigger) {
      ;(modalTrigger as any).click()
    }
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <div
      className={`card bgi-no-repeat ${props.className}`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + props.image)})`,
      }}
    >
      <div className='card-body'>
        <div style={{width: '100%', display: 'flex'}}>
          <span
            className='card-title fw-bold text-muted text-hover-primary fs-4'
            style={{width: '100%'}}
          >
            {props.title}
          </span>
          <span
            className='card-title fw-bold text-muted text-hover-primary fs-4'
            style={{
              textAlign: 'right',
              justifyContent: 'right',
              width: '100%',
              float: 'right',
              cursor: 'pointer',
            }}
            onClick={() => openModal(props.descriptions.column)}
          >
            <span title="Edit Rules">
              <i className='ki-duotone ki-setting-4 fs-2x'></i>
            </span>
          </span>
        </div>
        <div className='fw-bold text-primary my-6'></div>
        {props.descriptions.rules.map((row: string, index: number) => (
          <Fragment key={`lw26-rows-${index}`}>
            <div className='d-flex'>
              <KTIcon iconName='exit-right-corner' className='fs-2' />
              <span className='ms-4 text-primary fw-semibold fs-6 me-2'>{row}</span>
            </div>
            {props.descriptions.rules.length - 1 > index && (
              <div className='separator separator-dashed my-3' />
            )}
          </Fragment>
        ))}
      </div>

      <>
        <span data-bs-toggle='modal' data-bs-target={`#kt_modal_${props.cardId}`}></span>
        <div
          className='modal fade'
          onClick={closeModal}
          tabIndex={-1}
          id={`kt_modal_${props.cardId}`}
        >
          <div
            className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered h-auto'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='modal-content' style={{width: '100%'}}>
              <div className='modal-header py-3'>
                <h5 className='modal-title'>Data Field - {props.descriptions.column}</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                >
                  <i className='ki-duotone ki-cross fs-2x'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </div>
              </div>
              <div className='modal-body'>
                {props?.columnRules &&
                  props?.columnRules[props.descriptions.column]?.rules.map(
                    (r: Rule, index: number) => (
                      <div key={index}>
                        <div className='mb-4'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              checked={selectedRuleIds.includes(r.Id)}
                              onChange={() => toggleRule(r)}
                            />
                            <label className='form-check-label text-gray-600 fw-semibold fs-6'>
                              {r.desc}
                            </label>
                          </div>
                        </div>
                        <br></br>
                      </div>
                    )
                  )}
                <div style={{width: '100%', display: 'flex', justifyContent: 'left'}}>
                  <button
                    className='btn btn-sm btn-primary me-3'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={closeModal}
                  >
                    <i
                      className='ki-duotone ki-copy-success'
                    >
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                    {'Modify Rules'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default UserSelectionWidget