import { Modal } from 'bootstrap';
import DataReadinessComponent from './DataReadinessComponent'
import $ from 'jquery'
import { useNavigate } from 'react-router-dom';

const BankingProcessModal = (props) => {

  const navigate = useNavigate();

  const handleClick = () => {
    if (props.financesolutionoption === 'data-profiling') {
      navigate('/process/bpms/finance/remittance/profiling');
    } else if (props.financesolutionoption === 'data-enrichment') {
      navigate('/process/finance/banking/data-enrichment');
    }
  }
  
  return (
    <>
      <span data-bs-toggle='modal' data-bs-target='#kt_modal_JNM'></span>
      <div className='modal fade' tabIndex={-1} id='kt_modal_JNM'>
        <div className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered h-auto modal-dialog mw-1000px'>
          <div className='modal-content' style={{width: '100%'}}>
            <div className='modal-header py-4'>
              <h2>Data Readiness</h2>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <i className='ki-duotone ki-cross fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>  
            <DataReadinessComponent component={props.component} {...props} />
            <div className='d-flex flex-stack pt-0 pb-8'>
            <div style={{ marginLeft: 'auto', ...(window.innerWidth > 768 ? { paddingRight: '75px' } : {paddingRight:'15px'}) }}> 
            {props.financesolutionoption&&props.financesolutionoption !==''?
              <button
                type='button'
                className='btn btn-lg btn-primary'
                data-bs-dismiss='modal'
                onClick={handleClick}
                // onClick={handleContinueClick}
              >
                Continue
                <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </button>
              :
              <button
              type='button'
              disabled
              className='btn btn-lg btn-light'
              data-bs-dismiss='modal'
            >
              Continue
              <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </button>
}
            </div>
          </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BankingProcessModal
