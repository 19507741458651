import React, { useState } from 'react'
import $ from 'jquery'

const ConstructionListWidget = (props) => {
  
  const [modalOpen,setModal]=useState(false)

  const onModalClose = () =>{
    setModal(false);
  }

  const handleClick = () => {
    props.financeOption('');
    setModal(true);
  
    // Use setTimeout to trigger the click after a slight delay
    setTimeout(() => {
      const modalId = 'kt_modal_JNM'; // Replace with your actual modal ID
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`);
      if (modalTrigger) {
        ;(modalTrigger as any).click(); // Cast to 'any' to access 'click' method
      }
    }, 1); // You can adjust the delay time as needed
  }
  
  return (
    <>
      <div id='kt_app_content' className='app-content flex-column-fluid'>
        <div className='row g-5 g-xl-10'>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Project Planning and Management</span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Coordination</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Strategically coordinating and executing tasks, including resource allocation, scheduling, and risk management, to ensure successful project completion within defined constraints
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_JNM'
                    onClick={()=>{handleClick()}}
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Budgeting and Cost Estimation</span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Forecasting</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Systematically planning and estimating financial resources required for a project, covering costs associated with materials, labor, equipment, and overhead, to facilitate efficient financial management
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Customer'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Supply Chain Management</span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Optimization</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Managing the end-to-end process of sourcing, production, and distribution, optimizing the supply chain to enhance efficiency, reduce costs, and meet customer demands
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_account'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Site Selection and Analysis
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Strategy</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                Conducting a thorough analysis to determine the most suitable location for a project, considering factors such as accessibility, infrastructure, and environmental impact
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Risk Management
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Mitigation</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Identifying, assessing, and mitigating potential risks that could impact project success, ensuring proactive measures are in place to address uncertainties
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Security'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Quality Control and Assurance
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Compliance</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Implementing processes to ensure that products or services meet established quality standards, fostering customer satisfaction and compliance with regulatory requirements
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Compliance'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Equipment and Asset Management
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Optimization</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                Optimizing the utilization, maintenance, and replacement of equipment and assets throughout the project lifecycle to maximize efficiency and reduce downtime
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Safety Management
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Security</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Prioritizing and enforcing safety measures to create a secure working environment, minimizing the risk of accidents and promoting the well-being of project stakeholders
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Security'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Labour Productivity and Resource Allocation
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'> Efficiency</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Efficiently managing human resources and project assets to enhance productivity, meet project goals, and ensure effective resource allocation
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Compliance'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Environmental Impact Assessment
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Sustainability</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                Evaluating the potential environmental effects of a project and incorporating sustainable practices to minimize ecological impact and adhere to regulatory standards
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                   Energy Efficiency and Sustainable Construction
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Green</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Integrating energy-efficient and sustainable construction practices to reduce environmental impact and contribute to long-term ecological and economic viability
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Security'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Project Performance Analysis
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Metrics</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Continuously monitoring and analyzing project performance metrics to identify areas for improvement, enhance efficiency, and ensure alignment with project objectives
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Compliance'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Subcontractor Management
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Collaboration</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                Coordinating and overseeing subcontractors to ensure they align with project objectives, timelines, and quality standards, fostering collaboration and successful project outcomes
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          

          
        </div>
      </div>
    </>
  )
}

export default ConstructionListWidget
