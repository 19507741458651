import React, { useState } from 'react'
import $ from 'jquery'

const ManufacturingListWidget = (props) => {
  
  const [modalOpen,setModal]=useState(false)

  const onModalClose = () =>{
    setModal(false);
  }

  const handleClick = () => {
    props.financeOption('');
    setModal(true);
  
    // Use setTimeout to trigger the click after a slight delay
    setTimeout(() => {
      const modalId = 'kt_modal_JNM'; // Replace with your actual modal ID
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`);
      if (modalTrigger) {
        ;(modalTrigger as any).click(); // Cast to 'any' to access 'click' method
      }
    }, 1); // You can adjust the delay time as needed
  }
  
  return (
    <>
      <div id='kt_app_content' className='app-content flex-column-fluid'>
        <div className='row g-5 g-xl-10'>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Production Planning and Scheduling</span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Optimization</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Optimizes manufacturing operations with efficient planning and scheduling, minimizing lead times and enhancing overall efficiency, ensuring timely delivery
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    // data-bs-toggle='modal'
                    // data-bs-target='#kt_modal_JNM'
                    onClick={()=>{handleClick()}}
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Inventory Management</span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Efficiency</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Balances supply and demand, reducing costs by efficiently managing stock levels. Prevents stockouts, ensuring product availability, and contributing to improved cash flow
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Customer'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>Quality Control and Assurance</span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Consistency</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Maintains product quality with systematic processes during production and preventive measures, ensuring consistency and compliance
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_account'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Supply Chain Management
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Integration</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                Efficiently manages the end-to-end flow of goods and services, enhancing collaboration with suppliers and partners, minimizing costs, and ensuring timely delivery to meet customer demands
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Equipment Maintenance and Predictive Maintenance
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Reliability</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Ensures machinery reliability and longevity by implementing proactive maintenance strategies, reducing downtime and unforeseen breakdowns through predictive maintenance techniques
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Security'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Shop Floor Control
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Real-time</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Optimizes shop floor operations by monitoring and controlling production processes in real-time, improving efficiency, and responding promptly to changing demands
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Compliance'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Workforce Management
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Productivity</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                Efficiently manages human resources, optimizing workforce productivity, ensuring proper skill alignment, and addressing staffing needs to meet production goals
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Product Lifecycle Management(PLM)
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Lifecycle</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Manages the entire lifecycle of a product, from concept to disposal. Enhances collaboration, accelerates time-to-market, and ensures product quality and compliance
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm fw-bold btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Security'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className='col-xl-4 mb-5 mb-xl-8'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Predictive Analytics for Demand Forecasting
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Forecasting</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4  '>
                <div className='fs-7 fw-normal text-muted'>
                Utilizes advanced analytics to predict future demand patterns, enabling informed decision-making, reducing stockouts, and optimizing inventory levels
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Compliance'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row g-5 g-xl-8'>
          <div className='col-xl-4 mb-5 mb-xl-10'>
            <div className='card pt-4 h-md-100 mb-6 mb-md-0'>
              <div className='card-header pt-5'>
                <h4 className='card-title d-flex align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    Cost Management and Optimization
                  </span>
                  <span className='text-gray-400 mt-1 fw-bold fs-7'>Efficiency</span>
                </h4>
              </div>
              <div className='card-body py-6 pt-4'>
                <div className='fs-7 fw-normal text-muted'>
                Systematically analyzes and optimizes costs across various aspects of operations, improving financial efficiency, and ensuring profitability
                </div>
                <div className='modal-footer flex-end pt-4'>
                  <button
                    type='button'
                    className=' btn-sm btn btn-light btn-active-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_Loan'
                  >
                    Proceed
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          

        </div>
        
        
      </div>
    </>
  )
}

export default ManufacturingListWidget
