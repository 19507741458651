import React, {useEffect, useRef, useState} from 'react'
import './uploadfile.css'
import * as XLSX from 'xlsx'
import SuccessCheck from './SuccessCheck'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'

const UploadFile: React.FC<any> = ({fileType, props}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [successMessage, setSuccessMessage] = useState<string | null>(null)
  const [tableData, setTableData] = useState<any[]>([])
  const [jsondata, setJsonData] = useState<any[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploadProgress, setUploadProgress] = useState<number | null>(null)

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
    try {
      setErrorMessage(null) // Clear any previous error messages
      setSuccessMessage(null)
      setUploadProgress(null)

      const file = e.target.files && e.target.files[0]

      if (!file) {
        setErrorMessage('No file selected.')
        return
      }

      if (fileType === 'CSV') {
        // Handle CSV file
        handleCSVFile(file)
      } else if (fileType === 'JSON') {
        // Handle JSON file
        handleJSONFile(file)
      } else {
        setErrorMessage('Unsupported file type.')
      }
    } catch (error) {
      setErrorMessage('An error occurred while uploading the file.')
    }
  }

  const handleCSVFile = async (file: File) => {
    try {
      setErrorMessage(null) // Clear any previous error messages
      setSuccessMessage(null)
      setUploadProgress(null)

      const reader = new FileReader()

      reader.onload = (event) => {
        try {
          const binaryString = event.target?.result as string
          const data = new Uint8Array(new TextEncoder().encode(binaryString))
          const workbook = XLSX.read(data, {type: 'array'})
          const sheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[sheetName]

          const expectedColumns = ['ruleid', 'rule-description', 'rulename', 'error']
          const header = XLSX.utils.sheet_to_json(worksheet, {header: 1})[0] as string[]

          if (!header || !expectedColumns.every((col, index) => header[index] === col)) {
            setErrorMessage(
              'Invalid file format. Expected columns: ruleid, rule-description, rulename, error.'
            )
            setTableData([])
            setUploadProgress(null)
            return
          }

          const excelData = XLSX.utils.sheet_to_json(worksheet, {header: 1, raw: true})
          setTableData(excelData)
          setSuccessMessage('File successfully uploaded.')
        } catch (error) {
          console.error('Error while processing the CSV file:', error)
          setErrorMessage('An error occurred while processing the CSV file.')
          setTableData([]) // Clear table data on error
        }
      }

      reader.readAsBinaryString(file)
      // Simulate a 5-second delay during file upload
      setUploadProgress(0)
      const totalSteps = 10
      for (let step = 1; step <= totalSteps; step++) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        const progress = (step / totalSteps) * 100
        setUploadProgress(progress)
      }
      setUploadProgress(null) // Clear progress after upload
    } catch (error) {
      setErrorMessage('An error occurred while uploading the CSV file.')
    }
  }

  const handleJSONFile = async (file: File) => {
    try {
      setErrorMessage(null) // Clear any previous error messages
      setSuccessMessage(null)
      setUploadProgress(null)

      const reader = new FileReader()

      reader.onload = (event) => {
        try {
          const jsonString = event.target?.result as string
          const jsonData = JSON.parse(jsonString)

          // Ensure that jsonData is in the expected format
          if (
            !Array.isArray(jsonData) ||
            jsonData.length === 0 ||
            typeof jsonData[0] !== 'object'
          ) {
            setErrorMessage('Invalid JSON format.')
            setTableData([])
            setUploadProgress(null)
            return
          }

          // Define the expected columns in your JSON object
          const expectedColumns = ['ruleid', 'rule-description', 'rulename', 'error']
          // Check if the JSON object has the expected columns
          const header = Object.keys(jsonData[0])
          if (!expectedColumns.every((col) => header.includes(col))) {
            setErrorMessage(
              'Invalid JSON format. Expected columns: ruleid, rule-description, rulename, error.'
            )
            setTableData([])
            setUploadProgress(null)
            return
          }

          // Process and set the JSON data
          setTableData(jsonData)
          console.log('T', tableData, jsonData)
          setSuccessMessage('File successfully uploaded.')
        } catch (error) {
          console.error('Error while processing the JSON file:', error)
          setErrorMessage('An error occurred while processing the JSON file.')
          setTableData([]) // Clear table data on error
        }
      }

      reader.readAsText(file)
      // Simulate a 5-second delay during file upload
      setUploadProgress(0)
      const totalSteps = 10
      for (let step = 1; step <= totalSteps; step++) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        const progress = (step / totalSteps) * 100
        setUploadProgress(progress)
      }
      setUploadProgress(null) // Clear progress after upload
    } catch (error) {
      setErrorMessage('An error occurred while uploading the JSON file.')
    }
  }

  const handleButtonClick = () => {
    setTableData([])
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  useEffect(() => {
    setErrorMessage(null)
    setSuccessMessage(null)
    setUploadProgress(null)
    setTableData([])
  }, [props.uploadExcel])
  console.log("PROPS",props.uploadExcel)

  const navigate = useNavigate()
  useEffect(() => {
    if (successMessage && uploadProgress === null) {
      Swal.fire({
        text: 'Connected Successfully !',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(function () {
        navigate('/process/bpms/finance/remittance/profiling')
      })
    }
  }, [successMessage])



  return (
    <div>
      <div className='upload-container'>
        {
          //       successMessage && uploadProgress === null ? (
          //         <div className='success-container'>
          //           <SuccessCheck />
          //           <div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          //             {successMessage}
          //           </div>
          //           {fileType==="CSV"&&
          //           <button className='btn btn-success' onClick={() => setSuccessMessage(null)}>
          //             {'Preview'}
          //           </button>
          // }
          //         </div>
          //       ) :
          errorMessage ? (
            <div className='success-container'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52' className='checkmark'>
                <circle
                  cx='26'
                  cy='26'
                  r='25'
                  fill='none'
                  className='checkmark__circle checkmark__cross'
                ></circle>
                <path
                  fill='none'
                  d='M 12,12 L 40,40 M 40,12 L 12,40'
                  className='checkmark__check'
                ></path>
              </svg>
              <div style={{paddingTop: '20px', paddingBottom: '20px'}}>{errorMessage}</div>
            </div>
          ) : uploadProgress && errorMessage === null ? (
            <div
              style={{
                width: '100%',
                background: '#EEF1F7',
                borderRadius: '26px',
                display: 'flex',
                flexDirection: 'column',
                padding: '20px',
                marginTop: '25px',
              }}
            >
              <div style={{display: 'flex', alignItems: 'left'}}>
                <span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='68'
                    height='87'
                    viewBox='0 0 68 87'
                    fill='none'
                  >
                    <path
                      d='M56 84.7461H12C6.47715 84.7461 2 80.2689 2 74.7461V12.7461C2 7.22325 6.47715 2.74609 12 2.74609H38.6716L66 30.0745V74.7461C66 80.2689 61.5228 84.7461 56 84.7461Z'
                      fill='#EEF1F7'
                      stroke='#CBD0DC'
                      stroke-width='4'
                    />
                  </svg>
                </span>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingLeft: '15px',
                    width: '100%',
                  }}
                >
                  {uploadProgress}%
                  <div
                    style={{
                      background: '#CBD0DC',
                      height: '13px',
                      borderRadius: '12px',
                      width: '100%',
                    }}
                  >
                    <div
                      style={{
                        background: '#3D96FF',
                        height: '13px',
                        width: `${uploadProgress}%`,
                        borderTopLeftRadius: '12px',
                        borderBottomLeftRadius: '12px',
                      }}
                    ></div>
                  </div>
                  <div style={{paddingTop: '15px'}}>Uploading File</div>
                </span>
              </div>
            </div>
          ) : tableData.length > 0 && uploadProgress === null ? (
            <div style={{width: '100%'}}>
              <h3>Uploaded File</h3>
              <table className='table align-middle table-row-dashed fs-6 gy-5'>
                <thead>
                  <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
                    {tableData[0].map((column: string, index: number) => (
                      <th key={index} className='min-w-125px'>
                        {column}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableData.slice(1).map((row: any[], rowIndex: number) => (
                    <tr key={rowIndex}>
                      {row.map((cell: any, cellIndex: number) => (
                        <td key={cellIndex} className='align-items-center'>
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              style={{
                width: '100%',
                borderRadius: '26px',
                border: '4px dashed rgba(61, 150, 255, 0.25)',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='46'
                  height='46'
                  viewBox='0 0 46 46'
                  fill='none'
                >
                  <path
                    d='M14.2988 37.0684H10.6188C4.98376 36.6659 2.45386 32.3342 2.45386 28.4817C2.45386 24.6292 4.98378 20.2784 10.5229 19.8951C11.3088 19.8184 11.9988 20.4317 12.0563 21.2367C12.1138 22.0226 11.5197 22.7126 10.7147 22.7701C6.99635 23.0384 5.32886 25.8367 5.32886 28.5009C5.32886 31.165 6.99635 33.9634 10.7147 34.2317H14.2988C15.0847 34.2317 15.7363 34.8834 15.7363 35.6692C15.7363 36.455 15.0847 37.0684 14.2988 37.0684Z'
                    fill='#292D32'
                  />
                  <path
                    d='M31.9506 37.0683C31.9123 37.0683 31.8932 37.0683 31.8548 37.0683C31.069 37.0683 30.3407 36.4167 30.3407 35.6308C30.3407 34.8067 30.954 34.1933 31.759 34.1933C34.1165 34.1933 36.2249 33.3692 37.8732 31.8933C40.8632 29.2867 41.0548 25.53 40.2498 22.885C39.4448 20.2592 37.2023 17.25 33.2923 16.7708C32.6598 16.6942 32.1614 16.215 32.0464 15.5825C31.2798 10.9825 28.8073 7.80083 25.0507 6.65083C21.179 5.44333 16.6556 6.63167 13.8381 9.58333C11.0973 12.4392 10.4648 16.445 12.0556 20.8533C12.324 21.6008 11.9407 22.425 11.1932 22.6933C10.4457 22.9617 9.62151 22.5783 9.35318 21.8308C7.41734 16.4258 8.29903 11.2508 11.7682 7.60917C15.314 3.89083 21.0065 2.41499 25.894 3.90999C30.379 5.28999 33.5415 8.98916 34.6915 14.1258C38.6015 15.0075 41.7448 17.9783 42.9907 22.08C44.3515 26.5458 43.1248 31.1458 39.7707 34.0592C37.6432 35.9758 34.864 37.0683 31.9506 37.0683Z'
                    fill='#292D32'
                  />
                  <path
                    d='M23 42.7034C19.1475 42.7034 15.5442 40.6526 13.57 37.3367C13.3592 37.0109 13.1484 36.6276 12.9759 36.2059C12.3242 34.8451 11.9792 33.2926 11.9792 31.6826C11.9792 25.6067 16.9242 20.6617 23 20.6617C29.0758 20.6617 34.0208 25.6067 34.0208 31.6826C34.0208 33.3117 33.6759 34.8451 32.9859 36.2634C32.8325 36.6276 32.6217 37.0109 32.3917 37.3751C30.4559 40.6526 26.8525 42.7034 23 42.7034ZM23 23.5367C18.515 23.5367 14.8542 27.1976 14.8542 31.6826C14.8542 32.8709 15.1033 33.9826 15.5825 34.9984C15.7358 35.3242 15.87 35.5926 16.0233 35.8417C17.48 38.3142 20.1441 39.8284 22.9808 39.8284C25.8175 39.8284 28.4816 38.3142 29.9191 35.8801C30.0916 35.5926 30.2451 35.3242 30.3601 35.0559C30.8776 34.0017 31.1266 32.8901 31.1266 31.7017C31.1458 27.1976 27.485 23.5367 23 23.5367Z'
                    fill='#292D32'
                  />
                  <path
                    d='M21.9073 35.0174C21.5431 35.0174 21.179 34.8833 20.8915 34.5958L18.9939 32.6983C18.4381 32.1424 18.4381 31.2224 18.9939 30.6666C19.5498 30.1108 20.4698 30.1108 21.0256 30.6666L21.9457 31.5866L25.0123 28.7499C25.6065 28.2133 26.5073 28.2516 27.044 28.8266C27.5806 29.4016 27.5423 30.3216 26.9673 30.8583L22.8848 34.6341C22.5973 34.8833 22.2523 35.0174 21.9073 35.0174Z'
                    fill='#292D32'
                  />
                </svg>
              </div>
              <div style={{color: '#292D32', fontSize: '20px', paddingTop: '12px'}}>
                <b>{`Choose ${fileType === 'CSV' ? 'an Excel' : 'JSON'} file to upload`}</b>
              </div>
              <div
                style={{
                  color: '#A9ACB4',
                  fontSize: '16px',
                  paddingTop: '12px',
                  paddingBottom: '20px',
                }}
              >
                {` Supported formats:${fileType === 'CSV' ? 'XLS, XLSX, CSV.' : 'JSON'}`}
              </div>
              <input
                type='file'
                accept='.xlsx, .csv, .json'
                onChange={(e) => handleFileUpload(e, fileType)} // Set the file type to 'csv' here
                ref={fileInputRef} // Associate the ref with the file input
                style={{display: 'none'}} // Hide the file input
              />
              <button className='btn btn-primary' onClick={handleButtonClick}>
                {'Browse Files'}
              </button>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default UploadFile
