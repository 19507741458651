/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import { PageLink, PageTitle } from '../../../../../../_metronic/layout/core'
import DataEnrichmentModal from './DataEnrichmentModal'
import { SCHEMA } from '../../../../../pages/data/trad/brms/Config'
import { useNavigate } from 'react-router-dom'

interface Rule {
  Id: string
  rule: string
  desc: string
}

const DataEnrichmentComponent: React.FC<any>= (props) => {
  const [modalOpen,setModal]=useState(false)

  const navigate = useNavigate();

  const onModalClose = () =>{
    setModal(false);
  }

  const handleClick=()=>{
    const modalId = 'kt_modal_DE_Loader' // Replace with your actual modal ID
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    if (modalTrigger) {
      ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
    }
    let apiData = {
      db: SCHEMA,
      table: props.selectedTable,
      data: props.selectedRulesHistory.map((item) => ({
        FieldName: item.column,
        selected_rules: item.rules.map((rule: Rule, index: number) => ({
          Id: item.ruleIds[index],
          rule: rule,
        })),
      })),
    }
    props.applyDataEnrichment('',apiData)
    // props.getDataEnrichment('',apiData)
  }

  const Data_Enrichment_Breadcrumbs: Array<PageLink> = [
    {
      title: 'Finance - Banking Business Process',
      path: '/process/finance/banking',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
    <PageTitle breadcrumbs={Data_Enrichment_Breadcrumbs}>Data Enrichment</PageTitle>
      <div className='card'>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Data Profiling Statistics Report</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>50 Business Rules Applied</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Data Fields</th>
                  <th className='min-w-140px'>Total Records</th>
                  <th className='min-w-140px'>Unique Records</th>
                  <th className='min-w-140px'>Null Records</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {/* 1st records mapping start */}
                {props.postDataSuccess&&props.postResponse&&props.postResponse.length&&
                  props.postResponse.map((data,index)=>
                  <>
                <tr>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div
                        className='symbol symbol-35px me-4 cursor-pointer'
                        data-bs-toggle='collapse'
                        data-bs-target={`#dp_data_${index}`}
                        aria-expanded='false'
                        aria-controls={`dp_data_${index}`}
                      >
                        <span className='symbol-label bg-light-primary '>
                          {' '}
                          <i className='ki-duotone ki-double-down fs-1 text-primary'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        </span>
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {data.fieldName?data.fieldName:''}
                        </span>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {`${data.selected_rules&&data.selected_rules.length} Business rules applied`}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex justify-content-start flex-column'>
                      <span className='text-dark fw-bold text-muted text-hover-primary fs-6'>
                        {data.totalNoOfRows?Number(data.totalNoOfRows).toLocaleString():''}
                      </span>
                    </div>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                      <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                        <span className='fw-bold fs-7 '>
                          {data.totalNoOfUniqueRows?Number( data.totalNoOfUniqueRows).toLocaleString():''}
                        </span>
                        <span className='fw-bolder fs-6 text-gray-400'>
                          {data.uniquePercentage?data.uniquePercentage:'0%'}
                        </span>
                      </div>
                      <div className='h-5px mx-3 w-100 bg-light mb-3'>
                        <div
                          className='bg-success rounded h-5px'
                          role='progressbar'
                          style={{width: `${data.uniquePercentage?data.uniquePercentage:'0%'}`}}
                        ></div>
                      </div>
                    </div>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                      <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                        <span className='fw-bold fs-7'>
                          {data.totalNoOfNullRows?Number(data.totalNoOfNullRows).toLocaleString():''}
                        </span>
                        <span className='fw-bolder fs-6 text-gray-400'>
                          {data.nullPercentage?data.nullPercentage:'0%'}
                        </span>
                      </div>
                      <div className='h-5px mx-3 w-100 bg-light mb-3'>
                        <div
                          className='bg-danger rounded h-5px'
                          role='progressbar'
                          style={{width: `${data.nullPercentage?data.nullPercentage:'0%'}`}}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
                     <tr id={`dp_data_${index}`} className='collapse show'>
                     <td colSpan={4}>
                       <div >
                         <div className='row pb-1'>
                          {data.selected_rules&&data.selected_rules.length&&data.selected_rules.map((rule=>
                           <div className='col'>
                             <div className='d-flex mb-1'>
                               <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                                 <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                                   <span className='fw-bold fs-7 text-gray-400'>
                                     {rule.desc?rule.desc:''}
                                   </span>
                                   <span className='fw-bolder fs-6 text-danger'>
                                    {rule.error?rule.error:'0%'}
                                   </span>
                                 </div>
                                 <div className='h-5px mx-3 w-100 bg-light mb-3'>
                                   <div
                                     className='bg-primary rounded h-5px'
                                     role='progressbar'
                                     style={{width: `${rule.error?rule.error:'0%'}`}}
                                   ></div>
                                 </div>
                               </div>
                             </div>
                           </div>
                           ))}
                         </div>
                        
                       </div>
                     </td>
                   </tr>
                   </>
                )}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
      <div className='d-flex flex-stack pt-5'>
      <div style={{marginRight: 'auto'}}>
      <button
        type='button'
        className='btn btn-flex btn-light-primary my-6 me-3'
        id=''
        onClick={() => navigate(-1)}
      >
        <i className='ki-duotone ki-exit-left fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>{' '}
        Back
      </button>
            </div>
            <div style={{marginLeft: 'auto'}}>
              <button
                type='button'
                className='btn btn-sm btn-primary'
                  onClick={()=>{setModal(true);handleClick();}}
              >
                Apply Data Enrichment Rules
                <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </button>
            </div>
          </div>
          <DataEnrichmentModal {...props}/>
    </>
  )
}

export default DataEnrichmentComponent
