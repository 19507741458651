import React from 'react';
import InBoundTransaction from '../../../../../_metronic/assets/myimages/InBoundTransaction.png';

const InBoundTransactions = () => {
  return (
    <>
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>In Bound Transactions</span>
        </h3>
      </div>
      <div className='card-body' style={{ height: '500px', overflowY: 'auto' }}>
        <img src={InBoundTransaction} alt="customerchum" style={{ maxWidth: '100%' }} />
      </div>
    </div>
    </>
  );
};

export default InBoundTransactions;
