import React, {useEffect, useState} from 'react'
import Loader from '../../../pages/data/trad/brms/Loader'
import {SCHEMA} from '../../../pages/data/trad/brms/Config'
import AddNewCustomRule from './AddNewCustomRule'
import UploadNewRule from '../../../process/finance/banking/remittance/UploadNewRule'

interface ColumnEnabled {
  [key: string]: boolean
}

interface SetDBSubSectionParams {
  column: string;
  section: string;
}

interface FieldsColumnProps {
  setDBSubSection: (params: SetDBSubSectionParams) => void;
  setSelectedTable: (table: string) => void
  isFetchTablesFailed: boolean
  fetchingTables: boolean
  fetchTableColumns: (params: {db: string; table: string}) => void
  fetchTables: (params: {db: string}) => void
  dynamicFields?: string[]
  selectedTable?: string | any
  tableColumns?: {[key: string]: string[]}
  selectedColumn?: string
  removeSelectedRules: (selectedColumn: string) => void
  uploadExcelProps: () => void
  columnEnabled?: ColumnEnabled[]
  setSelectedColumn: (params: {column: string}) => void
  fetchColumnRules: (params: {db: string; table: string; column: string}) => void
  fetchingColumns: boolean
  isFetchColumnsFailed: boolean
  selectedRulesHistory: {
    column: string
    ruleIds: string[]
    rules: string[]
  }[]
}

interface TableCollapseState {
  [key: string]: boolean
}

const BusinessRuleOptions = [
  {
    id: 1,
    name: 'Banking',
    options: [
      // 'Transaction Fraud Detection',
      // 'Compliance Monitoring',
      // 'Customer Segmentation for Marketing',
      // 'Customer Churn Prediction',
      // 'Cross-Sell and Upsell Opportunities',
      // 'Remittance Forecasting',
      // 'Operational Efficiency Enhancement',
      // 'Customer Experience Personalization',
      'Profitability and Compliance Optimization',
      'Anti-Money Laundering (AML) Compliance Check',
      'Transaction Amount Limit',
      'Currency Exchange Rate Optimization',
      'Dynamic Fee Adjustments',
      'Transaction Review Based on Customer Behavior',
      'Customer Risk Profiling',
      'Transaction Profitability Threshold',
    ],
  },
]

const DatabaseStructure: React.FC<FieldsColumnProps> = (props) => {
  const [selectedcolumn, setSelectedcolumn] = useState<string | null>(null)
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [tableCollapseState, setTableCollapseState] = useState<TableCollapseState>({})
  const [modalopen, setModalOpen] = useState<boolean>(false)
  const [modalclicked, setModalClicked] = useState<boolean>(false)
  const [option, setOption] = useState<string>('')
  const [selectedColumn, setSelectedColumn] = useState('')
  
  const handleRadioChange = (column) => {
    setSelectedColumn(column)
  //  props.setSelectedColumn({ column: column })
    props.setDBSubSection({ column: column, section: "BusinessRuleSection" });
  }

  const handleTableClick = (table: string) => {
    props.setSelectedTable(table)
    props.fetchTableColumns({db: SCHEMA, table})
    setSelectedcolumn(null)
    props.setDBSubSection({ column: '', section: '' }); // Empty string parameters
    setTableCollapseState((prevState) => ({
      ...prevState,
      [table]: !prevState[table],
    }))
  }

  const toggleColumn = (column: string) => {
    props.setDBSubSection({ column: '', section: '' }); // Empty string parameters

    if (selectedcolumn === column) {
      props.removeSelectedRules(selectedcolumn)
      setSelectedcolumn('')
      
    } else setSelectedcolumn(column)
    if (
      props.selectedRulesHistory.length > 0 &&
      props.selectedRulesHistory.findIndex((entry) => entry.column === column) !== -1
    ) {
      props.removeSelectedRules(column)
      props.setSelectedColumn({column: ''})
    } else {
      props.setSelectedColumn({column})
      props.fetchColumnRules({
        db: SCHEMA,
        table: props.selectedTable!,
        column,
      })
    }
  }

  useEffect(() => {
    if (props.selectedColumn) setSelectedcolumn(props.selectedColumn)
  }, [props.selectedColumn])

  useEffect(() => {
    if (props.selectedRulesHistory.length > 0) {
      setIsCollapsed(false)
    }
  }, [props.selectedRulesHistory])

  useEffect(() => {
    if (modalopen) {
      props.uploadExcelProps()
      const modalId = `addnewrule_modal`
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
      if (modalTrigger) {
        ;(modalTrigger as any).click()
      }
    }
  }, [modalopen, option, modalclicked])

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    if (props.selectedTable) {
      setIsCollapsed(false)
    } else setIsCollapsed(true)
  })
  console.log('COLLAPSED', isCollapsed)

  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          {props.isFetchTablesFailed && <div style={{color: 'red'}}>Host not available</div>}
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>DatanXzen Rule Engine</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              DatanXzen is the respository of business proccess
            </span>
          </h3>
        </div>
        <div className='separator border-primary my-4 mt-2'></div>

        <div className='card-body py-3' style={{height: '425px', overflowY: 'auto'}}>
          <div className='m-0 mb-2'>
            <div
              className={`d-flex align-items-center collapsible toggle mb-2 ${
                isCollapsed ? 'collapsed' : 'show'
              }`}
              data-bs-toggle='collapse'
              data-bs-target='#kt_job_10_20'
              onClick={() => {
                props.fetchTables({ db: SCHEMA });
                props.setDBSubSection({ column: '', section: '' }); // Empty string parameters
              }}
            
            >
              <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              </div>
              <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>Data Rules</h4>
            </div>

            <div id='kt_job_10_20' className={`collapse fs-6 ${isCollapsed ? '' : 'show'}`}>
              <div className='ms-10'>
                {props.fetchingTables ? (
                  <Loader />
                ) : (
                  (props.dynamicFields ?? []).length > 0 &&
                  (props.dynamicFields ?? []).map((table, index) => (
                    <>
                      <div
                        className={`d-flex align-items-center collapsible py-0 toggle mb-2 ${
                          isCollapsed ? 'collapsed' : 'show'
                        }`}
                        data-bs-toggle='collapse'
                        data-bs-target={`#kt_job_${index}_200`}
                        onClick={() => handleTableClick(table)}
                      >
                        <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                          <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                          <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                            <span className='path3'></span>
                          </i>
                        </div>

                        <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>{table}</h4>
                      </div>

                      <div
                        id={`kt_job_${index}_200`}
                        className={`${isCollapsed ? '' : 'show'} fs-6 ms-1`}
                      >
                        <div className='mb-1'>
                          <div className='ms-12'>
                            {props.fetchingColumns ? (
                              <Loader />
                            ) : (
                              table === props.selectedTable &&
                              props?.tableColumns?.[props.selectedTable]?.map((column) => (
                                <div className='row' key={column}>
                                  <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      value={column}
                                      checked={
                                        props.selectedColumn === column ||
                                        props.selectedRulesHistory.some(
                                          (obj) => obj.column === column && obj.ruleIds.length > 0
                                        )
                                      }
                                      disabled={
                                        !!props.columnEnabled &&
                                        props.columnEnabled.some((entry) => entry[column])
                                      }
                                      onChange={() => {
                                        toggleColumn(column)
                                      }}
                                    />
                                    <label className='form-check-label'>{column}</label>
                                  </div>

                                  <br></br>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                )}
              </div>
            </div>
            <div className='separator separator-dashed'></div>
          </div>
          <div className='m-0 mb-2'>
            <div
              className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
              data-bs-toggle='collapse'
              data-bs-target='#kt_job_41'
            >
              <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              </div>
              <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>Business Rules</h4>
            </div>

            {/* <div id='kt_job_41' className='collapse fs-6 ms-1'>
              <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                <div className='m-0 mb-2'>
                  {BusinessRuleOptions.map((field) => (
                    <div className='m-0 mb-2' key={field.id}>                
                      <div id={`kt_job_${field.id}`} className={` fs-6`}>
                        <div className='mb-1'>
                          {field.options.map((column) => (
                            <div className='row' key={column}>
                              <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  value={column}
                                   checked={selectedColumn.includes(column)}
                                   onChange={() => handleCheckboxChange(column)}
                                />
                                <label className='form-check-label'>{column}</label>
                              </div>
                              <br></br>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div> */}
            <div id='kt_job_41' className='collapse fs-6 ms-1'>
              <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                <div className='m-0 mb-2'>
                  {BusinessRuleOptions.map((field) => (
                    <div className='m-0 mb-2' key={field.id}>
                      <div id={`kt_job_${field.id}`} className={` fs-6`}>
                        <div className='mb-1'>
                          {field.options.map((column) => (
                            <div className='row' key={column}>
                              <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                                <input
                                  className='form-check-input'
                                  type='radio'
                                  name={field.name} // Grouping radio buttons by name
                                  value={column}
                                  checked={selectedColumn === column}
                                  onChange={() => handleRadioChange(column)} // Handle radio button change
                                />
                                <label className='form-check-label'>{column}</label>
                              </div>
                              <br></br>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
          </div>
          <div className='m-0 mb-2'>
            <div
              className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
              data-bs-toggle='collapse'
              data-bs-target='#kt_job_42'
            >
              <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              </div>
              <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>Custom Rules</h4>
            </div>
            <div id='kt_job_42' className='collapse fs-6 ms-1'>
              <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                <span
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setOption('form')
                    setModalOpen(true)
                    setModalClicked(!modalclicked)
                  }}
                >
                  <li className='d-flex align-items-center py-2'>
                    <span className='bullet bullet-vertical bg-primary me-5'></span>
                    Add New Custom Rules
                  </li>
                </span>
              </div>
              <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                <span
                  style={{cursor: 'pointer'}}
                  onClick={() => {
                    setOption('file')
                    setModalOpen(true)
                    setModalClicked(!modalclicked)
                    props.uploadExcelProps()
                  }}
                >
                  <li className='d-flex align-items-center py-2'>
                    <span className='bullet bullet-vertical bg-primary me-5 text-hover-primary'></span>
                    Upload New Custom Rules
                  </li>
                </span>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
          </div>
        </div>
      </div>
      {/* {modalopen&& */}
      <>
        <span data-bs-toggle='modal' data-bs-target={`#addnewrule_modal`}></span>
        <div className='modal fade ' tabIndex={-1} id={`addnewrule_modal`}>
          <div
            className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered mw-650px'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='modal-content' style={{width: '100%'}}>
              <div className='modal-header py-3'>
                <h5 className='modal-title'>
                  {option === 'form' ? 'Add New Rules' : 'Upload New Custom Rules'}
                </h5>
                <div
                  className='btn btn-icon btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={closeModal}
                >
                  <i className='ki-duotone ki-cross fs-2x'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </div>
              </div>
              <div className='modal-body scroll-y mx-5 mx-xl-15 my-3'>
                {option === 'form' ? (
                  <AddNewCustomRule />
                ) : (
                  <UploadNewRule fileType={'CSV'} props={props} />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  )
}

export default DatabaseStructure
