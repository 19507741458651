export const SET_DYNAMIC_FIELDS = 'SET_DYNAMIC_FIELDS';
export const SET_SELECTED_TABLE = 'SET_SELECTED_TABLE';
export const SET_SELECTED_COLUMN = 'SET_SELECTED_COLUMN';
export const SET_TABLE_COLUMNS = 'SET_TABLE_COLUMNS';
export const SET_COLUMN_RULES = 'SET_COLUMN_RULES';
export const TOGGLE_COLUMN_ENABLED = 'TOGGLE_COLUMN_ENABLED';
export const SET_SELECTED_RULES = 'SET_SELECTED_RULES';
export const APPEND_SELECTED_RULES = 'APPEND_SELECTED_RULES';
export const REMOVE_SELECTED_RULES = 'REMOVE_SELECTED_RULES';
export const POST_DATA_WAITING = 'POST_DATA_WAITING';
export const POST_DATA_SUCCESS = 'POST_DATA_SUCCESS';
export const POST_DATA_FAILED = 'POST_DATA_FAILED';
export const CLEAR_PROPS = 'CLEAR_PROPS';
export const GRAPH_DATA_LOADING = 'GRAPH_DATA_LOADING';
export const GRAPH_DATA_SUCCESS = 'GRAPH_DATA_SUCCESS';
export const GRAPH_DATA_FAILED = 'GRAPH_DATA_FAILED';
export const ERROR_DATA_LOADING = 'ERROR_DATA_LOADING';
export const ERROR_DATA_SUCCESS = 'ERROR_DATA_SUCCESS';
export const ERROR_DATA_FAILED = 'ERROR_DATA_FAILED';
export const FETCHING_TABLES = 'FETCHING_TABLES';
export const FETCH_TABLES_FAILED = 'FETCH_TABLES_FAILED';
export const FETCHING_COLUMNS = "FETCHING_COLUMNS";
export const FETCHING_COLUMNS_FAILED = "FETCHING_COLUMNS_FAILED";
export const FETCHING_RULES="FETCHING_RULES";
export const FETCHING_RULES_FAILED="FETCHING_RULES_FAILED";
export const CLEAR_POPUP_PROPS="CLEAR_POPUP_PROPS";
export const RESET_STATE = "RESET_STATE";
export const FINANCE_SOLUTION_OPTION = "FINANCE_SOLUTION_OPTION";
export const APPLYING_DATA_ENRICHMENT = "APPLYING_DATA_ENRICHMENT";
export const APPLIED_DATA_ENRICHMENT = "APPLIED_DATA_ENRICHMENT";
export const FAILED_DATA_ENRICHMENT = "FAILED_DATA_ENRICHMENT";
export const DATA_PROFILING_COMPLETED = "DATA_PROFILING_COMPLETED";
export const SELECTED_ENRICHMENT_FIELD = "SELECTED_ENRICHMENT_FIELD";
export const SET_ENRICHMENT_SELECTED_RULES = "SET_ENRICHMENT_SELECTED_RULES";
export const FETCHING_BUSINESS_RULES ="FETCHING_BUSINESS_RULES";
export const FETCH_BUSINESS_RULES_SUCCESS ="FETCH_BUSINESS_RULES_SUCCESS";
export const FETCH_BUSINESS_RULES_FAILED ="FETCHING_BUSINESS_RULES_FAILED";
export const ENRICHMENT_MODAL_DATA_LOADING = "ENRICHMENT_MODAL_DATA_LOADING";
export const ENRICHMENT_MODAL_DATA_SUCCESS = "ENRICHMENT_MODAL_DATA_SUCCESS";
export const ENRICHMENT_MODAL_DATA_FAILED = "ENRICHMENT_MODAL_DATA_FAILED";
export const FETCHING_ENRICHED_DATA ="FETCHING_ENRICHED_DATA";
export const FETCHING_ENRICHED_DATA_SUCCESS ="FETCHING_ENRICHED_DATA_SUCCESS";
export const FETCHING_ENRICHED_DATA_FAILED ="FETCHING_ENRICHED_DATA_FAILED";
export const DATABASE_STRUC_SELECTED_SECTION ="DATABASE_STRUC_SELECTED_SECTION";
