import React, { useEffect, useState } from 'react';
import './Dataloader.css';
import SuccessCheck from '../../../../../pages/data/trad/brms/SuccessCheck';
import { useNavigate } from 'react-router-dom';
import { SCHEMA } from '../../../../../pages/data/trad/brms/Config';

const DataLoader: React.FC<any> = (props) => {
  const [progress, setProgress] = useState(0);
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);
  const [showLoader,setShowLoader]=useState(true);

  const loadingTextsEnrichment = [
    'Augmenting your data with additional insights...',
    'Preparing to enrich your dataset with valuable information...',
    'Loading data for comprehensive enrichment...',
    'Initiating the enhancement process for your data...',
    'Enriching your dataset with relevant details...',
    'Infusing additional intelligence into your data...',
    'Your data is undergoing enrichment for a deeper understanding...',
    'Integrating supplementary information to enhance your dataset...',
    'Elevating your data with enriched attributes...',
    'Enabling your data with added context and richness...',
    'Embarking on the journey of data enhancement...',
    'Injecting valuable details into your dataset...',
    'Enhancing the completeness of your data...',
    'Loading additional insights to fortify your dataset...',
    'Your data is being enriched for more informed analysis...',
    'Infusing context to empower your dataset...',
    'Bolstering your data with enhanced features...',
    'Integrating valuable elements to amplify your dataset...',
    'Preparing to enrich your data for deeper understanding...',
    'Your dataset is undergoing a transformation with enriched information...',
    'Elevating the depth and quality of your data...',
    'Loading supplemental details for a more comprehensive view...',
    'Infusing intelligence into your dataset for advanced analytics...',
    'Enriching your data to unlock hidden potential...',
    'Enhancing your dataset with contextual richness...',
  ];

  const navigate= useNavigate();
  const handleClick = () => {
   
    // props.getDataEnrichment('', apiData)
    navigate('/process/bpms/finance/remittance/enrichment/report')
  }


  useEffect(() => {
    setShowLoader(true);
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress + 10) % 110);
      setLoadingTextIndex((prevIndex) => (prevIndex + 1) % loadingTextsEnrichment.length);
    }, 400);

    const timeout = setTimeout(() => {
      setShowLoader(false);
      clearInterval(interval); // Clear the interval immediately
    }, 10000); // 10 seconds

    return () => {
      clearTimeout(timeout); // Clear the timeout when unmounting
    };
  }, [props.ismodalClicked]);
  const renderMessage = () => {
    if (props.enrichmentDataFailed || props.FailedDataEnrichment) {
      return 'Something went wrong';
    } else {
      return showLoader ? loadingTextsEnrichment[loadingTextIndex] : 'Data Enrichment Completed';
    }
  };

  useEffect(() => {
    setShowLoader(true); // Hide the loader when conditions are met
    setProgress(0);
    setLoadingTextIndex(0);
}, [props.ismodalClicked]);

  return (
    <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',textAlign:'center'}}>
      {showLoader?
         <svg
         className='loader-svg'
      version="1.1"
      id="L6"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
    >
      <rect fill="none" stroke="#3e97ff" strokeWidth="4" x="25" y="25" width="50" height="50">
        <animateTransform
          attributeName="transform"
          dur="0.5s"
          from="0 50 50"
          to="180 50 50"
          type="rotate"
          id="strokeBox"
          attributeType="XML"
          begin="rectBox.end"
        />
      </rect>
      <rect x="27" y="27" fill="#3e97ff" width="46" height="50">
        <animate
          attributeName="height"
          dur="1.3s"
          attributeType="XML"
          from="50"
          to="0"
          id="rectBox"
          fill="freeze"
          begin="0s;strokeBox.end"
        />
      </rect>
    </svg>
    :
    <SuccessCheck/>
      }
    <div className="percent">{renderMessage()}</div>
    {showLoader?null: 
    <div className='d-flex flex-stack pt-10'>
    <div style={{marginLeft: 'auto'}}>
          <button
            type='button'
            className='btn btn-sm btn-primary'
            data-bs-dismiss='modal'
            onClick={handleClick}
          >
            Enrichment Report
            <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </button>

    </div>
  </div>}
    </div>
  );
};

export default DataLoader
