// AppReducer.tsx

import {ENRICHMENT_DATA_SUCCESS, ENRICHMENT_DATA_WAITING} from '../../../brms/Config'
import {
  SET_DYNAMIC_FIELDS,
  SET_SELECTED_TABLE,
  SET_TABLE_COLUMNS,
  SET_COLUMN_RULES,
  SET_SELECTED_COLUMN,
  TOGGLE_COLUMN_ENABLED,
  APPEND_SELECTED_RULES,
  SET_SELECTED_RULES,
  POST_DATA_WAITING,
  POST_DATA_SUCCESS,
  POST_DATA_FAILED,
  CLEAR_PROPS,
  REMOVE_SELECTED_RULES,
  GRAPH_DATA_SUCCESS,
  GRAPH_DATA_LOADING,
  GRAPH_DATA_FAILED,
  FETCH_TABLES_FAILED,
  FETCHING_COLUMNS,
  FETCHING_COLUMNS_FAILED,
  FETCHING_RULES,
  FETCHING_RULES_FAILED,
  ERROR_DATA_SUCCESS,
  ERROR_DATA_FAILED,
  ERROR_DATA_LOADING,
  CLEAR_POPUP_PROPS,
  RESET_STATE,
  FINANCE_SOLUTION_OPTION,
  APPLYING_DATA_ENRICHMENT,
  APPLIED_DATA_ENRICHMENT,
  FAILED_DATA_ENRICHMENT,
  DATA_PROFILING_COMPLETED,
  SELECTED_ENRICHMENT_FIELD,
  SET_ENRICHMENT_SELECTED_RULES,
  FETCHING_BUSINESS_RULES,
  FETCH_BUSINESS_RULES_SUCCESS,
  FETCH_BUSINESS_RULES_FAILED,
  ENRICHMENT_MODAL_DATA_LOADING,
  ENRICHMENT_MODAL_DATA_SUCCESS,
  ENRICHMENT_MODAL_DATA_FAILED,
  FETCHING_ENRICHED_DATA,
  FETCHING_ENRICHED_DATA_SUCCESS,
  FETCHING_ENRICHED_DATA_FAILED,
} from '../Actions/actionTypes'

export interface AppState {
  dbStructureSelectedSection: any
  selectedOption: any
  dynamicFields: any[]
  selectedTable: string | null
  tableColumns: Record<string, any>
  columnRules: any
  columnEnabled: Array<Record<string, boolean>>
  selectedRules: any[]
  selectedRulesHistory: any[]
  selectedColumn: any
  postingData?: boolean
  postDataSuccess?: boolean
  postDataFailed?: boolean
  postResponse?: any
  enrichmentDataWaiting?: boolean
  enrichmentDataSuccess?: boolean
  enrichmentDataFailed?: boolean
  DataEnrichment?: any
  postDataErrorMessage?: string
  graphDataLoading?: boolean
  graphDataSuccess?: boolean
  graphDataError?: boolean
  graphData?: any
  selectedColumnList: any[]
  fetchingTables: boolean
  isFetchTables: boolean
  isFetchTablesFailed: boolean
  fetchingColumns: boolean
  isFetchColumnsFailed: boolean
  isFetchimgColumnsSuccess: boolean
  fetchingRules: boolean
  isFetchingRulesSuccess: boolean
  isFetchingRulesFailed: boolean
  graphDataPagination?: any
  errorDataLoading?: boolean
  errorDataSuccess?: boolean
  errorDataError?: boolean
  errorData?: any
  errorDataPagination?: any
  componentData?: any
  financesolutionoption?: string | null
  isApplyingDataEnrichment?: boolean
  AppliedDataEnrichment?: boolean
  FailedDataEnrichment?: boolean
  ProfilingCompleted?: boolean
  uploadExcel?: boolean
  selectedEnrichmentFieldData?: any
  ismodalClicked?: boolean
  isFetchingBusinessRules?: boolean
  fetchBusinessRulesSuccess?: boolean
  fetchBusinessRulesFailed?: boolean
  BusinessRules: any
  reportState: any
  isEnrichmentCustomerDataLoading?: boolean
  isEnrichmentCustomerDataSuccess?: boolean
  isEnrichmentDataError?: boolean
  EnrichmentCustomerData?: any
  customerEnrichmentDataPagination?: any
  enrichedCustDataPagination?: any
  isBusinessRuleSelected?: boolean
  isFetchingEnrichedData?: boolean
  fetchingEnrichedDataSuccess?: boolean
  fetchingEnrichedDataFailed?: boolean
  EnrichedCustRespDataByRule: any
  EnrichedRequestDataByRule: any
  selectedBusinessRuleOption?: any

  // Data archival
  dbNamesLoading?: boolean
  dbNamesSuccess?: boolean
  dbNamesFailed?: boolean
  dbNames: any[]
  tableNamesLoading?: boolean
  tableNamesSuccess?: boolean
  tableNamesFailed?: boolean
  tableNames: any[]
  tableInfoLoading?: boolean
  tableInfoSuccess?: boolean
  tableInfoFailed?: boolean
  tableInfo: any
  dataExecutionLoading?: boolean
  dataExecutionSuccess?: boolean
  dataExecutionFailed?: boolean
  dataExecution: any
  dataHistoryLoading?: boolean
  dataHistorySuccess?: boolean
  dataHistoryFailed?: boolean
  dataHistoryDetailsLoading?: boolean
  dataHistoryDetailsSuccess?: boolean
  dataHistoryDetailsFailed?: boolean
  dataHistoryDetails: any
  dataHistory: any
  dataPreviewLoading?: boolean
  dataPreviewSuccess?: boolean
  dataPreviewFailed?: boolean
  dataPreview: any
  selectedTableList: any[]
  tableInfoList: any[]
  selectedDataArchivalTable?: any
}

const initialState: AppState = {
  dbStructureSelectedSection: null,
  selectedOption: null,
  dynamicFields: [],
  selectedTable: null,
  tableColumns: {},
  columnRules: {},
  columnEnabled: [],
  selectedRules: [],
  selectedRulesHistory: [],
  selectedColumn: null,
  postingData: false,
  postDataSuccess: false,
  postDataFailed: false,
  enrichmentDataWaiting: false,
  enrichmentDataSuccess: false,
  enrichmentDataFailed: false,
  graphDataLoading: false,
  graphDataSuccess: false,
  graphDataError: false,
  errorDataLoading: false,
  errorDataSuccess: false,
  errorDataError: false,
  selectedColumnList: [],
  fetchingTables: false,
  isFetchTables: false,
  isFetchTablesFailed: false,
  fetchingColumns: false,
  isFetchColumnsFailed: false,
  isFetchimgColumnsSuccess: false,
  fetchingRules: false,
  isFetchingRulesSuccess: false,
  isFetchingRulesFailed: false,
  graphDataPagination: {},
  errorDataPagination: {},
  componentData: {},
  financesolutionoption: null,
  isApplyingDataEnrichment: false,
  AppliedDataEnrichment: false,
  FailedDataEnrichment: false,
  ProfilingCompleted: false,
  uploadExcel: false,
  selectedEnrichmentFieldData: null,
  ismodalClicked: false,
  isFetchingBusinessRules: false,
  fetchBusinessRulesSuccess: false,
  fetchBusinessRulesFailed: false,
  BusinessRules: [],
  reportState: {},
  isEnrichmentCustomerDataLoading: false,
  isEnrichmentCustomerDataSuccess: false,
  isEnrichmentDataError: false,
  EnrichmentCustomerData: {},
  customerEnrichmentDataPagination: {},
  enrichedCustDataPagination: {},
  isFetchingEnrichedData: false,
  fetchingEnrichedDataSuccess: false,
  fetchingEnrichedDataFailed: false,
  EnrichedCustRespDataByRule: [],
  EnrichedRequestDataByRule: true,
  selectedBusinessRuleOption: {},
  isBusinessRuleSelected: false,

  // Data Archival Flow
  dbNamesLoading: false,
  dbNamesSuccess: false,
  dbNamesFailed: false,
  dbNames: [],
  tableNamesLoading: false,
  tableNamesSuccess: false,
  tableNamesFailed: false,
  tableNames: [],
  tableInfoLoading: false,
  tableInfoSuccess: false,
  tableInfoFailed: false,
  tableInfo: null,
  dataExecutionLoading: false,
  dataExecutionSuccess: false,
  dataExecutionFailed: false,
  dataExecution: null,
  dataHistoryLoading: false,
  dataHistorySuccess: false,
  dataHistoryFailed: false,
  dataHistory: null,
  dataHistoryDetailsLoading: false,
  dataHistoryDetailsSuccess: false,
  dataHistoryDetailsFailed: false,
  dataHistoryDetails: null,
  dataPreviewLoading: false,
  dataPreviewSuccess: false,
  dataPreviewFailed: false,
  dataPreview: null,
  selectedTableList: [],
  tableInfoList: [],
  selectedDataArchivalTable:''
}

export const AppReducer = (state: AppState = initialState, action: any): AppState => {
  switch (action.type) {
    case 'DATABASE_STRUC_SELECTED_SECTION':
      const {selectedOption, sectionName} = action.payload // Destructure payload
      return {
        ...state,
        isBusinessRuleSelected: sectionName === 'BusinessRuleSection' ? true : false,
        selectedBusinessRuleOption: {
          selectedOption: selectedOption,
          sectionName: selectedOption.section,
        },
        selectedOption: selectedOption,
        dbStructureSelectedSection: selectedOption,
      }

    case 'FETCHING_TABLES':
      return {
        ...state,
        fetchingTables: true,
        isFetchTables: false,
        isFetchTablesFailed: false,
        dynamicFields: [],
      }
    case SET_DYNAMIC_FIELDS:
      return {
        ...state,
        fetchingTables: false,
        isFetchTables: true,
        isFetchTablesFailed: false,
        dynamicFields: action.payload,
      }
    case FETCH_TABLES_FAILED:
      return {
        ...state,
        fetchingTables: false,
        isFetchTables: false,
        isFetchTablesFailed: true,
        dynamicFields: [],
      }
    case SET_SELECTED_TABLE:
      return {
        ...state,
        selectedTable: action.payload.tableName,
      }
    case SET_SELECTED_COLUMN:
      return {
        ...state,
        selectedColumn: state.selectedColumn === action.payload.column ? '' : action.payload.column,
      }
    case FETCHING_COLUMNS:
      return {
        ...state,
        fetchingColumns: true,
        isFetchColumnsFailed: false,
        isFetchimgColumnsSuccess: false,
        tableColumns: {},
      }
    case SET_TABLE_COLUMNS:
      return {
        ...state,
        fetchingColumns: false,
        isFetchColumnsFailed: false,
        isFetchimgColumnsSuccess: true,
        tableColumns: {
          ...state.tableColumns,
          [action.payload.tableName]: action.payload.columns,
        },
        columnEnabled: action.payload.columns.map((t: any) => ({[t]: false})),
      }
    case FETCHING_COLUMNS_FAILED:
      return {
        ...state,
        fetchingColumns: false,
        isFetchColumnsFailed: true,
        isFetchimgColumnsSuccess: false,
        tableColumns: {},
      }
    case FETCHING_RULES:
      return {
        ...state,
        fetchingRules: true,
        isFetchingRulesSuccess: false,
        isFetchingRulesFailed: false,
      }
    case SET_COLUMN_RULES:
      let arrayKey = {
        [Object.keys(action.payload.rules)[0]]:
          action.payload.rules[Object.keys(action.payload.rules)[0]],
      }

      return {
        ...state,
        fetchingRules: false,
        isFetchingRulesSuccess: true,
        isFetchingRulesFailed: false,
        columnRules: {
          ...state.columnRules,
          ...arrayKey,
        },
        selectedColumnList: {
          ...state.selectedColumnList,
          ...arrayKey,
        },
      }
    case FETCHING_RULES_FAILED:
      return {
        ...state,
        fetchingRules: false,
        isFetchingRulesSuccess: false,
        isFetchingRulesFailed: true,
      }
    case TOGGLE_COLUMN_ENABLED:
      return {
        ...state,
        columnEnabled: {
          ...state.columnEnabled,
          ...action.payload,
        },
      }
    case SET_SELECTED_RULES:
      return {
        ...state,
        selectedRules: action.payload,
      }
    case APPEND_SELECTED_RULES:
      const existingEntryIndex = state.selectedRulesHistory.findIndex(
        (entry) => entry.column === action.payload.column
      )
      let updatedColumnEnabled
      if (existingEntryIndex !== -1) {
        const updatedHistory = [...state.selectedRulesHistory]
        updatedHistory[existingEntryIndex] = {
          column: action.payload.column,
          rules: action.payload.rules,
          ruleIds: action.payload.ruleIds,
        }
        if (updatedHistory[existingEntryIndex].rules.length === 0) {
          updatedColumnEnabled = state.columnEnabled.map((c) =>
            Object.keys(c)[0] === state.selectedColumn
              ? {[Object.keys(c)[0]]: false}
              : {[Object.keys(c)[0]]: true}
          )
        } else
          updatedColumnEnabled = state.columnEnabled.map((c) => ({
            [Object.keys(c)[0]]: false,
          }))
        return {
          ...state,
          selectedRulesHistory: updatedHistory,
          columnEnabled: updatedColumnEnabled,
        }
      } else {
        if (action.payload.rules.length === 0) {
          updatedColumnEnabled = state.columnEnabled.map((c) =>
            Object.keys(c)[0] === state.selectedColumn
              ? {[Object.keys(c)[0]]: false}
              : {[Object.keys(c)[0]]: true}
          )
        } else
          updatedColumnEnabled = state.columnEnabled.map((c) => ({
            [Object.keys(c)[0]]: false,
          }))
        return {
          ...state,
          selectedRulesHistory: [
            ...state.selectedRulesHistory,
            {
              column: action.payload.column,
              rules: action.payload.rules,
              ruleIds: action.payload.ruleIds,
            },
          ],
          columnEnabled: updatedColumnEnabled,
        }
      }

    case REMOVE_SELECTED_RULES:
      return {
        ...state,
        selectedRulesHistory: state.selectedRulesHistory.filter(
          (entry) => entry.column !== action.payload.column
        ),
        columnEnabled: state.columnEnabled.map((c) => ({
          [Object.keys(c)[0]]: false,
        })),
      }
    case POST_DATA_WAITING:
      return {
        ...state,
        postingData: true,
        postDataSuccess: false,
        postDataFailed: false,
      }
    case POST_DATA_SUCCESS:
      return {
        ...state,
        postingData: false,
        postDataSuccess: true,
        postDataFailed: false,
        postResponse: action.payload,
      }

    case POST_DATA_FAILED:
      return {
        ...state,
        postingData: false,
        postDataSuccess: false,
        postDataFailed: true,
        postDataErrorMessage: action.payload,
      }
    case ENRICHMENT_DATA_WAITING:
      return {
        ...state,
        enrichmentDataWaiting: true,
        enrichmentDataSuccess: false,
        enrichmentDataFailed: false,
      }
    case ENRICHMENT_DATA_SUCCESS:
      return {
        ...state,
        enrichmentDataWaiting: false,
        enrichmentDataSuccess: true,
        enrichmentDataFailed: false,
        DataEnrichment: action.payload,
      }

    case CLEAR_PROPS:
      return initialState
    case GRAPH_DATA_LOADING:
      return {
        ...state,
        graphDataLoading: true,
        graphDataSuccess: false,
        graphDataError: false,
        graphDataPagination: {},
      }
    case GRAPH_DATA_SUCCESS:
      let rows, page
      rows = 10
      page = Math.ceil(Number(action.payload.count) / 10)
      return {
        ...state,
        graphDataLoading: false,
        graphDataSuccess: true,
        graphDataError: false,
        graphData: action.payload.response.Data,
        graphDataPagination: {rows, page},
        componentData: {
          component: 'GRAPH',
          table: state.selectedTable,
          fieldName: action.payload.fieldName,
          ruleID: action.payload.ruleID,
          input: action.payload.input,
          count: action.payload.count,
          page: action.payload.page,
          heading: action.payload.heading,
        },
      }
    case GRAPH_DATA_FAILED:
      return {
        ...state,
        graphDataLoading: false,
        graphDataSuccess: false,
        graphDataError: true,
        graphDataPagination: {},
      }
    case ERROR_DATA_LOADING:
      return {
        ...state,
        errorDataLoading: true,
        errorDataSuccess: false,
        errorDataError: false,
        errorDataPagination: {},
      }
    case ERROR_DATA_SUCCESS:
      let row, pages
      row = action.payload.rows
      pages = Math.ceil(action.payload.count / 10)
      return {
        ...state,
        errorDataLoading: false,
        errorDataSuccess: true,
        errorDataError: false,
        errorData: action.payload.response.Data,
        errorDataPagination: {row: action.payload.rows, pages},
        componentData: {
          component: 'ERROR',
          table: state.selectedTable,
          fieldName: action.payload.fieldName,
          ruleID: action.payload.ruleID,
          input: action.payload.input,
          count: action.payload.count,
          page: action.payload.page,
          heading: action.payload.heading,
        },
      }

    case ERROR_DATA_FAILED:
      return {
        ...state,
        errorDataLoading: false,
        errorDataSuccess: false,
        errorDataError: true,
        errorDataPagination: {},
      }

    case CLEAR_POPUP_PROPS:
      return {
        ...state,
        errorDataLoading: false,
        errorDataSuccess: false,
        errorDataError: false,
        errorDataPagination: {},
        graphDataLoading: false,
        graphDataSuccess: false,
        graphDataError: false,
        graphDataPagination: {},
        errorData: [],
        graphData: [],
      }

    case FINANCE_SOLUTION_OPTION:
      return {
        ...state,
        financesolutionoption: action.payload,
      }

    case APPLYING_DATA_ENRICHMENT:
      return {
        ...state,
        isApplyingDataEnrichment: true,
        AppliedDataEnrichment: false,
        FailedDataEnrichment: false,
      }

    case APPLIED_DATA_ENRICHMENT:
      return {
        ...state,
        isApplyingDataEnrichment: false,
        AppliedDataEnrichment: true,
        FailedDataEnrichment: false,
      }

    case FAILED_DATA_ENRICHMENT:
      return {
        ...state,
        isApplyingDataEnrichment: false,
        AppliedDataEnrichment: false,
        FailedDataEnrichment: true,
      }

    case FETCHING_BUSINESS_RULES:
      return {
        ...state,
        isFetchingBusinessRules: true,
        fetchBusinessRulesSuccess: false,
        fetchBusinessRulesFailed: false,
        BusinessRules: [],
      }

    case FETCH_BUSINESS_RULES_SUCCESS:
      return {
        ...state,
        isFetchingBusinessRules: false,
        fetchBusinessRulesSuccess: true,
        fetchBusinessRulesFailed: false,
        BusinessRules: action.payload,
      }

    case FETCH_BUSINESS_RULES_FAILED:
      return {
        ...state,
        isFetchingBusinessRules: false,
        fetchBusinessRulesSuccess: false,
        fetchBusinessRulesFailed: true,
      }
    case DATA_PROFILING_COMPLETED:
      return {
        ...state,
        ProfilingCompleted: true,
      }

    case 'UPLOAD_EXCEL_PROPS':
      return {
        ...state,
        uploadExcel: !state.uploadExcel,
      }

    case RESET_STATE:
      return initialState
    case SELECTED_ENRICHMENT_FIELD:
      return {
        ...state,
        reportState: action.payload,
        selectedEnrichmentFieldData: action.payload,
      }
    case 'MODAL_CLICKED':
      return {
        ...state,
        ismodalClicked: !state.ismodalClicked,
      }
    case SET_ENRICHMENT_SELECTED_RULES:
      return {
        ...state,
        selectedEnrichmentFieldData: {
          ...state.selectedEnrichmentFieldData,
          selectedEnrichmentRule: action.payload,
        },
      }
    case ENRICHMENT_MODAL_DATA_LOADING:
      return {
        ...state,
        isEnrichmentCustomerDataLoading: true,
        isEnrichmentCustomerDataSuccess: false,
        isEnrichmentDataError: false,
        EnrichmentCustomerData: {},
      }
    case ENRICHMENT_MODAL_DATA_SUCCESS:
      return {
        ...state,
        isEnrichmentCustomerDataLoading: false,
        isEnrichmentCustomerDataSuccess: true,
        isEnrichmentDataError: false,
        customerEnrichmentDataPagination: {rows: action.payload.rows, page: action.payload.page},
        EnrichmentCustomerData: action.payload.response,
      }
    case ENRICHMENT_MODAL_DATA_FAILED:
      return {
        ...state,
        isEnrichmentCustomerDataLoading: false,
        isEnrichmentCustomerDataSuccess: false,
        isEnrichmentDataError: true,
        EnrichmentCustomerData: {},
      }
    case FETCHING_ENRICHED_DATA:
      return {
        ...state,
        isEnrichmentCustomerDataLoading: true,
        isFetchingEnrichedData: true,
        fetchingEnrichedDataSuccess: false,
        fetchingEnrichedDataFailed: false,
        EnrichedRequestDataByRule: action.payload, // Store the payload in EnrichedCustRespDataByRule
      }

    case FETCHING_ENRICHED_DATA_SUCCESS:
      return {
        ...state,
        isEnrichmentCustomerDataLoading: false,
        isFetchingEnrichedData: false,
        fetchingEnrichedDataSuccess: true,
        fetchingEnrichedDataFailed: false,
        enrichedCustDataPagination: {rows: action.payload.rows, page: action.payload.page},
        EnrichedCustRespDataByRule: action.payload,
        // Update state with data from the action payload if needed
      }
    case FETCHING_ENRICHED_DATA_FAILED:
      return {
        ...state,
        isEnrichmentCustomerDataLoading: false,
        isFetchingEnrichedData: false,
        fetchingEnrichedDataSuccess: false,
        fetchingEnrichedDataFailed: true,
        // Update state with error information if needed
      }

    //Data Archival Flow
    case 'DB_NAMES_LOADING':
      return {
        ...state,
        dbNamesLoading: true,
        dbNamesSuccess: false,
        dbNamesFailed: false,
      }

    case 'DB_NAMES_SUCCESS':
      return {
        ...state,
        dbNamesLoading: false,
        dbNamesSuccess: true,
        dbNamesFailed: false,
        dbNames: action.payload, // Accessing the schema array from the payload
      }

    case 'DB_NAMES_FAILED':
      return {
        ...state,
        dbNamesLoading: false,
        dbNamesSuccess: false,
        dbNamesFailed: true,
      }

    case 'TABLE_NAMES_LOADING':
      return {
        ...state,
        tableNamesLoading: true,
        tableNamesSuccess: false,
        tableNamesFailed: false,
      }

    case 'TABLE_NAMES_SUCCESS':
      return {
        ...state,
        tableNamesLoading: false,
        tableNamesSuccess: true,
        tableNamesFailed: false,
        tableNames: action.payload,
      }

    case 'TABLE_NAMES_FAILED':
      return {
        ...state,
        tableNamesLoading: false,
        tableNamesSuccess: false,
        tableNamesFailed: true,
      }

    case 'DATA_EXECUTION_LOADING':
      return {
        ...state,
        dataExecutionLoading: true,
        dataExecutionSuccess: false,
        dataExecutionFailed: false,
      }

    case 'DATA_EXECUTION_SUCCESS':
      return {
        ...state,
        dataExecutionLoading: false,
        dataExecutionSuccess: true,
        dataExecutionFailed: false,
        dataExecution: action.payload,
      }

    case 'DATA_EXECUTION_FAILED':
      return {
        ...state,
        dataExecutionLoading: false,
        dataExecutionSuccess: false,
        dataExecutionFailed: true,
      }

    case 'DATA_PREVIEW_LOADING':
      return {
        ...state,
        dataPreviewLoading: true,
        dataPreviewSuccess: false,
        dataPreviewFailed: false,
      }

    case 'DATA_PREVIEW_SUCCESS':
      return {
        ...state,
        dataPreviewLoading: false,
        dataPreviewSuccess: true,
        dataPreviewFailed: false,
        dataPreview: action.payload,
      }

    case 'DATA_PREVIEW_FAILED':
      return {
        ...state,
        dataPreviewLoading: false,
        dataPreviewSuccess: false,
        dataPreviewFailed: true,
      }

    case 'DATA_HISTORY_LOADING':
      return {
        ...state,
        dataHistoryLoading: true,
        dataHistorySuccess: false,
        dataHistoryFailed: false,
      }

    case 'DATA_HISTORY_SUCCESS':
      return {
        ...state,
        dataHistoryLoading: false,
        dataHistorySuccess: true,
        dataHistoryFailed: false,
        dataHistory: action.payload,
      }

    case 'DATA_HISTORY_FAILED':
      return {
        ...state,
        dataHistoryLoading: false,
        dataHistorySuccess: false,
        dataHistoryFailed: true,
      }

    case 'DATA_HISTORY_DETAILS_LOADING':
      return {
        ...state,
        dataHistoryDetailsLoading: true,
        dataHistoryDetailsSuccess: false,
        dataHistoryDetailsFailed: false,
      }

    case 'DATA_HISTORY_DETAILS_SUCCESS':
      return {
        ...state,
        dataHistoryDetailsLoading: false,
        dataHistoryDetailsSuccess: true,
        dataHistoryDetailsFailed: false,
        dataHistoryDetails: action.payload,
      }

    case 'DATA_HISTORY_DETAILSFAILED':
      return {
        ...state,
        dataHistoryDetailsLoading: false,
        dataHistoryDetailsSuccess: false,
        dataHistoryFailed: true,
      }

      case 'SET_ARCHIVAL_TABLE':
        return{
          ...state,
          selectedDataArchivalTable:action.payload
        }

    case 'TABLE_INFO_LOADING':
      return {
        ...state,
        tableInfoLoading: true,
        tableInfoSuccess: false,
        tableInfoFailed: false,
        
        
                // selectedDataArchivalTable:action.payload.table
      }

    // case 'TABLE_INFO_SUCCESS':
    //   let data
    //   if (state.tableInfoList.length > 0) data = [...state.tableInfoList, action.payload]
    //   else data = [action.payload]
    //   return {
    //     ...state,
    //     tableInfoLoading: false,
    //     tableInfoSuccess: true,
    //     tableInfoFailed: false,
    //     tableInfo: action.payload,
    //     tableInfoList: data,
    //   }

    case 'TABLE_INFO_SUCCESS':
      return {
        ...state,
        tableInfoLoading: false,
        tableInfoSuccess: true,
        tableInfoFailed: false,
        tableInfo: action.payload.data,
        // selectedDataArchivalTable:action.payload.table
      }

    case 'TABLE_INFO_FAILED':
      return {
        ...state,
        tableInfoLoading: false,
        tableInfoSuccess: false,
        tableInfoFailed: true,
      }
    default:
      return state
  }
}
