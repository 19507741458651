import React, { useEffect } from 'react';
import { create } from '@amcharts/amcharts4/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import { XYChart, CategoryAxis, ValueAxis, ColumnSeries, XYCursor, LabelBullet } from '@amcharts/amcharts4/charts';

const InboundComponent: React.FC = () => {
  useEffect(() => {
    // Define colors array
    const colors = [
      '#006400', // Dark Green
      '#008B8B', // Dark Cyan
      '#104E8B', // Dodger Blue
      '#00008B', // Dark Blue
      '#2F4F4F', // Dark Slate Grey
      '#4B0082', // Indigo
      '#483D8B', // Dark Slate Blue
      '#4B0082', // Indigo
      '#2F4F4F', // Dark Slate Grey
      '#006400', // Dark Green
      '#556B2F', // Dark Olive Green
      '#006400'  // Dark Green
    ];

    // Use am4core
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    const chart = create('chartCountry', XYChart);
    chart.language.locale = am4lang_en_US;
    chart.paddingRight = 20;

       // Disable the AmCharts logo
chart.logo.disabled = true;
    // Add data
    const data = [
      { country: 'AG', value: 20 },
      { country: 'BB', value: 2 },
      { country: 'CA', value: 149 },
      { country: 'DM', value: 154 },
      { country: 'GB', value: 60 },
      { country: 'GH', value: 5 },
      { country: 'GY', value: 161 },
      { country: 'HN', value: 73 },
      { country: 'JM', value: 470437 },
      { country: 'KY', value: 653 },
      { country: 'PH', value: 24 },
      { country: 'US', value: 950 },
    ];
    
    chart.data=data;
    const max = Math.max(...data.map(item => item.value));
    // Create axes
    const categoryAxis = chart.xAxes.push(new CategoryAxis());
    categoryAxis.dataFields.category = 'country';
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.grid.template.disabled = true; // Disable grid lines for x-axis
    categoryAxis.title.text = 'Country Code';

    // Create value axis
    const valueAxis = chart.yAxes.push(new ValueAxis());
    valueAxis.renderer.grid.template.disabled = false; // Disable grid lines for y-axis
    valueAxis.title.text = 'Number of Transactions';

    // Set up logarithmic scale for the y-axis
    valueAxis.min = 1; // Start from 1
    valueAxis.max = max * 10;
    //valueAxis.max = max + (max * 0.1);

    //valueAxis.max = 10000000; // Max value
    valueAxis.strictMinMax = true;
    valueAxis.logarithmic = true; // Use logarithmic scale

    // Create series
    const series = chart.series.push(new ColumnSeries());
    series.dataFields.valueY = 'value';
    series.dataFields.categoryX = 'country';
    series.name = 'Country Values';

    // Set colors for each column
    series.columns.template.stroke = am4core.color('#000'); // Default stroke color
    series.columns.template.strokeOpacity = 0.5; // Adjust opacity
    series.columns.template.adapter.add('fill', (fill, target) => {
      // Check if target.dataItem is defined
      if (target.dataItem) {
        const country = target.dataItem['categoryX'];
        // Set different colors based on country
        switch(country) {
          case 'AG': return am4core.color('#006400'); // Dark Green
          case 'BB': return am4core.color('#008B8B'); // Dark Cyan
          case 'CA': return am4core.color('#104E8B'); // Dodger Blue
          case 'DM': return am4core.color('#00008B'); // Dark Blue
          case 'GB': return am4core.color('#2F4F4F'); // Dark Slate Grey
          case 'GH': return am4core.color('#4B0082'); // Indigo
          case 'GY': return am4core.color('#483D8B'); // Dark Slate Blue
          case 'HN': return am4core.color('#4B0082'); // Indigo
          case 'JM': return am4core.color('#2F4F4F'); // Dark Slate Grey
          case 'KY': return am4core.color('#006400'); // Dark Green
          case 'PH': return am4core.color('#556B2F'); // Dark Olive Green
          case 'US': return am4core.color('#006400'); // Dark Green
          default: return fill;
        }
      }
      // Return default fill if target.dataItem is undefined
      return fill;
    });

    // Add value labels on top of the bars
    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.text = '{valueY}';
    labelBullet.label.dy = -10; // Adjust vertical offset

    // Assign colors to each series individually
    for (let i = 0; i < colors.length; i++) {
      const column = series.columns.template;
      column.adapter.add("fill", (fill, target) => {
        if (target.dataItem && i === target.dataItem.index) {
          // Set label text color to match bar color
          labelBullet.label.fill = am4core.color(colors[i]);
          return am4core.color(colors[i]);
        }
        return fill;
      });
    }

     // Modify the tooltip text to show full country name
     series.columns.template.tooltipText = '{country}: {valueY.value}';

     // Create a map to store country codes and their corresponding full names
     const countryNames = {
       'AG': 'Argentina',
       'BB': 'Barbados',
       'CA': 'Canada',
       'DM': 'Dominica',
       'GB': 'United Kingdom',
       'GH': 'Ghana',
       'GY': 'Guyana',
       'HN': 'Honduras',
       'JM': 'Jamaica',
       'KY': 'Cayman Islands',
       'PH': 'Philippines',
       'US': 'United States'
     };
 
     // Modify the tooltip text adapter to use the full country name
     series.columns.template.adapter.add('tooltipText', (text, target) => {
       if (target.dataItem) {
         const countryCode = target.dataItem['categoryX'];
         const countryName = countryNames[countryCode];
         return `${countryName}: {valueY.value}`;
       }
       return text;
     });

    valueAxis.renderer.line.stroke = am4core.color('#000');
    valueAxis.renderer.line.strokeWidth = 0.3;
    valueAxis.renderer.line.strokeOpacity = 1;

      // Add a horizontal line on top of the chart
const topLine = valueAxis.axisRanges.create();
topLine.value = max * 10; // Set the value where the horizontal line should appear
topLine.grid.stroke = am4core.color('black'); // Set line color
topLine.grid.strokeWidth = 2; // Set line width

    // Add cursor
    chart.cursor = new XYCursor();

    // Dispose chart when component unmounts
    return () => {
      chart.dispose();
    };

  }, []);

  return <div id="chartCountry" style={{ width: '100%', height: '450px' }}></div>;
};

export default InboundComponent;



