import React, { useState } from 'react';
import './style.css';

const JNMCarouselComponent: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(1);

  const handleSlideChange = (slideNumber: number) => {
    setCurrentSlide(slideNumber);
  };

  const goToPreviousSlide = () => {
    const previousSlide = currentSlide === 1 ? 4 : currentSlide - 1;
    setCurrentSlide(previousSlide);
  };

  const goToNextSlide = () => {
    const nextSlide = currentSlide === 4 ? 1 : currentSlide + 1;
    setCurrentSlide(nextSlide);
  };

  return (
    <div >
     
    
    <div id="slider">
      <input type="radio" name="slider" id="slide1" checked={currentSlide === 1} onChange={() => handleSlideChange(1)} />
      <input type="radio" name="slider" id="slide2" checked={currentSlide === 2} onChange={() => handleSlideChange(2)} />
      <input type="radio" name="slider" id="slide3" checked={currentSlide === 3} onChange={() => handleSlideChange(3)} />
      <input type="radio" name="slider" id="slide4" checked={currentSlide === 4} onChange={() => handleSlideChange(4)} />

      <div id="slides">
       
        <div id="overflow">
          <div className="inner">
            <div className={`slide slide_1`} >
              <div className="slide-content">
                <h2>Slide 1</h2>
                <p>Content for Slide 1</p>
              </div>
            </div>
            <div className={`slide slide_2`}>
              <div className="slide-content">
                <h2>Slide 2</h2>
                <p>Content for Slide 2</p>
              </div>
            </div>
            <div className={`slide slide_3`} >
              <div className="slide-content">
                <h2>Slide 3</h2>
                <p>Content for Slide 3</p>
              </div>
            </div>
            <div className={`slide slide_4`}>
              <div className="slide-content">
                <h2>Slide 4</h2>
                <p>Content for Slide 4</p>
              </div>
            </div>
          </div>
        </div>
     
      </div>
      <div style={{ display: 'flex', width: '100%',alignItems: 'center',paddingTop:'15px' }}>
  <span style={{ flex: 1, display: 'flex', justifyContent: 'flex-start,' }}>
    <button className="btn btn-primary" onClick={goToPreviousSlide}>
      Previous
    </button>
  </span>
  
  <div id="bullets">
    <label htmlFor="slide1"></label>
    <label htmlFor="slide2"></label>
    <label htmlFor="slide3"></label>
    <label htmlFor="slide4"></label>
  </div>
  
  <span style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
    <button className="btn btn-primary" onClick={goToNextSlide}>
      Next
    </button>
  </span>
</div>

    </div>
    </div>
  );
};

export default JNMCarouselComponent;
