import React, {Fragment, useEffect, useState} from 'react'

interface Rule {
  Id: string
  rule: string
  desc: string
}

interface profilingData {
  selectedColumn: string | any
  setSelectedColumn: (params: {column: string}) => void
  fetchColumnRules: (params: {db: string; table: string; column: string}) => void
  selectedTable: string
  modalClicked: any
  reportState: any
  selectedEnrichmentFieldData: any
  appendSelectedRules: (
    selectedColumn: string,
    selectedRules: string[],
    selectedRuleIds: string[]
  ) => void
  selectedRulesHistory: {
    column: string
    ruleIds: string[]
    rules: string[]
  }[]
}

const ProfilingDataCard: React.FC<profilingData> = (props) => {
  const [isScrollable, setIsScrollable] = useState(false)

  useEffect(() => {
    const appliedRulesSection = document.getElementById('kt_user_view_details')
    if (appliedRulesSection) {
      const currentHeight = appliedRulesSection.scrollHeight
      setIsScrollable(currentHeight >= 204)
    }
  }, [props.selectedRulesHistory])

  return (
    <div className='card-body py-5'>
      <div className='d-flex flex-center flex-column py-4'>
        <span className='fs-3 text-gray-800 text-hover-primary fw-bold mb-3'>
          {props.selectedEnrichmentFieldData.fieldName
            ? props.selectedEnrichmentFieldData.fieldName
            : ''}
        </span>
        <div className='mb-9'>
          <div className='badge badge-lg badge-light-primary d-inline'>Analysis</div>
        </div>
        {/*begin::Info heading*/}
        <div className='fw-bold mb-3'>
          Profiling Report
          <span
            className='ms-2'
            data-bs-toggle='popover'
            data-bs-trigger='hover'
            data-bs-html='true'
            data-bs-content='Applied business rules and data profiling statistics report.'
          >
            <i className='ki-duotone ki-information fs-7'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
            </i>
          </span>
        </div>
        {/*end::Info heading*/}
        <div className='d-flex flex-wrap flex-center'>
          {/*begin::Stats*/}
          <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3'>
            <div className='fs-4 fw-bold text-gray-700'>
              <span className='w-75px text-primary'>
                    {Number(
                  props.selectedEnrichmentFieldData.fieldData &&
                    props.selectedEnrichmentFieldData.fieldData.totalNoOfRows
                    ? props.selectedEnrichmentFieldData.fieldData.totalNoOfRows
                    : ''
                ).toLocaleString()}
              </span>
            </div>
            <div className='fw-semibold text-muted'>Total</div>
          </div>
          <div className='border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3'>
            <div className='fs-4 fw-bold text-gray-700'>
              <span className='w-50px text-success'>
                {Number(
                  props.selectedEnrichmentFieldData.fieldData.totalNoOfUniqueRows &&
                    props.selectedEnrichmentFieldData.fieldData.totalNoOfUniqueRows
                    ? props.selectedEnrichmentFieldData.fieldData.totalNoOfUniqueRows
                    : ''
                ).toLocaleString()}
              </span>
            </div>
            <div className='fw-semibold text-muted'>Unique</div>
          </div>
          <div className='border border-gray-300 border-dashed rounded py-3 px-3 mb-3'>
            <div className='fs-4 fw-bold text-gray-700'>
              <span className='w-50px text-danger'>
                {Number(
                  props.selectedEnrichmentFieldData.fieldData &&
                    props.selectedEnrichmentFieldData.fieldData.totalNoOfNullRows
                    ? props.selectedEnrichmentFieldData.fieldData.totalNoOfNullRows
                    : ''
                ).toLocaleString()}
              </span>
            </div>
            <div className='fw-semibold text-muted'>Null</div>
          </div>
        </div>
      </div>
      {/* Selected Rule Details */}

      <div className='d-flex flex-stack fs-6 py-3'>
        <div className='fw-bold'>Applied business rules</div>
      </div>
      {/*end::Details toggle*/}
      <div className='separator'></div>

      {/*begin::Details content*/}
      <div
        id='kt_user_view_details'
        className=''
        style={isScrollable ? {maxHeight: '204px', overflowY: 'auto'} : {height: 'auto'}}
      >
        <div className='pb-5 fs-6'>
          {props.reportState &&
            props.reportState.selectedRule &&
            props.reportState.selectedRule.map((r) => (
              <Fragment key={r.Id}>
                <div className='fw-bold mt-5 text-gray-800 mb-1'>{r.desc}</div>
                <div className='text-gray-600 text-hover-primary'>{`Error : ${r.error}`}</div>
              </Fragment>
            ))}
        </div>
      </div>
      {/*end::Details content*/}
    </div>
  )
}

export default ProfilingDataCard
