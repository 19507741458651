import React, {useEffect} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import {
  XYChart,
  CategoryAxis,
  ValueAxis,
  ColumnSeries,
  XYCursor,
  LabelBullet,
} from '@amcharts/amcharts4/charts'

const SolidGComponent = () => {
  useEffect(() => {
    // Create chart instance
    let chart = am4core.create('chartdiv', am4charts.XYChart)

       // Disable the AmCharts logo
chart.logo.disabled = true;

    // Add data
    const data = [
      {year: '2018', revenue: 1849331.81},
      {year: '2019', revenue: 9338991.79},
      {year: '2020', revenue: 15581482.77},
      {year: '2021', revenue: 24308041.35},
      {year: '2022', revenue: 29329335.88},
      {year: '2023', revenue: 29586488.79},
    ]

    chart.data = data
    const max = Math.max(...data.map((item) => item.revenue))

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'year'
    categoryAxis.title.text = 'Year'
    categoryAxis.renderer.grid.template.disabled = true

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
    valueAxis.title.text = 'Total Amount ($)'
    valueAxis.renderer.labels.template.adapter.add('text', (text) => `$${text}`)
    valueAxis.strictMinMax = true;
    valueAxis.max = max * 10;
    valueAxis.min = 100000 // Start from 100,000
    valueAxis.logarithmic = true

    // Create series
    let series = chart.series.push(new am4charts.LineSeries())
    series.dataFields.valueY = 'revenue'
    series.dataFields.categoryX = 'year'
    series.name = 'Revenue'
    series.stroke = am4core.color('#6a0dad')
    series.strokeWidth = 2
    series.tooltipText = '{name}: ${valueY}'

    // Add markers
    let bullet = series.bullets.push(new am4charts.CircleBullet())
    bullet.circle.fill = am4core.color('#6a0dad')
    bullet.circle.strokeWidth = 2

    const labelBullet = series.bullets.push(new LabelBullet())
    labelBullet.label.text = '${valueY}'
    labelBullet.label.dy = -15
    labelBullet.label.fill = am4core.color('#1B1A55')

    // labelBullet.label.background.fill = am4core.color('#EEEEEE')
    //labelBullet.label.background.strokeWidth = 10

    //labelBullet.label.background.stroke = am4core.color('#212121')
    // labelBullet.label.background.cornerRadius(5, 5, 5, 5);

    // Enable responsive layout
    chart.responsive.enabled = true

    // Enable scrollbar
    //chart.scrollbarX = new am4core.Scrollbar();

    // Add cursor
    chart.cursor = new am4charts.XYCursor()

    return () => {
      chart.dispose()
    }
  }, [])

  return <div id='chartdiv' style={{width: '100%', height: '470px'}}></div>
}

export default SolidGComponent