import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const AddNewCustomRule = () => {
  const [selectedOption, setSelectedOption] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [state,setState] = useState({
    ruleid:'',
    rulename:'',
    ruledesc:'',
    ruleerror:''  })

  const [errors,setErrors] = useState({
    ruleid:'',
    rulename:'',
    ruledesc:'',
    ruleerror:'' 
  })



  const openSweetAlert = () => {
    // e.preventDefault();

    Swal.fire({
      text: "Are you sure you want to cancel ?",
      icon: "warning",
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: "No,return",
      confirmButtonText: "Yes, Cancel it",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn  btn-light"
      }
    }).then(function(){
      const modalId = `addnewrule_modal`
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
      if (modalTrigger) {
        ;(modalTrigger as any).click()
      }
      setModalOpen(false)
    },function(){
      return null
    })
  }

  const renderFormFields = () => {
       return (
      <form
        id='kt_modal_new_card_form'
        className='form fv-plugins-bootstrap5 fv-plugins-framework'
        action='#'
        data-select2-id='select2-data-kt_modal_new_card_form'
      >
        <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-semibold form-label mb-2'>Rule ID</label>
          <div className='position-relative'>
            <input
              type='text'
              value={state.ruleid}
              onChange={(e)=>{setState({...state,[e.target.name]:e.target.value});setErrors({...errors,[e.target.name]:''})}}
              className='form-control form-control-solid'
              placeholder='Enter rule id'
              name='ruleid'
            />
          </div>
          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
            {errors.rulename}
          </div>
        </div>
        <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-semibold form-label mb-2'>Rule Description</label>
          <div className='position-relative'>
            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Enter rule name'
              name='rulename'
              value={state.rulename}
              onChange={(e)=>{setState({...state,[e.target.name]:e.target.value});setErrors({...errors,[e.target.name]:''})}}
            />
          </div>
          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
            {errors.rulename}
          </div>
        </div>

        <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-semibold form-label mb-2'>Error Value</label>
          <div className='position-relative'>
            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Enter userdescription'
              name='ruledesc'
              value={state.ruledesc}
              onChange={(e)=>{setState({...state,[e.target.name]:e.target.value});setErrors({...errors,[e.target.name]:''})}}
            />
          </div>
          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
            {errors.ruledesc}
          </div>
        </div>

        <div className='text-center pt-10'>
          <button
            type='reset'
            id='kt_modal_new_card_cancel'
            className='btn btn-light me-3'
            onClick={openSweetAlert}
          >
            Discard
          </button>
          <button
            type='button'
            id='kt_modal_new_card_submit'
            className='btn btn-primary'
            onClick={showSuccessCheck}
          >
            <span className='indicator-label'>Submit</span>
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </div>

        {/* {selectedConnection.fields.map((field) => (
          <div key={field} className='mb-3 mt-3'>
            <label htmlFor={field} className='form-label'>
              {field}
            </label>
            <input type='text' className='form-control' id={field} />
          </div>
        ))}
        <div className='text-end'>
          {' '}
          <button type='button' className='btn btn-primary' onClick={showSuccessCheck}>
            Connect
          </button>
        </div> */}
      </form>
    )
  }

  const showSuccessCheck = () => {
    const fields: string[] = ['hostname', 'serverport', 'username', 'password'];
    const newErrors: any = {};
  
    for (const field of fields) {
      if (state[field] === '') {
        newErrors[field] = `${field} is required`;
      }
    }
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      setErrors({
        ruleid:'',
    rulename:'',
    ruledesc:'',
    ruleerror:'' 
      });
      setShowSuccess(true);
    }
  };

  
  useEffect(() => {
    if (modalOpen) {
      const modalId = `addnewrule_modal`
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
      if (modalTrigger) {
        ;(modalTrigger as any).click()
      }
    }
  }, [modalOpen, selectedOption])
const navigate = useNavigate();
  useEffect(()=>{
    if(showSuccess)
    {
      Swal.fire({
        text: "Connected Successfully !",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "btn btn-primary",
        }
      }).then(function(){
        const modalId = `addnewrule_modal`
        const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
        if (modalTrigger) {
          ;(modalTrigger as any).click()
        }
        setModalOpen(false)
        navigate('/process/bpms/finance/remittance/profiling')
      })
    }
  },
  [showSuccess])

  return (
<>
{renderFormFields()}
</>
  )
}

export default AddNewCustomRule
