import React from 'react';
import { PageLink, PageTitle } from '../../../../_metronic/layout/core';
import BankingProcessDesign from './BankingProcessDesign';

const BP_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Finance',
    path: '/apps/process/fin/',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Banking Solution',
    path: '/apps/conventional-analytics/profile',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const BusinessProcessList = (props) => {
  return (
    <>
      <PageTitle breadcrumbs={BP_Breadcrumbs}>Banking Business Process</PageTitle>
      <BankingProcessDesign {...props} />
    </>
  );
};

export default BusinessProcessList;
