import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const AveragescoresChart: React.FC = () => {
  useEffect(() => {
    let chart = am4core.create('average', am4charts.XYChart);
    // Disable the AmCharts logo
    chart.logo.disabled = true;

    // Sample data with active and inactive users
    const data = [
      { user: 'Active', frequency: 2.87, recency: 2.8, monetary: 2.78 },
      { user: 'Inactive', frequency: 1.92, recency: 2.23, monetary: 2.3 },
    ];

    chart.data = data;

    // Create category axis
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = 'user';
    categoryAxis.renderer.grid.template.disabled = true; // Disable grid lines
    categoryAxis.renderer.minGridDistance = 50; // Increase spacing between categories
    categoryAxis.renderer.labels.template.fontSize = 14; // Increase font size
    categoryAxis.renderer.labels.template.fontWeight = 'bold'; // Bold font
    categoryAxis.renderer.cellStartLocation = 0.1; // Start the first cell a bit earlier
    categoryAxis.renderer.cellEndLocation = 0.8; // End the last cell a bit later
    
    // Create value axis
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.fontSize = 14; // Increase font size
    valueAxis.renderer.labels.template.fontWeight = 'bold'; // Bold font

    // Create series for frequency
    let frequencySeries = chart.series.push(new am4charts.ColumnSeries());
    frequencySeries.dataFields.valueY = 'frequency';
    frequencySeries.dataFields.categoryX = 'user';
    frequencySeries.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    frequencySeries.name = 'Frequency';
    frequencySeries.fill = am4core.color('#1f77b4'); // Blue color for frequency
    frequencySeries.columns.template.stroke = am4core.color('#1f77b4'); // Set border color same as fill color

    // Create series for recency
    let recencySeries = chart.series.push(new am4charts.ColumnSeries());
    recencySeries.dataFields.valueY = 'recency';
    recencySeries.dataFields.categoryX = 'user';
    recencySeries.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    recencySeries.name = 'Recency';
    recencySeries.fill = am4core.color('#2ca02c'); // Green color for recency
    recencySeries.columns.template.stroke = am4core.color('#2ca02c'); // Set border color same as fill color
    recencySeries.columns.template.width = am4core.percent(80); // Adjust column width

    // Create series for monetary
    let monetarySeries = chart.series.push(new am4charts.ColumnSeries());
    monetarySeries.dataFields.valueY = 'monetary';
    monetarySeries.dataFields.categoryX = 'user';
    monetarySeries.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
    monetarySeries.name = 'Monetary';
    monetarySeries.fill = am4core.color('#ff7f0e'); // Orange color for monetary
    monetarySeries.columns.template.stroke = am4core.color('#ff7f0e'); // Set border color same as fill color

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top'; // Position legend at the bottom
    chart.legend.labels.template.fontSize = 14; // Increase font size
    chart.legend.labels.template.fontWeight = 'bold'; // Bold font

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    return () => {
      chart.dispose();
    };
  }, []);

  return <div id="average" style={{ width: '100%', height: '450px' }}></div>;
};

export default AveragescoresChart;