import React, { useEffect, useState } from 'react';
import './Cogloader.css';

const CogLoader: React.FC<any> = (props) => {
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);

  const loadingTextsProfiling = [
    'Gathering data for analysis...',
    'Preparing to profile your dataset...',
    'Loading data for in-depth examination...',
    'Initiating data profiling process...',
    'Inspecting dataset for insights...',
    'Data crunching in progress...',
    'Exploring your data for patterns...',
    'Getting ready to profile your information...',
    'Analyzing dataset characteristics...',
    'Fetching details for comprehensive profiling...',
    'Uncovering hidden insights in your data...',
    'Preparing to unveil the story within your dataset...',
    'Initiating the journey into data discovery...',
    'Loading data for detailed profiling and examination...',
    'Scanning dataset for valuable information...',
    'Getting ready to unveil the data\'s secrets...',
    'Your data is undergoing a thorough profiling process...',
    'Analyzing attributes for a comprehensive profile...',
    'Diving deep into the data for meaningful patterns...',
    'Processing data to reveal its unique characteristics...',
    'Mapping out the nuances within your dataset...',
    'Profiling your data to extract valuable knowledge...',
    'Initiating the exploration of dataset intricacies...',
    'Your data is undergoing detailed scrutiny for insights...',
    'Extracting key features from your dataset...',
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingTextIndex((prevIndex) => (prevIndex + 1) % loadingTextsProfiling.length);
    }, 800);
  
    return () => {
      clearInterval(interval);
      if (!props.postingDta) {
        // Perform your desired function here when postingDta becomes false
        props.completedDataProfiling();
      }
    };
  }, [props.postingDta]);

  return (
    <div className="loader-container">
      <div className="loader">
        <ul className="hexagon-container">
          <li className="hexagon hex_1"></li>
          <li className="hexagon hex_2"></li>
          <li className="hexagon hex_3"></li>
          <li className="hexagon hex_4"></li>
          <li className="hexagon hex_5"></li>
          <li className="hexagon hex_6"></li>
          <li className="hexagon hex_7"></li>
        </ul>
      </div>
      <div className="loader-text">{loadingTextsProfiling[loadingTextIndex]}</div>
    </div>
  );
};

export default CogLoader;
