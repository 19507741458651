// Import necessary libraries and types
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import {KTIcon} from '../../../../../_metronic/helpers'

// Define the types
type SelectedRule = {
  Id: string
  rule: string
  desc: string
  stats: {
    IsBlank: string
    IsDuplicate: string
    IsUnique: string
  }
}

type DataStructure = {
  fieldName: string
  totalNoOfRows: string
  totalNoOfErrorRows: string
  totalNoOfNullRows: string
  totalNoOfUniqueRows: string
  nullPercentage: string
  errorPercentage: string
  uniquePercentage: string
  selected_rules: SelectedRule[],
 
}

// Ensure that the DataFieldReportTable component accepts an array
type DataFieldReportTableProps = {
  postDataSuccess: boolean
  postResponse: DataStructure[]
  data: string,
  props:any
  // props:any
}

const DataFieldReportTable: React.FC<DataFieldReportTableProps> = ({
  postDataSuccess,
  postResponse,
  data,
},props) => {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState<string>('')

  const [error, setError] = useState<string | null>(null)

  const navigateToReportBreakdown = (fieldName: string) => {

    const fieldData = postResponse.filter((data) => data.fieldName === fieldName)
    
    console.log('FIELD_DATAA', fieldData)

    if (!fieldData) {
      // Handle the case where field data is not found

      // You can display an error message to the user or take other appropriate actions
      const errorMessage = `Field data not found for field name: ${fieldName}`
      console.error(errorMessage)
      setError(errorMessage)
      return
    }

    // Extract FieldData, SelectedRule, RuleStats from the found fieldData
    const {selected_rules, ...restFieldData} = fieldData[0]
    const selectedRule = selected_rules // Assuming you want the first rule
    // Reset error state
    setError(null)
    // props.selectedEnrichmentField({
    //   fieldData: restFieldData,
    //   selectedRule: selectedRule,
    //   ruleStats: selectedRule[0].stats,
    //   fieldName: fieldName,
    // })

    return navigate('/process/bpms/finance/remittance/profiling/report/breakdown', {
      state: {
        fieldData: restFieldData,
        selectedRule: selectedRule,
        ruleStats: selectedRule[0].stats,
        fieldName: fieldName,
      },
    })
  }

  // const navigateToReportStatistics = (fieldName: string) => {
  //   const fieldData = postResponse.filter((data) => data.fieldName === fieldName)
  //   console.log('FIELD_NAME', fieldName)
  //   if (!fieldData) {
  //     // Handle the case where field data is not found
  //     console.error(`Field data not found for field name: ${fieldName}`)
  //     // You can display an error message to the user or take other appropriate actions
  //     const errorMessage = `Field data not found for field name: ${fieldName}`
  //     console.error(errorMessage)
  //     setError(errorMessage)
  //     return
  //   }
  //   // Extract FieldData, SelectedRule, RuleStats from the found fieldData
  //   const {selected_rules, ...restFieldData} = fieldData[0]
  //   const selectedRule = selected_rules // Assuming you want the first rule

  //   // Reset error state
  //   setError(null)

  //   return navigate('/brms/action/statistics', {
  //     state: {
  //       fieldData: restFieldData,
  //       selectedRule: selectedRule,
  //       ruleStats: selectedRule[0].stats,
  //       fieldName: fieldName,
  //     },
  //   })
  // }

  const filteredResponse =
    postResponse && postResponse.length > 0
      ? postResponse.filter((fieldData) => {
          const fieldName = fieldData.fieldName?fieldData.fieldName:''
          return (fieldName&&fieldName!=='')?fieldName.toLowerCase().includes(searchQuery.toLowerCase()):''
        })
      : []
      
  return (
    <div className='card'>
      <div className='card-header border-0'>
        <div className='card-title'>
          <div className='d-flex align-items-center position-relative my-1'>
            <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-5'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <input
              type='text'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className='form-control form-control-solid w-250px ps-13'
              placeholder='Search'
            />
          </div>
        </div>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button btn btn-light-primary me-3'
              table={data}
              filename='Business Rule Analytical Report'
              sheet='DataFieldReport'
              buttonText={
                <>
                  <KTIcon iconName='exit-up' className='fs-2' />
                  Export
                </>
              }
            />
          </div>
        </div>
      </div>
      <div className='card-body py-4 table-responsive'>
        
        <table id={data} className='table table-responsive align-middle table-row-dashed fs-6 gy-5'>
          <thead>
            <tr className='text-start text-muted fw-bold fs-7 text-uppercase gs-0'>
              <th className='min-w-125px'>Data Field</th>
              <th className='min-w-125px'>TOT Records</th>
              <th className='min-w-125px'>TOT UNIQE</th>
              <th className='min-w-125px'>UNIQE %</th>
              <th className='min-w-125px'>NULL Records</th>
              <th className='min-w-125px'>NULL %</th>
              <th className='min-w-100px text-center'>Actions</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-semibold'>
            {postDataSuccess &&
              postResponse.length > 0 &&
              (searchQuery === '' ? postResponse : filteredResponse).map((data, index) => (
                <tr key={index}>
                  <td className='align-items-center'>
                    <b>{data.fieldName?data.fieldName:''}</b>
                  </td>
                  <td>{data.totalNoOfRows?data.totalNoOfRows:''}</td>
                  <td>
                    <div className='badge py-3 px-4 fs-7 badge-light'>
                      {data.totalNoOfUniqueRows?data.totalNoOfUniqueRows:''}
                    </div>
                  </td>
                  <td>
                    <div className='badge py-3 px-4 fs-7 badge-light-success'>
                      {data.uniquePercentage?data.uniquePercentage:''}
                    </div>
                  </td>
                  <td>
                    <div className='badge py-3 px-4 fs-7 badge-light fw-bold'>
                      {data.totalNoOfNullRows?data.totalNoOfNullRows:''}
                    </div>
                  </td>
                  <td>
                    <div className='badge py-3 px-4 fs-7 badge-light-danger fw-bold'>
                      {data.nullPercentage?data.nullPercentage:''}
                    </div>
                  </td>
                  <td className='text-center'>
                    <button
                      className='btn btn-light btn-sm btn-active-light-primary me-2'
                      onClick={() => navigateToReportBreakdown(data.fieldName)}
                    >
                      View Breakdown
                    </button>
                    {/* <div className='dropdown'>
                    <span
                      className='btn btn-light btn-active-light-primary btn-flex btn-center btn-sm'
                      data-bs-toggle='dropdown'
                      data-bs-auto-close='outside'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Actions <i className='ki ki-bold-arrow-down fs-5 ms-1'></i>
                    </span>
                    <div className='dropdown-menu dropdown-menu-sm dropdown-menu-end'>
                      <ul className='navi navi-hover'>
                        <li className='navi-item'>
                          <span
                            className='navi-link'
                            onClick={() => navigateToReportBreakdown(data.fieldName)}
                          >
                            <span className='navi-icon'>
                              <i className='ki ki-bold-arrow-right'></i>
                            </span>
                            <span className='navi-text'>View</span>
                          </span>
                        </li>
                        <li className='navi-item'>
                          <span
                            className='navi-link'
                            onClick={() => navigateToReportStatistics(data.fieldName)}
                          >
                            <span className='navi-icon'>
                              <i className='ki ki-bold-arrow-right'></i>
                            </span>
                            <span className='navi-text'>Statistics</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>  */}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DataFieldReportTable
