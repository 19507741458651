import $ from 'jquery'
import { useNavigate } from 'react-router-dom';
import JNMCarouselComponent from '../JNM/components/JNMCarouselComponent';

const MoneyRemittanceIntroModal = (props) => {

  const navigate = useNavigate();

  const handleClick = () => {
    if (props.financesolutionoption === 'data-profiling') {
      navigate('/process/bpms/finance/remittance/profiling');
    } else if (props.financesolutionoption === 'data-enrichment') {
      navigate('/process/finance/banking/data-enrichment');
    }
  }
  
  return (
    <>
      <span data-bs-toggle='modal' data-bs-target='#kt_modal_JNM'></span>
      <div className='modal modal-lg fade' tabIndex={-1} id='kt_modal_JNM'>
        <div className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered h-auto modal-dialog mw-1000px'>
          <div className='modal-content rounded' style={{width: '100%'}}>
            <div className='modal-header justify-content-end border-0 pb-0'>
              <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
                <i className='ki-duotone ki-cross fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>  
            <div style={{paddingTop:'20px',paddingBottom:'20px', display:'flex',justifyContent:'center',alignItems:'center'}}>
            <JNMCarouselComponent />
            </div>
           
            <button type='reset' className='btn btn-primary me-3'
            data-bs-dismiss='modal'
             onClick={()=>navigate('/process/bpms/finance/remittance/connection')}>
          Procced
        </button>
          </div>
          </div>
        </div>
    </>
  )
}

export default MoneyRemittanceIntroModal
