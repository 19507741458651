import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const PieChartComponent: React.FC = () => {
  useEffect(() => {
    // Themes
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create("kt_amcharts_3", am4charts.PieChart);
       // Disable the AmCharts logo
chart.logo.disabled = true;

    // Add data
    chart.data = [
      { "category": "Male", "value": 6304 },
      { "category": "Female", "value": 5549 },
      { "category": "Unknown", "value": 1074 },
    ];

    // Set inner radius
    chart.innerRadius = am4core.percent(30);

    // Create pie series
    let series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "category";

    series.colors.list = [
      am4core.color("#67b7dc"), // Male - Red
      am4core.color("#8067dc"), // Female - Green
      am4core.color("#c767dc")  // Unknown - Blue
    ];

    // Add labels
    series.labels.template.disabled = true;
    series.ticks.template.disabled = true;

    // Enable legends
    chart.legend = new am4charts.Legend();

    return () => {
      chart.dispose(); // Cleanup on unmount
    };
  }, []);

  return <div id="kt_amcharts_3" style={{ width: "100%", height: "450px " }}></div>;
};

export default PieChartComponent;
