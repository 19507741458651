import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

const DatabaseConnOption = () => {
  const [selectedOption, setSelectedOption] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [state,setState] = useState({
    hostname:'',
    serverport:'',
    username:'',
    password:''
  })

  const [errors,setErrors] = useState({
    hostname:'',
    serverport:'',
    username:'',
    password:''
  })

  const connectionOptions = [
    {
      value: 'Oracle',
      title: 'Oracle',
      desc: 'Enable communication between the application and the Oracle database',
      fields: ['Server IP', 'Username', 'Password', 'Hostname'],
    },
    {
      value: 'MySql',
      title: 'MySQL',
      desc: 'Enable communication between the application and the MySQL database',
      fields: ['Connection Type', 'Username', 'Password', 'Hostname'],
    },
    {
      value: 'MSSQLServer',
      title: 'MSSQL Server',
      desc: 'Enable communication between the application and the MSSQL Server database',
      fields: ['Custom Field 1', 'Custom Field 2', 'Custom Field 3'],
    },
  ]

  const handleOptionChange = (value) => {
    setShowSuccess(false)
    setSelectedOption(value)
    // openModal()
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const openModal = () => {
    setModalOpen(true)
  }

  const openSweetAlert = () => {
    // e.preventDefault();

    Swal.fire({
      text: "Are you sure you want to cancel ?",
      icon: "warning",
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: "No,return",
      confirmButtonText: "Yes, Cancel it",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn  btn-light"
      }
    }).then(function(){
      const modalId = `upload_modal_${selectedOption}`
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
      if (modalTrigger) {
        ;(modalTrigger as any).click()
      }
      setModalOpen(false)
    },function(){
      return null
    })
  }

  const renderFormFields = () => {
    const selectedConnection = connectionOptions.find((option) => option.value === selectedOption)

    if (!selectedConnection) return null

    return (
      <form
        id='kt_modal_new_card_form'
        className='form fv-plugins-bootstrap5 fv-plugins-framework'
        action='#'
        data-select2-id='select2-data-kt_modal_new_card_form'
      >
        <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-semibold form-label mb-2'>Hostname</label>
          <div className='position-relative'>
            <input
              type='text'
              value={state.hostname}
              onChange={(e)=>{setState({...state,[e.target.name]:e.target.value});setErrors({...errors,[e.target.name]:''})}}
              className='form-control form-control-solid'
              placeholder='Enter host name'
              name='hostname'
            />
          </div>
          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
            {errors.hostname}
          </div>
        </div>
        <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-semibold form-label mb-2'>Server Port</label>
          <div className='position-relative'>
            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Enter server port'
              name='serverport'
              value={state.serverport}
              onChange={(e)=>{setState({...state,[e.target.name]:e.target.value});setErrors({...errors,[e.target.name]:''})}}
            />
          </div>
          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
            {errors.serverport}
          </div>
        </div>

        <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-semibold form-label mb-2'>Username</label>
          <div className='position-relative'>
            <input
              type='text'
              className='form-control form-control-solid'
              placeholder='Enter user name'
              name='username'
              value={state.username}
              onChange={(e)=>{setState({...state,[e.target.name]:e.target.value});setErrors({...errors,[e.target.name]:''})}}
            />
          </div>
          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
            {errors.username}
          </div>
        </div>

        <div className='d-flex flex-column mb-7 fv-row fv-plugins-icon-container'>
          <label className='required fs-6 fw-semibold form-label mb-2'>Password</label>
          <div className='position-relative'>
            <input
              type='password'
              className='form-control form-control-solid'
              placeholder='Enter password'
              name='password'
              value={state.password}
              onChange={(e)=>{setState({...state,[e.target.name]:e.target.value});setErrors({...errors,[e.target.name]:''})}}
            />
          </div>
          <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
            {errors.password}
          </div>
        </div>

        <div className='text-center pt-10'>
          <button
            type='reset'
            id='kt_modal_new_card_cancel'
            className='btn btn-light me-3'
            onClick={openSweetAlert}
          >
            Discard
          </button>
          <button
            type='button'
            id='kt_modal_new_card_submit'
            className='btn btn-primary'
            onClick={showSuccessCheck}
          >
            <span className='indicator-label'>Submit</span>
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </div>

        {/* {selectedConnection.fields.map((field) => (
          <div key={field} className='mb-3 mt-3'>
            <label htmlFor={field} className='form-label'>
              {field}
            </label>
            <input type='text' className='form-control' id={field} />
          </div>
        ))}
        <div className='text-end'>
          {' '}
          <button type='button' className='btn btn-primary' onClick={showSuccessCheck}>
            Connect
          </button>
        </div> */}
      </form>
    )
  }

  const showSuccessCheck = () => {
    const fields: string[] = ['hostname', 'serverport', 'username', 'password'];
    const newErrors: any = {};
  
    for (const field of fields) {
      if (state[field] === '') {
        newErrors[field] = `${field} is required`;
      }
    }
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      setErrors({
        hostname: '',
        serverport: '',
        username: '',
        password: '',
      });
      setShowSuccess(true);
    }
  };

  
  useEffect(() => {
    if (modalOpen) {
      const modalId = `upload_modal_${selectedOption}`
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
      if (modalTrigger) {
        ;(modalTrigger as any).click()
      }
    }
  }, [modalOpen, selectedOption])
const navigate = useNavigate();
  useEffect(()=>{
    if(showSuccess)
    {
      Swal.fire({
        text: "Connected Successfully !",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "btn btn-primary",
        }
      }).then(function(){
        const modalId = `upload_modal_${selectedOption}`
        const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
        if (modalTrigger) {
          ;(modalTrigger as any).click()
        }
        setModalOpen(false)
        navigate('/process/bpms/finance/remittance/profiling')
      })
    }
  },
  [showSuccess])

  return (
    <>
      <div className='row mx-auto w-100 py-10 fv-plugins-bootstrap5 fv-plugins-framework'>
        {connectionOptions.map((option) => (
          <div key={option.value} className='col-lg-4'>
            <input
              type='radio'
              className='btn-check'
              name='db_conn_name'
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => handleOptionChange(option.value)}
              id={`kt_db_conn_${option.value}`}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10'
              htmlFor={`kt_db_conn_${option.value}`}
            >
              <i className='ki-duotone ki-fasten fs-3x pe-3'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <span className='d-block fw-semibold text-start'>
                <span className='text-dark fw-bold d-block fs-4 mb-2'>{option.title}</span>
                <span className='text-muted fw-semibold fs-6'>{option.desc}</span>
              </span>
            </label>
            <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
          </div>
        ))}
      </div>
      <div className='d-flex flex-stack pt-0 pb-8'>
                <div
                  style={{
                    marginLeft: 'auto',
                    ...(window.innerWidth > 768 ? {paddingRight: '28px'} : {paddingRight: '15px'}),
                  }}
                >
                  <button
                    type='button'
                    disabled={selectedOption===''?true:false}
                    className={`btn btn-lg ${selectedOption===''?'btn-light': 'btn-primary'}`}
                    onClick={() => {
                      openModal()
                      setErrors({hostname:'',serverport:'',username:'',password:''})
                    }}
                  >
                    Continue
                    <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </button>
                  {/* <SweetAlertExample /> */}
                </div>
              </div>

      {/* {modalOpen && ( */}
        <>
          <span data-bs-toggle='modal' data-bs-target={`#upload_modal_${selectedOption}`}></span>
          <div className='modal fade' tabIndex={-1} id={`upload_modal_${selectedOption}`}>
            <div
              className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered mw-650px'
              onClick={(e) => e.stopPropagation()}
            >
              <div className='modal-content' style={{width: '100%'}}>
                <div className='modal-header py-3'>
                  <h5 className='modal-title'>{selectedOption}</h5>
                  <div
                    className='btn btn-icon btn-active-light-primary ms-2'
                    data-bs-dismiss='modal'
                    aria-label='Close'
                    onClick={closeModal} 
                  >
                    <i className='ki-duotone ki-cross fs-2x'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </div>
                </div>
                <div className='modal-body scroll-y mx-5 mx-xl-15 my-3'>
                  {/* {showSuccess ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <SuccessCheck />
                      <div style={{paddingTop: '20px'}}>
                        {`Connected to ${selectedOption} successfully!`}
                      </div>
                      <div className='d-flex flex-center flex-wrap'>
                        <a
                          href='#'
                          className='btn btn-outline btn-outline-danger btn-active-danger m-2'
                        >
                          Cancel
                        </a>
                        <a href='#' className='btn btn-danger m-2'>
                          Ok, I got it
                        </a>
                      </div>
                    </div>
                  ) : ( */}
                    {renderFormFields()}
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        </>
      {/* )} */}
    </>
  )
}

export default DatabaseConnOption
