import React, {Fragment, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import ProfilingDataCard from './component/ProfilingDataCard'
import {useNavigate} from 'react-router-dom'
import RuleLoader from '../../pages/data/trad/brms/RuleLoader'

const Data_Enrich_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Business Process Management Process',
    path: '/process/bpms',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Finance',
    path: '/process/bpms/finance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Money Remittance',
    path: '/process/bpms/finance/remittance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface Rule {
  Id: string
  rule: string
  desc: string
}

interface props {
  selectedColumn: string | any
  setSelectedColumn: (params: {column: string}) => void
  fetchColumnRules: (params: {db: string; table: string; column: string}) => void
  setEnrichmentRules: (params: any) => void

  reportState: any
  selectedTable: string
  BusinessRules: any
  modalClicked: any
  selectedEnrichmentFieldData: any

  appendSelectedRules: (
    selectedColumn: string,
    selectedRules: string[],
    selectedRuleIds: string[]
  ) => void

  selectedRulesHistory: {
    column: string
    ruleIds: string[]
    rules: string[]
  }[]

  applyDataEnrichment: (string, apiData: any) => void
  columnRules?: {
    [key: string]: {
      rules: Rule[]
    }
  }
}

const EnrichmentRules: React.FC<props> = (props) => {
  const navigate = useNavigate()

  const [isScrollable, setIsScrollable] = useState(false)
  const [selectedruleforenrichment, setSelectedRuleforenrichment] = useState<any>([])
  const [modalopen, setModalOpen] = useState(false)

  // const handleRuleSelection = (selectedRule) => {

  //   if (selectedruleforenrichment.length) {

  //     const ruleIndex = selectedruleforenrichment.findIndex((item) => item.Id === selectedRule.Id)

  //     if (ruleIndex !== -1) {
  //       // If the rule is already selected, remove it
  //       setSelectedRuleforenrichment((prevState) =>
  //         prevState.filter((_, index) => index !== ruleIndex)
  //       )
  //     } else {
  //       // Otherwise, add the rule
  //       setSelectedRuleforenrichment((prevState) => [...prevState, selectedRule])
  //     }

  //   } else setSelectedRuleforenrichment((prevState) => [...prevState, selectedRule])

  //   console.log('RRR : ', selectedruleforenrichment, props.BusinessRules.rules)
  // }

  const handleRuleSelection = (selectedRule) => {
    const isSelected = selectedruleforenrichment.some((rule) => rule.Id === selectedRule.Id)
    setSelectedRuleforenrichment((prevState) =>
      isSelected
        ? prevState.filter((rule) => rule.Id !== selectedRule.Id)
        : [...prevState, selectedRule]
    )
  }

  const handleSubmit = () => {
    setModalOpen(true)

    props.setEnrichmentRules(selectedruleforenrichment)

    const timerId = setTimeout(() => {
      setModalOpen(false)

      navigate('/process/bpms/finance/remittance/enrichment/preview')
    }, 5000) // 5 seconds

    return () => clearTimeout(timerId)
  }

  // useEffect(() => {
  //   if (modalopen) {
  //     const modalId = `loader_modal`
  //     const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
  //     if (modalTrigger) {
  //       ;(modalTrigger as any).click()
  //     }
  //   }
  // }, [modalopen])

  const closeModal = () => {
    setModalOpen(false)
  }

  useEffect(() => {
    const appliedRulesSection = document.getElementById('kt_enrich_rules_scr')
    if (appliedRulesSection) {
      const currentHeight = appliedRulesSection.scrollHeight
      setIsScrollable(currentHeight >= 275)
    }
  }, [])

  useEffect(() => {
    if (
      props.selectedEnrichmentFieldData &&
      props.selectedEnrichmentFieldData.selectedEnrichmentRule &&
      props.selectedEnrichmentFieldData.selectedEnrichmentRule.length
    )
      setSelectedRuleforenrichment(props.selectedEnrichmentFieldData.selectedEnrichmentRule)
  }, [props.selectedEnrichmentFieldData])

   console.log("SELECTED_RULE :: ",setSelectedRuleforenrichment)

  return (
    <>
      <PageTitle breadcrumbs={Data_Enrich_Breadcrumbs}>Data Enrichment</PageTitle>

      <div className='d-flex flex-column flex-lg-row mb-0'>
        <div className='flex-column flex-lg-row-auto w-lg-260px w-xl-360px'>
          <div className='card mb-5 mb-xl-4' style={{maxWidth: '350px', overflowX: 'auto'}}>
            <ProfilingDataCard {...props} />
          </div>
        </div>

        <div className='flex-lg-row-fluid ms-lg-10'>
          <div className='card card-flush mb-4 mb-xl-2'>
            <div className='card-header mt-4'>
              <div className='card-title flex-column'>
                <h2 className='mb-1'>
                  {`Data Enrichment - ${
                    props?.selectedEnrichmentFieldData?.fieldName
                      ? props?.selectedEnrichmentFieldData?.fieldName
                      : ''
                  }`}
                </h2>
                <div className='fs-6 fw-semibold text-muted'>
                  <strong>
                    {props.BusinessRules &&
                    props.BusinessRules.rules &&
                    props.BusinessRules.rules.length !== undefined
                      ? props.BusinessRules.rules.length
                      : ''}{' '}
                    enrichment rules
                  </strong>{' '}
                  available for above data field
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div
                id='kt_enrich_rules_scr'
                className=''
                style={{maxHeight: '275px', overflowY: 'auto'}}
              >
                {/* {props.BusinessRules &&
                  props.BusinessRules.rules &&
                  props.BusinessRules.rules.map((r) => {
                    const isChecked =
                      (selectedruleforenrichment &&
                        selectedruleforenrichment.some(
                          (item) =>  item.Id === r.Id
                        ))
                    return (
                      <Fragment key={r.Id}>
                        <div className='d-flex'>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input me-3'
                              name='email_notification_0'
                              type='checkbox'
                              value='0'
                              id='kt_modal_update_email_notification_0'
                              checked={isChecked}
                              onChange={() => handleRuleSelection(r)}
                            />
                            <label className='form-check-label' htmlFor='kt_enrich_rules_page'>
                              <div className='fw-bold'>{r.name}</div>
                              <div className='text-gray-600'>{r.desc}</div>
                            </label>
                          </div>
                        </div>
                        <div className='separator separator-dashed my-5'></div>
                      </Fragment>
                    )
                  })} */}

                {props.BusinessRules?.rules?.map((rule) => (
                  <Fragment key={rule.Id}>
                    <div className='d-flex'>
                      <div className='form-check form-check-custom form-check-solid'>
                        <input
                          className='form-check-input me-3'
                          type='checkbox'
                          checked={selectedruleforenrichment.some(
                            (selectedRule) => selectedRule.Id === rule.Id
                          )}
                          onChange={() => handleRuleSelection(rule)}
                        />
                        <label className='form-check-label' htmlFor='kt_enrich_rules_page'>
                          <div className='fw-bold'>{rule.name}</div>
                          <div className='text-gray-600'>{rule.desc}</div>
                        </label>
                      </div>
                    </div>
                    <div className='separator separator-dashed my-5'></div>
                  </Fragment>
                ))}
              </div>

              <div className='d-flex justify-content-end align-items-center mt-12'>
                <button
                  type='button'
                  className='btn btn-light me-5'
                  id='kt_enrichment_rules_submition_cancel'
                  onClick={() =>
                    navigate('/process/bpms/finance/remittance/profiling/report/breakdown', {
                      state: {
                        fieldData: props?.reportState?.fieldData,
                        selectedRule: props?.reportState?.selectedRule,
                        ruleStats: props?.reportState?.selectedRule[0].stats,
                        fieldName: props?.reportState?.fieldName,
                      },
                    })
                  }
                >
                  Back
                </button>
                <button
                  type='button'
                  disabled={selectedruleforenrichment.length > 0 ? false : true}
                  className='btn btn-primary'
                  id='kt_enrichment_rules_submition'
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  <span className='indicator-label'>Apply Rules</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalopen && (
        <div
          className='modal fade show'
          tabIndex={-1}
          role='dialog'
          style={{
            display: 'flex',
            width: '100vw',
            background: 'transparent',
            boxShadow: 'none',
            border: 'none',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <div className='modal-backdrop fade show'></div> */}
          <div
            style={{
              width: '100%',
              background: 'transparent',
              boxShadow: 'none',
              border: 'none',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className='d-flex flex-column justify-content-center align-items-center modal-dialog modal-dialog-centered modal-dialog mw-1000px h-100vh'
          >
            <div
              className='modal-content'
              style={{
                width: '100%',
                height: '100%',
                background: 'transparent',
                boxShadow: 'none',
                border: 'none',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className='modal-header'>
                {/* Optionally, you can include a close button in the header */}
                {/* <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={() => setShowmodal(false)}></button> */}
              </div>
              <div className='modal-body'>
                <RuleLoader />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default EnrichmentRules
