import React from 'react'
import {useNavigate} from 'react-router-dom'
import archivalProcessFlow from '../../../../../src/_metronic/assets/myimages/ArchivalProcess.png'

const ArchivalTableInfo: React.FC<any> = (props) => {
  const navigate = useNavigate()

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        {props?.tableInfo ? (
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Table Statistics</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              Calculate table statistics data and fetch archival retention policy details
            </span>
          </h3>
        ) : (
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>High-level Architecture</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Data Archival Process Flow</span>
          </h3>
        )}
      </div>
      <div className='separator border-primary my-4 mt-2'></div>
      <div className='card-body py-3'>
        <div className='row'>
          {props?.tableInfo ? (
            <div className='col-lg-6'>
              <>
                <div className='d-flex align-items-center mb-3'>
                  <span
                    data-kt-element='bullet'
                    className='bullet bullet-vertical d-flex align-items-center min-h-50px mh-100 me-4 bg-secondary'
                  ></span>
                  <div className='mb-4'>
                    <div className='flex-grow-1 me-5'>
                      <div className='text-gray-600 fw-semibold fs-3'>Table Name</div>
                      <div className='text-gray-700 fw-semibold fs-6'>
                        <div className='fs-4 fw-bold counted'>{props?.tableInfo?.tableName}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-3'>
                  <span
                    data-kt-element='bullet'
                    className='bullet bullet-vertical d-flex align-items-center min-h-50px mh-100 me-4 bg-secondary'
                  ></span>
                  <div className='mb-4'>
                    <div className='flex-grow-1 me-5'>
                      <div className='text-gray-600 fw-semibold fs-2'>Total Records</div>
                      <div className='text-gray-700 fw-semibold fs-6'>
                        <div
                          className='fs-4 fw-bold counted'
                          data-kt-countup='true'
                          data-kt-countup-value={props?.tableInfo?.totalNoOfRecords}
                          data-kt-countup-prefix=''
                          data-kt-initialized='1'
                        >
                          {Number(props?.tableInfo?.totalNoOfRecords).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-3'>
                  <span
                    data-kt-element='bullet'
                    className='bullet bullet-vertical d-flex align-items-center min-h-50px mh-100 me-4 bg-secondary'
                  ></span>
                  <div className='mb-4'>
                    <div className='flex-grow-1 me-5'>
                      <div className='text-gray-600 fw-semibold fs-3'>Records From</div>
                      <div className='text-gray-700 fw-semibold fs-6'>
                        <div className='fs-4 fw-bold counted'>{props?.tableInfo?.fromYears}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center mb-3'>
                  <span
                    data-kt-element='bullet'
                    className='bullet bullet-vertical d-flex align-items-center min-h-50px mh-100 me-4 bg-secondary'
                  ></span>
                  <div className='mb-4'>
                    <div className='flex-grow-1 me-5'>
                      <div className='text-gray-600 fw-semibold fs-3'>Records To</div>
                      <div className='text-gray-700 fw-semibold fs-6'>
                        <div className='fs-4 fw-bold counted'>{props?.tableInfo?.toYears}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          ) : (
            <img src={archivalProcessFlow} alt='archivalProcessFlow' style={{ maxWidth: '100%', height: '342px' }} />
          )}
          {props?.tableInfo && (
            <div className='col-lg-6'>
              <div className='d-flex align-items-center mb-3'>
                <span
                  data-kt-element='bullet'
                  className='bullet bullet-vertical d-flex align-items-center min-h-50px mh-100 me-4 bg-primary'
                ></span>
                <div className='mb-4'>
                  <div className='flex-grow-1 me-5'>
                    <div className='text-gray-600 fw-semibold fs-3'>Retention Periods</div>
                    <div className='text-gray-700 fw-semibold fs-6'>
                      <div className='fs-4 fw-bold counted'>
                        {props?.tableInfo?.retentionYear} Years
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex align-items-center mb-3'>
                <span
                  data-kt-element='bullet'
                  className='bullet bullet-vertical d-flex align-items-center min-h-50px mh-100 me-4 bg-primary'
                ></span>
                <div className='mb-4'>
                  <div className='flex-grow-1 me-5'>
                    <div className='text-gray-600 fw-semibold fs-2'>Retention Records</div>
                    <div className='text-gray-700 fw-semibold fs-6'>
                      <div
                        className='fs-4 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value={props?.tableInfo?.retentionRecords}
                        data-kt-countup-prefix=''
                        data-kt-initialized='1'
                      >
                        {Number(props?.tableInfo?.retentionRecords).toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center mb-3'>
                <span
                  data-kt-element='bullet'
                  className='bullet bullet-vertical d-flex align-items-center min-h-50px mh-100 me-4 bg-primary'
                ></span>
                <div className='mb-4'>
                  <div className='flex-grow-1 me-5'>
                    <div className='text-gray-600 fw-semibold fs-3'>Approx. Estimate Time</div>
                    <div className='text-gray-700 fw-semibold fs-4'>
                      {props?.tableInfo?.estimateTimeValue}{' '}
                      {props?.tableInfo?.estimateTimeMeasuredIn}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='m-4'>
          {props.tableInfo && (
            <button
              className='btn btn-light-primary me-3 w-100'
              onClick={() => navigate('/data/archival/confirmation')}
            >
              {'Continue'}
            </button>
          )}
        </div>
    </div>
  )
}
export default ArchivalTableInfo
