import {BrowserRouter as Router, Route, Link, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const data_archival_summary_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intelligent Analytics',
    path: '/data/quality',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ArchivalExecutionReport = (props) => {
  const navigate = useNavigate()

  return (
    <>
      <PageTitle breadcrumbs={data_archival_summary_Breadcrumbs}>Data Archival Summary</PageTitle>
      <div className='card mb-4 mb-xl-4' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Execution Report</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='mb-6 notice d-flex bg-light-success rounded border-success border border-dashed mb-2 p-6'>
            <i className='ki-duotone ki-double-check fs-2tx text-success me-4'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-semibold'>
                <h4 className='text-gray-900 fw-bold'>Data Archival Submission Successful!. </h4>
                <div className='fs-6 text-gray-700'>
                  Your data has been successfully submitted for archival processing.
                  <strong> Here's a summary of the execution report:</strong>
                </div>
              </div>
            </div>
          </div>
         
          {props?.dataExecution && (
            <>
               <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Archival Task Id</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {' '}
                    {props?.dataExecution?.archivalTaskId}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Archival Task Name</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataExecution?.archivalTaskName}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Archival Table Name</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataExecution?.archivalTableName}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Archived Record Count</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataExecution?.archivedRecordCount}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Estimate TimeMeasured In</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataExecution?.estimateTimeMeasuredIn}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Estimate Time Value</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataExecution?.estimateTimeValue}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Execution Start Time</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {' '}
                    {props?.dataExecution?.executionStartTime}
                  </span>
                </div>
              </div>

              {/* <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Execution End Time</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {' '}
                    {props?.dataExecution?.executionEndTime}
                  </span>
                </div>
              </div> */}

            </>
          )}
        </div>
      </div>
      <>
        <div className='d-flex justify-content-end align-items-center mt-2'>
          <button
            type='button'
            className='btn btn-sm btn-light-primary'
            onClick={() => navigate('/data/archival')}
          >
            <i className='ki-duotone ki-arrow-left fs-2 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            Redirect to Home
          </button>
        </div>
      </>
    </>
  )
}

export default ArchivalExecutionReport
