import {useIntl} from 'react-intl'
//import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
// import pdf from '../../../../../app/modules/charts/Exec-Summary.pdf'
import {SidebarSub} from './SidebarSub'
import {KTIcon} from '../../../../helpers'
import {BOTZILLA_URL, BOTZILLA_VERSION} from '../../../../../app/pages/data/trad/brms/Config'

const SidebarMenuMain = () => {
  const intl = useIntl()

  // const openPDFInNewWindow = () => {
  //   const newWindow = window.open(pdf, '_blank', 'fullscreen=yes')
  //   if (newWindow) {
  //     newWindow.focus()
  //   }
  // }

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
        //
      />

      {/* {/ <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' /> /} */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Data Intelligence
          </span>
        </div>
      </div>
      {/* <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      > */}

      <SidebarMenuItemWithSub
        to='/pages/data'
        title='Intelligent Data'
        fontIcon='abstract-26'
        icon='abstract-26'
      >
        {/* {/ <SidebarMenuItem to='/brms' title='Business Rule Solution' hasBullet={true} /> /} */}
        <SidebarMenuItem icon='data' to='/data/quality' title='Data Quality Solution' />
        {/* <SidebarMenuItem to='/data/operation' title='Data Profiling Solution' hasBullet={true} /> */}
        <SidebarMenuItem icon='save-2' to='/data/archival' title='Data Archival Solution' />
        <SidebarMenuItem icon='abstract-22' to='/data/masked' title='Data Masked Solution' />
        <SidebarMenuItem icon='abstract-12' to='#' title='Data Governance Solution' />

        {/* <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} /> 
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          /> 
           <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          /> 
        </SidebarMenuItemWithSub> */}

        {/* <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub> */}
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/intelligent-analytics'
        title='Intelligent Analytics'
        fontIcon='chart-line-up'
        icon='chart-line-up'
      >
        <SidebarSub to='/pages/data' title='Banking Finance' fontIcon='bi-archive' icon='bank'>
          <SidebarMenuItem
            to='/insight/demographics'
            title='Customer Demographics'
            hasBullet={true}
          />

          <SidebarMenuItem
            to='/insight/inbound-trans'
            title='In Bound Transactions'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/insight/outbound-trans'
            title='Out Bound Transactions'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/insight/ytd-transfer'
            title='Transactions by Year'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/insight/trans-by-country'
            title='Transaction types by Country'
            hasBullet={true}
          />
        </SidebarSub>

        <SidebarSub to='/pages/data' title='Healthcare' fontIcon='bi-archive' icon='pulse'>
          <SidebarMenuItem
            to='/healthcare/pre-existing'
            title='Pre-existing Condition'
            hasBullet={true}
          />
          <SidebarMenuItem to='/healthcare/stays' title='Hospital stays' hasBullet={true} />
          <SidebarMenuItem to='/healthcare/claims' title='Number of Claims' hasBullet={true} />
          <SidebarMenuItem to='/healthcare/diagnosis' title='Total diagnosis' hasBullet={true} />
          <SidebarMenuItem to='/healthcare/procedures' title='Total Procedures' hasBullet={true} />
        </SidebarSub>
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub
        to='/pages/data'
        title='Cognitive Analytics'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/claims-fraudulent' title='Claims Fraudulent' hasBullet={true} />
        <SidebarMenuItem to='/rfm-analysis' title='RFM Analysis' hasBullet={true} />
        <SidebarMenuItem to='/customer-churn' title='Customer Churn' hasBullet={true} />
        <SidebarMenuItem to='#' title='Revenue Forecast' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/dashboard'
        icon='frame'
        title='Executive Summary'
        fontIcon='bi-app-indicator'
      />

      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      {/* <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      > */}
      {/* {/ <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} /> /}
      {/ <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} /> /}
      {/ <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} /> /}
      {/ <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} /> /}
      {/ <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} /> /}
      {/ <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} /> /}
      {/ </SidebarMenuItemWithSub> /} */}

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Libraries</span>
        </div>
      </div>
 
      <SidebarMenuItem icon='data' to='/data/quality' title='Data Quality' />
      <SidebarMenuItem icon='save-2' to='/data/archival' title='Data Archival' />
      <SidebarMenuItem icon='abstract-22' to='/data/masked' title='Data Masking' />
      <SidebarMenuItem icon='text-align-center' to='/self-service' title={'Self Service Analytics'} />
      <SidebarMenuItem icon='size' to='#' title={'Image Reader'} />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Business Process Solution
          </span>
        </div>
      </div>

      {/* <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItemWithSub to='/' icon='abstract-28' title='BPMS' fontIcon='bi-layers'>
        {/* <SidebarMenuItemWithSub to='' title='Users' hasBullet={true}>
          <SidebarMenuItem to='/apps/user-management/users' title='User List' hasBullet={true} />
          <SidebarMenuItem
            to='/apps/user-management/users/view'
            title='View User'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub> */}

        {/* <SidebarMenuItem to='/process/finance/banking' title='Banking Solution' hasBullet={true} /> */}
        <SidebarMenuItem to='/process/bpms/finance' title='Finance' hasBullet={true} />
        <SidebarMenuItem to='/process/bpms/insurance' title='Insurance' hasBullet={true} />
        <SidebarMenuItem to='/process/bpms/construction' title='Construction' hasBullet={true} />
        <SidebarMenuItem to='/process/bpms/manufacturing' title='Manufacturing' hasBullet={true} />
        {/* {/ <SidebarMenuItem to='/bpms' title='Business Process Solution' hasBullet={true} /> /} */}

        {/* <SidebarMenuItemWithSub to='/fin/banking' icon='abstract-28' title='Banking Solution' fontIcon='bi-layers'>
      
        <SidebarMenuItem to='' title='Money Remittance Process' hasBullet={true} />
        <SidebarMenuItem to='' title='Customer Onboarding and Account Management' hasBullet={true} />
        <SidebarMenuItem to='' title='Credit Scoring and Loan Approval' hasBullet={true} />
        <SidebarMenuItem to='' title='Fraud Detection and Security' hasBullet={true} />
        <SidebarMenuItem to='' title='Risk Management and Compliance' hasBullet={true} />
        <SidebarMenuItem to='' title='Payments and Transactions Processing' hasBullet={true} />
        <SidebarMenuItem to='' title='Customer Relationship Management' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      </SidebarMenuItemWithSub>

      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL} rel="noreferrer"
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}

      <div className='menu-item'>
        <a target='_blank' className='menu-link' href={BOTZILLA_URL} rel='noreferrer'>
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>BotZilla {BOTZILLA_VERSION}</span>
        </a>
      </div>
    </ >
  )
}

export default SidebarMenuMain
