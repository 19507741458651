import React from 'react';
import demograph from '../../../../../_metronic/assets/myimages/demograph.png';

const CustomerDemographic = () => {
  return (
    <>
    <div className='card mt-0'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Customer Demograph</span>
        </h3>
      </div>
      <div className='card-body' style={{ height: '500px', overflowY: 'auto' }}>
        <img src={demograph} alt="customerchum" style={{ maxWidth: '100%' }} />
      </div>
    </div>
    </>
  );
};

export default CustomerDemographic;
