import React, {useEffect, useState} from 'react'

// Define the business rules as key-value pairs
const businessRules: {[key: string]: string} = {
  'Profitability and Compliance Optimization':
    'AI/ML models to optimize profitability while ensuring compliance with regulatory standards in money remittance transactions.',
  'Anti-Money Laundering (AML) Compliance Check':
    'AI/ML models to conduct real-time AML checks on all transactions.',
  'Transaction Amount Limit':
    'AI/ML models to enforce transaction amount limits based on regulatory guidelines.',
  'Currency Exchange Rate Optimization':
    'AI/ML models to regularly analyze currency exchange rate trends and adjust rates dynamically to maximize profitability.',
  'Dynamic Fee Adjustments':
    'AI/ML models to implement dynamic fee structures that adjust based on factors such as transaction size, frequency, and risk level.',
  'Transaction Review Based on Customer Behavior':
    'AI/ML models to analyze customer transaction behavior patterns. If a customer\'s behavior deviates significantly from their historical patterns, conduct a review to ensure compliance and prevent potential fraudulent activities.',
  'Customer Risk Profiling':
    'AI/ML models to implement a risk-based approach to customer profiling, categorizing customers based on their risk level.',
  'Transaction Profitability Threshold':
    'AI/ML models to conduct a cost-benefit analysis to determine whether processing the transaction aligns with business goals, especially if the potential profit margin for a transaction falls below a defined threshold.',
};


interface Rule {
  Id: string
  desc: string
}

interface Props {
  dbStructureSelectedSection: string | any
  selectedOption: string | any
  selectedColumn: string | any
  isFetchingRulesFailed: boolean
  fetchingRules: boolean
  appendSelectedRules: (
    selectedColumn: string,
    selectedRules: string[],
    selectedRuleIds: string[]
  ) => void
  selectedRulesHistory: {
    column: string
    ruleIds: string[]
    rules: string[]
  }[]
  columnRules?: {
    [key: string]: {
      rules: Rule[]
    }
  }
}

const RuleBookStructure: React.FC<Props> = (props) => {
  const [selectedRules, setSelectedRules] = useState<string[]>([])
  const [selectedRuleIds, setSelectedRuleIds] = useState<string[]>([])
  const [isCollapsed, setIsCollapsed] = useState(true)
  // const [isVisible, setIsVisible] = useState(true)

  console.log('#toggleRule1 : ', props.selectedOption)
  console.log('#toggleRule2 : ', props.dbStructureSelectedSection)

  useEffect(() => {
    // This code will run on page render
    //setIsVisible(false) // Set isVisible to false to hide the element
  }, []) // Empty dependency array ensures that the effect runs only once on mount

  const toggleRule = (rule: Rule) => {
    const isSelected = selectedRuleIds.includes(rule.Id)
    if (isSelected) {
      setSelectedRuleIds((prevSelectedRuleIds) =>
        prevSelectedRuleIds.filter((selectedRule) => selectedRule !== rule.Id)
      )
      setSelectedRules((prevSelectedRules) =>
        prevSelectedRules.filter((selectedRule) => selectedRule !== rule.desc)
      )
    } else {
      setSelectedRuleIds((prevSelectedRuleIds) => [...prevSelectedRuleIds, rule.Id])
      setSelectedRules((prevSelectedRules) => [...prevSelectedRules, rule.desc])
    }
  }

  useEffect(() => {
    if (props.selectedColumn) {
      // Check if the selectedRules or selectedRuleIds have changed
      const rulesChanged =
        JSON.stringify(selectedRules) !==
        JSON.stringify(props.selectedRulesHistory?.[props.selectedRulesHistory.length - 1]?.rules)
      const idsChanged =
        JSON.stringify(selectedRuleIds) !==
        JSON.stringify(props.selectedRulesHistory?.[props.selectedRulesHistory.length - 1]?.ruleIds)

      // Only call appendSelectedRules if there are changes
      if (rulesChanged || idsChanged) {
        props.appendSelectedRules(props.selectedColumn, selectedRules, selectedRuleIds)
      }
    }
  }, [props.selectedColumn, selectedRuleIds])

  useEffect(() => {
    // Reset selected rules when a new column is selected

    const index = props.selectedRulesHistory.filter((i) => i.column === props.selectedColumn)
    if (index.length !== 0) {
      let i = props.selectedRulesHistory.findIndex((i) => i.column === props.selectedColumn)
      setSelectedRules(props.selectedRulesHistory[i].rules)
      setSelectedRuleIds(props.selectedRulesHistory[i].ruleIds)
    } else {
      setSelectedRules([])
      setSelectedRuleIds([])
    }
  }, [props.selectedColumn])

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        {props.isFetchingRulesFailed && <div style={{color: 'red'}}>Something went wrong</div>}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Rules Book</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            More than 50 new business rules added
          </span>
        </h3>
      </div>
      <div className='separator border-primary my-4 mt-2'></div>
      <div className='card-body py-3' style={{height: '415px', overflowY: 'auto'}}>
        {(props.selectedColumn ||(props?.selectedOption?.section === "BusinessRuleSection")) && (
          <div className='m-0' id='ruleBook'>
            <div
              className={`d-flex align-items-center collapsible toggle pb-2 ${
                isCollapsed ? 'show' : 'collapsed'
              }`}
              data-bs-toggle='collapse'
              data-bs-target='#kt_job_10_22'
            >
              <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              </div>
              {/* <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>{props.selectedColumn}</h4> */}

              {(props?.selectedOption?.section === "BusinessRuleSection") ? (
                <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                  {props.selectedOption.column}
                </h4>
              ) : (
                <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                  {props.selectedColumn}
                </h4>
              )}
            </div>

            {(props?.selectedOption?.section === "BusinessRuleSection") ? (
              <div
                id='kt_job_10_22'
                className={isCollapsed ? 'show fs-6 ms-1' : 'collapsed fs-6 ms-1'}
                style={{overflowY: 'auto', maxHeight: '300px'}} // Adjust maxHeight as needed
              >
                <div key={props.selectedOption.column} className='mb-4'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm ps-10'>
                    <span className='bullet bg-primary me-3'></span>
                    <label
                      className='form-check-label text-gray-600 fw-semibold fs-6'
                      htmlFor={`flexRadioLg_${props.selectedOption.column}`}
                    >
                      {businessRules[props.selectedOption.column]}
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div
                id='kt_job_10_22'
                className={isCollapsed ? 'show fs-6 ms-1' : 'collapsed fs-6 ms-1'}
                style={{overflowY: 'auto', maxHeight: '300px'}} // Adjust maxHeight as needed
              >
                {props.fetchingRules
                  ? 'Loading Rules'
                  : props?.columnRules &&
                    props?.columnRules[props.selectedColumn || ''] &&
                    props?.columnRules[props.selectedColumn || ''].rules.map((rule) => (
                      <div key={rule.Id} className='mb-4'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm ps-10'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id={`flexRadioLg_${rule.Id}`}
                            checked={selectedRuleIds.includes(rule.Id)}
                            onChange={() => toggleRule(rule)}
                          />
                          <label
                            className='form-check-label text-gray-600 fw-semibold fs-6'
                            htmlFor={`flexRadioLg_${rule.Id}`}
                          >
                            {rule.desc}
                          </label>
                        </div>
                      </div>
                    ))}
              </div>
            )}
          </div>
        )}
      </div>
      
    </div>
  )
}

export default RuleBookStructure
