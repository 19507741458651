import {Link, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const data_archival_conf_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intelligent Analytics',
    path: '/data/quality',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ArchivalTableDataPreview = (props) => {
  const navigate = useNavigate()

  const executeRules = () => {

    const schemaName = 'datanxzen-QA'
    const tableName =props?.tableInfo?.tableName
    const retentionYear = props?.tableInfo?.retentionYear
    const product = 'datanxzen'
    // const tableName = 'PX3000_DEMO_PERF_TEST'

    props.getDataExecution({
      product,
      schemaName,
      tableName,
      retentionYear,
    })
    return navigate('/data/archival/execution-summary')
  }

  return (
    <>
      <PageTitle breadcrumbs={data_archival_conf_Breadcrumbs}>Data Archival Confirmation</PageTitle>

      <div className='card mb-4 mb-xl-4' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Data Retention Content Confirmation </h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='notice d-flex bg-light-info rounded border-info border border-dashed mb-10 p-4'>
            {/* Icon */}
            <i className='ki-duotone ki-information fs-2tx text-info me-3'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
            </i>
            {/* Wrapper */}
            <div className='d-flex flex-stack flex-grow-1'>
              {/* Content */}
              <div className='mb-3 mb-md-0 fw-semibold'>
                <div className='fs-6 text-gray-700'>
                  <span className='text-gray-900 fw-bold fs-3'>Need Attention ! </span>Before
                  proceeding with the data archival process, we want to inform you about the
                  potential impact on your database records.
                  <br></br>
                  <div className='bullet w-8px h-6px rounded-2 bg-info me-3'></div>
                  <strong>Impact:</strong> Some older records may be removed, so review critical
                  data before proceeding.
                  <br></br>
                  <div className='bullet w-8px h-6px rounded-2 bg-info me-3'></div>
                  <strong>Retention:</strong> We follow strict policies to protect your data, but
                  let us know if you have specific requirements.
                </div>
              </div>
            </div>
          </div>
          {props?.tableInfo ? (
            <>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Table Name</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{props?.tableInfo?.tableName}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>TotalNoOfRecords</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.tableInfo?.totalNoOfRecords}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>From</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{props?.tableInfo?.fromYears}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>To</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>{props?.tableInfo?.toYears}</span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Retention Records</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.tableInfo?.retentionRecords}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Estimate Time Value</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.tableInfo?.estimateTimeValue}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Estimate TimeMeasured In</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.tableInfo?.estimateTimeMeasuredIn}
                  </span>
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <>
        <div className='d-flex justify-content-end align-items-center mt-4'>
          <button
            type='button'
            className='btn btn-sm btn-primary me-2' // Add margin to the right
            onClick={() => navigate('/data/archival')}
          >
            <i className='ki-duotone ki-arrow-left fs-2 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            Back
          </button>

          <button type='button' className='btn btn-sm btn-primary' onClick={executeRules}>
            Execute
            <i className='ki-duotone ki-arrow-right fs-2 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </button>
        </div>

        {/* <div className='d-flex justify-content-end mt-auto'>
          <button className='btn btn-primary  me-3' onClick={() => navigate('/data/archival')}>
            <div className='me-3 rotate-90'>
              <i className='ki-duotone ki-left fs-3'></i>
              <span className='me-3'> Back </span>
            </div>
          </button>
          <button className='btn btn-primary me-3 align-self-center' onClick={executeRules}>
            Execute
          </button>
        </div> */}
      </>
    </>
  )
}

export default ArchivalTableDataPreview
