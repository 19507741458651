import React, {useEffect, useState} from 'react'
import DataFieldReportTable from './DataFieldReportTable'

import {useNavigate} from 'react-router-dom'
import Loader from './Loader'
import { PageLink, PageTitle } from '../../../../../_metronic/layout/core'
import BankingProcessModal from '../../../../process/finance/banking/JNM/components/BankingProcessModal'

const UserSelectionRuleReport = (props) => {
  const navigate = useNavigate()

  const data_profiling_report_Breadcrumbs: Array<PageLink> = [
    {
      title: 'Finance',
      path: '/process/bpms/finance',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Money Remittance',
      path: '/process/bpms/finance',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Data Profiling',
      path: '/process/bpms/finance/remittance/profiling',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const [idname] = useState('table-to-xls')

  const [modalOpen,setModal]=useState(false)
  const onModalClose = () =>{
    setModal(false);
  }

  const handleClick=()=>{
    const modalId = 'kt_modal_JNM' // Replace with your actual modal ID
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    if (modalTrigger) {
      ;(modalTrigger as any).click() // Cast to 'any' to access 'click' method
    }
  }

  useEffect(() => {
    if (modalOpen) {
      handleClick();
    }
  }, [modalOpen]);
  return (
    <>
      {props.postingData ? <Loader /> : props.postDataFailed ? 'Something went wrong' : null}
     
      <PageTitle breadcrumbs={data_profiling_report_Breadcrumbs}>Analysis Report</PageTitle>


      {/* <div className='app-main flex-column flex-row-fluid' id=''>
        <div className='d-flex flex-column flex-column-fluid'>
          <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
            <div
              id='kt_app_toolbar_container'
              className='app-container container-xxl d-flex flex-stack'
            >
              <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
                <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                  <li className='breadcrumb-item text-muted'>
                    <a href='/brms' className='text-muted text-hover-primary'>
                      Data Intellegence
                    </a>
                  </li>
                  <li className='breadcrumb-item'>
                    <span className='bullet bg-gray-400 w-5px h-2px'></span>
                  </li>
                  <li className='breadcrumb-item text-muted'>Conventional Analytics</li>
                  <li className='breadcrumb-item'>
                    <span className='bullet bg-gray-400 w-5px h-2px'></span>
                  </li>
                  <li className='breadcrumb-item text-muted'>
                    Business Rules Engine - Report Summary
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <DataFieldReportTable response={props.postResponse} data={idname} {...props} />
<div style={{display:'flex',width:'100%'}}>
<span style={{width:'100%',justifyContent:'left'}}>
      <button
        type='button'
        className='btn btn-flex btn-light-primary my-6 me-3'
        id=''
        onClick={() => navigate('/process/bpms/finance/remittance/profiling/preview')}
      >
        <i className='ki-duotone ki-exit-left fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>{' '}
        Back
      </button>
      </span>
      {/* <span style={{width:'100%',justifyContent:'right',float:'right',alignItems:'right',display:'flex'}}>
      <button
        type='button'
        className='btn btn-flex btn-light-primary my-6 '
        onClick={()=>{setModal(true)}}
      >
        Data Enrichment
      </button>
      </span> */}
      </div>
      {modalOpen&&
      <BankingProcessModal component='enrichment' onClose={onModalClose} {...props}/>}
      

    </>
  )
}

export default UserSelectionRuleReport
