import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
//import {MegaMenu} from './MegaMenu'

export function MenuInner(props) {
  const intl = useIntl()
  console.log("LLL",props)
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' props={props}/>

      <MenuInnerWithSub
        title='Business Process Solution'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
        props={props}
      >
        <MenuInnerWithSub
          title='Finance'
          to='/crafted/pages'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
          props={props}
        >
          <MenuInnerWithSub
            title='Banking'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
            props={props}
          >
            <MenuItem
              to='/fin/bank/money-remittance'
              title='Money Remittance Process'
              hasBullet={true}
              props={props}
            />
            <MenuItem
              to='/fin/bank/onboarding'
              title='Customer Onboarding and Account Management'
              hasBullet={true}
              props={props}
            />
            <MenuItem
              to='/fin/bank/cred-score'
              title='Credit Scoring and Loan Approval'
              hasBullet={true}
              props={props}
            />
            <MenuItem
              to='/fin/bank/fraud-detection'
              title='Fraud Detection and Security'
              hasBullet={true}
              props={props}
            />
            <MenuItem
              to='/fin/bank/risk-management'
              title='Risk Management and Compliance'
              hasBullet={true}
              props={props}
            />
            <MenuItem
              to='/fin/bank/transactions-processing'
              title='Payments and Transactions Processing'
              hasBullet={true}
              props={props}
            />
            <MenuItem
              to='/fin/bank/crm'
              title='Customer Relationship Management'
              hasBullet={true}
              props={props}
            />
          </MenuInnerWithSub>
          {/* <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub> */}
        </MenuInnerWithSub>
      </MenuInnerWithSub>

      <MenuInnerWithSub
        title='Conventional Analytics'
        to='/apps/conventional'
        menuPlacement='bottom-start'
        menuTrigger='click'
        props={props}
      >
        {/* PAGES */}
        <MenuItem icon='abstract-28' to='/brms' title='Business Rule Solution' props={props}/>
        <MenuItem icon='abstract-28' to='#' title='Data Quality Solution' props={props}/>
        <MenuItem icon='abstract-28' to='#' title='Data Profiling Solution' props={props}/>
        <MenuItem icon='abstract-28' to='#' title='Data Archival Solution' props={props}/>
      </MenuInnerWithSub>

      <MenuInnerWithSub
        title='Advanced Analytics'
        to='/apps/advance'
        menuPlacement='bottom-start'
        menuTrigger='click'
        props={props}
      >
        {/* PAGES */}
        <MenuItem icon='abstract-28' to='/rfm-analysis' title='RFM Analysis' props={props}/>
        <MenuItem icon='abstract-28' to='/customer-churn' title='Customer Chrun' props={props}/>
      </MenuInnerWithSub>

     
    </>
  )
}
