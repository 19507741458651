import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import InboundComponent from './components/InboundComponent'
import InboundTranComponent from './components/InboundTranComponent'
import React, {useState} from 'react'

const Data_Enrich_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intellegent Analytics',
    path: '/app/intellegent-analytics',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const InboundTransactionChart: React.FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={Data_Enrich_Breadcrumbs}>Inbound Transactions Insight</PageTitle>
      <div className='row gx-5 gx-xl-10 mb-12'>
        <div className='card card-flush '>
          <div className='card-header pt-2'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-500'>
                Total Transactions by Country
              </span>
            </h3>
          </div>
          <InboundComponent />
        </div>
      </div>
      <div className='row gx-5 gx-xl-10'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-500'>Total Currency ($) by Country </span>
            </h3>
          </div>
          <InboundTranComponent />
        </div>
        
      </div>
    </>
  )
}

export default InboundTransactionChart
