import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'

const APIConnOption = (props: any) => {
  const [selectedAPIConn, setSelectedAPIConn] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [state,setState] = useState({
    api1:'',
    api2:'',
    api3:'',
  })

  const [errors,setErrors] = useState({
    api1:'',
    api2:'',
    api3:'',
  })

  const apiConnectionOptions = [
    {
      value: 'GraphQL',
      title: 'GraphQL',
      desc: 'Describe the types of data available and the relationships between them',
      fields: ['GraphQL Field 1', 'GraphQL Field 2', 'GraphQL Field 3'],
    },
    {
      value: 'SalesForce',
      title: 'SalesForce',
      desc: 'Facilitates collaboration among teams and supports integration with other third-party applications',
      fields: ['SalesForce Field 1', 'SalesForce Field 2', 'SalesForce Field 3'],
    },
    {
      value: 'APIRest',
      title: 'API-Rest',
      desc: 'Responses from the server include the requested data or information about the operation',
      fields: ['API-Rest Field 1', 'API-Rest Field 2', 'API-Rest Field 3'],
    },
  ]

  const handleAPIConnChange = (value) => {
    setShowSuccess(false)
    setSelectedAPIConn(value)
    // openModal()
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const openSweetAlert = () => {
    // e.preventDefault();

    Swal.fire({
      text: 'Are you sure you want to cancel ?',
      icon: 'warning',
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonAriaLabel: 'Close',
      cancelButtonText: 'No,return',
      confirmButtonText: 'Yes, Cancel it',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn  btn-light',
      },
    }).then(function () {
      const modalId = `upload_modal_${selectedAPIConn}`
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
      if (modalTrigger) {
        ;(modalTrigger as any).click()
      }
      setModalOpen(false)
    })
  }
  const openModal = () => {
    setModalOpen(true)
    const modalId = `upload_modal_${selectedAPIConn}`
    const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    if (modalTrigger) {
      ;(modalTrigger as any).click()
    }
  }

  const navigate = useNavigate()
  useEffect(() => {
    if (showSuccess) {
      Swal.fire({
        text: 'Connected Successfully !',
        icon: 'success',
        buttonsStyling: false,
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(function () {
        const modalId = `upload_modal_${selectedAPIConn}`
        const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
        if (modalTrigger) {
          ;(modalTrigger as any).click()
        }
        setModalOpen(false)
        navigate('/process/bpms/finance/remittance/profiling')
      })
    }
  }, [showSuccess])

  useEffect(() => {
    if (modalOpen) {
      const modalId = `upload_modal_${selectedAPIConn}`
      const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
      if (modalTrigger) {
        ;(modalTrigger as any).click()
      }
    }
  }, [modalOpen, selectedAPIConn])

  const renderFormFields = () => {
    const selectedConnection = apiConnectionOptions.find(
      (option) => option.value === selectedAPIConn
    )

    if (!selectedConnection) return null

    return (
      <form>
        {selectedConnection.fields.map((field,index) => (
          <div key={field} className='mb-3 mt-3'>
            <label htmlFor={field} className='form-label'>
              {field}
            </label>
            <input name={`api${index+1}`} value={state[`api${index+1}`]} type='text' 
             onChange={(e)=>{setState({...state,[e.target.name]:e.target.value});setErrors({...errors,[e.target.name]:''})}}
            className='form-control' id={field} />
             <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'>
            {errors[`api${index+1}`]}
          </div>
          </div>
        ))}
        <div className='text-center pt-10'>
          <button
            type='reset'
            id='kt_modal_new_card_cancel'
            className='btn btn-light me-3'
            onClick={openSweetAlert}
          >
            Discard
          </button>
          <button
            type='button'
            id='kt_modal_new_card_submit'
            className='btn btn-primary'
            onClick={(e) => showSuccessCheck(e)}
          >
            <span className='indicator-label'>Submit</span>
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </button>
        </div>
      </form>
    )
  }

  const showSuccessCheck = (e) => {
    e.preventDefault();
    const fields: string[] = ['api1', 'api2', 'api3'];
    const newErrors: any = {};
  
    for (const field of fields) {
      if (state[field] === '') {
        newErrors[field] = `This Field is required`;
      }
    }
  
    setErrors(newErrors);
  
    if (Object.keys(newErrors).length === 0) {
      setErrors({
        api1: '',
        api2: '',
        api3: '',
      });
      setShowSuccess(true);
    }
  };

  return (
    <>
      <div className='row mx-auto w-100 py-10 fv-plugins-bootstrap5 fv-plugins-framework'>
        {apiConnectionOptions.map((option) => (
          <div key={option.value} className='col-lg-4'>
            <input
              type='radio'
              className='btn-check'
              name='api_conn_name'
              value={option.value}
              checked={selectedAPIConn === option.value}
              onChange={() => handleAPIConnChange(option.value)}
              id={`kt_api_conn_${option.value}`}
            />
            <label
              className='btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10'
              htmlFor={`kt_api_conn_${option.value}`}
            >
              {option.value === 'GraphQL' ? (
                <i className='ki-duotone ki-graph-up fs-3x pe-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                  <span className='path5'></span>
                  <span className='path6'></span>
                </i>
              ) : option.value === 'SalesForce' ? (
                <i className='ki-duotone ki-delivery-3 fs-3x pe-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                </i>
              ) : option.value === 'APIRest' ? (
                <i className='ki-duotone ki-arrow-up-down fs-3x pe-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              ) : null}

              <span className='d-block fw-semibold text-start'>
                <span className='text-dark fw-bold d-block fs-4 mb-2'>{option.title}</span>
                <span className='text-muted fw-semibold fs-6'>{option.desc}</span>
              </span>
            </label>
            <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
          </div>
        ))}
      </div>
      <div className='d-flex flex-stack pt-0 pb-8'>
        <div
          style={{
            marginLeft: 'auto',
            ...(window.innerWidth > 768 ? {paddingRight: '28px'} : {paddingRight: '15px'}),
          }}
        >
          <button
            type='button'
            disabled={selectedAPIConn===''?true:false}
            className={`btn btn-lg ${selectedAPIConn===''?'btn-light':'btn-primary'}`}
            onClick={() => {
              openModal()
              setErrors({api1:'',api2:'',api3:''})
            }}
          >
            Continue
            <i className='ki-duotone ki-arrow-right fs-3 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
          </button>
          {/* <SweetAlertExample /> */}
        </div>
      </div>
      {/* {modalOpen && ( */}
      <>
        <span data-bs-toggle='modal' data-bs-target={`#upload_modal_${selectedAPIConn}`}></span>
        <div className='modal fade' tabIndex={-1} id={`upload_modal_${selectedAPIConn}`}>
          <div
            className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered h-auto'
            onClick={(e) => e.stopPropagation()}
          >
            <div className='modal-content' style={{width: '100%'}}>
              <div className='modal-header'>
                <h5 className='modal-title'>{selectedAPIConn}</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                  onClick={closeModal}
                >
                  <i className='ki-duotone ki-cross fs-2x'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </div>
              </div>
              <div className='modal-body'>
                {/* {showSuccess ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      <SuccessCheck />
                      <div style={{paddingTop: '20px'}}>
                        {`Connected to ${selectedAPIConn} successfully!`}
                      </div>
                    </div>
                  ) : ( */}
                {renderFormFields()}
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </>
      {/* )} */}
    </>
  )
}

export default APIConnOption
