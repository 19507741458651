import React, {useEffect, useRef, useState, useCallback} from 'react'
import * as d3 from 'd3'
import {KTIcon} from '../../../../../_metronic/helpers'
import ViewActionModal from './ViewActionModal'
import {useNavigate} from 'react-router-dom'
import {SCHEMA} from './Config'

interface RuleData {
  Id: string
  rule: string
  desc: string
  error: string
  count: string
  stats: {
    error: string
  }
}

interface PieChartProps {
  data: {
    fieldData: {
      fieldName: string
      TotalNoOfRows: string
      NoOfRowsNulls: string
      nullPercentage: string
      errorPercentage: string
      unique: string
    }
    fieldName: string
    selectedRule: RuleData[]
  }
  props: {
    selectedTable: string
    selectedColumn: string
    getGraphData: (
      db: string,
      table: string,
      column: string,
      ruleid: string,
      input: string,
      count: number,
      page: number
    ) => void
    graphData: any[]
    graphDataSuccess: boolean
    graphDataPagination: {rows: number; page: number}
    clearPopupProps: () => void
  }
  Datakey: RuleData
  ruleid: string
  count: string
}

const PieChart: React.FC<PieChartProps> = ({data, props, Datakey, ruleid, count}) => {
  const navigate = useNavigate()
  const svgRef = useRef<SVGSVGElement>(null)
  const [pieChartData, setPieChartData] = useState<any[]>([])
  const [heading, setHeading] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pieColorScheme, setPieColorScheme] = useState<any>({})

  const selectedRuleArray = data.selectedRule

  const createPieChart = useCallback(() => {
    const newPieChartData = [
      {
        label: Datakey.rule,
        value: parseFloat(Datakey.error.replace('%', '')) || 0,
        color: 'rgb(163, 181, 212)',
      },
      {
        label: 'TOT Records ',
        value: parseFloat('100'),
        color: 'rgb(76, 125, 183)',
      },
    ]

    setPieChartData(newPieChartData)

    const width = 300
    const height = 350
    const radius = Math.min(width, height) / 2

    const svg = d3.select(svgRef.current)

    svg.attr('width', width).attr('height', height)

    const pieChartGroup = svg.append('g').attr('transform', `translate(${width / 2},${height / 2})`)

    const pie = d3.pie().value((d) => d.value)

    pieChartGroup.selectAll('*').remove()

    const tooltip = d3
      .select('body')
      .append('div')
      .style('position', 'absolute')
      .style('z-index', '10')
      .style('visibility', 'hidden')
      .style('background', '#000')
      .style('color', '#fff')
      .style('padding', '10px')
      .style('border-radius', '3px')
      .style('font-size', '14px')

    const arc = d3.arc().innerRadius(0).outerRadius(radius)

    const arcs = pieChartGroup.selectAll('arc').data(pie(newPieChartData)).enter().append('g')

    arcs
      .append('path')
      .attr('d', arc)
      .attr('fill', (d) => d.data.color)
      // .on('click', (event, d) => {
      //   handleArcClick(d.data.label)
      // })

      .on('mouseover', (event, d) => {
        const hoverArc = d3
          .arc()
          .innerRadius(0)
          .outerRadius(radius * 0.9)
        d3.select(event.currentTarget)
          .transition()
          .duration(200)
          .attr('d', hoverArc)
          .style('stroke', 'white')
          .style('stroke-width', '2px')
          .style('cursor', 'pointer')
        tooltip.text(
          d.data.value !== 0 && d.data.value !== 100 ? `${d.data.label}: ${d.data.value}%` : ''
        )
        tooltip.style(
          'visibility',
          d.data.value !== 0 && d.data.value !== 100 ? 'visible' : 'hidden'
        )
        d3.select(event.currentTarget).style('opacity', 1)
      })
      .on('mouseout', function (event, d) {
        const originalArc = d3.arc().innerRadius(0).outerRadius(radius)
        if (event) {
          d3.select(event.currentTarget)
            .transition()
            .duration(200)
            .attr('d', originalArc)
            .style('stroke', 'none')
          tooltip.style('visibility', 'hidden')
          d3.select(event.currentTarget).style('opacity', 0.9)
        }
      })
      .on('mousemove', (event, d) => {
        if (event) {
          tooltip.style('top', `${event.pageY - 10}px`).style('left', `${event.pageX + 10}px`)
        }
      })

    arcs
      .append('text')
      .attr('transform', (d) => {
        const [x, y] = arc.centroid(d)
        const distance = radius * 0.6
        const angle = Math.atan2(y, x)
        const newX = Math.cos(angle) * distance
        const newY = Math.sin(angle) * distance
        return `translate(${newX},${newY})`
      })
      .attr('text-anchor', 'middle')
      .style('font-size', '12px')
      .style('font-weight', 'bold')
      .style('fill', 'white')
      .text((d) => (d.data.value !== 0 && d.data.value !== 100 ? `${d.data.value}%` : ''))
  }, [data, selectedRuleArray, pieColorScheme])

  useEffect(() => {
    createPieChart()
  }, [createPieChart])

  // console.log('PIE', data)
  const handleArcClick = async (label) => {
    // setIsModalOpen(false);
    // props.clearPopupProps();
    setHeading(label)
    // let input =
    //   label === 'Unique Percentage' ? 'UNIQUE' : label === 'Error Percentage' ? 'ERROR' : 'NULL'
    // props.getGraphData(SCHEMA, props.selectedTable, data.fieldName,ruleid, input,Number(count),1)
    // const modalId = 'kt_modal_1'
    // const modalTrigger = document.querySelector(`[data-bs-target="#${modalId}"]`)
    // if (modalTrigger) {
    //   ;(modalTrigger as any).click()
    // }
    // setIsModalOpen(true)
  }

  return (
    <>
      <div className='fv-row'>
        <div className='row d-flex'>
          <div className='col-lg-12'>
            <span className='d-block fw-bold  '>
              <div className='d-flex flex-wrap flex-md-nowrap'>
                <i className='ki-duotone ki-chart-pie-simple fs-1 text-primary me-3'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                <div className='d-flex justify-content-end'>
                  <span className='text-gray-600 fw-bolder d-block fs-5 flex-column'>
                    {Datakey.desc}
                  </span>
                </div>
              </div>
              <svg ref={svgRef} className='pie-chart-svg'></svg>
            </span>
          </div>
        </div>
      </div>
      <ViewActionModal heading={heading} props={props} />
    </>
  )
}

export default PieChart
