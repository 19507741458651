import React, {useEffect, useState} from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'
import { KTIcon } from '../../../../../../_metronic/helpers'
// Define a type for the state object
interface ReportStateProp {
  // data: any
  heading: string
  // rows: number
  // page: number,
  ruleId: any
  props: any
  affected: number
}
const CustomerDataRuleEnrichModal: React.FC<ReportStateProp> = ({
  heading,
  ruleId,
  affected,
  props,
}) => {
  const [data, setData] = React.useState<any>(null)
  const [rows, setRows] = React.useState<number>(10)
  const [page, setPage] = React.useState<number>(1)
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (props.isEnrichmentCustomerDataLoading) {
      setLoading(true)
    }
    console.log("##props A12: ", props);

    if (props.fetchingEnrichedDataSuccess === true) {
      setLoading(false)
        setData(props.EnrichedCustRespDataByRule.response.Data.map(
          ({
            //CustomerKey,
            FirstName,
            LastName,
            EmailAddress,
            Phone,
            BirthDate,
            Gender,
            Zipcode,
          }) => ({
            //CustomerKey: `${CustomerKey ? CustomerKey : '-'}`,
            FirstName: `${FirstName ? FirstName : '-'}`,
            LastName: `${LastName ? LastName : '-'}`,
            EmailAddress: `${EmailAddress ? EmailAddress : '-'}`,
            Phone: `${Phone ? Phone : '-'}`,
            DOB: `${BirthDate ? BirthDate : '-'}`,
            Gender: `${Gender ? Gender : '-'}`,
            ZipCode: `${Zipcode ? Zipcode : '-'}`,
            // Identification_Id: `${documentName ? documentName.slice(0, 2).toUpperCase() : ''}-${
            //   documentValue ? documentValue : ''
            // }`,
          })
        )
      )
    }
  }, [props.fetchingEnrichedDataSuccess, heading])

  React.useEffect(() => {
    if (props.fetchingEnrichedDataSuccess) {
      setRows(
        props?.enrichedCustDataPagination?.rows
          ? props.enrichedCustDataPagination.rows
          : 0
      )
      setPage(
        props?.enrichedCustDataPagination?.page
          ? props.enrichedCustDataPagination.page
          : 0
      )
    }
  }, [data, props.enrichedCustDataPagination, props.fetchingEnrichedDataSuccess])

  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(rows)

  const recordsPerPage = rows || 10 // Default to 10 records per page

  useEffect(() => {
    // Reset to the first page whenever the data changes
    setCurrentPage(1)
  }, [])

  const handleAPI = (pageNumber) => {

    console.log('#ENRICHED CUST DATA PAGINATION : ', currentPage, page, pageNumber, rowsPerPage, ruleId)
    
    let apiData = {
      ruleId: ruleId,
      ruleDesc:heading,
      rows: rowsPerPage,
      page: pageNumber,
    }
    props.fetchEnrichedCustData(apiData, page)
  }

  const totalPages = (page && page !== 0 && page) || 1 // Default to 1 page if not provided
  const startIndex = (currentPage - 1) * recordsPerPage
  const endIndex = startIndex + recordsPerPage
  const headers = data?.length ? Object.keys(data[0]) : []

  // Calculate the range of page numbers to display
  const startRange = Math.max(1, currentPage - 5)
  const endRange = Math.min(totalPages, startRange + 9)

  return (
    <>
      {/* <span data-bs-toggle='modal' data-bs-target='#kt_modal_1'></span>
      <div className='modal bg-body fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog  modal-fullscreen'>
          <div className='modal-content shadow-none' style={{width: '100%'}}></div> */}

      <span data-bs-toggle='modal' data-bs-target='#kt_modal_enrich_report'></span>
      <div className='modal fade' tabIndex={-1} id='kt_modal_enrich_report'>
        <div className='d-flex justify-content-center align-items-center modal-dialog modal-dialog-centered h-auto modal-dialog modal-xl mw-1255 px'>
          <div className='modal-content' style={{width: '100%'}}>
            <div className='modal-header pb-4 pt-2 mt-0 justify-content-end'>
              <h5
                className='modal-title'
                style={{
                  float: 'left',
                  width: '100%',
                  justifyContent: 'left',
                  alignItems: 'left',
                  display: 'flex',
                  paddingLeft: '20px',
                }}
              >
                <h2>{heading}</h2>
              </h5>
              <span
                style={{
                  float: 'right',
                  width: '100%',
                  justifyContent: 'right',
                  alignItems: 'right',
                  display: 'flex',
                }}
              >
                {data && data.length > 0 && (
                  <ReactHTMLTableToExcel
                    id='test-table-xls-button'
                    className='download-table-xls-button btn btn-light-primary me-3'
                    table={'table-to-xls'}
                    filename='DataFieldReport'
                    sheet='DataFieldReport'
                    //  buttonText='Export'

                    buttonText={
                      <>
                        <KTIcon iconName='exit-up' className='fs-2' />
                        Export
                      </>
                    }
                  />
                )}
              </span>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <i
                  className='ki-duotone ki-cross fs-2x'
                  onClick={() => {
                    props.clearPopupProps()
                  }}
                >
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
              </div>
            </div>
            <div className='modal-body py-2 scroll-y m-1'>
              {loading ? (
                'Loading...'
              ) : data && data.length ? (
                <>
                  <div className='card-body mt-3 pb-0 px-4 py-0'>
                    <div className='table-responsive'>
                      {data && data.length > 0 ? (
                        <table id={'table-to-xls'} className='table table-row-bordered gy-5'>
                          <thead>
                            <tr className='fw-bold fs-6 text-gray-800'>
                              {headers.map(
                                (heading, index) =>
                                  heading !== 'Identification_Id' && (
                                    <th key={heading} className='text-center'>
                                      {heading}
                                    </th>
                                  )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                {headers.map(
                                  (header, colIndex) =>
                                    header !== 'Identification_Id' && (
                                      <td key={colIndex} className='text-center'>
                                        {row[header]}
                                      </td>
                                    )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        'No Search Results'
                      )}
                    </div>
                  </div>
                </>
              ) : (
                'No Records Found'
              )}
            </div>
            <div className='modal-footer'>
              {rowsPerPage !== 0 && page !== 0 && totalPages > 1 && (
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                  <div style={{display: 'flex', alignItems: 'center', width: 'auto'}}>
                    <select 
                      className='form-select form-select-solid me-4'
                      style={{width: '80px'}}
                      aria-label='Select example'
                      onChange={(e) => {
                        const selectedRows = parseInt(e.target.value)
                        setRowsPerPage(selectedRows)
                        setCurrentPage(1) // Reset current page to 1 when changing rows per page
                        handleAPI(1) // Fetch data for the first page after changing rows per page
                      }}
                    >
                      {Array.from(
                        {length: Math.min(totalPages, 10)},
                        (
                          _,
                          index // Ensure not more than 10 options
                        ) => (
                          <option key={index} value={(index + 1) * 10}>
                            {(index + 1) * 10}
                          </option>
                        )
                      )}
                    </select>
                    {`Showing ${(currentPage - 1) * rowsPerPage + 1} to 
                      ${Math.min(currentPage * rowsPerPage, totalPages)} of ${affected} records`}
                  </div>
                  <ul className='pagination'>
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                      <a 
                        className='page-link'
                        onClick={() => {
                          setCurrentPage(currentPage - 1)
                          handleAPI(currentPage - 1)
                        }}
                      >
                        Previous
                      </a>
                    </li>
                    {Array.from({length: endRange - startRange + 1}, (_, index) => (
                      <li
                        key={startRange + index}
                        className={`page-item ${
                          currentPage === startRange + index ? 'active' : ''
                        }`}
                      >
                        <a
                          className='page-link'
                          onClick={() => {
                            setCurrentPage(startRange + index)
                            handleAPI(startRange + index)
                          }}
                        >
                          {startRange + index}
                        </a>
                      </li>
                    ))}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                      <a
                        className='page-link'
                        onClick={() => {
                          setCurrentPage(currentPage + 1)
                          handleAPI(currentPage + 1)
                        }}
                      >
                        Next
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomerDataRuleEnrichModal

