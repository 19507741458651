import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import ColumnChart from './components/ColumnChart'
import React, {useState} from 'react'
import PieChart from './components/PieChart'
import AveragescoresChart from './components/Averagescores'
import Circular from './components/Circular'

const Data_Enrich_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Congnitive Analytics',
    path: '/app/congnitive-analytics',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RfmAnalysisChart: React.FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={Data_Enrich_Breadcrumbs}>RFM Analysis Insight</PageTitle>
      <div className='row gx-5 gx-xl-10 mb-12'>
        <div className='col-5'>
          <div className='card card-flush pb-5'>
            <div className='card-header pt-2'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-500'>
                  Distribution of Dataset for Churn Model
                </span>
              </h3>
            </div>
            <Circular />
          </div>
        </div>
        <div className='col-7'>
          <div className='card card-flush pb-5'>
            <div className='card-header pt-2'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-500'>
                  Average RFM Scores for Test Set Customer
                </span>
              </h3>
            </div>
            <AveragescoresChart />
          </div>
        </div>
      </div>
      
      <div className='row gx-5 gx-xl-10'>
        <div className='col-5'>
          <div className='card card-flush pb-5'>
            <div className='card-header pt-2'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-500'>
                  Customer Segmentation Distribution
                </span>
              </h3>
            </div>
            <PieChart />
          </div>
        </div>
        <div className='col-7'>
          <div className='card card-flush pb-5'>
            <div className='card-header pt-2'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-500'>
                  Average RFM Scores by Customer Segmentation
                </span>
              </h3>
            </div>
            <ColumnChart />
          </div>
        </div>
      </div>
    </>
  )
}

export default RfmAnalysisChart
