import React from 'react';
import TransactionsByYear from '../../../../../_metronic/assets/myimages/TransactionsByYear.png';

const TransactionsByYears = () => {
  return (
    <>
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'> Transactions by Year</span>
        </h3>
      </div>
      <div className='card-body' style={{ height: '550px', overflowY: 'auto' }}>
        <img src={TransactionsByYear} alt="customerchum" style={{ maxWidth: '100%' }} />
      </div>
    </div>
    </>
  );
};

export default TransactionsByYears;
