import React, {useState} from 'react'
import {SQLTable} from './SQLTable'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const FormFields = [
  {id: 1, name: 'DatanXzen', options: ['customerdata', 'transactiondata', 'accountdata']},
]

const SelfService_Breadcrumbs = [
  {
    title: 'NLP Analytics',
    path: '/process/finance/banking/data-enrichment',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

interface TableData {
  SendingCountryID_ID?: string
  ReceivingCountryId?: string
  Amount?: number
  Count?: number
}

interface State {
  description: string
  table: {data: TableData[]}
  column: string
}

const SelfServiceAnalytics = (props) => {
  const [state, setState] = useState<State>({description: '', table: {data: []}, column: ''})

  // const [state, setState] = useState({description: '', table: '', column: ''})
  const [errors, setErrors] = useState({description: '', table: '', column: ''})
  const [selectedColumn, setSelectedColumn] = useState<string[]>([])
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [isInputDisabled, setisInputDisabled] = useState(true)
  const [showSQLTable, setShowSQLTable] = useState(false)
  const [buttonLabel, setButtonLabel] = useState('Generate SQL')
  const [sqlQuery, setSqlQuery] = useState('') // State to store the SQL query

  const handleCheckboxChange = (column) => {
    const selectedIndex = selectedColumn.indexOf(column)
    let newSelected = [...selectedColumn]

    if (selectedIndex === -1) {
      newSelected.push(column)
    } else {
      newSelected.splice(selectedIndex, 1)
    }
    setShowSQLTable(false)
    setSelectedColumn(newSelected)
    //setIsButtonDisabled(newSelected.length === 0)
    setisInputDisabled(newSelected.length === 0)
  }

  const handleSelectAll = () => {
    setSelectedColumn(FormFields[0].options)
    setIsButtonDisabled(true)
    setisInputDisabled(false)
  }

  const handleUnselectAll = () => {
    setSelectedColumn([])
    setIsButtonDisabled(true)
    setisInputDisabled(true)
  }

  // const handleTextAreaChange = (e) => {
  //   const {value} = e.target
  //   setState((prevState) => ({...prevState, description: value}))
  //   setIsButtonDisabled(value.trim() === '')

  //   // If the textarea value is empty, null, or cleared, set the SQL query state to an empty string
  //   if (!value || value.trim() === '') {
  //     setSqlQuery('')
  //   }
  // }

  const handleTextAreaChange = (e) => {
    const {value} = e.target
    setState((prevState) => ({...prevState, description: value}))
    setIsButtonDisabled(value.trim() === '') // Disable button if textarea value is empty
  }

  const handleGetReportClick = () => {
    setShowSQLTable(true)
    setButtonLabel('Get Report')

    // Find the selected input from the data array
    const selectedInput = data.find((item) => item.input === state.description)

    // If selected input is found, update the SQL query state with the corresponding SQL query
    // and render the SQL table component with the corresponding output data
    if (selectedInput) {
      setSqlQuery(selectedInput.sql)
      setState((prevState) => ({...prevState, table: {data: selectedInput.output}}))
    } else {
      // If selected input is not found, reset the SQL query state and table data state
      setSqlQuery('')
      setState((prevState) => ({...prevState, table: {data: []}}))
    }
  }

  const handleColumnToggle = (id) => {
    setSelectedColumn((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((selected) => selected !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  // Define a mapping of input text to SQL queries and output data
  const data = [
    {
      Id: '1',
      input: 'Display total USD outbound transaction amounts, by country, for 2021',
      sql: `SELECT TR.SendingCountryID_ID,
      ROUND(SUM(TR.TransactionAmount_USD),2) 
      FROM \`datanxzen-QA\`.transactiondata TR
      WHERE YEAR(TR.TransactionDate_DTE) = 2021
      GROUP BY TR.SendingCountryID_ID;`,
      output: [
        {SendingCountryID_ID: 'CA', Amount: 2660739.06},
        {SendingCountryID_ID: 'GB', Amount: 9773350.15},
        {SendingCountryID_ID: 'JM', Amount: 2944.53},
        {SendingCountryID_ID: 'KY', Amount: 633447.64},
        {SendingCountryID_ID: 'TC', Amount: 222.77},
        {SendingCountryID_ID: 'US', Amount: 12237337.2},
      ],
    },
    {
      Id: '2',
      input: 'Fetch total inbound transaction amounts in USD, by country, for 2021',
      sql: `SELECT TR.ReceivingCountryId,
      ROUND(SUM(TR.TransactionAmount_USD),2) 
      FROM \`datanxzen-QA\`.transactiondata TR
      WHERE YEAR(TR.TransactionDate_DTE) = 2021
      GROUP BY TR.ReceivingCountryId;`,
      output: [
        {ReceivingCountryId: 'AG', Amount: 400.84},
        {ReceivingCountryId: 'CA', Amount: 11765.96},
        {ReceivingCountryId: 'DM', Amount: 21000.97},
        {ReceivingCountryId: 'GB', Amount: 742.38},
        {ReceivingCountryId: 'GY', Amount: 19824.04},
        {ReceivingCountryId: 'HN', Amount: 3063.81},
        {ReceivingCountryId: 'JM', Amount: 25118678.45},
        {ReceivingCountryId: 'KY', Amount: 67898.17},
        {ReceivingCountryId: 'US', Amount: 64666.74},
      ],
    },
    {
      Id: '3',
      input:
        'Retrieve the count of sender customers by country, for outbound transactions in the year 2021',
      sql: `SELECT TR.SendingCountryID_ID,
      COUNT(DISTINCT(TR.SenderMTS))
      FROM \`datanxzen-QA\`.transactiondata TR
      WHERE YEAR(TR.TransactionDate_DTE) = '2021'
      GROUP BY TR.SendingCountryID_ID;`,
      output: [
        {SendingCountryID_ID: 'CA', Count: 640},
        {SendingCountryID_ID: 'GB', Count: 2171},
        {SendingCountryID_ID: 'JM', Count: 6},
        {SendingCountryID_ID: 'KY', Count: 73},
        {SendingCountryID_ID: 'TC', Count: 1},
        {SendingCountryID_ID: 'US', Count: 3109},
      ],
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={SelfService_Breadcrumbs}>Self Service</PageTitle>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <div className='card'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Database Schema Structure</span>
              </h3>
            </div>
            <div className='separator border-primary my-4 mt-2'></div>

            <div className='card-body py-3'>
              <div className='m-0 mb-2'>
                {FormFields.map((field) => (
                  <div className='m-0 mb-2' key={field.id}>
                    <div
                      className={`d-flex align-items-center collapsible toggle mb-2`}
                      data-bs-toggle='collapse'
                      data-bs-target={`#kt_job_${field.id}`}
                      onClick={() => handleColumnToggle(field.id)}
                    >
                      <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                        <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                        <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                          <span className='path3'></span>
                        </i>
                      </div>
                      <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>{field.name}</h4>
                    </div>
                    <div id={`kt_job_${field.id}`}>
                      <div className={` fs-6 ms-1`}>
                        <div className='mb-1'>
                          <div className='ms-12'>
                            {field.options.map((column) => (
                              <div className='row' key={column}>
                                <div className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value={column}
                                    checked={selectedColumn.includes(column)}
                                    onChange={() => handleCheckboxChange(column)}
                                  />
                                  <label className='form-check-label'>{column}</label>
                                </div>
                                <br></br>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center mt-8 mb-4'>
                        <button
                          className='btn btn-sm btn-light-primary flex-grow-1'
                          onClick={handleSelectAll}
                        >
                          <i className='ki-duotone ki-check-square fs-2 ms-1 me-0'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                          Select All
                        </button>
                        <button
                          className='btn btn-sm btn-light-danger flex-grow-1 ms-2'
                          onClick={handleUnselectAll}
                        >
                          <i className='ki-duotone ki-cross-square fs-2 ms-1 me-0'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                          Unselect All
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='col-xl-8'>
          <div className='card'>
            <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Natural Language Processor</span>
              </h3>
            </div>
            <div className='separator border-primary my-4 mt-2'></div>

            <div className='card-body py-3'>
              <div className='d-flex flex-column pb-5'>
                <label htmlFor='description' className='form-label'>
                  {'Kindly provide in detailed message for report fetch'}
                </label>
                <textarea
                  className={`form-control ${isInputDisabled ? 'disabled' : ''}`}
                  data-kt-autosize='true'
                  onChange={handleTextAreaChange}
                  disabled={isInputDisabled}
                ></textarea>
              </div>
              <hr className='pb-3 text-primary'></hr>
              <div className='notice d-flex bg-light-primary rounded border-white border border-dashed mb-2 p-6'>
                <i className='ki-duotone ki-message-programming fs-1 text-primary me-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                </i>
                <div className='d-flex flex-stack flex-grow-1'>
                  <div className='fw-semibold'>
                    <h5 className='text-gray-900 fw-bold pb-2'>Generated SQL Query:</h5>
                    <div className='fs-6 text-gray-700'>
                      <code className='py-1'>{sqlQuery}</code>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-end align-items-center mt-8 mb-4'>
                <button
                  type='button'
                  id='kt_modal_new_card_submit'
                  className={`btn btn-sm btn-primary ${isButtonDisabled ? 'disabled' : ''}`}
                  onClick={handleGetReportClick} // Attach handleGetReportClick to onClick event
                >
                  Get Report
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl-12'>
          {showSQLTable ? (
            state.table.data && state.table.data.length > 0 ? (
              <div className='card'>
                <div className='card-body'>
                  <h3 className='card-title'>Report Data</h3>
                  <div className='table-responsive'>
                    <table className='table table-striped table-hover'>
                      <thead>
                        <tr>
                          {/* Assuming the keys in the output data are consistent */}
                          {Object.keys(state.table.data[0]).map((key) => (
                            <th key={key}>{key}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {state.table.data.map((row, index) => (
                          <tr key={index}>
                            {/* Assuming the keys in the output data are consistent */}
                            {Object.values(row).map((value, idx) => (
                              <td key={idx}>{value}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div className='card'>
                <div className='card-body'>
                  <h3 className='card-title'>Report Data</h3>
                  <p className='text-danger'>No record found, kindly try again.</p>
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
    </>
  )
}

export default SelfServiceAnalytics
