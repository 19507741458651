import React from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import NewChartComponent from '../components/NewChartComponent'
import TotalClaimsChart from './chart/TotalClaimsChart'

const healthcare_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intellegent Analytics',
    path: '/app/intellegent-analytics',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'HealthCare',
    path: '/app/healthcare',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const TotalClaims: React.FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={healthcare_Breadcrumbs}>Total Claims Insight</PageTitle>
      <div className='row gx-5 gx-xl-10 mb-12'>
        <div className='card card-flush'>
          <div className='card-header pt-2'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-500'>Number of patient by claims</span>
            </h3>
          </div>
          <TotalClaimsChart />
        </div>
      </div>
    </>
  )
}

export default TotalClaims
