import React, { FC } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import { checkIsActive, KTIcon, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';
import pdf from '../../../../../app/modules/charts/Exec-Summary.pdf'

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { app } = config;

  const openPDFInNewWindow = () => {
   // const pdf = 'your-pdf-url-here'; // Replace 'your-pdf-url-here' with the actual URL of the PDF
    const newWindow = window.open(pdf, '_blank', 'fullscreen=yes');
    if (newWindow) {
      newWindow.focus();
    }
  };

  const handleItemClick = (e) => {

    console.log("handleItemClick - ",title);
    
    e.stopPropagation();
    if (title === 'Dashboard') localStorage.removeItem('title');
    localStorage.setItem('sidemenuClicked', 'true');
    if (title === 'Executive Summary') openPDFInNewWindow(); // Call the function when 'Execute Summary' is clicked
  };

  return (
    <div className='menu-item'>
      <Link
        className={clsx('menu-link without-sub', { active: isActive })}
        to={to}
        onClick={handleItemClick}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title'>{title}</span>
      </Link>
      {children}
    </div>
  );
};

export { SidebarMenuItem };
