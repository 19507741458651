import React from 'react'
import {useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const data_archival_hist_details_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intelligent Analytics',
    path: '/data/quality',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const ArchivalHistoryDetails = (props) => {
  const navigate = useNavigate()
  return (
    <>
      <PageTitle breadcrumbs={data_archival_hist_details_Breadcrumbs}>
        Archival History Details
      </PageTitle>
      <div className='card mb-5 mb-xl-5' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Archival Audit Data</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          {props?.dataHistoryDetails && (
            <>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Archival Task Id</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataHistoryDetails?.archivalTaskId}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Archival Task Name</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataHistoryDetails?.archivalTaskName}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Execution Start Time</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataHistoryDetails?.executionStartTime}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Execution End Time</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataHistoryDetails?.executionEndTime}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Archival Table Name</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataHistoryDetails?.archivalTableName}
                  </span>
                </div>
              </div>

              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Archived Record Count</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataHistoryDetails?.archivedRecordCount}
                  </span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Execution Time</label>

                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {props?.dataHistoryDetails?.executionTime}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <>
        <div className='d-flex justify-content-end align-items-center mt-0'>
          <button
            type='button'
            className='btn btn-sm btn-primary'
            onClick={() => navigate('/data/archival/history')}
          >
            <i className='ki-duotone ki-arrow-left fs-2 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            Back
          </button>
        </div>
      </>
    </>
  )
}

export default ArchivalHistoryDetails
