import { combineReducers, Reducer } from "redux";
import { AppReducer, AppState } from "./AppReducer";

export interface RootState {
  AppReducer: AppState;
}

const rootReducer: Reducer<RootState> = combineReducers({
  AppReducer,
});

export default rootReducer;
