import React from 'react';
import './Successcheck.css';

const SuccessCheck = () => {
  return (
    <div className="main-container mt-10">
      <div className="check-container">
        <div className="check-background">
          <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg" className='check-svg'>
            <rect width="65" height="51" rx="10" fill="#3e97ff" />
            <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="rectangle" stroke-linejoin="rectangle" />
          </svg>
        </div>
        <div className="check-shadow"></div>
      </div>
    </div>
  );
}

export default SuccessCheck;
