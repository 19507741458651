import React from 'react'

import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import DatabaseStructure from '../../../modules/quality/component/DatabaseStructure'
import UserSelectionStructure from '../../../modules/quality/component/UserSelectionStructure'
import RuleBookStructure from '../../../modules/quality/component/RuleBookStructure'

const data_profiling_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Finance - Banking Process',
    path: '/process/finance/banking',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Money Transfer',
    path: '/process/finance/digital-banking',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BusinessRuleSelection: React.FC<any> = (props) => {
  return (
    <>
      <PageTitle breadcrumbs={data_profiling_Breadcrumbs}>Data Profiling</PageTitle>

      <div className='row g-5 g-xl-8'>
        {props.isBusinessRuleSelected === true ? (
          <>
            <div className='col-xl-4'>
              <DatabaseStructure {...props} />
            </div>
            <div className='col-xl-8'>
              <RuleBookStructure {...props} />
            </div>
          </>
        ) : (
          <>
            <div className='col-xl-4'>
              <DatabaseStructure {...props} />
            </div>
            <div className='col-xl-4'>
              <RuleBookStructure {...props} />
            </div>
            <div className='col-xl-4'>
              <UserSelectionStructure {...props} />
            </div>
          </>
        )}
      </div>
     
      {/* <div style={{display: 'flex', justifyContent: 'center'}}>
        {props.selectedRulesHistory &&
        props.selectedRulesHistory.length > 0 &&
        props.selectedRulesHistory[props.selectedRulesHistory.length - 1].rules.length > 0 ? (
          <button
            style={{
              height: '35px',
              width: '20%',
              border: 'none',
              background: '#007bff',
              cursor: 'pointer',
              color: '#fff',
              borderRadius: '14px',
            }}
            onClick={() => navigate('/confirm')}
          >
            {'Continue'}
          </button>
        ) : (
          <button
            style={{
              height: '35px',
              width: '20%',
              border: 'none',
              background: 'grey',
              cursor: 'pointer',
              borderRadius: '14px',
            }}
          >
            {'Continue'}
          </button>
        )}
      </div> */}
    </>
  )
}

export default BusinessRuleSelection
