import {lazy, FC, Suspense, useEffect} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

import {BusinessRuleProcessing} from '../pages/data/trad/BusinessRuleProcessing'
import BusineesRuleSelectionPreview from '../pages/data/trad/brms/BusineesRuleSelectionPreview'
import BusineesRuleSelectionReport from '../pages/data/trad/brms/BusineesRuleSelectionReport'
import BusineesRuleSelectionReportBreakdown from '../pages/data/trad/brms/BusineesRuleSelectionReportBreakdown'
// import BusineesRuleSelectionReportStatistics from '../pages/data/trad/brms/BusineesRuleSelectionReportStatistics'

import BusineesRuleSelection from '../pages/data/trad/BusineesRuleSelection'
import Customerchurn from '../pages/data/trad/brms/CustomerChurn'
import DataEnrichmentComponent from '../process/finance/banking/JNM/components/DataEnrichmentComponent'
import BusinessProcessList from '../process/finance/banking/BusinessProcessList'
import InsuranceProductList from '../process/bpms/insurance/InsuranceProductList'
import FinanceProductList from '../process/bpms/finance/FinanceProductList'
import SourceConnectivity from '../process/bpms/finance/SourceConnectivity'
import DataProfiling from '../modules/quality/DataProfiling'
import ConstructionProductList from '../process/bpms/construction/ConstructionProductList'
import ManufacturingProductList from '../process/bpms/manufacturing/ManufacturingProductList'
import DataEnrichment from '../modules/quality/DataEnrichment'
import DataEnrichRulePreview from '../modules/quality/component/DataEnrichRulePreview'
import DataEnrichmentReport from '../modules/quality/component/DataEnrichmentReport'
import CustomerDemographic from '../pages/data/trad/brms/CustomerDemographic'
import InBoundTransactions from '../pages/data/trad/brms/InBoundTransactions'
import OutBoundTransactions from '../pages/data/trad/brms/OutBoundTransaction'
import TransactionsByYears from '../pages/data/trad/brms/TransactionsByYear'
import CustomerDemographicChart from '../modules/charts/CustomerDemographicChart'
import InboundTransactionChart from '../modules/charts/InboundTransactionChart'
import OutboundTransactionChart from '../modules/charts/OutboundTransactionChart'
import TransactionByCountryChart from '../modules/charts/TransactionByCountryChart'
import YTDTransferDataChart from '../modules/charts/YTDTransferDataChart'
import RfmAnalysisChart from '../modules/charts/RfmAnalysisChart'
import SelfServiceAnalytics from '../modules/quality/component/SelfServiceAnalytics'
// import OperationalEfficiencyEnhancement from '../modules/quality/component/businessRule/OperationalEfficiencyEnhancement'
import DataArchivalSelection from '../modules/archival/DataArchivalSelection'
import ArchivalTableDataPreview from '../modules/archival/component/ArchivalTableDataPreview'
import DataArchivalHistory from '../modules/archival/component/DataArchivalHistory'
import ArchivalHistoryDetails from '../modules/archival/component/ArchivalHistoryDetails'
import ArchivalExecutionReport from '../modules/archival/component/ArchivalExecutionReport'
import ClaimsFraudulentChart from '../modules/charts/ClaimsFraudulentChart'
import PreExistingCondition from '../modules/charts/healthcare/PreExistingCondition'
import HospitalStays from '../modules/charts/healthcare/HospitalStays'
import TotalClaims from '../modules/charts/healthcare/TotalClaims'
import TotalDiagnosis from '../modules/charts/healthcare/TotalDiagnosis'
import TotalProcedures from '../modules/charts/healthcare/TotalProcedures'
import DataMaskedStruture from '../modules/data/masked/DataMaskedStruture'

const PrivateRoutes = (props) => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))

  useEffect(() => {
    if (localStorage.getItem('sidemenuClicked') === 'true') {
      props.resetState()
      localStorage.setItem('sidemenuClicked', 'false')
    }
  }, [localStorage.getItem('sidemenuClicked')])

  return (
    <Routes>
      <Route element={<MasterLayout {...props} />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        {/* Pages */}
        {/* Added by Arun on 28/11/2023 for Business processing management system screen*/}
        <Route path='/bpms' element={<BusinessRuleProcessing />} />
        <Route path='/data/quality' element={<BusineesRuleSelection {...props} />} />
        <Route path='/data/masked' element={<DataMaskedStruture {...props} />} />

        {/* <Route path='/data/operation' element={<OperationalEfficiencyEnhancement />} /> */}
        {/* <Route path='/brms/action/statistics' element={<BusineesRuleSelectionReportStatistics {...props} />} /> */}

        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        
        <Route path='/claims-fraudulent' element={<ClaimsFraudulentChart />} />
        <Route path='/rfm-analysis' element={<RfmAnalysisChart />} />
        <Route path='/customer-churn' element={<Customerchurn />} />
        <Route path='/demographics-analysis' element={<CustomerDemographic />} />
        <Route path='/In-bound-transactions' element={<InBoundTransactions />} />
        <Route path='/Out-bound-transactions' element={<OutBoundTransactions />} />
        <Route path='/transaction_by_years' element={<TransactionsByYears />} />

        {/* Business Process Flow Routing || Added by Arun Jadhav 24/01/2024*/}
        <Route path='/process/finance/banking' element={<BusinessProcessList {...props} />} />
        <Route
          path='/process/finance/banking/data-enrichment'
          element={<DataEnrichmentComponent {...props} />}
        />

        <Route path='/process/bpms/finance' element={<FinanceProductList {...props} />} />
        <Route path='/process/bpms/insurance' element={<InsuranceProductList {...props} />} />
        <Route path='/process/bpms/construction' element={<ConstructionProductList {...props} />} />
        <Route
          path='/process/bpms/Manufacturing'
          element={<ManufacturingProductList {...props} />}
        />

        {/* Testing */}
        <Route
          path='/process/bpms/finance/remittance/connection'
          element={<SourceConnectivity {...props} />}
        />
        <Route
          path='/process/bpms/finance/remittance/profiling'
          element={<DataProfiling {...props} />}
        />
        <Route
          path='/process/bpms/finance/remittance/profiling/preview'
          element={<BusineesRuleSelectionPreview {...props} />}
        />
        <Route
          path='/process/bpms/finance/remittance/profiling/report'
          element={<BusineesRuleSelectionReport {...props} />}
        />
        <Route
          path='/process/bpms/finance/remittance/profiling/report/breakdown'
          element={<BusineesRuleSelectionReportBreakdown {...props} />}
        />
        <Route
          path='/process/bpms/finance/remittance/enrichment'
          element={<DataEnrichment {...props} />}
        />
        <Route
          path='/process/bpms/finance/remittance/enrichment/preview'
          element={<DataEnrichRulePreview {...props} />}
        />
        <Route
          path='/process/bpms/finance/remittance/enrichment/report'
          element={<DataEnrichmentReport {...props} />}
        />

        <Route path='/data-enrichment' element={<DataEnrichment {...props} />} />
        <Route path='/enrichment-preview' element={<DataEnrichRulePreview />} />

        {/* Implementing charts on 13022024 */}
        <Route path='/insight/demographics' element={<CustomerDemographicChart />} />
        <Route path='/insight/inbound-trans' element={<InboundTransactionChart />} />
        <Route path='/insight/outbound-trans' element={<OutboundTransactionChart />} />
        <Route path='/insight/ytd-transfer' element={<YTDTransferDataChart />} />
        <Route path='/insight/trans-by-country' element={<TransactionByCountryChart />} />
        <Route path='/self-service' element={<SelfServiceAnalytics />} />

        {/* Implementing Data Archival Flow */}
        <Route path='/data/archival' element={<DataArchivalSelection {...props} />} />
        <Route path='/data/archival/confirmation' element={<ArchivalTableDataPreview {...props} />} />
        <Route path='/data/archival/history' element={<DataArchivalHistory {...props} />} />
        <Route path='/data/archival/history/preview' element={<ArchivalHistoryDetails {...props} />} />
        <Route path='/data/archival/execution-summary' element={<ArchivalExecutionReport {...props} />} />

       {/* Implementing charts @Arun 02042024 */}
        <Route path='/healthcare/pre-existing' element={<PreExistingCondition />} />
        <Route path='/healthcare/stays' element={<HospitalStays />} />
        <Route path='/healthcare/claims' element={<TotalClaims />} />
        <Route path='/healthcare/diagnosis' element={<TotalDiagnosis />} />
        <Route path='/healthcare/procedures' element={<TotalProcedures />} />
        
        {/* Lazy Modules *}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
