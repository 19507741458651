import React from 'react';
import './loader.css';

const Loader = () => (
    <div className="loader-overlay">
    <div id="kt_page_loading_overlay" className="loader-wrapper">
      <div className="spinner-border text-primary" role="status"></div>
      <span className="text-gray-800 fs-6 fw-semibold mt-5">Loading...</span>
    </div>
  </div>
);

export default Loader;
