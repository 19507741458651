import React, {useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import ViewBreakdownWidget from './ViewBreakdownWidget'
import {useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../../_metronic/layout/core'
import {SCHEMA} from './Config'

// Define a type for the state object
type Rules = {
  Id: string
  rule: string
  desc: string
  stats: {
    IsBlank: string
    IsDuplicate: string
    IsUnique: string
  }
}

type ReportState = {
  fieldData: {
    totalNoOfRows: string
    totalNoOfNullRows: string
    totalNoOfUniqueRows: string
    nullPercentage: string
    errorPercentage: string
    uniquePercentage: string
  }
  selectedRule: Rules[]

  fieldName: string
}

const DP_Report_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Finance',
    path: '/process/bpms/finance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Money Remittance',
    path: '/process/bpms/finance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Data Profiling',
    path: '/process/bpms/finance/remittance/profiling',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const BusineesRuleSelectionReportBreakdown: any = (props: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {state} = location

  // Use the defined type for the state
  const reportState: ReportState | undefined = state as ReportState

  useEffect(() => {
    // props.reportState(reportState)
    props.selectedEnrichmentField(reportState)
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={DP_Report_Breadcrumbs}>Report Breakdown</PageTitle>

      {/* <div className='app-main flex-column flex-row-fluid' id=''>
        <div className='d-flex flex-column flex-column-fluid'>
          <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
            <div
              id='kt_app_toolbar_container'
              className='app-container container-xxl d-flex flex-stack'
            >
              <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
                <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                  <li className='breadcrumb-item text-muted'>
                    <a href='/brms' className='text-muted text-hover-primary'>
                      Data Intellegence
                    </a>
                  </li>
                  <li className='breadcrumb-item'>
                    <span className='bullet bg-gray-400 w-5px h-2px'></span>
                  </li>
                  <li className='breadcrumb-item text-muted'>Conventional Analytics</li>
                  <li className='breadcrumb-item'>
                    <span className='bullet bg-gray-400 w-5px h-2px'></span>
                  </li>
                  <li className='breadcrumb-item text-muted'>
                    Business Rules Engine - Report Breakdown
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <ViewBreakdownWidget data={reportState} props={props} />

      <button
        type='button'
        className='btn btn-flex btn-light-primary me-3 my-6'
        id=''
        style={{marginTop: '-20px'}}
        onClick={() => navigate('/process/bpms/finance/remittance/profiling/report')}
      >
        <i className='ki-duotone ki-exit-left fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>{' '}
        Back
      </button>

      <button
        type='button'
        className='btn btn-flex btn-primary me-3 my-6'
        id=''
        style={{marginTop: '-20px'}}
        onClick={() => {
          props.fetchBusinessRules('', {
            db: SCHEMA,
            table: props.selectedTable,
            column: reportState.fieldName,
          })
          navigate('/process/bpms/finance/remittance/enrichment')
        }}
      >
        Data Enrichment
        {/* <i className=' ki-duotone ki-exit-right fs-2'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i> */}
      </button>
    </>
  )
}

export default BusineesRuleSelectionReportBreakdown
