import React, {useEffect} from 'react'
import {create} from '@amcharts/amcharts4/core'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US'
import {
  XYChart,
  CategoryAxis,
  ValueAxis,
  ColumnSeries,
  XYCursor,
  Legend,
  LegendDataItem,
  LabelBullet,
} from '@amcharts/amcharts4/charts'

const InboundTranComponent: React.FC = () => {
  useEffect(() => {
    // Use am4core
    am4core.useTheme(am4themes_animated)

    // Create chart instance
    const chart = create('chartTrans', XYChart)
    chart.language.locale = am4lang_en_US
    chart.paddingRight = 20

       // Disable the AmCharts logo
chart.logo.disabled = true;

    // Add data
    const data = [
      {country: 'AG', value: 51448.67},
      {country: 'BB', value: 659.16},
      {country: 'CA', value: 48624.37},
      {country: 'DM', value: 686998.08},
      {country: 'GB', value: 14334.18},
      {country: 'GH', value: 104.13},
      {country: 'GY', value: 81136.02},
      {country: 'HN', value: 27529.28},
      {country: 'JM', value: 112602837.54},
      {country: 'KY', value: 167188.69},
      {country: 'PH', value: 13424.95},
      {country: 'US', value: 317687.33},
    ]
    chart.data = data

    const max = Math.max(...data.map((item) => item.value))

    // Create axes
    const categoryAxis = chart.xAxes.push(new CategoryAxis())
    categoryAxis.dataFields.category = 'country'
    categoryAxis.renderer.minGridDistance = 30
    categoryAxis.renderer.grid.template.disabled = true // Disable grid lines for x-axis
    categoryAxis.title.text = 'Country Code'

    const valueAxis = chart.yAxes.push(new ValueAxis())
    valueAxis.renderer.grid.template.disabled = false // Disable grid lines for y-axis
    valueAxis.title.text = 'Currency($)'
    valueAxis.numberFormatter.numberFormat = '$#,###' // Add $ sign to vertical axis values
    valueAxis.renderer.minGridDistance = 30
    valueAxis.strictMinMax = true
    valueAxis.min = 1 // Start from 1
    valueAxis.max = max * 10 // Adjust max value
    valueAxis.logarithmic = true // Use logarithmic scale

    // Create series
    const series = chart.series.push(new ColumnSeries())
    series.dataFields.valueY = 'value'
    series.dataFields.categoryX = 'country'
    series.name = 'Country Values'

    // Define an array of unique colors
    const colors = [
      '#FF0000',
      '#FF6347',
      '#FF4500',
      '#FF7F50',
      '#DC143C',
      '#CD5C5C',
      '#FFA07A',
      '#FF8C00',
      '#FFD700',
      '#DAA520',
      '#FFA500',
      '#FFDAB9',
    ]

    // Set different colors for each column
    series.columns.template.stroke = am4core.color('#000') // Default stroke color
    series.columns.template.strokeOpacity = 0.5 // Adjust opacity
    series.columns.template.adapter.add('fill', (fill, target) => {
      // Check if target.dataItem is defined
      if (target.dataItem) {
        return am4core.color(colors[target.dataItem.index])
      }
      // Return default fill if target.dataItem is undefined
      return fill
    })

    const labelBullet = series.bullets.push(new LabelBullet())
    labelBullet.label.text = '{valueY}'
    labelBullet.label.dy = -10 // Adjust vertical offset
    labelBullet.label.truncate = false
    labelBullet.label.numberFormatter.numberFormat = '$#,###'
    labelBullet.label.fontWeight = 'bold'
    labelBullet.label.fontSize = 12; 

    // Assign colors to each series individually
    for (let i = 0; i < colors.length; i++) {
      const column = series.columns.template
      column.adapter.add('fill', (fill, target) => {
        if (target.dataItem && i === target.dataItem.index) {
          // Set label text color to match bar color
          labelBullet.label.fill = am4core.color(colors[i])
          return am4core.color(colors[i])
        }
        return fill
      })
    }


     // Modify the tooltip text to show full country name
     series.columns.template.tooltipText = '{country}: {valueY.value}';

     // Create a map to store country codes and their corresponding full names
     const countryNames = {
       'AG': 'Argentina',
       'BB': 'Barbados',
       'CA': 'Canada',
       'DM': 'Dominica',
       'GB': 'United Kingdom',
       'GH': 'Ghana',
       'GY': 'Guyana',
       'HN': 'Honduras',
       'JM': 'Jamaica',
       'KY': 'Cayman Islands',
       'PH': 'Philippines',
       'US': 'United States'
     };
 
     // Modify the tooltip text adapter to use the full country name
     series.columns.template.adapter.add('tooltipText', (text, target) => {
       if (target.dataItem) {
         const countryCode = target.dataItem['categoryX'];
         const countryName = countryNames[countryCode];
         return `${countryName}: {valueY.value}`;
       }
       return text;
     });

    // Add vertical axis line on the left side
    const valueAxis2 = chart.yAxes.push(new ValueAxis())
    valueAxis2.renderer.line.stroke = am4core.color('#000')
    valueAxis2.renderer.line.strokeWidth = 0.3
    valueAxis2.renderer.line.strokeOpacity = 1

    // Add cursor
    chart.cursor = new XYCursor()

    // Dispose chart when component unmounts
    return () => {
      chart.dispose()
    }
  }, [])

  return <div id='chartTrans' style={{width: '100%', height: '450px'}}></div>
}

export default InboundTranComponent

