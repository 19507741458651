import React from 'react'
import RandomForestFeatures from './components/RandomForestFeatures'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import claimsFraudSummary from '../../../_metronic/assets/myimages/ClaimsFraudSummary.png'
import dataAnalysis from '../../../_metronic/assets/myimages/DataAnalysis.png'
import dataModel1 from '../../../_metronic/assets/myimages/DataModel1.png'
import dataModel2 from '../../../_metronic/assets/myimages/DataModel2.png'
import FraudulentSummaryChart from './fraudulent/FraudulentSummaryChart'

const Data_Enrich_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intellegent Analytics',
    path: '/app/intellegent-analytics',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ClaimsFraudulentChart = () => {
  return (
    <>
      <PageTitle breadcrumbs={Data_Enrich_Breadcrumbs}>Claims Fraudulent Insight</PageTitle>

      <div className='row gx-5 gx-xl-10 mb-12'>
        <div className='card card h-xl-100'>
          <div className='card-header pt-5 mb-2'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>Data Analysis</span>
            </h3>
          </div>
          <img
            src={dataAnalysis}
            alt='dataAnalysis'
            style={{width: '100%', height: '600px'}}
            // style={{maxWidth: '100%', height: '342px'}}
          />
        </div>
      </div>
      <div className='row gx-5 gx-xl-10 mb-12'>
        <div className='card card h-xl-100'>
          <div className='card-header pt-5 mb-2'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>Data Model</span>
            </h3>
          </div>
          <img
            src={dataModel1}
            alt='dataModel1'
            style={{width: '100%', height: '550px'}}
            // style={{maxWidth: '100%', height: '342px'}}
          />
        </div>
      </div>
      <div className='row gx-5 gx-xl-10 mb-12'>
        <div className='card card h-xl-100'>
          <div className='card-header pt-5 mb-2'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>Data Model</span>
            </h3>
          </div>
          <img
            src={dataModel2}
            alt='dataModel2'
            style={{width: '100%', height: '550px'}}
            // style={{maxWidth: '100%', height: '342px'}}
          />
        </div>
      </div>

      <div className='row gx-5 gx-xl-10 mb-12'>
        <div className='card card-flush h-xl-100'>
          <div className='card-header pt-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>Random Forest Model Features</span>
            </h3>
          </div>
          <RandomForestFeatures />
        </div>
      </div>

      <div className='row gx-5 gx-xl-4 mb-4'>
        <div className='col-7'>
          <div className='card card-flush pb-5'>
            <div className='card-header pt-2'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-500'>
                  Random Forest with higher weight to minority class
                </span>
              </h3>
            </div>
            <div className='card-body p-9'>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>The accuracy is</label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>0.86</span>
                </div>
              </div>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>The precision is</label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>0.38</span>
                </div>
              </div>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>The recall is</label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>0.86</span>
                </div>
              </div>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>F1-Score</label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>0.53</span>
                </div>
              </div>
              <div className='separator separator-content border-dark my-5'>
                <span className='w-80px fw-bold'>Summary</span>
              </div>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>Actual Non-Fraud</label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>1471</span>
                </div>
              </div>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>Actual Fraud</label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>152</span>
                </div>
              </div>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>Predicated Non-Fraud</label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>1280</span>
                </div>
              </div>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>Predicated Fraud</label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>343</span>
                </div>
              </div>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>
                  Predicated Non-Fraud but Fraud
                </label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>22</span>
                </div>
              </div>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>
                  Predicated Fraud but Non-Fraud
                </label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>213</span>
                </div>
              </div>

              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>Highlighting</label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>21.134  % Records</span>
                </div>
              </div>
              <div className='row mb-3'>
                <label className='col-lg-7 fw-bold text-muted'>Captured</label>
                <div className='col-lg-5'>
                  <span className='fw-bolder fs-6 text-dark'>85.526 %</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='col-5'>
          <div className='card card-flush pb-5'>
            <div className='card-header pt-2'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold text-gray-500'>
                  Distribution of Dataset for Churn Model
                </span>
              </h3>
            </div>
            <img
            src={claimsFraudSummary}
            alt='claimsFraudSummary'
            //style={{width: '100%', height: '550px'}}
            // style={{maxWidth: '100%', height: '342px'}}
          />
          </div>
        </div>
      </div>
    </>
  )
}

export default ClaimsFraudulentChart
