import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'

const data_archival_history_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Intelligent Analytics',
    path: '/data/quality',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DataArchivalHistory = (props) => {
  const navigate = useNavigate()

  const getDetails = (id) => {
    props.getDataHistoryDetails({product: 'datanxzen', archivalTaskId: `${id}`})
    return navigate('/data/archival/history/preview')
  }
  return (
    <>
      <PageTitle breadcrumbs={data_archival_history_Breadcrumbs}>Data Archival History</PageTitle>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Archival History List</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              fetching all archival execution data from audit data{' '}
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-200px rounded-start'> {'Archival Task Name'}</th>
                  <th className='min-w-150px'>{'Table Name '}</th>
                  <th className='min-w-125px'>{'Archival TaskId'}</th>
                  <th className='min-w-100px'>{'Archived Record Count'}</th>
                  {/* <th className='min-w-150px'>{'Execution End Time'}</th>
                  <th className='min-w-150px'>{'Execution Start Time'}</th> */}
                  <th className='min-w-100px'>{'Execution Time'}</th>
                  <th className='min-w-20px text-end rounded-end'></th>
                </tr>
              </thead>
              <tbody>
                {props?.dataHistory?.map((t) => (
                  <tr>
                    <td>
                      <div className='d-flex align-items-center ps-4 '>
                        <div className='d-flex justify-content-start flex-column'>
                          <div className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {t?.archivalTaskName}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {t?.archivalTableName}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {t?.archivalTaskId}
                      </div>
                    </td>
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                        {t?.archivedRecordCount}
                      </div>
                    </td>
                    {/* <td>
                      <span className='badge badge-light-primary fs-7 fw-semibold'>
                        {t?.executionEndTime}
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-light-danger fs-7 fw-semibold'>
                        {t?.executionStartTime}
                      </span>
                    </td> */}
                    <td>
                      <div className='text-dark fw-bold text-hover-primary d-block mb-1'>
                        {t?.executionTime}
                      </div>
                    </td>
                    <td className='text-end'>
                      <div
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                        onClick={() => getDetails(t?.archivalTaskId)}
                      >
                        <KTIcon iconName='switch' className='fs-3' />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-end align-items-center mt-4'>
          <button
            type='button'
            className='btn btn-sm btn-primary'
            onClick={() => navigate('/data/archival')}
          >
            <i className='ki-duotone ki-arrow-left fs-2 ms-1 me-0'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            Back
          </button>
        </div>
    </>
  )
}

export default DataArchivalHistory
