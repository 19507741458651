import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import DatabaseStructure from './component/DatabaseStructure'
import RuleBookStructure from './component/RuleBookStructure'
import UserSelectionStructure from './component/UserSelectionStructure'

const Data_Profiling_Breadcrumbs: Array<PageLink> = [
  {
    title: 'Finance',
    path: '/process/bpms/finance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
  {
    title: 'Money Remittance',
    path: '/process/bpms/finance',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const DataProfiling: any = (props) => {
  return (
    <>
      <PageTitle breadcrumbs={Data_Profiling_Breadcrumbs}>Data Profiling</PageTitle>
      <div className='row g-5 g-xl-8'>
        {/* Database Section  */}
        <div className='col-xl-4'>
          <DatabaseStructure {...props} />
        </div>
        <div className='col-xl-4'>
          <RuleBookStructure {...props} />
        </div>
        <div className='col-xl-4'>
          <UserSelectionStructure {...props} />
        </div>
      </div>
    </>
  )
}

export default DataProfiling
